import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
export const configJSON = require("./config");
import {getStorageData} from "../../../framework/src/Utilities"
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { ChangeEvent, MouseEvent } from "react";
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  match: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  individualbuilding : string
  selectCountry: string[]
  selectedCountry: string
  selectCity: {[key:string]:string}[]
  selectComplex:string[]
  userProfileData:any[]
  selectBuilding: string[]
  selectUnit: string[]
  selectedUnit:string
  selectUserType: string
  token:string;
  selectCompany : string[]
  selectedCompany: string
  selectedCity: string
  selectedComplex: string
  selectedBuilding:string
  userProfile:any;
  ViewAll: boolean;
  sliceLength: number;
  title: string;
  loading: boolean;
  topMenu: string[];
  duplicateData: any;
  userInfo : any
  unitNumber:string
  floorNumber :string
  ownerID :string
  name:string
  size:string
  configuration:string
  purchasePrice : string
  purchaseDate: string
  residentName: string
  crrentValuation : string
  rentStatus : string
  tenantName : string
  addAnother : boolean
  selectedImages :any[]
  currentTab: 'gallery' | 'userDetail' | 'addUnit' | 'viewUserProfile';
  unitStatu:string
  rentStatu: string
  open:boolean
  isSuspended:boolean
  detailForm:boolean
  familiesName : string
  relation:string
  relations:any[]
  nationalID:string
  national : any[]
  famliyId:string
  avatarImage:any
  imageFile:string
  menu:null
  anchorEl: HTMLButtonElement | null;
  editData:any
  editId: string
  deleteId:string
  moreAnchorEl: HTMLButtonElement | null
  viweEl:HTMLButtonElement | null
  addTenant:boolean
  viwePage:boolean
  email:string
  tenantData:any
  passport: string
  phoneNumber: string
  idNumber:string
  findPerson:any
  rentAmount:string
  fromDate:string
  toDate: string
  tenantId:string
  buildingId : string
  isSelect:boolean
  nameSelected:string
  suspendUser:boolean
  successModel:boolean,
  selectedTab:string,
  NameError:boolean,
  relationError:boolean,
  nationalError:boolean,
  idError:boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CECommunityManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCountryApiId: string = ""
  getCompnyApiId: string = ""
  getCityApiId:string = ""
  getComplexApiId: string = ""
  getUserProfileDataApiId: string = ""
  getBuildingApiId: string = ""
  getUserProfileApiId:string = ""
  getUnitApiId: string = ""
  addUserProfileApiId : string =""
  familiesApiId: string = ""
  editFamiliesApiId: string = ""
  getRelationsApiId : string = ""
  getIdProofApiId: string = ""
  deleteFamilyApiId: string =""
  addTenantApiId: string =""
  findPesronApiId: string = ""
  addAnotherUnitApiId: string = ""
  suspsendUserApiId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.state = {
      avatarImage: '/broken-image.jpg',
      individualbuilding: "",
      selectCountry: [],
      selectedCountry: "",
      selectCity: [],
      userProfileData:[],
      selectComplex: [],
      selectBuilding: [],
      selectUnit: [],
      selectUserType: "",
      token: "",
      selectCompany: [],
      selectedCompany: "",
      selectedCity: "",
      selectedComplex: "",
      selectedBuilding: "",
      userProfile: {},
      duplicateData: {},
      selectedUnit: "",
      ViewAll: false,
      sliceLength: 4,
      title: "Community Management",
      loading: false,
      topMenu: ["Community Management", "User Profiles"],
      userInfo: null,
      unitNumber: "",
      floorNumber: "",
      ownerID: "",
      name:"",
      size:"",
      configuration:"",
      purchasePrice: "",
      purchaseDate: "",
      residentName: "",
      crrentValuation: "",
      rentStatus : "",
      tenantName:"",
      addAnother:false,
      selectedImages : [],
      currentTab: "gallery",
      unitStatu : "",
      rentStatu: "",
      open:false,
      isSuspended:false,
      detailForm:false,
      familiesName : "",
      relation:"",
      relations:[],
      nationalID: "",
      national:[],
      famliyId: "",
      imageFile:"",
      menu:null,
      anchorEl:null,
      editData:{},
      editId:"",
      deleteId: "",
      moreAnchorEl:null,
      viweEl:null,
      addTenant: false,
      viwePage:false,
      email:"",
      tenantData:[],
      passport: "",
      phoneNumber: "",
      idNumber:"",
      findPerson:{},
      rentAmount:"",
      fromDate:"",
      toDate: "",
      tenantId: "",
      buildingId: "",
      isSelect:false,
      nameSelected:"",
      suspendUser: false,
      successModel:false,
      selectedTab:"",
      NameError:false,
      relationError:false,
      nationalError:false,
      idError:false

    };
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleMultiImageChange = this.handleMultiImageChange.bind(this)
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if(apiRequestCallId === this.getCountryApiId) {
        this.setState({selectCountry:responseJson.data.countries})        
      } else if (apiRequestCallId === this.getCompnyApiId) {
        this.setState({selectCompany: responseJson.compneys})
      } else if (apiRequestCallId === this.getCityApiId){
        this.setState({selectCity:responseJson})
      } else if (apiRequestCallId === this.getComplexApiId) {
        this.setState({selectComplex:responseJson.societies})
      } else if (apiRequestCallId === this.getBuildingApiId) {
        this.setState({selectBuilding:responseJson.data.building_list})
      } else if (apiRequestCallId === this.getUserProfileApiId) {
        this.updateGetData(responseJson)
        this.setState({userProfile:responseJson, duplicateData: responseJson, loading: false, })
      } else if (apiRequestCallId === this.getUnitApiId) {
        this.setState({selectUnit:responseJson.data.apartment_list || []})
      } else if (apiRequestCallId === this.getRelationsApiId) {
        this.setState({relations:responseJson.relaions})
      } else if (apiRequestCallId === this.getIdProofApiId) { 
        this.setState({national:responseJson.relaions})
      } else if (apiRequestCallId === this.familiesApiId) {
        this.handleUserProfileApi()   
      } else if (apiRequestCallId === this.deleteFamilyApiId) {
          this.handleUserProfileApi()          
      } 
      this.handleReciver(responseJson,apiRequestCallId)
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    let token  = await getStorageData("loginSuccessToken");
    const { id } = this.props.match.params;
    if(id){
      this.setState({currentTab:"viewUserProfile"})
    }
    this.setState({token:token})
    this.handleCompnyApi()
    this.handleBuildingApi()
    this.handleUserProfileApi()
    this.handleUnitApi()
    this.handleCountryApi()
    this.handleRelationsApi()
    this.handleIdProofApi()
  } 

  handleCountryFilterChange = (event: ChangeEvent<{ value: unknown | string}>) => {
    const { value } = event.target;
    if(value === "Complex"){
      this.handleComplexApi()
    }
    this.setState({individualbuilding:value as string})
};

  handleReciver = (responseJson:any,apiRequestCallId:string) => {
     if (apiRequestCallId === this.addTenantApiId){
      this.setState({tenantData:responseJson.data})
    } else if (apiRequestCallId === this.findPesronApiId) {
      this.setState({findPerson:responseJson.data})
    } else if (apiRequestCallId === this.addAnotherUnitApiId){
      this.handleUserProfileApi()
    } else if(apiRequestCallId === this.editFamiliesApiId){
      this.handleUserProfileApi()
    } else if (apiRequestCallId === this.suspsendUserApiId){
      this.handleUserProfileApi()
      this.setState({currentTab:"gallery"})
    }
  }

handleUnitStatu = (event: ChangeEvent<{value: unknown | string}>) => {
  const { value } = event.target;
  this.setState({unitStatu:value as string})
};

handleRentStatu = (event:ChangeEvent<{ value: unknown | string}>) => {
  const { value } = event.target;
  this.setState({rentStatu:value as string})
}

  handleMenuBar = (index: number, tag: string) => {

    switch (index) {
      case 1:
        this.setState({ topMenu: this.state.topMenu.slice(0, index + 1), userProfile: this.state.duplicateData, sliceLength: 4, ViewAll: false, title: "Community Management" , currentTab:"gallery"})
        break;

      case 2:
        this.setState({ topMenu: this.state.topMenu.slice(0, index + 1), title: tag ,currentTab:"userDetail"})
        break;

    }

  }

  handleSelectCountryChange = (event: ChangeEvent<{ value: unknown | string}>) => {
    const { value } = event.target;
    const values = {
      country: value ? value : "",
      city: this.state.selectedCity
    }
    this.handleComplexApi(values as {
      country:string,
      city:string
    })
  
    this.setState({selectedCountry:value as string,selectedCity:""},()=>{this.handleCityApi()})
};

handleClick = (event:  MouseEvent<HTMLButtonElement>, index?:any) => {
  this.setState({ anchorEl: event.currentTarget,
    editId: index?.id,
    editData:index,
    deleteId:index?.id
  });
};

handleViweDetails = (event:any) => {
  this.setState({
    viweEl: event.currentTarget
})
}

handleMoreClick = (event: MouseEvent<HTMLButtonElement>) => {
  this.setState({ moreAnchorEl: event.currentTarget});
}
handleClose = () => {
  this.setState({ anchorEl: null, moreAnchorEl:null });
};

  handleSelectCompanyChange = (event: ChangeEvent<{ value: unknown | string}>) => {
    const { value } = event.target;    
    this.handleCountryApi(value as string)  
    this.setState({ selectedCompany: value as string})
  };

handleSelectCityChange = (event: ChangeEvent<{ value: unknown | string}>) => {
  const { value } = event.target;

  const values = {
    country: this.state.selectedCountry,
    city: value ? value :""
  }
  this.handleComplexApi(values as {
    country:string,
    city:string
  })  
  this.setState({selectedCity:value as string})
};

handleSelectComplexChange = (event: ChangeEvent<{ value: unknown | string}>) => {
  const { value } = event.target;  
  this.handleBuildingApi(value)
  this.setState({selectedComplex:value as string})
};

handleSelectBuildingChange = (event: ChangeEvent<{ value: unknown | string}>) => {
  const { value } = event.target;
  this.handleUnitApi(value  as string)
  this.setState({selectedBuilding: value as string, buildingId:value as string})
};

  updateGetData = (response: any) => {
    let userData = { ...this.state.userInfo }
    const findData = response[this.state.selectedTab]?.data?.find((item: {
      attributes: any; id: any;
    }) => item.id === userData.id)
    if (findData?.id) {
      this.setState({ userInfo: findData })
    }
  }
  
handleSelectUnitChange = (event: ChangeEvent<{ value: unknown | string}>) => {
  const { value } = event.target;
  this.setState({selectedUnit:value as string})
};

handleSelectUserTypeChange = (event: ChangeEvent<{ value: unknown | string}>) => {
  const { value } = event.target;
  this.setState({selectUserType:value as string})
};

handleSearch = () => {
  this.handleUserProfileApi()
}

  handkeViewAll = (item: string , keys: string, title: string) => {
    const menu = this.state.topMenu
    menu.push(title)
    this.setState({ sliceLength: item?.length, ViewAll: true, title, userProfile: { [keys]: { data: item } }, topMenu: menu })
  }


  handleCard = (index: { attributes: { full_name: { name: string; }; }; } , titel:string , tab:string) => {    
    let menu = this.state.topMenu
    menu = [...menu , index.attributes?.full_name.name]
    this.setState({userInfo:index,currentTab:"userDetail" , topMenu:menu , title:titel ,selectedTab:tab})
  }

handleCountryApi = (value?: string) => {
  this.setState({loading: true})
  const params = value ? `?company_id=${value}` : ""
  const header = {
    "Content-Type": configJSON.dashboarContentType,
    token:this.state.token
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getCountryApiId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.countryApiEndpoint + `${params}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.dashboarApiMethodType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
}

handleCompnyApi = () => {
  this.setState({loading: true})
  const header = {
    "Content-Type": configJSON.dashboarContentType,
    token:this.state.token
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getCompnyApiId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.compnyApiEndpoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.dashboarApiMethodType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
}

handleCityApi = () => {
  const header = {
    "Content-Type": configJSON.dashboarContentType,
    token:this.state.token
  };
  const params = `?country_name=${this.state.selectedCountry}`

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getCityApiId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
   `bx_block_address/city_by_state${params}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.dashboarApiMethodType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
}

handleComplexApi = (value?:{
  country:string,
  city:string
}) => {
  const header = {
    "Content-Type": configJSON.dashboarContentType,
    token:this.state.token
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  const params = value ? `?country=${value.country}&city=${value.city}` : ""

  this.getComplexApiId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.complexApiEndpoin + `${params}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.dashboarApiMethodType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
}

handleBuildingApi = (value?: unknown) => {
  this.setState({loading: true})

  const header = {
    "Content-Type": configJSON.dashboarContentType,
    token:this.state.token
  };
  const params = value ? `?complex_id=${value}` : ""

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getBuildingApiId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.buildingApiEndpoin + `${params}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.dashboarApiMethodType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
}

handleUnitApi = (value?: string) => {
  this.setState({loading: true})
  const header = {
    "Content-Type": configJSON.dashboarContentType,
    token:this.state.token
  };

  const params = value ? `?building_id=${value}` : ""

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getUnitApiId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.unitApiEndPoint + `${params}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.dashboarApiMethodType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
}

handleUserProfileApi = (value?:string) => {
  
  const { selectedCity, selectedCompany, selectedComplex, selectedBuilding, selectedUnit, selectedCountry ,selectUserType } = this.state
  let userFilters: any = {
    city: selectedCity,
    company_id: selectedCompany === "all" ? "" : selectedCompany,
    complex_id: selectedComplex === "all" ? "" : selectedComplex,
    building_id: selectedBuilding === "all" ? "" : selectedBuilding,
    apartment_id: selectedUnit === "all" ? "" : selectedUnit,
    country: selectedCountry === "all" ? "" : selectedCountry,
    user_type : selectUserType === "all" ? "" : selectUserType,
    q:value
  }
  if(this.state.userInfo?.id){
    userFilters = {}
  }
  const header = {
    "Content-Type": configJSON.dashboarContentType,
    token:this.state.token
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getUserProfileApiId = requestMessage.messageId;
  let initialEndPoint = `${configJSON.userProfileApiEndPoint}?`;
  let isFirstParam = true;
  
  for (let filter in userFilters) {
    if (userFilters[filter] && userFilters[filter] !== 'All') {
      if (!isFirstParam) {
        initialEndPoint += '&';
      }
      initialEndPoint += `${filter}=${userFilters[filter]}`;
      isFirstParam = false;
    }
  }

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    initialEndPoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.dashboarApiMethodType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
}

  handleUnitNumber = (event: ChangeEvent<{ value: unknown | string}>) => {
    this.setState({ unitNumber: event.target.value as string })
  }

  handleFloorNumber = (event:ChangeEvent<{ value: unknown | string}>) => {
    this.setState({floorNumber:event.target.value as string})
  }

  handleOwnerID = (event:ChangeEvent<{ value: unknown | string}>) => {
    this.setState({ownerID:event.target.value as string})
  }

  handleSize = (event:ChangeEvent<{ value: unknown | string}>) => {
    this.setState({size:event.target.value as string})
  }
 
  handleConfiguration = (event:ChangeEvent<{ value: unknown | string}>) => {
    this.setState({configuration:event.target.value as string})
  }

  handlePurchasePrice = (event:ChangeEvent<{ value: unknown | string}>) => {
    this.setState({purchasePrice:event.target.value as string})
  } 

  handlePurchaseDate = (event:ChangeEvent<{ value: unknown | string}>) => {
    this.setState({purchaseDate:event.target.value as string})
  }

  handleCurrentValuation = (event:ChangeEvent<{ value: unknown | string}>) => {
    this.setState({crrentValuation:event.target.value as string})
  }

  handleRentAmount = (event:ChangeEvent<{ value: unknown | string}>) => {
    this.setState({rentAmount:event.target.value as string})
  }

  handleAddAnother = (titel:string) => {
    let menu = this.state.topMenu
    menu = [...menu , titel]
    this.setState({currentTab:"addUnit",topMenu:menu , title:titel})
  }

  handleFilter = (value:ChangeEvent<{ value: unknown | string}>) => {
      this.handleUserProfileApi(value as unknown as string);
  }

  handleDialogClose = () => {
    this.setState({ open: false });
  };

  handleImageChange(event:any) {
    const file = event.target.files[0];
    this.setState({imageFile:file})
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        this.setState({
          avatarImage: reader.result
        });
      };
      reader.readAsDataURL(file);
    }
  }

  handleMultiImageChange(event: any) {
    const files = event.target.files;
    if (files) {
      const fileList = Array.from(files);
      
      fileList.forEach((file:any) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          this.setState((prevState) => ({
            selectedImages: [...prevState.selectedImages, reader.result],
          }));
        };
        reader.readAsDataURL(file);
      });
    }
  }

  handleDeleteImage(index:number) {
    this.setState(prevState => ({
      selectedImages: prevState.selectedImages.filter((_, i) => i !== index)
    }));
  }
  
  
  addfamiliesApi = () => {
    const header = {
      token: this.state.token
    };
      let formdata = new FormData();
      formdata.append("name", this.state.familiesName);
      formdata.append("relation_id", this.state.relation.toString());
      formdata.append("id_proof_id", this.state.nationalID.toString());
      formdata.append("id_number", this.state.famliyId.toString());
      { this.state.imageFile && formdata.append("image", this.state.imageFile)}      
      formdata.append("account_id", this.state.userInfo?.attributes?.id?.toString())

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.familyApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formdata
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPost
      );
      this.familiesApiId = requestMessage.messageId;
      runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleFromDate = (event:ChangeEvent<{ value: unknown | string}>) => {
    this.setState({fromDate:event.target.value as string})
  }

  handleToDate = (event:ChangeEvent<{ value: unknown | string}>) => {
    this.setState({toDate:event.target.value as string})
  }

  addAddAnotherUnitApi = () => {
    const header = {
      token: this.state.token
    };
    
    const reoles =  this.state.userInfo?.attributes?.role?.map((item:{name:string})=> item?.name)

      let formdata = new FormData();
      formdata.append("[data][role]", reoles);
      formdata.append("[data][apartment_name]", this.state.unitNumber);
      formdata.append("[data][building_management_id]", this.state.buildingId);
      formdata.append("[data][tenant_id]", this.state.tenantId);
      formdata.append("[data][rent_amount]", this.state.rentAmount)
      formdata.append("[data][start_date]", this.state.fromDate)
      formdata.append("[data][expires_on]", this.state.toDate);
      formdata.append("[data][account_id]", this.state.ownerID);
      formdata.append("[data][floor_number]", this.state.floorNumber);
      formdata.append("[data][size]", this.state.size);
      formdata.append("[data][purchase_price]", this.state.purchasePrice)
      formdata.append("[data][configuration]", this.state.configuration)
      formdata.append("[data][purchase_date]", this.state.purchaseDate)
      formdata.append("[data][current_valuation]", this.state.crrentValuation)
      formdata.append("data[unit_type]", this.state.unitStatu)

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.addAnotherUnitEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formdata
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPost
      );
      this.addAnotherUnitApiId = requestMessage.messageId;
      runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleSubmit = () => {
    this.addAddAnotherUnitApi()
    const topmanu = [...this.state.topMenu]
    topmanu.splice(3,1)
    this.setState({
      currentTab:"userDetail" , 
      topMenu: topmanu,
      title: topmanu[topmanu.length - 1],
      unitNumber: "",
      buildingId: "",
      tenantId: "",
      rentAmount: "",
      fromDate: "",
      toDate: "",
      ownerID: "",
      floorNumber: "",
      size: "",
      purchasePrice: "",
      configuration: "",
      purchaseDate: "",
      crrentValuation: "",
      unitStatu: "",
    })

  }

  handleEditData = () => {   
    this.setState({ 
      open: true, 
      anchorEl: null, 
      familiesName: this.state.editData?.name,
      relation: this.state.editData?.relation?.id,
      nationalID:this.state.editData?.id_proof?.id,
      famliyId:this.state.editData?.id_number,
    })
  }

  editfamiliesApi = () => {
    const header = {
      token: this.state.token
    };
      let formdata = new FormData();
      formdata.append("name", this.state.familiesName);
      formdata.append("relation_id",this.state.relation);
      formdata.append("id_proof_id",this.state.nationalID);
      formdata.append("id_number", this.state.famliyId);
{ this.state.imageFile &&     formdata.append("image", this.state.imageFile)}      
formdata.append("account_id", this.state.userInfo?.attributes?.id.toString())

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.editFamilyEndPoint}${this.state.editId}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formdata
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPut
      );
      this.editFamiliesApiId = requestMessage.messageId;
      runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleFamliyName = (event:ChangeEvent<{ value: unknown | string}>) => {
    this.setState({familiesName:event.target.value as string, NameError:false})
  }

  handleAddFamliy = () => {
    if(this.state.familiesName === ""){
      this.setState({NameError:true})
    } else if (this.state.relation === "") {
      this.setState({relationError:true})
    } else if (this.state.nationalID === "") {
      this.setState({nationalError:true})
    } else if (this.state.famliyId === ""){
      this.setState({idError:true})
    } else if (this.state.editId){
      this.editfamiliesApi()
      this.setState({open:false})

    } else {
      this.addfamiliesApi()
      this.setState({open:false})
    }
    
  }

  handleRelationsApi = () => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token:this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getRelationsApiId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.relationsApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  deleteFamilyApi = () => {

    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token:this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteFamilyApiId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteFamilyApiEndPoint}${this.state.deleteId}`

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDelete
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleRelationsChange = (event: ChangeEvent<{ value: unknown | string}>) => {
    const { value } = event.target;
    this.setState({ relation: value as string, relationError:false})
  }

  handleIdProofApi = () => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token:this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getIdProofApiId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.idProofApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleNationalIDChange = (event: ChangeEvent<{ value: unknown | string}>) => {
    const { value } = event.target;
    this.setState({ nationalID: value as string,nationalError:false})
  }

  handleID = (event:ChangeEvent<{ value: unknown | string}>) => {
    this.setState({famliyId:event.target.value as string , idError:false})
  }

  handleView = (index:string) => {
    this.setState({viwePage:true,addTenant:false,viweEl:null , })
    this.findPersonApi(index)
}

addTenantApi = () => {
  const header = {
    "Content-Type": configJSON.dashboarContentType,
    token:this.state.token
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.addTenantApiId = requestMessage.messageId;
  const queryParams = {
    email: this.state.email,
    id_number: this.state.idNumber,
    phone_number: this.state.phoneNumber,
    passport: this.state.passport
  };
  
  const queryParamsString = Object.entries(queryParams)
    .filter(([key, value]) => value !== undefined && value !== null && value !== "")
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join("&");

    const url = `${configJSON.addTenantApiEndPoint}${queryParamsString.length > 0 ? '?' + queryParamsString : ''}`;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    url
    
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.dashboarApiMethodType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
}
handleChange = (event:ChangeEvent<{ value: unknown | string}>) => {
  const {  value } = event.target;
  this.setState({
    email:value as string
  });
}

handleChangePassport = (event:ChangeEvent<{ value: unknown | string}>) => {
  const {  value } = event.target;
  this.setState({
    passport:value as string
  });
}

handleChangePhone = (event:ChangeEvent<{ value: unknown | string}>) => {
  const {  value } = event.target;
  this.setState({
    phoneNumber:value as string
  });
}

handleIdNumber = (event:ChangeEvent<{ value: unknown | string}>) => {
  const {  value } = event.target;
  this.setState({
    idNumber:value as string
  });
}

findPersonApi = (index:string) => {
  const header = {
    "Content-Type": configJSON.dashboarContentType,
    token:this.state.token
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.findPesronApiId = requestMessage.messageId;


  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.findPersonApiEndPoit}?id=${index}`
    
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.dashboarApiMethodType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
}

suspendUserApi = (index:string) => {
  const header = {
    "Content-Type": configJSON.dashboarContentType,
    token:this.state.token
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.suspsendUserApiId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.suspendUserApiEndPoint}?id=${index}`
    
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.httpPut
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
}

handleSuspendUser = (index:string) => {
  const topmanu = [...this.state.topMenu]
  topmanu.splice(2,1)
  this.suspendUserApi(index)
  this.setState({successModel:true,suspendUser:false,topMenu:topmanu , title:topmanu[topmanu.length -1]})
}

  // Customizable Area End
}
