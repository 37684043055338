import React from "react";
import {
  Select,
  Box,
  MenuItem,
} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { languageCondition } from "../../../components/src/ConditionManager.web";

interface SelectFilterTable {
  label: string,
  testId: string,
  filterState: string,
  listState: string,
  selectStyle: string,
  selectFormStyle: string
  allOption?: boolean
}

const accountFilterpropertyTable=
  [

    {
      label: 'Select Plan',
      testId: 'planList',
      filterState: 'planFilter',
      listState: 'planList',
      selectStyle: 'selectForm',
      selectFormStyle: 'formControl'
    },
    {
      label: 'Select Status',
      testId: 'statusList',
      filterState: 'statusFilter',
      listState: 'statusList',
      selectStyle: 'selectForm',
      selectFormStyle: 'formControl'
    },
  ]


const selectFiltersTable: any = {
  'documents_filter':[
    {
      label: 'Select Country',
      allOption: true,
      testId: 'countryList',
      filterState: 'countryFilter',
      listState: 'countriesList',
      selectStyle: 'selectForm',
      selectFormStyle:'selectFormControl'
    },
    {
      label: 'Select City',
      testId: 'cityList',
      filterState: 'cityFilter',
      listState: 'cityList',
      selectStyle: 'selectForm',
      selectFormStyle:'selectFormControl',
      allOption: true,
    },
    {
      label: 'Select Complex',
      testId: 'complexList',
      filterState: 'complexFilter',
      listState: 'complexList',
      selectStyle: 'selectForm',
      selectFormStyle:'selectFormControl'
    },
    {
      label: 'Select Building',
      testId: 'buildingList',
      filterState: 'buildingFilter',
      listState: 'buildingList',
      selectStyle: 'selectForm',
      selectFormStyle:'selectFormControl'
    },
  ],
  'annoucementsFilter': [
    {
      label: 'Select Country',
      testId: 'countryList',
      filterState: 'countryFilter',
      listState: 'countryList',
      selectStyle: 'selectForm',
      selectFormStyle: 'formControl',
      allOption: true
    },
    {
      label: 'Select City',
      testId: 'cityList',
      filterState: 'cityFilter',
      listState: 'cityList',
      selectStyle: 'selectForm',
      selectFormStyle: 'formControl',
      allOption: true
    },
    {
      label: 'Select Complex',
      testId: 'complexList',
      filterState: 'complexFilter',
      listState: 'complexList',
      selectStyle: 'selectForm',
      selectFormStyle: 'formControl',
      allOption: true
    },
    {
      label: 'Select Building',
      testId: 'buildingList',
      filterState: 'buildingFilter',
      listState: 'buildingList',
      selectStyle: 'selectForm',
      selectFormStyle: 'formControl',
      allOption: true
    },
    {
      label: 'Select Category',
      testId: 'categoryList',
      filterState: 'categoryFilter',
      listState: 'categoryList',
      selectStyle: 'selectForm',
      selectFormStyle: 'formControl',
      allOption: true
    },
    {
      label: 'Select Year',
      testId: 'yearList',
      filterState: 'yearFilter',
      listState: 'yearList',
      selectStyle: 'selectForm',
      selectFormStyle: 'formControl',
      allOption: true
    },
  ],
  'dropdown_filter': [
    {
      label: 'Sort By',
      testId: 'sortByList',
      filterState: 'sortBy',
      listState: 'dropDownData',
      selectStyle: 'selectFormSortBy',
      selectFormStyle: 'formControlSortBy'
    },
  ],
  'property_developer': [

    {
      label: 'Select Plan',
      testId: 'planList',
      filterState: 'planFilter',
      listState: 'planList',
      selectStyle: 'selectForm',
      selectFormStyle: 'formControl'
    },
    {
      label: 'Select Country',
      testId: 'countryList',
      filterState: 'countryFilter',
      listState: 'countryList',
      selectStyle: 'selectForm',
      selectFormStyle: 'formControl'
    },
    {
      label: 'Ends In',
      testId: 'endsInList',
      filterState: 'endsInFilter',
      listState: 'endsInList',
      selectStyle: 'selectForm',
      selectFormStyle: 'formControl'
    },
    {
      label: 'Select Status',
      testId: 'statusList',
      filterState: 'statusFilter',
      listState: 'statusList',
      selectStyle: 'selectForm',
      selectFormStyle: 'formControl'
    },
  ],
  'general_assembly': [
    {
      label: 'Select Country',
      testId: 'countryList',
      filterState: 'countryFilter',
      listState: 'countryList',
      selectStyle: 'selectForm',
      selectFormStyle: 'formControl'
    },
    {
      label: 'Select City',
      testId: 'cityList',
      filterState: 'cityFilter',
      listState: 'cityList',
      selectStyle: 'selectForm',
      selectFormStyle: 'formControl'
    },
    {
      label: 'Select Complex',
      testId: 'complexList',
      filterState: 'complexFilter',
      listState: 'complexList',
      selectStyle: 'selectForm',
      selectFormStyle: 'formControl'
    },
    {
      label: 'Select Building',
      testId: 'buildingList',
      filterState: 'buildingFilter',
      listState: 'buildingList',
      selectStyle: 'selectForm',
      selectFormStyle: 'formControl'
    },
    {
      label: 'Select Plan',
      testId: 'planList',
      filterState: 'planFilter',
      listState: 'planList',
      selectStyle: 'selectForm',
      selectFormStyle: 'formControl'
    },
    {
      label: 'Ends In',
      testId: 'endsInList',
      filterState: 'endsInFilter',
      listState: 'endsInList',
      selectStyle: 'selectForm',
      selectFormStyle: 'formControl'
    },
    {
      label: 'Select Status',
      testId: 'statusList',
      filterState: 'statusFilter',
      listState: 'statusList',
      selectStyle: 'selectForm',
      selectFormStyle: 'formControl'
    },
  ],
  'owner': [
    {
      label: 'Select Country',
      testId: 'countryList',
      filterState: 'countryFilter',
      listState: 'countryList',
      selectStyle: 'selectForm',
      selectFormStyle: 'formControl'
    },
    {
      label: 'Select City',
      testId: 'cityList',
      filterState: 'cityFilter',
      listState: 'cityList',
      selectStyle: 'selectForm',
      selectFormStyle: 'formControl'
    },
    {
      label: 'Select Plan',
      testId: 'planList',
      filterState: 'planFilter',
      listState: 'planList',
      selectStyle: 'selectForm',
      selectFormStyle: 'formControl'
    },
    {
      label: 'Ends In',
      testId: 'endsInList',
      filterState: 'endsInFilter',
      listState: 'endsInList',
      selectStyle: 'selectForm',
      selectFormStyle: 'formControl'
    },
    {
      label: 'Select Status',
      testId: 'statusList',
      filterState: 'statusFilter',
      listState: 'statusList',
      selectStyle: 'selectForm',
      selectFormStyle: 'formControl'
    },
  ],
  'property_manager': [
    {
      label: 'Select Country',
      testId: 'countryList',
      filterState: 'countryFilter',
      listState: 'countryList',
      selectStyle: 'selectForm',
      selectFormStyle: 'formControl'
    },
    {
      label: 'Select City',
      testId: 'cityList',
      filterState: 'cityFilter',
      listState: 'cityList',
      selectStyle: 'selectForm',
      selectFormStyle: 'formControl'
    },
    {
      label: 'Select Plan',
      testId: 'planList',
      filterState: 'planFilter',
      listState: 'planList',
      selectStyle: 'selectForm',
      selectFormStyle: 'formControl'
    },
    {
      label: 'Ends In',
      testId: 'endsInList',
      filterState: 'endsInFilter',
      listState: 'endsInList',
      selectStyle: 'selectForm',
      selectFormStyle: 'formControl'
    },
    {
      label: 'Select Status',
      testId: 'statusList',
      filterState: 'statusFilter',
      listState: 'statusList',
      selectStyle: 'selectForm',
      selectFormStyle: 'formControl'
    },
  ],
}

export const statusMapping: any = {
  "extend_request": "Extend Request",
  "suspend": "Suspend",
  "deactive": "Deactive",
  "active": "Active",
  "pending": "Pending",
}

export const userRoleMapping: any = {
  "Property Developers": "property_developer",
  "General Assembly": "general_assembly",
  "Owners": "owner",
  "Property Managers": "property_manager",
}


export const AnnouncementsFilter = (props: any) => {
  const { classComponent, filterList, t, i18n,userrole } = props;
  console.log(classComponent,"classComponentData")
  const { language } = i18n;
if(userrole === "account_manager" ){
  selectFiltersTable["property_developer"] = accountFilterpropertyTable;
}


  const renderValue = (testId: string, value: any) => {
    switch (testId) {
      case 'countryList': {
        return value
      }
      case 'categoryList': {
        return value.id
      }
      case 'buildingList': {
        return value.id
      }
      case 'complexList': {
        return value.id
      }
      case 'cityList': {
        return value.name
      }
      case 'sortByList': {
        return value.value
      }
      case 'statusList': {
        return value.value
      }
      case 'endsInList': {
        return value.value
      }
      case 'planList': {
        return value.id
      }
      default: {
        return value
      }
    }
  }
  const renderOptional = (testId: string, value: any) => {
    switch (testId) {
      case 'countryList': {
        return value
      }
      case 'categoryList': {
        return value?.attributes?.category_title
      }
      case 'buildingList': {
        return value.name
      }
      case 'complexList': {
        return value.attributes?.name
      }
      case 'cityList': {
        return value.name
      }
      case 'sortByList': {
        return value.label
      }
      case 'endsInList': {
        return value.label
      }
      case 'statusList': {
        return value.label
      }
      case 'planList': {
        return value.attributes.title
      }
      default: {
        return value
      }
    }
  }

  const iconComponent = (props: any) => (
    <ExpandMoreIcon style={webStyle.expandIcon} />
  );
  return <>
    {
      selectFiltersTable[filterList].map((select: SelectFilterTable, i: number) => {
        return (
          <Box className="filter-filter" id="ChairmanInvoices">
            <Select
              data-testid={select.testId}
              label={select.label}
              // table_class_select text_capital_select 
              className={`select-input selectBuildingForChairmanInvoices ${select.filterState == 'sortBy' ? 'bold-text' :classComponent.state[select.filterState] ? '' : 'filter_select_color_announcement'}`}
              IconComponent={iconComponent}
              displayEmpty
              style={classComponent.state[select.filterState] === 'announcement_by' || classComponent.state[select.filterState] === 'announcement_on' ? webStyle.selectFormSortByBig : webStyle[select.selectStyle]}
              //@ts-ignore
              value={classComponent.state[select.filterState]}
              onChange={(e) => classComponent.handleFilterChange(e, select.filterState)}
              inputProps={{
                name: select.testId,
                id: 'outlined-age-native-simple',
              }}
            >
              <MenuItem value="" disabled style={{ color: '#848484' }}>
                {t(select.label)}
              </MenuItem>
                <MenuItem value={"all"}>All</MenuItem>
              {classComponent.state[select.listState].length &&
                classComponent.state[select.listState].map((item: any, i: number) => {
                  return (
                    <MenuItem key={i} value={renderValue(select.testId, item)} style={webStyle.menuOption} className="table_class_select text_capital">
                      {renderOptional(select.testId, item)}
                    </MenuItem>
                  );
                })}
            </Select>
          </Box>
        )
      })
    }
  </>
}

const webStyle: any = {
  formControl: {
    width: '200px',
    height: '50px',
    marginRight: '10px',
    marginBottom: '10px'
  },
  selectForm: {
    minWidth: '200px',
    height: '50px',
    color: 'rgba(0,0,0,0.8)',
    fontWeight: 'bold',
    border: '1px solid #f0f0f0'
  },
  selectFormSortBy: {
    width: '120px',
    fontWeight: '900',
    border: '1px solid rgba(0,0,0,0.2)',
    borderRadius: "8px",
  },
  selectFormSortByBig: {
    width: '220px',
    fontWeight: 'bold',
    border: '1px solid rgba(0,0,0,0.2)',
    borderRadius: "8px",
  },
  formControlSortBy: {
    minWidth: '100px',
    marginRight: '10px',
    marginBottom: '10px',
    borderRadius: "8px",
  },
  menuOption: {
    textTransform: 'capitalize'
  }
}