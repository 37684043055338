// Customizable Area Start
import { Button } from "@material-ui/core";
import React from "react";

const CommonButton = ({ t, label, color, isVariant, ...props }: any) => {
  const handleColor = () => {
    if (color === "orange") {
      return "orange_btn";
    }
    if (color === "blue") {
      return "blue_btn";
    }
    if (color === "white") {
      return "white_btn";
    }
  };

  return (
    <Button className={`common_btn ${handleColor()} ${isVariant ? "variant" : ""}`} {...props}>
      {t(label)}
    </Button>
  );
};

export default CommonButton;
// Customizable Area End
