// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { apiCallWithToken as apiCall } from "../../../../components/src/APICallComponent/index.web";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
export interface Props {
  navigation: any;
  id: string;

  classes: any;
  history: any;
  match: any;
  location: any;
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;

  companiesList: any;
  countriesList: any;
  cityList: any;
  regionList: any;
  statusList: any;
  currentPage: number;
  totalCount: number;
  totalPages: number;
  companyFilter: string;
  countryFilter: string;
  cityFilter: string;
  regionFilter: string;
  statusFilter: string;
  openMenu: number;
  complex: any;
  page: any;
  pagination: any;
  openDetails: number;
  buildingFilter: any;
  unitFilter: any;
  incidentList: any;
  complexList:any;
  buildingList:any;
  loading:boolean;
  searchQuery:any;
  showError:any;
  error:any;
  unitList:any
}

interface SS {
  id: any;
}

export default class COOIncidentListingController extends CommonApiCallForBlockComponent<
  Props,
  S,
  SS
> {
  getIncidentList: any;
  getCountryApiCallId: any;
  getComplexListApiCallId:any;
  getCitiesApiCallId:any;
  getBuildingApiCallId:any;
  getStatusApiCallId:any;
  GetIncidentFilterApi:any;
  getFilterTableApiCallId:any;
  getUnitApiCallId:any;
  timeout:any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.AccoutLoginSuccess)];

    this.state = {
      txtInputValue: "",
      loading:false,
      txtSavedValue: "A",
      enableField: false,
      complexList:[],
      complex: "",
      companiesList: [],
      countriesList: [],
      cityList: [],
      regionList: [],
      statusList: [],
      currentPage: 1,
      totalCount: 1,
      totalPages: 1,
      companyFilter: "",
      countryFilter: "",
      cityFilter: "",
      regionFilter: "",
      statusFilter: "",
      openMenu: 1,
      page: 1,
      buildingFilter: "",
      unitFilter: "",
      buildingList:[],
      pagination: {
        current_page: 1,
        total_count: 0,
        total_pages: 1,
      },
      openDetails: 0,
      incidentList: [],
      searchQuery:"",
      showError:false,
      error:null,
      unitList:[]
      
    };
    this.timeout=null;
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount(): Promise<void> {
    
    this.getIncidents();
   
    this.getCountryList()
    // this.getCitiesList()
  
    this.getStatusList()
  }
  handleIncidentListing = (id: any) => {};
  showError = () => {
   
    if (this.state.error) {
     
      this.setState({
        showError: true,
        loading: false,
      });
    }
   
  };
  getIncidents = async () => {
    this.setState({loading: true})
    this.getIncidentList = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `/bx_block_custom_form/incident_managements?page=${this.state.page}&per_page=10&q=${this.state.searchQuery.trim()}&status=${this.state.statusFilter.trim()}&complex=${this.state.companyFilter}&building=${this.state.buildingFilter}&city=${this.state.cityFilter.trim()}&unit=${this.state.unitFilter}&country=${this.state.countryFilter}`,
    });
  };
  getCountryList = async () => {
    this.getCountryApiCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `/bx_block_custom_form/incident_managements/country_list`,
    });
  };
  getComplexList = async () => {
    this.getComplexListApiCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_custom_form/incident_managements/list_of_complex?country=${this.state.countryFilter}&city=${this.state.cityFilter}`,
    });
  };
  getCitiesList = async () => {
  
    this.getCitiesApiCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint:`bx_block_address/city_by_state?country_name=${this.state.countryFilter}`
     
    });
  };
  getUnitList = async () => {
    this.getUnitApiCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_custom_form/incident_managements/unit_list?building_management_id=${this.state.buildingFilter}`,
    });
  };
  handleFilterDataKeyUp = (e:any) => {
   
    const searchQuery = e?.target?.value;
    clearTimeout(this.timeout);

   
      this.setState({ searchQuery }, () => {
        this.getIncidents();
      });
   
  };
  handleFilterTable=async(e:any)=>{
    this.setState({searchQuery:e, loading:true})
    this.getFilterTableApiCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_custom_form/incident_managements/search?q=${e.trim()}`,
    });
  }
  async componentDidUpdate(prevProps: any, prevState: any): Promise<void> {
    if (prevState.page !== this.state.page) {
      await this.getIncidents();
    }
  }
  getBuildingList = async () => {
    this.getBuildingApiCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_society_management/society_managements/building_list?society_management_id=${this.state.companyFilter}`,
    });
  };
  getStatusList = async () => {
    this.getStatusApiCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_custom_form/incident_managements/list_of_status`,
    });
  };
  handleIncidentPagination = (e: any, value: any) => {
    this.setState({
      page:value
    })
   
    
   
  };
  handleIncidentListSucc = (responseJson: any) => {
    if (responseJson) {
      this.setState({loading:false, incidentList: responseJson.incidents.data,
        pagination:{
          current_page:responseJson.meta.pagination?.current_page,
          total_count:responseJson.meta.pagination?.total_count,
          total_pages:responseJson.meta.pagination?.total_pages,
        },
      })
    }
   
  };
  handleCountrySucc = (responseJson: any) => {
    this.setState({ countriesList: responseJson.country_list });
  };
  handleComplexListSucc=(resposeJson:any)=>{
    this.setState({complexList:resposeJson.data})
  }
  handleBuildingListSucc=(resposeJson:any)=>{
    this.setState({buildingList:resposeJson.data.buildings})
  }
  handleCitiyListSucc=(resposeJson:any)=>{
  
    this.setState({cityList:resposeJson})
  }
  handleStatusListSucc=(responseJson:any)=>{
    this.setState({statusList:responseJson.status})
  }
  handleFilter = (event:any) => {
    event.preventDefault()
    // this.getIncidentSearchFilter()
    this.getIncidents()
  }
  handleUnitList=(responseJson:any)=>{
    this.setState({unitList:responseJson.data.apartments})
  }
  handleFilterStatus=(responseJson:any)=>{
    if (responseJson) {
      this.setState({loading:false, incidentList: responseJson.incidents.data,
        pagination:{
          current_page:responseJson.meta.pagination?.current_page,
          total_count:responseJson.meta.pagination?.total_count,
          total_pages:responseJson.meta.pagination?.total_pages,
        },
      })
    }
  
  
  }
  getIncidentSearchFilter = async () => {
    
    this.setState({loading: true})
    this.GetIncidentFilterApi = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint:`bx_block_custom_form/incident_managements/filter?status=${this.state.statusFilter.trim()}&complex=${this.state.companyFilter}&building=${this.state.buildingFilter}&city=${this.state.cityFilter.trim()}&unit=${this.state.unitFilter}&country=${this.state.countryFilter}`
     
    });
  };
  handleFilterTableData=(responseJson:any)=>{
    // if (responseJson.data) {
    //   this.setState({ loading:false,incidentList: responseJson.incidents.data,
    //     pagination:{
    //       current_page:responseJson.meta.pagination.current_page,
    //       total_count:responseJson.meta.pagination.total_count,
    //       total_pages:responseJson.meta.pagination.total_pages,
    //     },
    //   })
    // }
    if ( responseJson?.incidents) {
      
      this.setState({incidentList: responseJson.incidents.data,page:responseJson.meta.pagination.current_page, pagination:responseJson.meta.pagination,loading:false})
    } else if (responseJson?.errors) {
      this.setState({ error:responseJson.errors,searchQuery:""});
      this.showError();
      
    }  else {
     
      this.setState({ error: responseJson?.error || "Something went wrong1!" ,searchQuery:""});
      this.showError();
     
    }
   
   
    
  }
  async receive(from: string, message: Message) {
   
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
     
      if (responseJson) {
        
        switch (apiRequestCallId) {
        
          case this.getIncidentList:
            return this.handleIncidentListSucc(responseJson);

          case this.getCountryApiCallId:
            return this.handleCountrySucc(responseJson);

          case this.getComplexListApiCallId:
            return this.handleComplexListSucc(responseJson)
          case this.getCitiesApiCallId:
            return this.handleCitiyListSucc(responseJson)
          case this.getBuildingApiCallId:
            return this.handleBuildingListSucc(responseJson)
          case this.getStatusApiCallId:
            return this.handleStatusListSucc(responseJson)
          case this.GetIncidentFilterApi:
            return this.handleFilterStatus(responseJson)
          case this.getFilterTableApiCallId:
            return this.handleFilterTableData(responseJson)
          case this.getUnitApiCallId :
            return this.handleUnitList(responseJson)
        }
        
      }
    }
  }

 
}
// Customizable Area End
