import React from "react";
// Customizable Area Start
import { Box, Breadcrumbs, Button, Chip, Divider, Grid, IconButton, InputAdornment, MenuItem, Popover, Select, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Typography, styled } from "@material-ui/core";
import MainScreen from "./Company Employee/MainScreen.web";
import { withTranslation } from "react-i18next";
import AlertError from "../../../components/src/AlertError.web";
import Loader from "../../../components/src/Loader.web";
import { messageIcon, socialIcon, callIcon, mailIcon } from './assets'
import AddIcon from '@material-ui/icons/Add';
import SelectArrowIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from "@material-ui/icons/Search";
import ShareDocumentModal from "../../../components/src/DocumentComponent/ShareModal.web";
import DashboardProfileController, { Props, TFunction, CompanyListItem, ComplexListItemResponse, BuildingListItem, ComplexDataItem, IndividualBuildingListItem } from "./DashboardProfileController.web";

interface CountryList {
    id: string;
    type: string;
    attributes:
    {
        name: string
    }
}
// Customizable Area End


class DashboardProfile extends DashboardProfileController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderTabPanel = (children: React.ReactNode, value: number, childrenIndex: number) => {
        return (
            <Box
                hidden={value !== childrenIndex}
                aria-labelledby={`simple-tab-${childrenIndex}`}
                id={`simple-tabpanel-${childrenIndex}`}
                role="tabpanel"
            >
                {value === childrenIndex && (
                    <Box>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </Box>
        )
    };

    renderRegistered = () => {
        const { t }: { t: TFunction } = this.props;
        switch(this.state.viewDetailRole) {
            case "registeredOwners" : 
                return <Typography data-testId={"registeredOwnerTestId"} className="navigationTopBreadHeading" onClick={() => this.handleNavigation("RegisteredOwner")}>{t("Registered Owners")}</Typography>
            case "registeredResidents" :
                return <Typography data-testId={"registeredOwnerTestIdAA"} className="navigationTopBreadHeading" onClick={() => this.handleNavigation("RegisteredResidents")}>{t("Registered Residents")}</Typography>
            case "registerPropertyManager" :
                return <Typography data-testId={"registeredOwnerTestIdBB"} className="navigationTopBreadHeading" onClick={() => this.handleNavigation("PropertyManagerDashboard")}>{t("Registered Property Managers")}</Typography>
        }
    }

    renderDashboardProfile = () => {
        const { t }: { t: TFunction } = this.props;
        return (
            <DashboardProfileContainer>
                <Box>
                    <Breadcrumbs>
                        <Typography data-testId={"myDashboardTestId"} className="navigationTopBreadHeading" onClick={()=>this.handleNavigation("DashboardCompanyEmployee")}>{t("My Dashboard")}</Typography>
                        <Typography data-testId={"generalDashboardTestId"} className="navigationTopBreadHeading" onClick={()=>this.handleNavigation("DashboardCompanyEmployee")}>{t("General Dashboard")}</Typography>
                        {this.renderRegistered()}                       
                        <Typography color="primary">{t("Owner Details")}</Typography>
                    </Breadcrumbs>
                </Box>
                <Typography data-testId={"salesManagerName"} className="ownerUserName">
                    {this.state.userDetails.data.attributes.full_name.name}
                </Typography>
                <Typography className="generalDetailsHeading">
                    {t("General Details")}
                </Typography>
                <Box>
                    <Grid container spacing={1}>
                        <Grid item lg={4}>
                            <Box className='profileSectionContainer'>
                                <img src={this.handleNullValues(this.state.userDetails.data.attributes.profile_pic)} className="userProfile" />
                                <Typography component={"p"} className="generalDetailsHeading" data-testId={"ownerNameTestId"}>{this.state.userDetails.data.attributes.full_name.name}</Typography>
                                <Box className="profileLabelContainer">
                                    <Chip className="ownerLabelChip" label="Owner" />
                                    <Chip className="payingLabelChip" label="Paying Client" />
                                </Box>
                                <Box className="profileIcons" data-testId={"messageIconTestId"} onClick={()=>{this.handleOpenShareModal(this.state.userDetails.data.attributes.website[0].twitter_link)}}>
                                    <img src={messageIcon} className="socialIcon" />
                                    <img alt="" className="socialIcon" src={callIcon} />
                                    <img src={mailIcon} className="socialIcon" />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item lg={8}>
                            <Box className="aboutSectionContainer">
                                <Typography component={"p"} className="aboutOwnerHeading">{t("About")}</Typography>
                                <Typography component={"p"} className="aboutOwnerPara">
                                    {this.state.userDetails.data.attributes.bio.bio !== null ? this.state.userDetails.data.attributes.bio.bio : "N/A"}
                                </Typography>
                                <Grid container>
                                    <Grid lg={4} item>
                                        <Box component={'div'} className="aboutOwnerDetails">
                                            <Typography component={"p"} className="aboutOwnerHeading">{t("Gender")}</Typography>
                                            <Typography component={"p"}>{this.state.userDetails.data.attributes.gender.gender ? this.state.userDetails.data.attributes.gender.gender : "N/A"}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={4}>
                                        <Box component={'div'} className="aboutOwnerDetails">
                                            <Typography component={"p"} className="aboutOwnerHeading">{t("DOB")}</Typography>
                                            <Typography component={"p"}>{this.state.userDetails.data.attributes.date_of_birth.date_of_birth ? this.state.userDetails.data.attributes.date_of_birth.date_of_birth : "N/A"}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={4}>
                                        <Box component={'div'} className="aboutOwnerDetails">
                                            <Typography component={"p"} className="aboutOwnerHeading">{t("Hobbies")}</Typography>
                                            {this.state.userDetails.data.attributes.hobbies.hobbies !== null ? this.state.userDetails.data.attributes.hobbies.hobbies.map((hobby) => {
                                                return <Chip className="hobbiesListChip" label={hobby} />
                                            })
                                            : "N/A"
                                        }
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Box component={'div'}>
                                    <Typography component={"p"} className="aboutOwnerHeading">{t("Social Media")}</Typography>
                                    <img src={socialIcon} data-testId={"socialMediaIconsTestId"} onClick={()=>{this.handleOpenShareModal(this.state.userDetails.data.attributes.website[0].twitter_link)}} />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box component={'div'}>
                    <Box component={'div'} className="tabSectionContainer">
                        <Box component={'div'}>
                            <TabsHeaderContainer value={this.state.tabValue} onChange={this.handleTabChange} data-testId={"cooTabChangeTestId"}>
                                <Tab className='tabsItem' label="complex" value={0}></Tab>
                                <Tab className='tabsItem' label="individual buildings" value={1}></Tab>
                            </TabsHeaderContainer>
                        </Box>
                        <Button startIcon={<AddIcon />} disableElevation disableRipple disableTouchRipple disableFocusRipple className='addUnit'>{t("Add another unit")}</Button>
                    </Box>
                    <Box component={'div'} className='tabsChild2'>
                        {this.renderTabPanel(this.renderComplexTable(), this.state.tabValue, 0)}
                        {this.renderTabPanel(this.renderIndividualBuildingTable(), this.state.tabValue, 1)}
                    </Box>
                </Box>
            </DashboardProfileContainer>
        )
    };

    renderComplexTable = () => {
        const { t }: { t: TFunction } = this.props;
        return (
            <SalesComplexDetailsContainer>
                <Box className="headingContainer">
                <Select
                        value={this.state.country}
                        onChange={this.handleCountryChange}
                        MenuProps={{
                            style: { marginTop: "50px" }
                        }}
                        data-testId={"countryTestId"}
                        className="selectItem"
                        displayEmpty
                        IconComponent={SelectArrowIcon}
                        variant="outlined"
                    >
                        <MenuItem value="">{t("Select Country")}</MenuItem>
                        {this.state.countryList.map((country: CountryList, countryIndex: number) => {
                            return (<MenuItem value={country.attributes.name} key={countryIndex} data-testId={`countryListItem${countryIndex}`}>{country.attributes.name}</MenuItem>)
                        })}
                    </Select>
                    <Select
                        data-testId={"cityTestId"}
                        value={this.state.city}
                        onChange={this.handleCityChange}
                        IconComponent={SelectArrowIcon}
                        className="selectItem"
                        MenuProps={{
                            style: { marginTop: "50px" }
                        }}
                        displayEmpty
                        variant="outlined"
                    >
                        <MenuItem value="">{t("Select City")}</MenuItem>
                        {this.state.cityList.map((cityListItem, cityIndex: number) => {
                            return <MenuItem value={cityListItem.name} key={cityIndex} data-testId={`cityItemTestId${cityIndex}`}>{cityListItem.name}</MenuItem>
                        })}
                    </Select>
                    <Select
                        value={this.state.company}
                        onChange={this.handleCompanyChange}
                        data-testId={"companyTestId"}
                        MenuProps={{
                            style: { marginTop: "50px" }
                        }}
                        className="selectItem"
                        IconComponent={SelectArrowIcon}
                        variant="outlined"
                        displayEmpty
                    >
                        <MenuItem value="">{t("Select Company")}</MenuItem>
                        {this.state.companyList.map((companyListItem: CompanyListItem, companyIndex: number) => {
                            return <MenuItem key={companyIndex} value={companyListItem.attributes.company_name} data-testId={`companyListItem${companyIndex}`}>{companyListItem.attributes.company_name}</MenuItem>
                        })}
                    </Select>

                    <Select
                        MenuProps={{
                            style: { marginTop: "50px" }
                        }}
                        value={this.state.complex}
                        onChange={this.handleComplexChange}
                        data-testId={"complexTestId"}
                        className="selectItem"
                        IconComponent={SelectArrowIcon}
                        variant="outlined"
                        displayEmpty
                    >
                        <MenuItem value="">{t("Select Complex")}</MenuItem>
                        {this.state.complexList.map((complex: ComplexListItemResponse, complexIndex: number) => {
                            return <MenuItem value={complex.attributes.name} key={complexIndex} data-testId={`complexItem${complexIndex}`}>{complex.attributes.name}</MenuItem>
                        })}
                    </Select>
                    <Select
                        onChange={this.handleBuildingChange}
                        MenuProps={{
                            style: { marginTop: "50px" }
                        }}
                        IconComponent={SelectArrowIcon}
                        className="selectItem"
                        data-testId={"buildingTestId"}
                        variant="outlined"
                        displayEmpty
                        value={this.state.building}
                    >
                        <MenuItem value="">{t("Select Building")}</MenuItem>
                        {this.state.buildingList.map((building: BuildingListItem, buildingIndex: number) => {
                            return <MenuItem value={building.name} key={buildingIndex} data-testId={`buildingItem${buildingIndex}`}>{building.name}</MenuItem>
                        })}
                    </Select>
                    <Button
                        variant="contained"
                        color="primary"
                        className="searchButton"
                        startIcon={<SearchIcon />}
                        data-testId={"cooComplexSearchBtnTestId"}
                        onClick={() => this.getComplexTabData()}
                    >
                        {t("Search")}
                    </Button>
                </Box>
                <Box className='tableDataContainer'>
                    <Box className="tableListHeading">
                        <Typography className="listPara" data-testId={"complexTabHeadingTestId"}>
                            {t("List of owned units")}
                        </Typography>
                        <SearchTextFieldContainer
                            onChange={this.handleSearchInput}
                            value={this.state.searchText}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon className='searchInputIcon' />
                                    </InputAdornment>
                                ),
                            }}
                            variant="outlined"
                            data-testId={"complexTabSeachTestId"}
                            placeholder="Search by complex name"
                        />
                    </Box>
                    <Divider />
                    <Box>
                        {this.renderComplexTableList()}
                    </Box>
                </Box>
            </SalesComplexDetailsContainer>
        )
    };

    renderComplexTableList = () => {
        const { t }: { t: TFunction } = this.props;
        return (
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="tableTopHeader" align="left">#</TableCell>
                                        <TableCell className="tableTopHeader" align="left">{t("Company Name")}</TableCell>
                                        <TableCell className="tableTopHeader" align="left">{t("Complex Name")}</TableCell>
                                        <TableCell className="tableTopHeader" align="left">{t("Building Id")}</TableCell>
                                        <TableCell className="tableTopHeader" align="left">{t("Building Name")}</TableCell>
                                        <TableCell className="tableTopHeader" align="left">{t("Unit Number")}</TableCell>
                                        <TableCell className="tableTopHeader" align="left">{t("Status")}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.complexData.length === 0 ?<Typography className="noDataFound">{t("No data found")}</Typography> : this.state.complexData.map((complex: ComplexDataItem, complexIndex: number) => (
                                        <TableRow>
                                            <TableCell className='tableCellContent' align="left">{complexIndex + 1}</TableCell>
                                            <TableCell className="tableCellContent" align="left" data-testId={`companyName${complexIndex}`}>{complex.attributes.company ? complex.attributes.company : 'N/A'}</TableCell>
                                            <TableCell className='tableCellContent' align="left">{complex.attributes.complex_name}</TableCell>
                                            <TableCell className='tableCellContent' align="left">{complex.attributes.building_id}</TableCell>
                                            <TableCell className='tableCellContent' align="left">{complex.attributes.building_name}</TableCell>
                                            <TableCell className='tableCellContent' align="left">{complex.attributes.unit_number}</TableCell>
                                            <TableCell className='tableCellContent' align="left"><Chip className={complex.attributes.status === "Rented" ? "rentedStatus" : "hobbiesListChip"} label={complex.attributes.status} /></TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
        )
    }

    renderIndividualBuildingTable = () => {
        const { t }: { t: TFunction } = this.props;
        return (
            <SalesBuildingDetailsContainer>
                <Box className="headingContainer">
                <Select
                        onChange={this.handleCountryChange}
                        value={this.state.country}
                        IconComponent={SelectArrowIcon}
                        className="selectItem"
                        variant="outlined"
                        displayEmpty
                        MenuProps={{
                            style: { marginTop: "50px" }
                        }}
                    >
                        <MenuItem value="">{t("Select Country")}</MenuItem>
                        {this.state.countryList.map((country: CountryList, countryIndex: number) => {
                            return (<MenuItem value={country.attributes.name} key={countryIndex} data-testId={`countryListItem${countryIndex}`}>{country.attributes.name}</MenuItem>)
                        })}
                    </Select>
                    <Select
                        IconComponent={SelectArrowIcon}
                        className="selectItem"
                        value={this.state.city}
                        MenuProps={{
                            style: { marginTop: "50px" }
                        }}
                        variant="outlined"
                        displayEmpty
                        onChange={this.handleCityChange}
                    >
                        <MenuItem value="">{t("Select City")}</MenuItem>
                        {this.state.cityList.map((cityListItems, cityIndex: number) => {
                            return <MenuItem value={cityListItems.name} key={cityIndex} data-testId={`cityItemTestId${cityIndex}`}>{cityListItems.name}</MenuItem>
                        })}
                    </Select>
                    <Select
                        onChange={this.handleCompanyChange}
                        value={this.state.company}
                        IconComponent={SelectArrowIcon}
                        className="selectItem"
                        displayEmpty
                        variant="outlined"
                        MenuProps={{
                            style: { marginTop: "50px" }
                        }}
                    >
                        <MenuItem value="">{t("Select Company")}</MenuItem>
                        {this.state.companyList.map((companyListItem: CompanyListItem, companyIndex: number) => {
                            return <MenuItem value={companyListItem.attributes.company_name} key={companyIndex} data-testId={`companyListItem${companyIndex}`}>{companyListItem.attributes.company_name}</MenuItem>
                        })}
                    </Select>
                    <Select
                        onChange={this.handleComplexChange}
                        value={this.state.complex}
                        IconComponent={SelectArrowIcon}
                        className="selectItem"
                        displayEmpty
                        variant="outlined"
                        MenuProps={{
                            style: { marginTop: "50px" }
                        }}
                    >
                        <MenuItem value="">{t("Select Complex")}</MenuItem>
                        {this.state.complexList.map((complex: ComplexListItemResponse, complexIndex: number) => {
                            return <MenuItem value={complex.attributes.name} key={complexIndex} data-testId={`complexItem${complexIndex}`}>{complex.attributes.name}</MenuItem>
                        })}
                    </Select>
                    <Select
                        value={this.state.building}
                        className="selectItem"
                        onChange={this.handleBuildingChange}
                        displayEmpty
                        IconComponent={SelectArrowIcon}
                        variant="outlined"
                        MenuProps={{
                            style: { marginTop: "50px" }
                        }}
                    >
                        <MenuItem value="">
                            {t("Select Building")}
                        </MenuItem>
                    </Select>
                    <Button
                        startIcon={<SearchIcon />}
                        color="primary"
                        className="searchButton"
                        variant="contained"
                        onClick={() => this.getIndividualBuildingTabData()}
                    >
                        {t("Search")}
                    </Button>
                </Box>
                <Box className='tableDataContainer'>
                    <Box className="tableListHeading">
                        <Typography className="listPara" data-testId={"individualBuildingTabHeadingTestId"}>
                            {t("List of owned units")}
                        </Typography>
                        <SearchTextFieldContainer
                            variant="outlined"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon className='searchInputIcon' />
                                    </InputAdornment>
                                ),
                            }}
                            placeholder="Search by building name"
                            onChange={this.handleBuildingSearchInput}
                            value={this.state.searchText}
                        />
                    </Box>
                    <Divider />
                    <Box>
                        {this.renderIndividualBuildingTableList()}
                        
                    </Box>
                </Box>
            </SalesBuildingDetailsContainer>
        )
    };

    renderIndividualBuildingTableList = () => {
        const { t }: { t: TFunction } = this.props;
        return (
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left" className="tableTopHeader">{t("#")}</TableCell>
                                        <TableCell align="left" className="tableTopHeader">{t("Company Name")}</TableCell>
                                        <TableCell align="left" className="tableTopHeader">{t("Building Id")}</TableCell>
                                        <TableCell align="left" className="tableTopHeader">{t("Building Name")}</TableCell>
                                        <TableCell align="left" className="tableTopHeader">{t("Unit Number")}</TableCell>
                                        <TableCell align="left" className="tableTopHeader">{t("Status")}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.individualBuildingList.length === 0 ?<Typography className="noDataFound">{t("No data found")}</Typography> : this.state.individualBuildingList.map((individualBuilding: IndividualBuildingListItem, comapnyIndex: number) => (
                                        <TableRow key={comapnyIndex}>
                                            <TableCell className='tableCellContent'>{comapnyIndex + 1}</TableCell>
                                            <TableCell className='tableCellContent' data-testId={`companyName${comapnyIndex}`}>{individualBuilding.attributes.company ? individualBuilding.attributes.company : "N/A"}</TableCell>
                                            <TableCell className='tableCellContent'>{individualBuilding.attributes.building_id}</TableCell>
                                            <TableCell className='tableCellContent'>{individualBuilding.attributes.building_name ? individualBuilding.attributes.building_name : "N/A"}</TableCell>
                                            <TableCell className='tableCellContent'>{individualBuilding.attributes.unit_number}</TableCell>
                                            <TableCell className='tableCellContent'><Chip className={individualBuilding.attributes.status === "Rented" ? "rentedStatus" : "hobbiesListChip"} label={individualBuilding.attributes.status} /></TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
        )
    };
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <MainScreen>
                {this.renderDashboardProfile()}
                <AlertError show={this.state.showError} handleClose={this.handleAlertError} message={this.state.errorMessage} data-testId={"errorAlertTestId"} />
                <Loader loading={this.state.loader} />
                <ShareDocumentModal
                    language={"English"}
                    isOpen={this.state.isShareModalOpen}
                    handleClose={this.handleCloseShareModal}
                    heading="Share"
                    documentURL={this.state.shareUrl}
                    data-testId={"shareContractModalTestId"}
                />
            </MainScreen>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
export default withTranslation()(DashboardProfile);
export { DashboardProfile }
const DashboardProfileContainer = styled(Box)({
    padding: "30px",
    background: "#F4F7FF",
    "& .userProfile": {
        width: "70px",
        height: "70px"
    },
    "& .navigationTopBreadHeading": {
        color: "black",
        fontWeight: 400,
        fontSize: "16px",
        cursor: 'pointer',
    },

    "& .navigateRouteActive": {
        color: "#4480ec",
    },
    "& .ownerUserName": {
        fontWeight: 700,
        fontSize: "28px",
        marginBottom: "15px",
        lineHeight: "27px",
        marginTop: "15px",
    },
    "& .generalDetailsHeading": {
        fontWeight: 700,
        fontSize: "23px",
        lineHeight: "27px",
        marginTop: "15px",
        marginBottom: "15px",
    },
    "& .addUnit": {
        fontWeight: 900,
        color: "#FC8434",
        "&.MuiButton-text": {
            padding: 0
        },
        "&:hover": {
            backgroundColor: "transparent !important"
        }
    },
    "& .hobbiesListChip": {
        marginRight: "10px",
        background: "#FFF6EF",
        color: "#FC8434",
        fontWeight: 600,
        "&.MuiChip-root": {
            height: "25px",
            width: "95px",
        }
    },
    "& .ownerLabelChip": {
        fontWeight: 600,
        color: "#3779EE",
        background: "#EEF4FE",
        "&.MuiChip-root": {
            width: "95px",
            height: "25px",
        }
    },
    "& .payingLabelChip": {
        background: "#E2FFF4",
        color: "#42CA64",
        fontWeight: 600,
        "&.MuiChip-root": {
            height: "25px",
        }
    },
    "& .profileLabelContainer": {
        display: "flex",
        gap: 10,
        width: "130px",
    },
    "& .aboutOwnerHeading": {
        color: "#A4A5A6",
        fontWeight: 600,
        fontSize: "16px",
        marginBottom: "5px",
    },
    "& .aboutOwnerPara": {
        textAlign: "justify",
        marginTop: "10px",
    },
    "& .socialIcon": {
        background: "#FFF6EF",
        marginBottom: "15px",
        padding: "5px",
        borderRadius: "10px",
        marginTop: "15px",
    },
    "& .tabsChild1": {
        padding: 10,
        paddingLeft: 0,
    },
    "& .profileSectionContainer": {
        background: "white",
        padding: "20px",
        borderTopLeftRadius: "10px",
        borderBottomLeftRadius: "10px",
        minHeight: "215px",
    },
    "& .profileIcons": {
        justifyContent: 'space-between',
        display: "flex",
        width: "130px",
    },
    "& .aboutSectionContainer": {
        padding: "20px",
        borderTopRightRadius: "10px",
        borderBottomRightRadius: "10px",
        minHeight: "215px",
        background: "white",

    },
    "& .aboutOwnerDetails": {
        marginTop: "15px",
        marginBottom: "15px",
    },
    "& .tabSectionContainer": {
        justifyContent: "space-between",
        display: "flex",
    },
});

const TabsHeaderContainer = styled(Tabs)({
    marginTop: "20px",
    "& .MuiTabs-indicator": {
        background: "none !important"
    },
    "& .MuiTab-textColorInherit": {
        fontSize: 16,
        fontWeight: 600,
        color: 'white !important',
        background: "#A4A5A6 !important",
        borderRadius: "20px !important",
        textTransform: 'capitalize',
        marginRight: "20px",
        lineHeight: '24px !important',
    },
    "& .MuiTab-textColorInherit.Mui-selected": {
        lineHeight: '24px !important',
        fontSize: 16,
        background: "#3779EE !important",
        borderRadius: "20px !important",
        color: 'white !important',
        marginRight: "20px",
        textTransform: 'capitalize',
        fontWeight: 600,
    },
});

const SalesComplexDetailsContainer = styled(Box)({
    "& .headingContainer": {
        display: "flex",
        flexWrap: "wrap",
    },
    "& .selectItem": {
        background: "white",
        width: "190px",
        marginRight: "15px",
        display: "flex",
        marginTop: "15px",
        flexWrap: "wrap",
        border: "1px solid #ededed",
        "&.MuiOutlinedInput-root": {
            borderRadius: "10px"
        },
        "& .MuiSelect-outlined.MuiSelect-outlined": {
            paddingRight: "110px",
        },
    },
    "& .searchButton": {
        borderRadius: "10px",
        textTransform: "capitalize",
        fontWeight: 700,
        fontSize: "20px",
        height: "55px",
        backgroundColor: "#2b6fed",
        marginLeft: "15px",
        marginTop: "15px",
    },
    "& .tableListHeading": {
        padding: "15px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "5px",
    },
    "& .listPara": {
        lineHeight: "27px",
        fontWeight: 700,
        fontSize: "22px",
    },
    "& .tableTopHeader": {
        fontSize: "18px",
        color: "black",
        fontWeight: 700,
    },
    "& .MuiTableCell-alignLeft": {
        textAlign: "left"
    },
    "& .searchInputIcon": {
        color: "#808080"
    },
    "& .rentedStatus": {
        fontWeight: 700,
        color: "#2B6FEC",
        background: "#E8F0FD",
    },
    "& .tableDataContainer": {
        borderRadius: "20px",
        background: "white",
        marginTop: "20px",
    },
    "& .tableCellContent": {
        fontWeight: 600,
        marginLeft: "10px",
    },
    '& .noDataFound': {
        fontWeight: 900, 
        width: '100%', 
        marginTop: '10px'
    },
});

const SearchTextFieldContainer = styled(TextField)({
        width: "275px",
        background: "white",
        borderRadius: "10px",
        border: "1px solid #EAEAEA",
        "& .MuiOutlinedInput-root": {
            borderRadius: "10px",
            border: "1px solid #EAEAEA",
        },
        "& .MuiOutlinedInput-inputAdornedStart": {
        },
        "& ::placeholder": {
            color: "#3D424B !important",
        },
});

const SalesBuildingDetailsContainer = styled(Box)({
    "& .headingContainer": {
        display: "flex",
        flexWrap: "wrap",
    },
    "& .selectItem": {
        background: "white",
        width: "190px",
        marginRight: "15px",
        border: "1px solid #ededed",
        marginTop: "15px",
        flexWrap: "wrap",
        display: "flex",
        "&.MuiOutlinedInput-root": {
            borderRadius: "10px"
        },
        "& .MuiSelect-outlined.MuiSelect-outlined": {
            paddingRight: "110px",
        },
    },
    "& .searchButton": {
        borderRadius: "10px",
        textTransform: "capitalize",
        fontWeight: 700,
        fontSize: "20px",
        backgroundColor: "#2b6fed",
        marginLeft: "15px",
        marginTop: "15px",
        height: "55px",
    },
    "& .tableListHeading": {
        padding: "15px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "5px",
    },
    "& .listPara": {
        lineHeight: "27px",
        fontWeight: 700,
        fontSize: "22px",
    },
    "& .tableTopHeader": {
        color: "black",
        fontWeight: 700,
        fontSize: "18px",
    },
    "& .MuiTableCell-alignLeft": {
        textAlign: "left"
    },
    "& .rentedStatus": {
        fontWeight: 700,
        color: "#2B6FEC",
        background: "#E8F0FD",
    },
    "& .searchInputIcon": {
        color: "#808080"
    },
    "& .tableDataContainer": {
        borderRadius: "20px",
        background: "white",
        marginTop: "20px",
    },
    "& .tableCellContent": {
        fontWeight: 600,
        marginLeft: "10px",
    },
    '& .noDataFound': {
        fontWeight: 900, 
        width: '100%', 
        marginTop: '10px'
    },
});
// Customizable Area End
