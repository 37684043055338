// Customizable Area Start
import {Message} from "../../../framework/src/Message";
import {runEngine} from "../../../framework/src/RunEngine";
import MessageEnum, {getName} from "../../../framework/src/Messages/MessageEnum";

import * as Yup from 'yup';
import {imgPasswordInVisible, imgPasswordVisible} from "./assets";
import {ApiCatchErrorResponse} from "../../../components/src/APIErrorResponse";
import {ROLE} from "../../../framework/src/Enum";
import CommonApiCallForBlockComponent from "../../../components/src/ApiCallCommon.web";


export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
}

export interface S {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    otpAuthToken: string;
    reTypePassword: string;
    data: any[];
    passwordHelperText: string;
    enablePasswordField: boolean;
    enableReTypePasswordField: boolean;
    countryCodeSelected: string;
    phone: string;
    error: any;
    userType: string | null;
    allContries: [];
    selectCountry: string;
    allCity: [];
    selectCity: string;
    allBuilding: [];
    selectBuilding: any;
    allUnit: [];
    selectUnit: any;
    selectEmail: string;
    unitRegisterType: string;
    allComplex: [];
    selectComplex: any;
    loading: boolean;
    otp: any;
    selectCode: string;
    selectCode2: string;
    OTPTimer: number;
    isTimer: boolean;
    showDialog: boolean;
    showError: boolean;
    location:{lat:any, lng:any}
    defaultZoom:number;
    showBuildings:boolean;
    typeOfUser:any;
    selectedUserType:any;
}

export interface SS {
    id: any;
}

export default class EmailAccountRegistrationController extends CommonApiCallForBlockComponent<Props,
    S,
    SS> {
    arrayholder: any[];
    passwordReg: RegExp;
    emailReg: RegExp;
    createAccountApiCallId: any;
    createManagerAccountApiCallId: any;
    createAccountOwnerApiCallId: any;
    createRequestManaulApiCallId: any;
    createRequestApiCallId: any;
    changeUserTypeApiCallId: any;
    getCountryApiCallId: any;
    getComplexApiCallId: any;
    getCityApiCallId: any;
    verifyOtpApiCallId: any;
    getBuildingApiCallId: any;
    getUnitApiCallId: any;
    validationApiCallId: string = "";
    imgPasswordVisible: any;
    imgPasswordInVisible: any;
    resendOTPId: string = "";
    labelHeader: any;
    labelFirstName: string;
    lastName: string;
    labelEmail: string;
    labelPassword: string;
    labelRePassword: string;
    labelLegalText: string;
    labelLegalTermCondition: string;
    labelLegalPrivacyPolicy: string;
    btnTextSignUp: string;
    currentCountryCode: any;
    getSocietyListCallId:string = "";

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];
        this.receive = this.receive.bind(this);
        this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            reTypePassword: "",
            otpAuthToken: "",
            data: [],
            passwordHelperText: "",
            enablePasswordField: true,
            error: null,
            enableReTypePasswordField: true,
            countryCodeSelected: "",
            phone: "",
            userType: '',
            allContries: [],
            selectCountry: '',
            allCity: [],
            selectCity: '',
            allBuilding: [],
            selectBuilding: '',
            allUnit: [],
            selectUnit: '',
            selectCode: '+966',
            selectCode2: '+966',
            selectEmail: '',
            unitRegisterType: '',
            allComplex: [],
            selectComplex: "",
            //@ts-ignore
            //@ts-nocheck
            selectedUserType:false,
            loading: false,
            otp: '',
            showError: false,
            showDialog: false,
            OTPTimer: 30,
            isTimer: false,
            location:{lat:23.48, lng:  55.22},
            defaultZoom: 4,
            showBuildings:true,
            typeOfUser:sessionStorage.getItem('selectedUserType') || ""

        };
        this.arrayholder = [];
        this.passwordReg = new RegExp("\\w+");
        this.emailReg = new RegExp("\\w+");

        this.imgPasswordVisible = imgPasswordVisible;
        this.imgPasswordInVisible = imgPasswordInVisible;

        this.labelHeader = configJSON.labelHeader;
        this.labelFirstName = configJSON.labelFirstName;
        this.lastName = configJSON.lastName;
        this.labelEmail = configJSON.labelEmail;
        this.labelPassword = configJSON.labelPassword;
        this.labelRePassword = configJSON.labelRePassword;
        this.labelLegalText = configJSON.labelLegalText;
        this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
        this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
        this.btnTextSignUp = configJSON.btnTextSignUp;
    }

    manageMapClick = () => {
        this.setState({

        })
    }

    async componentDidMount(): Promise<void> {
        const userFromStorage = localStorage.getItem("user-selected");
        if(userFromStorage) {
            this.setState({ userType: userFromStorage })
        }
        const userType = sessionStorage.getItem('user-selected');
        if (userType) {
            this.setState({ selectedUserType: userType });
        } else {
            console.warn("No user type found in session storage.");
            this.setState({ selectedUserType: 'guest' });
        }
    }
    

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            let errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            switch (apiRequestCallId) {
                case this.createAccountApiCallId:
                    this.createAccountApiCallId = null;
                    this.handleCreateAccount(responseJson, errorReponse)
                    break;
                case this.verifyOtpApiCallId:
                    this.verifyOtpApiCallId = null;
                    this.handleVerifyOtp(responseJson, errorReponse)
                    break;
                case this.createManagerAccountApiCallId:
                    this.createManagerAccountApiCallId = null;
                    this.handleManagerAccountCreate(responseJson, errorReponse)
                    break;
                case this.createAccountOwnerApiCallId:
                    this.createAccountOwnerApiCallId = null;
                    this.handleCreateAccountOwner(responseJson, errorReponse)
                    break;
                case this.createRequestApiCallId:
                    this.createRequestApiCallId = null;
                    this.handleCreateRequestApiCallId(responseJson, errorReponse)
                    break;
                case this.createRequestManaulApiCallId:
                    this.createRequestManaulApiCallId = null;
                    this.handleCreateRequestManaulApiCallId(responseJson, errorReponse)
                    break;
                case this.changeUserTypeApiCallId:
                    this.changeUserTypeApiCallId = null;
                    this.handleChangeUserTypeApiCallId(responseJson, errorReponse)
                    break;
                case this.getCountryApiCallId:
                    this.getCountryApiCallId = null;
                    this.handleGetCountryApiCallId(responseJson, errorReponse)
                    break;
                case this.getComplexApiCallId:
                    this.getComplexApiCallId = null;
                    this.handleGetComplexApiCallId(responseJson, errorReponse)
                    break;
                case this.getCityApiCallId:
                    this.getCityApiCallId = null;
                    this.handleGetCityApiCallId(responseJson, errorReponse);
                    break;
                case this.getBuildingApiCallId:
                    this.getBuildingApiCallId = null;
                    this.handleGetBuildingApiCallId(responseJson, errorReponse);
                    break;
                case this.getUnitApiCallId:
                    this.getUnitApiCallId = null;
                    this.handleGtUnitApiCallId(responseJson, errorReponse)
                    break;
                case this.resendOTPId:
                    // @ts-ignore
                    this.resendOTPId = null;
                    this.handleResendOTPResponse(responseJson,errorReponse)
                    break;
                case this.getSocietyListCallId:
                    this.handleSocityResponse(responseJson)
                    break;
            }
        }
    }
    handleSocityResponse = (responseJson:any) =>{
        let dataCorrection:any = []
        responseJson.data.map((item: any) =>
            dataCorrection.push({
                value: item.id,
                label: item.attributes.name,
                lat: item.attributes.lat,
                long: item.attributes.long,
                is_building: item.attributes.is_building,
                is_building_list: item.attributes.is_building_list,
            })
        )
        // @ts-ignore
        this.setState({allComplex: dataCorrection})
    }

    handleResendOTPResponse = (responseJson:any,errorReponse:any) => {
        console.log("I CAME HERE 0",this.resendOTPId);
    }

    handleCreateAccount(responseJson: any, errorReponse: any) {
        if (!responseJson.errors) {
            localStorage.setItem('res_token', responseJson.meta.token)
            localStorage.setItem('res_user', JSON.stringify(responseJson.data.attributes))
            localStorage.setItem('res_user_id', responseJson.data.id)
            localStorage.setItem('user_email', responseJson.data.attributes.email)
            //@ts-ignore
            //@ts-nocheck
            this.updateType()
            this.setState({loading: false, error: null})
            //@ts-ignore
            //@ts-nocheck
            this.props.navigation.history.push('/otp')
        } else if (responseJson?.errors) {
            this.setState({loading: false})
            let error = responseJson.errors[0].errors;
            this.setState({error, showError: true});
        } else {
            this.setState({error: responseJson?.error || "Something went wrong!"});
        }

        // ApiCatchErrorResponse(responseJson.errors);
        this.setState({loading: false})
    }

    handleVerifyOtp(responseJson: any, errorReponse: any) {
        if (!responseJson.errors) {
            //@ts-ignore
            //@ts-nocheck
            this.setState({loading: false})
            //@ts-ignore
            //@ts-nocheck
            // if (sessionStorage.getItem('selectedUserType') == 'Tenant') {
                if (this.state.selectedUserType === 'Tenant') {
                //@ts-ignore
                //@ts-nocheck
                this.props.navigation.history.push('/RegisterUnitLink')

            } else {
//@ts-ignore
                //@ts-nocheck
                this.props.navigation.history.push('/registerunit')
            }
        } else if (responseJson?.errors) {
            let error = responseJson.errors[0].message;
            this.setState({error, showError: true});
        } else {
            this.setState({error: responseJson?.error[0].message || "Something went wrong!", showError: true});
            // ApiCatchErrorResponse(this.state.error);
        }
        this.setState({loading: false})
    }


 handleManagerAccountCreate(responseJson: any, errorReponse: any) {
        if (!responseJson.errors) {
            localStorage.setItem('res_token', responseJson.meta.token)
            localStorage.setItem('res_user', responseJson.data.attributes)
            localStorage.setItem('res_user_id', responseJson.data.id)
            localStorage.setItem('user_email', responseJson.data.attributes.email)
            //@ts-ignore
            //@ts-nocheck
            this.props.navigation.history.push('/otp')
        } else if (responseJson?.errors) {
            this.setState({loading: false})
            let error = responseJson.errors[0].errors;
            this.setState({error, showError: true});
            // ApiCatchErrorResponse(this.state.error);
            // ApiCatchErrorResponse(errorReponse);
        } else {
            this.setState({error: responseJson?.error || "Something went wrong!", showError: true});
            // ApiCatchErrorResponse(this.state.error);
            // ApiCatchErrorResponse(errorReponse);
        }
        this.setState({loading: false})
    }

    handleCreateAccountOwner(responseJson: any, errorReponse: any) {
        if (!responseJson.errors) {
            localStorage.setItem('res_token', responseJson.meta.token)
            localStorage.setItem('res_user', JSON.stringify(responseJson.data.attributes))
            localStorage.setItem('res_user_id', responseJson.data.id)
            localStorage.setItem('user_email', responseJson.data.attributes.email)
            //@ts-ignore
            //@ts-nocheck
            this.props.navigation.history.push({
                pathname: '/otp',
                state: {
                    //@ts-ignore
                    //@ts-nocheck
                    data: this.state.userType,
                },
            })
        } else {
            //Check Error Response
            this.setState({loading: false, showError: true, error: responseJson.errors[0].errors})
            // this.parseApiErrorResponse(responseJson);
        }
        // ApiCatchErrorResponse(errorReponse);
    }

    handleCreateRequestApiCallId(responseJson: any, errorReponse: any) {
        if (!responseJson.errors) {
            //@ts-ignore
            //@ts-nocheck
            this.props.navigation.history.push('/RegistrationRequestsignup')
            //@ts-ignore
            //@ts-nocheck
            this.setState({showDialog: false})
        } else {
            //Check Error Response
            // this.parseApiErrorResponse(responseJson);
            this.setState({loading: false, showError: true, error: responseJson.errors[0].errors})

        }
        if(errorReponse) {
        ApiCatchErrorResponse(errorReponse);
        }
    }

    handleCreateRequestManaulApiCallId(responseJson: any, errorReponse: any) {
        if (!responseJson.errors) {
            //@ts-ignore
            //@ts-nocheck
            this.props.navigation.history.push('/RegistrationRequestsignup')
        } else {
            //Check Error Response
            this.setState({showDialog: false,loading: false})
            this.errorModal(responseJson.errors[0].errors)
            // this.parseApiErrorResponse(responseJson);
        }
        ApiCatchErrorResponse(errorReponse);
    }

    handleChangeUserTypeApiCallId(responseJson: any, errorReponse: any) {
        if (!responseJson.errors) {
            //@ts-ignore
            //@ts-nocheck
            this.setState({loading: false})
            //@ts-ignore
            //@ts-nocheck
            // this.props.history.push('/addressfill')
        } else {
            //Check Error Response
            // this.parseApiErrorResponse(responseJson);
        }
        ApiCatchErrorResponse(errorReponse);
    }

    handleGetCountryApiCallId(responseJson: any, errorReponse: any) {
        if (!responseJson?.errors) {
            this.setState({allContries: responseJson?.data?.countries})
        } else {
            //Check Error Response
            // this.parseApiErrorResponse(responseJson);
        }
        ApiCatchErrorResponse(errorReponse);
    }

    resendOTP = async () => {
        const mail = localStorage.getItem("user_email")
        this.resendOTPId = await this.apiCall({
            contentType: "application/json",
            method: "GET",
            endPoint: `account_block/resend_otp?email=${mail}`,
        })
    }

    resendTimer = () => {
        if (this.state.OTPTimer > 0 && this.state.OTPTimer <= 30) {
            let intervalId = setInterval(() => {
                if (this.state.OTPTimer === 1) {
                    clearInterval(intervalId); // Clear interval when timer value reaches 0
                }
                this.setState(({OTPTimer}) => ({
                    OTPTimer: OTPTimer - 1,
                    isTimer: true
                }))
            }, 1000)
            setTimeout(() => {
                this.setState({isTimer: false, OTPTimer: 30});
            }, (this.state.OTPTimer * 1000) + 1000);
        }
    }

    handleGetComplexApiCallId(responseJson: any, errorReponse: any) {
        if (!responseJson.errors) {
            //@ts-ignore
            //@ts-nocheck
            let temp = []
            responseJson.data.map((item: any) =>
                temp.push({
                    value: item.id,
                    label: item.attributes.name,
                    is_building: item.attributes.is_building,
                    is_building_list: item.attributes.is_building_list,
                })
            )
            // @ts-ignore
            this.setState({allComplex: temp})
        } else {
            //Check Error Response
            // this.parseApiErrorResponse(responseJson);
        }
        ApiCatchErrorResponse(errorReponse);
    }

    handleGetCityApiCallId(responseJson: any, errorReponse: any) {
        if (!responseJson.errors) {
            this.setState({allCity: responseJson.data.cities})
        } else {
            //Check Error Response
            // this.parseApiErrorResponse(responseJson);
        }
        ApiCatchErrorResponse(errorReponse);
    }

    handleGtUnitApiCallId(responseJson: any, errorReponse: any) {
        if (!responseJson.errors) {
            if (sessionStorage.getItem("selectedUserType") === ROLE.PROPERTY_MANAGER || sessionStorage.getItem("selectedUserType") === ROLE.TENANT) {
                this.setState({allUnit: responseJson.data.apartments})
            } else {
                this.setState({allUnit: responseJson.data.unit_apartments})
            }
        } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
        }
        ApiCatchErrorResponse(errorReponse);
    }

    handleGetBuildingApiCallId(responseJson: any, errorReponse: any) {
        if (!responseJson.errors) {
            this.setState({allBuilding: responseJson.data.buildings})
        } else {
            //Check Error Response
            // this.parseApiErrorResponse(responseJson);
        }
        ApiCatchErrorResponse(errorReponse);
    }

    goToPrivacyPolicy() {
        const msg: Message = new Message(
            getName(MessageEnum.NavigationPrivacyPolicyMessage)
        );
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(msg);
    }

    goToTermsAndCondition() {
        const msg: Message = new Message(
            getName(MessageEnum.NavigationTermAndConditionMessage)
        );
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(msg);
    }

    isStringNullOrBlank(str: string) {
        return str === null || str.length === 0;
    }

    isValidEmail(email: string) {
        return this.emailReg.test(email);
    }

    createAccount(): boolean {

        if (
            this.isStringNullOrBlank(this.state.firstName) ||
            this.isStringNullOrBlank(this.state.lastName) ||
            this.isStringNullOrBlank(this.state.email) ||
            this.isStringNullOrBlank(this.state.password) ||
            this.isStringNullOrBlank(this.state.reTypePassword)
        ) {
            this.showAlert(
                configJSON.errorTitle,
                configJSON.errorAllFieldsAreMandatory
            );
            return false;
        }

        let phoneNumberError = this.validateCountryCodeAndPhoneNumber(
            this.state.countryCodeSelected,
            this.state.phone
        );

        if (phoneNumberError) {
            this.showAlert(configJSON.errorTitle, phoneNumberError);
            return false;
        }

        if (!this.isValidEmail(this.state.email)) {
            this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
            return false;
        }

        if (!this.passwordReg.test(this.state.password)) {
            this.showAlert(configJSON.errorTitle, configJSON.errorPasswordNotValid);
            return false;
        }

        if (this.state.password !== this.state.reTypePassword) {
            this.showAlert(
                configJSON.errorTitle,
                configJSON.errorBothPasswordsNotSame
            );
            return false;
        }

        const header = {
            "Content-Type": configJSON.contentTypeApiAddDetail
        };

        const attrs = {
            first_name: this.state.firstName,
            last_name: this.state.lastName,
            email: this.state.email,
            password: this.state.password,
            full_phone_number: "+" + this.state.countryCodeSelected + this.state.phone
        };

        const data = {
            type: "email_account",
            attributes: attrs
        };

        const httpBody = {
            data: data,
            token: this.state.otpAuthToken
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.createAccountApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            'account_block/accounts'
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeAddDetail
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    getValidations() {
        const headers = {
            "Content-Type": configJSON.validationApiContentType
        };

        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.validationApiCallId = getValidationsMsg.messageId;

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.urlGetValidations
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    }

    isNonNullAndEmpty(value: String) {
        return (
            value !== undefined &&
            value !== null &&
            value !== "null" &&
            value.trim().length > 0
        );
    }

    validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
        let error = null;

        if (this.isNonNullAndEmpty(phoneNumber)) {
            if (!this.isNonNullAndEmpty(String(countryCode))) {
                error = configJSON.errorCountryCodeNotSelected;
            }
        } else if (this.isNonNullAndEmpty(countryCode)) {
            if (!this.isNonNullAndEmpty(phoneNumber)) {
                error = "Phone " + configJSON.errorBlankField;
            }
        }

        return error;
    }

    imgEnableRePasswordFieldProps = {
        source: imgPasswordVisible
    };

    btnConfirmPasswordShowHideProps = {
        onPress: () => {
            this.setState({
                enableReTypePasswordField: !this.state.enableReTypePasswordField
            });
            this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
                .enableReTypePasswordField;
            this.imgEnableRePasswordFieldProps.source = this
                .txtInputConfirmPasswordProps.secureTextEntry
                ? imgPasswordVisible
                : imgPasswordInVisible;
        }
    };

    imgEnablePasswordFieldProps = {
        source: imgPasswordVisible
    };

    btnPasswordShowHideProps = {
        onPress: () => {
            this.setState({enablePasswordField: !this.state.enablePasswordField});
            this.txtInputPasswordProps.secureTextEntry = !this.state
                .enablePasswordField;
            this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
                .secureTextEntry
                ? imgPasswordVisible
                : imgPasswordInVisible;
        }
    };

    btnSignUpProps = {
        onPress: () => this.createAccount()
    };

    btnLegalPrivacyPolicyProps = {
        onPress: () => this.goToPrivacyPolicy()
    };

    btnLegalTermsAndConditionProps = {
        onPress: () => this.goToTermsAndCondition()
    };

    txtInputEmailWebPrpos = {
        onChangeText: (text: string) => {
            this.setState({email: text});
            //@ts-ignore
            this.txtInputEmailPrpos.value = text;
        }
    };

    txtInputEmailMobilePrpos = {
        ...this.txtInputEmailWebPrpos,
        keyboardType: "email-address"
    };

    txtInputEmailPrpos = this.isPlatformWeb()
        ? this.txtInputEmailWebPrpos
        : this.txtInputEmailMobilePrpos;

    txtPhoneNumberWebProps = {
        onChangeText: (text: string) => {
            this.setState({phone: text});

            //@ts-ignore
            this.txtPhoneNumberProps.value = text;
        }
    };

    txtPhoneNumberMobileProps = {
        ...this.txtPhoneNumberWebProps,
        autoCompleteType: "tel",
        keyboardType: "phone-pad"
    };

    txtPhoneNumberProps = this.isPlatformWeb()
        ? this.txtPhoneNumberWebProps
        : this.txtPhoneNumberMobileProps;

    txtInputLastNamePrpos = {
        onChangeText: (text: string) => {
            this.setState({lastName: text});

            //@ts-ignore
            this.txtInputLastNamePrpos.value = text;
        }
    };

    txtInputFirstNamePrpos = {
        onChangeText: (text: string) => {
            this.setState({firstName: text});

            //@ts-ignore
            this.txtInputFirstNamePrpos.value = text;
        }
    };

    txtInputConfirmPasswordProps = {
        onChangeText: (text: string) => {
            this.setState({reTypePassword: text});

            //@ts-ignore
            this.txtInputConfirmPasswordProps.value = text;
        },
        secureTextEntry: true
    };

    txtInputPasswordProps = {
        onChangeText: (text: string) => {
            this.setState({password: text});

            //@ts-ignore
            this.txtInputPasswordProps.value = text;
        },
        secureTextEntry: true
    };
    checkPhone = (value: any) => {


        let pettrn = /^5\d+$/

        if (value.includes('+')) {

            this.setState({error: 'Please enter valid mobile number', showError: true})
            return false
        } else if (this.state.selectCode == '+966' || this.state.selectCode == '+971') {

            if (!(pettrn.test(value))) {

                this.setState({error: 'Please enter valid mobile number', showError: true})
                return false
            } else {
                if (value.length == 9) {


                    return true
                } else {
                    this.setState({error: 'Please enter valid mobile number', showError: true})
                    return false
                }

            }
        } else {
            if (value.length == 10) {


                return true
            } else {
                this.setState({error: 'Please enter valid mobile number', showError: true})
                return false
            }

        }
    }
    createAccoun = (attributes: any) => {
        if (this.checkPhone(attributes.phone)) {

            const header = {
                "Content-Type": configJSON.contentTypeApiAddDetail
            };
            //@ts-ignore
            //@ts-nocheck
            this.setState({selectEmail: attributes.email, loading: true})

            const attrs = {
                full_name: attributes.full_name,
                last_name: attributes.lastName,
                email: attributes.email,
                password: attributes.password,
                full_phone_number: this.state.selectCode + attributes.phone,
                password_confirmation: attributes.confirm_password
            };

            const data = {
                type: "email_account",
                //@ts-ignore
                user_type: this.state.userType,
                attributes: attrs
            };

            const httpBody = {
                data: data
            };

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );
            this.createAccountApiCallId = requestMessage.messageId;
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                'account_block/accounts'
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(httpBody)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.apiMethodTypeAddDetail
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        }


    }

    createAccountOwner(attributes: any) {
        if (this.checkPhone(attributes.phone)) {

            this.setState({loading: true})

            const header = {
                "Content-Type": configJSON.contentTypeApiAddDetail
            };
            this.setState({selectEmail: attributes.email})
            let phone = attributes.phone
            if (attributes.phone.includes('+')) {
                phone = phone.slice(1)
            }

            const attrs = {

                full_name: attributes.full_name,
                last_name: attributes.lastName,
                email: attributes.email,
                password: attributes.password,
                full_phone_number: this.state.selectCode + phone,
                password_confirmation: attributes.confirm_password
            };

            const data = {
                type: "email_account",
                // @ts-ignore
                // @ts-nocheck
                "user_type": this.state.userType,
                attributes: attrs
            };

            const httpBody = {
                data: data
            };

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );
            this.createAccountOwnerApiCallId = requestMessage.messageId;
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                'account_block/accounts'
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(httpBody)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.apiMethodTypeAddDetail
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        }


    }

    createAccountManager = (attributes: any) => {
        if (this.checkPhone(attributes.phone)) {

            this.setState({selectEmail: attributes.email, loading: true})

            const header = {
                "Content-Type": configJSON.contentTypeApiAddDetail
            };

            const attrs = {
                email: attributes.email,
                company_name: attributes.company_name,
                full_name: attributes.managerName,
                // owner_full_name: attributes.ownerName,
                // owner_phone_number: this.state.selectCode + attributes.owner_phone,
                // owner_email: attributes.owner_email,
                password: attributes.password,
                full_phone_number: this.state.selectCode + "-" + attributes.phone,
                password_confirmation: attributes.confirm_password
            };

            const httpBody = {
                data: {
                    type: "email_account",
                    //@ts-ignore
                    user_type: this.state.userType,
                    attributes: attrs
                }
            };

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );
            this.createManagerAccountApiCallId = requestMessage.messageId;
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                'account_block/accounts'
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(httpBody)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.apiMethodTypeAddDetail
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;


        }

    }
    createRequest = () => {
        const header = {
            "Content-Type": configJSON.contentTypeApiAddDetail,
            "token": localStorage.getItem('res_token')
        };

        const attrs = {
            country: this.state.selectCountry,
            city: this.state.selectCity,
            //@ts-ignore
            //@ts-nocheck
            building_management_id: this.state.selectBuilding.id,
            //@ts-ignore
            //@ts-nocheck
            apartment_management_id: this.state.selectUnit.id,
            society_management_id: this.state.selectComplex,
            role:localStorage.getItem("RegisterUserType")
        };

        const httpBody = {
            data: attrs
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.createRequestApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            'bx_block_request_management/requests'
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeAddDetail
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    updateTypeOwner = () => {
        const { userType } = this.state;
        const { navigation } = this.props;
        if (!userType) return;
        localStorage.setItem("RegisterUserType", userType);
        sessionStorage.setItem("selectedUserType", userType);
        
        const routeMap:Record<string, string> = {
            'Owner': '/registerowner',
            'Property Manager': '/registermanager',
            'Tenant': '/register',
            'Owner Resident': '/register'
        };

        const pathname:string = routeMap[userType];

        if (pathname) {
            navigation.history.push({
                pathname,
                state: {
                    data: userType,
                },
            });
        }
    }

    updateType = (): boolean => {
        const header = {
            "Content-Type": configJSON.contentTypeApiAddalletail
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        //@ts-ignore
        //@ts-nocheck
        this.setState({loading: true})

        this.changeUserTypeApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            //@ts-ignore
            `account_block/user_type?user_type=${this.state.userType}&id=${localStorage.getItem('res_user_id')}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'PATCH'
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        const { key } = event;
        if (key === "Backspace" && !this.state.otp[index]) {
          event.preventDefault();
          const otpArray = this.state.otp.split("");
          otpArray.splice(index - 1, 1); 
          this.setState({ otp: otpArray.join("") });
        }
      };

    changeType = (value: any) => {
        localStorage.setItem("user-selected", value);
        this.setState({userType: value})
    }

    changeUnitType = (value: any) => {
        this.setState({unitRegisterType: value})
    }

    handleChange2 = (e: any) => {
        if (e.target.value) {
            // @ts-ignore
            // @ts-nocheck
            this.setState({...this.state, [e.target.name]: e.target.value})
        }
    }

    handleChange = (e: any) => {
        console.log(e)
        if (e.target.value) {
            // @ts-ignore
            // @ts-nocheck
            this.setState({...this.state, [e.target.name]: e.target.value}, () => this.getData(e))
        }
    }
    handleChangeCCode = (e: any) => {
        console.log(e)
        if (e) {
            // @ts-ignore
            // @ts-nocheck
            this.setState({selectCode: `+${e}`})
        }
    }

    handleChangeCode = (e: any) => {
        if (typeof e === 'object' && Object.keys(e).length !== 0) {
            return e?.dial_code
        } else {
            return ''
        }
    }

    getData(e: any) {
        if (e.target.name == 'selectCountry') {
            this.getCity()
        } else if (e.target.name == 'selectCity') {
            this.getComplexbyCity()
        } else if (e.target.name == 'selectComplex') {
            this.getBuilding()
        } else if (e.target.name == 'selectBuilding') {
            this.getUnit()
        }
    }

    getCountry = () => {
        const header = {
            "Content-Type": configJSON.contentTypeApiAddDetail,
            "token": localStorage.getItem('res_token')
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getCountryApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_address/country_list`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    getCity = () => {
        const header = {
            "Content-Type": configJSON.contentTypeApiAddDetail,
            "token": localStorage.getItem('res_token')
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getCityApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_address/city_list?country=${this.state.selectCountry}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    getBuilding() {
        const header = {
            "Content-Type": configJSON.contentTypeApiAddDetail,
            "token": localStorage.getItem('res_token')
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getBuildingApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_society_management/society_managements/building_list?society_management_id=${this.state.selectComplex}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    getUnit = () => {
        const header = {
            "Content-Type": configJSON.contentTypeApiAddDetail,
            "token": localStorage.getItem('res_token')
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getUnitApiCallId = requestMessage.messageId;

        if (sessionStorage.getItem("selectedUserType") === ROLE.PROPERTY_MANAGER) {
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `bx_block_property_manager/property_manager_requests/unit_list?society_management_id=${this.state.selectComplex}&building_management_id=${this.state.selectBuilding.id}`
            );
        } else if(sessionStorage.getItem("selectedUserType") === ROLE.TENANT){
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `/bx_block_settings/apartment_managements/tenant_unit_list?society_management_id=${this.state.selectComplex}&building_management_id=${this.state.selectBuilding.id}&unit_type=Rented`
            );
        } else {
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `bx_block_address/apartment_list?id=${this.state.selectBuilding.id}`
            );
        }

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    registerUnit = () => {
        if (this.state.unitRegisterType) {
            if (this.state.unitRegisterType == 'Manual') {
                //@ts-ignore
                //@ts-nocheck
                this.props.navigation.history.push('/registerunitmanually')
            } else {
                //@ts-ignore
                //@ts-nocheck
                this.props.navigation.history.push('/RegisterUnitLink')
            }
        }
    }

    getSocietyList = async () => {
        this.getSocietyListCallId = await this.apiCall({
            contentType: "application/json",
            method:"GET",
            endPoint: `bx_block_society_management/society_managements`,
        });
    }

    getComplex = () => {
        const header = {
            "Content-Type": configJSON.contentTypeApiAddDetail,
            "token": localStorage.getItem('res_token')
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getComplexApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_society_management/society_managements`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    getComplexbyCity = () => {
        const header = {
            "Content-Type": configJSON.contentTypeApiAddDetail,
            "token": localStorage.getItem('res_token')
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getComplexApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_address/housing_complex_list?city=${this.state.selectCity}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    handleInputChange = (newValue: string) => {
        this.setState({selectComplex: newValue})
    };

    handleInputChangeCOm = (e: any, newValue: any,isMapUpdate:boolean,setFieldValue?:any) => {
        if (newValue) {
            this.setState({selectComplex: newValue.value}, () => this.getData({target: {name: 'selectComplex'}}))
            if(newValue.is_building){
                this.setState({
                    showBuildings: false,
                    selectBuilding:newValue?.is_building_list[0]
                },()=>this.getUnit())
                setFieldValue("selectBuilding", newValue?.is_building_list[0]);

            }else{
                this.setState({
                    showBuildings: true,
                    selectBuilding:''
                })  
            }
            if(isMapUpdate){
                this.setState({location:{lat:newValue?.lat, lng:newValue?.long},defaultZoom:13})
            }else{
                this.setState({location:{lat:newValue?.lat || 0, lng:newValue?.long || 0}})
            }
        }
    };

    createRequestManual = (attributes: any) => {
        const header = {
            "Content-Type": configJSON.contentTypeApiAddDetail,
            "token": localStorage.getItem('res_token')
        };

        const attrs = {
            building_management_id: this.state.selectBuilding.id,
            apartment_name: this.state.selectUnit,
            society_management_id: this.state.selectComplex,
            role:localStorage.getItem("RegisterUserType")
        };

        const httpBody = {
            data: attrs
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.createRequestManaulApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            'bx_block_request_management/requests'
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeAddDetail
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;

    }

    handleChangeOTP = (otp: any) => this.setState({otp});

    verifyOtp = (): boolean => {
        const header = {
            "Content-Type": configJSON.contentTypeApiAddDetail
        };

        const httpBody = {
            otp: this.state?.otp || "111111",
            email: localStorage.getItem('user_email')
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        //@ts-ignore
        //@ts-nocheck
        this.setState({loading: true})

        this.verifyOtpApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `account_block/accounts/verify_user`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'POST'
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    addressSchema = () => {
        const validations = Yup.object().shape({
            selectCountry: Yup.string().required(`Country is required`).trim(),
            selectCity: Yup.string().required(`City is required`).trim(),
            selectBuilding: Yup.string().required(`Building is required`).trim(),
            selectComplex: Yup.string().required(`Complex is required`).trim(),
            selectUnit: Yup.string().required(`Unit is required`).trim(),
        });
        return validations
    }

    addressSchemaManual = () => {
        const validations = Yup.object().shape({
            selectBuilding: Yup.string().required(`Building is required`).trim(),
            selectComplex: Yup.string().required(`Complex is required`).trim(),
            selectUnit: Yup.string().required(`Unit is required`).trim(),
        });
        return validations
    }

    signupSchemaManager = () => {
        const validations = Yup.object().shape({
            //@ts-ignore
            company_name: Yup.string().required(`Company name is required`).trim().matches("^[a-zA-Z\u0600-\u06FF,-\s ][\s\a-zA-Z\u0600-\u06FF ,-]*$", "Only characters are allowed in username"),
            //@ts-ignore
            managerName: Yup.string().required(`Manager name is required`).trim().matches("^[a-zA-Z\u0600-\u06FF,-\s ][\s\a-zA-Z\u0600-\u06FF ,-]*$", "Only characters are allowed in username"),
            // ownerName: Yup.string().required(`Owner name is required`).trim().matches("^[a-zA-Z\-]+$","Only characters are allowed in username"),
            email: Yup.string().email('Email is not valid').required(`Email is required`).trim(),
            // owner_email: Yup.string().email().required(`Owner email is required`).trim(),
            phone: Yup.number()
                .typeError("Only numbers are allowed.")
                .required("Mobile number is required.")
                .positive("Negative numbers are not allowed.")
                .integer("Number can't contain a decimal."),
            // .min(100000000, "Minimum 9 digits are required.")
            // .max(1000000000, "Maximum 9 digits are allowed."),
            // owner_phone: Yup.number()
            //   .typeError("Only numbers are allowed.")
            //   .required("Mobile number is required.")
            //   .positive("Negative numbers are not allowed.")
            //   .integer("Number can't contain a decimal.")
            //   .min(100000000, "Minimum 9 digits are required.")
            //   .max(1000000000, "Maximum 9 digits are allowed."),
            password: Yup
                .string()
                .min(8, `Minimum Password length is 8.`)
                .max(16, `Maximum Password length is 16.`)
                .required(`New Password is required.`)
                .matches(
                    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/,
                    `Password must contain atleast a capital letter, a lowercase letter, a number and a special character.`
                ),
            confirm_password: Yup
                .string()
                .oneOf([Yup.ref("password"), null], `Password must match`)
                .required(`Confirm Password is required.`),

        });
        return validations
    }

    signupSchema = () => {
        const validations = Yup.object().shape({
            //@ts-ignore
            full_name: Yup.string()
            .required(`Name is required`)
            .matches(/^(?! )(?!.*  )[a-zA-Z\u0600-\u06FF,-]+( [a-zA-Z\u0600-\u06FF,-]+)*(?! )$/, "Only characters are allowed in name"),
          

            // full_name: Yup.string().required(`Name is required`).trim().matches("^[a-zA-Z\u0600-\u06FF,-\s ][\s\a-zA-Z\u0600-\u06FF ,-]*$", "Only characters are allowed in name"),
            // email: Yup.string().email('Email is not valid').required(`Email is required`).trim(),
            email: Yup.string()
  .email('Email is not valid')
  .required('Email is required')
  .matches(/^(?! )(?!.* $)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, "Spaces are not allowed at the beginning or end"),

            // phone: Yup.number()
            //     .typeError("Only numbers are allowed.")
            //     .required("Mobile number is required.")
            //     .positive("Negative numbers are not allowed.")
            //     .integer("Number can't contain a decimal."),
            phone: Yup.string()
            .required("Mobile number is required.")
            .matches(/^(?! )(?!.* $)[0-9]+$/, "Only numbers are allowed")
            .typeError("Only numbers are allowed."),
          
                
            // .min(100000000, "Minimum 9 digits are required.")
            // .max(1000000000, "Maximum 9 digits are allowed."),
           
            password: Yup
                .string()
                .min(8, `Minimum Password length is 8.`)
                .max(16, `Maximum Password length is 16.`)
                .required(`New Password is required.`)
                .matches(
                    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/,
                    `Password must contain atleast a capital letter, a lowercase letter, a number and a special character.`
                ),
            confirm_password: Yup
                .string()
                .oneOf([Yup.ref("password"), null], `Password must match`)
                .required(`Confirm Password is required.`),

        });
        return validations
    }

    EmailSchema = () => {
        const validations = Yup.object().shape({
            email: Yup.string().email()
                .trim()
                .required("Email is required.")
        });
        return validations
    }
}

// Customizable Area End