Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.PatchAPiMethod = "PATCH"
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ContentManagement";
exports.labelBodyText = "ContentManagement Body";
exports.createIncident="bx_block_custom_form/incidents"
exports.updateIncident="bx_block_custom_form/incidents/"
exports.incidentRelated="bx_block_custom_form/incidents/incident_related_list"
exports.check="/:(.*?);/"

exports.btnExampleTitle = "CLICK ME";

exports.contractListingMethod = "GET";
exports.contractListingEndPoint = "society_managements/1/bx_block_contract/employee_contracts?";
exports.contractListSortEndPoint = "society_managements/1/bx_block_contract/employee_contracts?sort_by=";
exports.contractListSearchEndPoint = "society_managements/1/bx_block_contract/employee_contracts/?search=";
exports.saveTemplatesMethod = "GET";
exports.saveTemplatesEndPoint = "society_managements/1/bx_block_contract/employee_contracts/saved_template?";
exports.searchSavedTemplateTextEndPoint = "society_managements/1/bx_block_contract/employee_contracts/saved_template/?search=";
exports.draftListMethod = "GET";
exports.draftListEndPoint = "society_managements/1/bx_block_contract/employee_contracts/draft_lease_list?";
exports.draftSearchEndPoint = "society_managements/1/bx_block_contract/employee_contracts/draft_lease_list/?search=";
exports.countryListMethod = "GET";
exports.countryEndPoint = "bx_block_address/country_list";
exports.cityListMethod = "GET";
exports.cityEndPoint = "bx_block_address/city_by_state?country_name=";
exports.buildingListMethod = "GET";
exports.individualBuildingListMethod = "GET";
exports.buildingListEndPoint = "bx_block_address/building_list?society_management_id=";
exports.complexListMethod = "GET";
exports.complexListEndPoint = "bx_block_my_team/company_employee_nominations/complex_list";
exports.unitNumberListMethod = "GET";
exports.unitNumberListDraftMethod = "PUT";
exports.unitNumberListEndPoint = "bx_block_address/all_apartment_list?building_management_id=";
exports.countryCodeAndFlagMethod = "GET";
exports.countryCodeWithFlagAndCityMethod = "PUT";
exports.countryCodeWithCityMethod = "POST";
exports.countryCodeAndFlagEndPoint = "account/accounts/country_code_and_flag";
exports.contractDetailsMethod = "Get";
exports.contractLeaseManagementMethod = "PUT";
exports.contractDetailsEndPoint = "society_managements/1/bx_block_contract/employee_contracts"
exports.terminateContractMethod = "PUT";
exports.terminateContractEndPoint = "society_managements/1/bx_block_contract/employee_contracts";
exports.draftContractDetailsMethod = "GET";
exports.draftContractDetailstEndPoint = "society_managements/1/bx_block_contract/employee_contracts/";
exports.deleteDraftContractDetailsMethod = "DELETE";
exports.deleteDraftContractDetailstEndPoint = "society_managements/1/bx_block_contract/employee_contracts/1?status=Draft";
exports.deleteDraftEndPoint = "society_managements/1/bx_block_contract/employee_contracts/";
exports.getContractBasedOnComplexMethod = "GET";
exports.getContractBasedOnComplexEndPoint = "bx_block_address/housing_complex_list";
exports.onwerListMethod = "GET";
exports.ownerListEndPoint = "bx_block_my_team/company_employee/employee_team_members/owner_list";
exports.templateListMethod = "GET";
exports.templateListEndPoint = "bx_block_contract/lease_templates"
exports.downloadContractMethod = "GET";
exports.downloadContractEndPoint = "";
exports.shareContractMethod = "GET";
exports.shareBuildingContractMethod = "PUT";
exports.shareContractEndPoint = "";
exports.templateListingMethod = "GET";
exports.templateDraftListingMethod = "PUT";
exports.templateListApplyMethod = "PUT";
exports.templateListingEndPoint = "bx_block_contract/lease_templates";
exports.searchTemplateListingMethod = "GET";
exports.searchTemplateListingEndPoint = "";
exports.getCityListMethod = "GET";
exports.getCityListIndividualBuildingMethod = "POST";
exports.getCityListEndPoint = "bx_block_my_team/company_employee_nominations/city_list?country=";
exports.validatePhoneNumbermethod = "GET";
exports.validatePhoneGettingNumberMethod = "PUT";
exports.validatePhoneNumberEndPoint = "bx_block_society_management/company_employee/validate_number?society_management_id=";
exports.termsListMethod = "GET";
exports.termsListBuildingComplexMethod = "POST";
exports.termsListEndPoint = "society_managements/5/bx_block_contract/terms";
exports.conditionListMethod = "GET";
exports.conditionListEndPoint = "society_managements/5/bx_block_contract/conditions";
exports.findPersonListMethod = "GET";
exports.findSaveTemplateArabicMethod = "PUT";
exports.findPersonListEndPoint = "society_managements/1/bx_block_contract/employee_contracts/find_person";
exports.createContractMethod = "POST";
exports.createContractEndPoint = "society_managements/5/bx_block_contract/contracts";
exports.saveContractTemplateMethod = "POST";
exports.saveContractTemplateEndPoint = "society_managements/5/bx_block_contract/saved_lease_templates";
exports.saveAsDraftMethod = "POST";
exports.saveAsDraftRemoveMethod = "PUT";
exports.saveAsDraftEndPoint = "";
exports.saveAsTenanatEndPoint = "POST";
exports.complexSelectListMethod = "GET";
exports.complexSelectListEndPoint = "bx_block_address/housing_complex_list";
exports.inviteMemberMethod = "POST";
exports.inviteMemberEndPoints = "bx_block_request_management/member_invitations";
exports.addTermsMethod = "POST";
exports.addTermsEndPoint = "society_managements/5/bx_block_contract/terms?text="
exports.companyListEndpoint = "bx_block_custom_user_subs/list_complexes_by_company";
exports.currencyListEndPoint = "bx_block_posts/classifieds/currency_list";
exports.userTypeEndpoint = "bx_block_roles_permissions/roles";
exports.findTenantPersonListEndPoint = "";
exports.complexTab = "Complex";
exports.SelectCountry = "Select Country";
exports.SelectCity = "Select City";
exports.SelectComplex = "Select Complex";
exports.SelectBuilding = "Select Building";
exports.SelectUnitNumber = "Select Unit Number";
exports.SelectContractStatus = "Select Contract Status";
exports.Owner = "Owner";
exports.ContractIssuer = "Contract Issuer";
exports.Search = "Search";
exports.LeaseManagement = "Lease Management";
exports.ContractListing = "Contracts Listing";
exports.SavedTemplates = "Saved Templates";
exports.Drafts = "Drafts";
exports.SortBy = "Sort By";
exports.OwnerName = "Owner Name";
exports.searchTemplateEndPoint = "bx_block_contract/lease_templates?search=";
exports.saveContractAsDraft = "society_managements/1/bx_block_contract/employee_contracts/";
exports.penaltyReasonsEndPoint = "society_managements/5/bx_block_contract/penanlty_late_payments/show_penanlty?tenant_id="
exports.penaltyUpdateEndpoint = "society_managements/5/bx_block_contract/penanlty_late_payments";
exports.deleteDraftEndpoint = "society_managements/1/bx_block_contract/employee_contracts/";
exports.ownerTenantActiveContractEndpoint = "society_managements/1/bx_block_contract/employee_contracts/deatils_via_unit?id=";
exports.updateContractExpiry = "society_managements/1/bx_block_contract/employee_contracts/";
exports.savedTemplateItemListEndpoint = "society_managements/1/bx_block_contract/employee_contracts/saved_template_detail?id=";
exports.deleteSavedTemplateItem = "society_managements/1/bx_block_contract/employee_contracts/delete_save_template?id=";
exports.unitNumberListOwnerEndPoint = "society_managements/1/bx_block_contract/employee_contracts/unit_list_for_tenant?building_management_id";
// Customizable Area End