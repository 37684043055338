Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/dashboard/dashboards";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.httpPost = "POST"
exports.httpPut = "PUT"
exports.httpDelete = "DELETE"
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.countryApiEndpoint = "bx_block_profile/profiles/country_list/"
exports.compnyApiEndpoint = "bx_block_my_team/company_employee_nominations/compney_list"
exports.cityApiEndpoint = "bx_block_my_team/company_employee_nominations/city_list"
exports.complexApiEndpoin = "bx_block_my_team/company_employee_nominations/complex_list/"
exports.buildingApiEndpoin = "bx_block_profile/profiles/building_list"
exports.unitApiEndPoint = "bx_block_profile/profiles/apartment_list/"
exports.userProfileApiEndPoint = "bx_block_profile/profiles/company_community_member_list"
exports.addUserProfileApiEndPoint = "bx_block_request_management/create_request"
exports.familyApiEndPoint = "bx_block_family/families"
exports.relationsApiEndPoint="bx_block_family/relations"
exports.idProofApiEndPoint = "bx_block_family/id_proofs"
exports.editFamilyEndPoint = "bx_block_family/families/"
exports.deleteFamilyApiEndPoint = "bx_block_family/families/"
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.labelMapTitleText = "POST";
exports.labelBodyText = "dashboard Body";
exports.checkLogin = "/bx_block_rent_payment/rent_payments/month_list";
exports.addTenantApiEndPoint = "society_managements/1/bx_block_contract/employee_contracts/find_person/"
exports.findPersonApiEndPoit = "society_managements/1/bx_block_contract/employee_contracts/find_person_details/"
exports.addAnotherUnitEndPoint = "bx_block_request_management/create_request"
exports.suspendUserApiEndPoint = "bx_block_profile/profiles/company_suspend_user/"
exports.getUserProfileEndPoint ="bx_block_profile/profiles/specific_user_profile"
exports.getUserprofileDataEndPoint="bx_block_profile/profiles/employee_profile?id="
exports.subscriptionDetailsEndPoint = "bx_block_dashboard/approval_dashbords/subscription_request_list"
exports.subscriptionListMethod = "GET";
exports.subscriptionListEndpoint = "bx_block_dashboard/company_employee_general_dashboard/subcription_listing?page=";
exports.dashboardDataEndPoint = "bx_block_dashboard/company_employee_general_dashboard/coo_general_dashboard";
exports.dashboardText="My Dashboards"
exports.GeneralDashboard="General Dashboard"
exports.TotalOpenIncidents="Total Open Incidents"
exports.SelectComplex="Select complex"
exports.SelectBuilding="Select Building"
exports.SelectCategory="Select Category"
exports.IncidentFactor="Incident Factor"
exports.SelectMonth="Select Month"
exports.SelectYear="Select Year"
exports.Search="SEARCH"
exports.TotalOpenTickets="Total Open tickets"
exports.registeredOwnerDataEndPoint = "bx_block_dashboard/company_employee_general_dashboard/company_owner_listing?";
exports.countryEndPoint = "bx_block_address/country_list";
exports.actualCountryListEndPoint = "bx_block_address/addresses";
exports.complexListEndPoint = "bx_block_address/housing_complex_list?";
exports.buidlingListEndPoint = "bx_block_address/building_list?society_management_id=";
exports.registeredResidents = "bx_block_dashboard/company_employee_general_dashboard/resident_listing?";
exports.cityEndPoint = "bx_block_address/city_by_state?country_name=";
exports.companiesDataEndPoint = "bx_block_dashboard/company_employee_general_dashboard/registered_company_listing?";
exports.totalOpenincidentsEndPoint = "bx_block_dashboard/company_employee_general_dashboard/company_incident_listing?";
exports.buildingHeader= "Building:";
exports.complexHeader= "Complex Name:";
exports.openTicket= "Total number of open tickets:";
exports.ticketDays= "Ticket open for more than 7 days:";
exports.ticketMoreDays="Ticket open for more than 30 days:";
exports.incident="Incident factor"; 
exports.location="See building on map";  
exports.topCategories="Top 3 categories for open tickets:"  
exports.complexList="bx_block_my_team/company_employee_nominations/complex_list"  
exports.bulidingList="bx_block_announcement/announcements/building_list?society_management_id="  
exports.categoryListAPi="bx_block_custom_form/incidents/incident_related_list"  
exports.sortBy="Sort By";  
exports.accountManagerEndPoint = "bx_block_report/property_manager_report/account_manager_listing";
exports.salesManagerEndPoint = "bx_block_report/property_manager_report/sales_manager_listing";
exports.individualBuilidingListEndpoint = "bx_block_dashboard/company_employee_general_dashboard/individual_building_list?";
exports.complexListEndpoint = "bx_block_address/housing_complex_list?";
exports.buildingListEndpoint = "bx_block_profile/profiles/building_list";
exports.unitListEndpoint = "bx_block_address/all_apartment_list?building_management_id=";
exports.topCategories="Top 3 categories for open tickets:";
exports.assignedSalesManagerEndPoint = "bx_block_dashboard/company_employee_general_dashboard/company_sales_manager_listing?"
exports.propertyManagerListEndpoint = "bx_block_dashboard/company_employee_general_dashboard/property_manager_listing";
exports.topCategories="Top 3 categories for open tickets:" 
exports.companyListEndPoint = "bx_block_custom_user_subs/list_companies_by_city?city=";
exports.companyListByCityEndPoint = "bx_block_custom_user_subs/list_companies_by_city?"
exports.salesManagerEndpoint = "bx_block_report/property_manager_report/sales_manager_listing";
exports.accountManagerEndpoint = "bx_block_report/property_manager_report/account_manager_listing";
exports.totalComplexEndpoint = "bx_block_dashboard/company_employee_general_dashboard/complex_list?";
exports.complexTabEndpoint = "bx_block_dashboard/company_employee_general_dashboard/sales_manager_assiged_complexes";
exports.accountManagerTabEndpoint = "bx_block_dashboard/company_employee_general_dashboard/company_account_manager_listing";
exports.assignAccountManagerEndpoint = "bx_block_dashboard/company_employee_general_dashboard/company_account_manager_listing?"
exports.salesManagerProfileDetailsEndpoint = "bx_block_dashboard/company_employee_general_dashboard/account_profile_details?id=";
exports.dashboardProfileComplexEndpoint = "bx_block_dashboard/company_employee_general_dashboard/assigened_complex_listing?id=";
exports.dashboardProfileIndividualBuildingEndpoint = "bx_block_dashboard/company_employee_general_dashboard/assigened_building_listing?id=";
exports.userProfileEndpoint = "bx_block_dashboard/company_employee_general_dashboard/account_profile_details?id=";
exports.ownerDetailsEndpoint = "bx_block_dashboard/company_employee_general_dashboard/get_employee_assigned_object_details?object_id="
exports.salesProfileAccountManagerListingEndpoint = "bx_block_dashboard/company_employee_general_dashboard/company_manager_listing";
exports.cooProfileSalesManagerListingEndpoint = "bx_block_dashboard/company_employee_general_dashboard/company_manager_listing?role=sales_manager";
exports.cooProfileAccountManagerListingEndpoint = "bx_block_dashboard/company_employee_general_dashboard/company_manager_listing?role=account_manager";
exports.reassignAccountManagerEndpoint = "bx_block_dashboard/company_employee_general_dashboard/assign_another_manager?";
exports.totalComplexlistEndpoint = "bx_block_dashboard/company_employee_general_dashboard/complex_list?";
exports.viewProfiledataAPIendpoint="bx_block_profile/profiles/employee_profile?id="
exports.GetpendingsubscriptionListApiendpoint="bx_block_dashboard/approval_dashbords/sales_subscription_request_list?"
exports.GetpendingListApiendpoint="bx_block_dashboard/approval_dashbords/complex_pending_unit_request_list"
exports.GetlistofunitsApiendpoint="bx_block_dashboard/approval_dashbords/complex_unit_request_list?building_id="
exports.AcceptSubscriptionAPIendpoint="bx_block_dashboard/approval_dashbords/accept_reject_subscription_request"
// Customizable Area End