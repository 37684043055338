// Customizable Area Start
import React from "react";
import "./MyTeam.web.css"
import {withStyles} from '@material-ui/core/styles';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {Backdrop, Container, Fade, IconButton, InputAdornment, Modal, TextField, Typography,Button} from "@material-ui/core";
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Select from "@material-ui/core/Select";
import {buildings, calanderIcon, userIcon} from "./assets"
import Divider from '@material-ui/core/Divider';
// Icons
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import GeneralHeader from "../../../components/src/HeaderComponent/GeneralHeaderComponent.web";
import {languageCondition} from "../../../components/src/ConditionManager.web";

// Icons
import {cancle, SearchIconImage} from "../../user-profile-basic/src/assets"
import TaskManagementController, {Props,} from "./TaskManagementController";
import "../../../web/src/assets/css/style.scss";
import Loader from "../../../components/src/Loader.web";
import '../../../web/src/i18n.js';
import MenuItem from "@material-ui/core/MenuItem";
import {Form, Formik} from "formik";
import moment from "moment";

class TaskManagement extends TaskManagementController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    //@ts-ignore
  const { t, i18n }: any = this.props;
  const {language} = i18n;
    return (
      <>
    <GeneralHeader>
            <Container className="link-decoration">
                <Box className="nav">
                    <Box style={{width: "100%"}}>
                        <Typography variant="body1" >
                            {t("My Team")} / <Box component="span" style={{color: "blue"}}>{t("Task Management")}</Box>
                        </Typography>
                        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                            <Typography className="bold-text subHed" style={{fontSize:"28px"}}>{t("Task Management")}</Typography>
                            <Box>
                                <AcceptButton style={{marginTop:"20px",marginRight:"10px"}} onClick={()=>this.setState({setOpen:true})}>{t("Create New Task")}</AcceptButton>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Grid container spacing={3} style={{marginTop: 10, marginBottom:30}}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item>
                                <Select id="filter-type-select" variant="outlined" displayEmpty 
                                 MenuProps={{
                                    transformOrigin: {
                                        horizontal: "left",
                                        vertical: "top",
                                    },
                                    getContentAnchorEl: null,
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left",
                                    },
                                }}
                                value={this.state.filterBuilding} style={{width:"180px",border:"1px solid #ECECEC",borderRadius:"10px",backgroundColor:"white",marginRight:"10px"}} className="select-input" onChange={(e) => this.setState({filterBuilding:e.target.value})}>
                                    <MenuItem value="" disabled>
                                        {t("Select Building")}
                                    </MenuItem>
                                    <MenuItem value=" ">
                                        {t("All")}
                                    </MenuItem>
                                    {
                                        this.state.buildingList.map((item:any,key:any)=>{
                                            return(
                                                <MenuItem key={key} value={item.id}>{item.name}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </Grid>
                            <Grid item>
                                <Select 
                                 MenuProps={{
                                    transformOrigin: {
                                        horizontal: "left",
                                        vertical: "top",
                                    },
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left",
                                    },
                                    getContentAnchorEl: null,
                                }}
                                id="filter-type-select1" variant="outlined" displayEmpty value={this.state.filterShortBy} style={{width:"180px",border:"1px solid #ECECEC",borderRadius:"10px",backgroundColor:"white",marginRight:"10px"}} className="select-input" onChange={(e) => this.setState({filterShortBy:e.target.value})}>
                                    <MenuItem value="" disabled>
                                        {t("Sort By")}
                                    </MenuItem>
                                    <MenuItem value={"Name"}>{t("Name")}</MenuItem>
                                    <MenuItem value={"Time"}>{t("Time")}</MenuItem>
                                </Select>
                            </Grid>
                            <Grid item>
                                <Select 
                                 MenuProps={{
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left",
                                    },
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left",
                                    },
                                    getContentAnchorEl: null,
                                }}
                                id="filter-type-select2" variant="outlined" displayEmpty value={this.state.filterStatus} style={{width:"180px",border:"1px solid #ECECEC",borderRadius:"10px",backgroundColor:"white",marginRight:"10px"}} className="select-input" onChange={(e) => this.setState({filterStatus:e.target.value})}>
                                    <MenuItem value="" disabled>
                                        {t("Status")}
                                    </MenuItem>
                                    <MenuItem value=" ">
                                        {t("All")}
                                    </MenuItem>
                                    <MenuItem value={"pending"}>{t("Pending")}</MenuItem>
                                    <MenuItem value={"closed"}>{t("Closed")}</MenuItem>
                                </Select>
                            </Grid>
                            <Grid item>
                                <ChairmanButton className="taskManagementSearch" onClick={()=> this.getTaskManagementTaskList()} variant="contained" color="primary" startIcon={<img src={SearchIconImage}/>}>{t("Search")}</ChairmanButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={3} style={{marginTop: 10, marginBottom:30, maxHeight: '55vh', overflowY: 'scroll'}}>
                    {
                        this.state.taskList?.length > 0 &&
                            this.state.taskList?.map((item:any,key:any)=>{
                                return(
                                    <Grid item xs={12} key={key}>
                                        <Box style={{backgroundColor:"white",width:"100%"}}>
                                            <Grid container style={{padding:"10px 5px", display: 'flex', flexDirection: 'column', gap: 15 }}>
                                                <Grid item xs={12}>
                                                    <Box style={{width:"95%",display:'flex',justifyContent:"space-between",alignItems:"center",margin:"10px 20px"}}>
                                                        <Typography variant="h6" className="bold-text" style={{fontWeight:"bold"}}>{item?.attributes?.title}</Typography>
                                                        <Typography variant="subtitle2" className={item?.attributes?.status ==="pending" ? "statusOngoingRed" : "statusOngoingGreen"} gutterBottom>{t(item?.attributes?.status || "-")}</Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Box style={{width:"95%",display:'flex',justifyContent:"space-between",alignItems:"center",margin:"-5px 20px"}}>
                                                        <Typography variant="subtitle1" color="textPrimary">{item?.attributes?.description || '-'}</Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={10}>
                                                    <Grid container spacing={0}>
                                                        <Grid item xs={3}>
                                                            <Box style={{display:'flex',alignItems:"center"}}>
                                                                <Box style={{margin:"10px"}}>
                                                                    <img src={buildings}  height="30px" width="30px" />
                                                                </Box>
                                                                <Box>
                                                                    <Typography variant="subtitle1" color="textSecondary" style={{color:"#FC8434"}}>{t("Building")}</Typography>
                                                                    <Typography variant="subtitle1" color="textPrimary" style={{ fontWeight: 600 }}>{item?.attributes?.building_management?.name || '-'}</Typography>
                                                                </Box>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Box style={{display:'flex',alignItems:"center"}}>
                                                                <Box style={{margin:"10px"}}>
                                                                    <img src={userIcon}  height="25px" width="23px" />
                                                                </Box>
                                                                <Box>
                                                                    <Typography variant="subtitle1" color="textSecondary" style={{color:"#FC8434"}}>{t("Assigned To")}</Typography>
                                                                    <Typography variant="subtitle1" color="textPrimary" style={{ fontWeight: 600 }}>{item?.attributes?.assign_to || '-'}</Typography>
                                                                </Box>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Box style={{display:'flex',alignItems:"center"}}>
                                                                <Box style={{margin:"10px"}}>
                                                                    <img src={calanderIcon} height="25px" width="25px" />
                                                                </Box>
                                                                <Box>
                                                                    <Typography variant="subtitle1" color="textSecondary" style={{color:"#FC8434"}}>{t("Assigned On")}</Typography>
                                                                    <Typography variant="subtitle1" color="textPrimary" style={{ fontWeight: 600 }}>{this.formatDateToLongFormat(item?.attributes?.assign_on)}</Typography>
                                                                </Box>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Box style={{display:'flex',alignItems:"center"}}>
                                                                <Box style={{margin:"10px"}}>
                                                                    <img src={calanderIcon} height="25px" width="25px"  />
                                                                </Box>
                                                                <Box>
                                                                    <Typography variant="subtitle1" color="textSecondary" style={{color:"#FC8434"}}>{t("Due on")}</Typography>
                                                                    <Typography variant="subtitle1" color="textPrimary" style={{ fontWeight: 600 }}>{this.formatDateToLongFormat(item?.attributes?.end_date)}</Typography>
                                                                </Box>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                )
                            })
                    }
                </Grid>
            </Container>
        <Modal
            style={ticketManagement.createTask}
            open={Boolean(this.state.setOpen)}
            onClose={this.handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}>
            <Fade in={Boolean(this.state.setOpen)}>
                <div style={ticketManagement.modalPaper} dir={languageCondition(language,"rtl","ltr")}>
                    <Box style={{display:'flex',alignItems:'center',justifyContent:'space-between',marginBottom:"10px"}}>
                        <Typography className="bold-text" variant="h5" style={{fontWeight:"bold"}}>
                            {t("Create New Task")}
                        </Typography>
                        <IconButton onClick={this.handleClose}>
                            <img src={cancle}
                                //@ts-ignore
                                 style={ticketManagement.cancelButton}/>
                        </IconButton>
                    </Box>
                    <Divider/>
                    <Formik initialValues={{
                        startDate:"",
                        endDate:"",
                        buildingId:"",
                        roleId:"",
                        assignedTo:"",
                        title:"",
                        description:"",
                    }}
                            validationSchema={this.TaskAddSchema()}
                            enableReinitialize
                            onSubmit={(values:any) => {this.createNewTask(values)}}
                    >
                        {({   values,
                              errors,
                              touched,
                              handleBlur,
                              setFieldValue }) => (
                                <Form translate="yes" >
                                    <Grid container spacing={2} style={{marginTop:"10px"}}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                label="Start Date" variant="outlined"
                                                placeholder="Start Date"
                                                style={{border:"1px solid #ECECEC",borderRadius:"10px",backgroundColor:"#f9f9f9",marginRight:"10px"}}
                                                type={this.state.startDateName} name="startDate"  fullWidth
                                                id="nominationDate"
                                                onFocus={this.handleStartDate}
                                                value={values.startDate}
                                                onChange={(e)=> setFieldValue("startDate",e.target.value)}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <DateRangeOutlinedIcon />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                inputProps={{
                                                    min: moment().format("YYYY-MM-DD"),
                                                    max: "5000-05-31",
                                                }}
                                            />
                                            <Typography color="error" variant="subtitle2">{errors.startDate}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField label={t("End Date")} variant="outlined"
                                                       name="endDate"  fullWidth
                                                       style={{border:"1px solid #ECECEC",borderRadius:"10px",backgroundColor:"#f9f9f9",marginRight:"10px"}}
                                                       id="nominationDate"
                                                       placeholder="End Date"
                                                       onFocus={this.handleEndDate}
                                                       type={this.state.endDateName}
                                                       value={values.endDate}
                                                       onChange={(e) => setFieldValue("endDate",e.target.value)}
                                                       InputProps={{
                                                           startAdornment: (
                                                               <InputAdornment position="start">
                                                                   <DateRangeOutlinedIcon />
                                                               </InputAdornment>
                                                           )
                                                       }}
                                                       inputProps={{
                                                           min: values.startDate,
                                                           max: "5000-05-31",
                                                       }}
                                            />
                                            <Typography color="error" variant="subtitle2">{errors.endDate}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Select fullWidth id="task-type-select" variant="outlined" displayEmpty value={values.buildingId} style={{border:"1px solid #ECECEC",borderRadius:"10px",backgroundColor:"#f9f9f9",marginRight:"10px"}} className="select-input inputForBuilding" onChange={(e) => setFieldValue("buildingId",e.target.value)}>
                                                <MenuItem value="" disabled>
                                                    {t("Select Building")}
                                                </MenuItem>
                                                {
                                                    this.state.buildingList.map((item:any,key:any)=>{
                                                        return(
                                                            <MenuItem key={key} value={item.id}>{item.name}</MenuItem>
                                                        )
                                                    })
                                                }
                                            </Select>
                                            <Typography color="error" variant="subtitle2">{errors.buildingId}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Select fullWidth id="task-type-select" variant="outlined" displayEmpty value={values.roleId} style={{border:"1px solid #ECECEC",borderRadius:"10px",backgroundColor:"#f9f9f9",marginRight:"10px"}} className="select-input inputForRole" onChange={(e) => {setFieldValue("roleId",e.target.value);this.getTaskManagementUserList(e.target.value)}}>
                                                <MenuItem value="" disabled>
                                                    {t("Select Role")}
                                                </MenuItem>
                                                {
                                                    this.state.roleList.map((item:any,key:any)=>{
                                                        return(
                                                            <MenuItem key={key} value={item.id}>{item.attributes.name}</MenuItem>
                                                        )
                                                    })
                                                }
                                            </Select>
                                            <Typography color="error" variant="subtitle2">{errors.roleId}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Select fullWidth id="task-type-select" variant="outlined" displayEmpty value={values.assignedTo} style={{border:"1px solid #ECECEC",borderRadius:"10px",backgroundColor:"#f9f9f9",marginRight:"10px"}} className="select-input inputForAssinged" onChange={(e) => setFieldValue("assignedTo",e.target.value)}>
                                                <MenuItem value="" disabled>
                                                    {t("Assign To")}
                                                </MenuItem>
                                                {
                                                    this.state.userList.map((item:any,key:any)=>{
                                                        return(
                                                            <MenuItem key={key} value={item.id}>{item.full_name}</MenuItem>
                                                        )
                                                    })
                                                }
                                            </Select>
                                            <Typography color="error" variant="subtitle2">{errors.assignedTo}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField name="Title" variant="outlined" label={t("title")}
                                               id="Nomination Title"
                                               className={languageCondition(language,"placeHolderAlignmentSpace","")}
                                               style={{border:"1px solid #ECECEC",borderRadius:"10px",backgroundColor:"#f9f9f9",marginRight:"10px"}}
                                               inputProps={{
                                                   maxLength: 40
                                               }}
                                               value={values.title}
                                               onChange={(e) => setFieldValue("title",e.target.value)}
                                               fullWidth
                                            />
                                            <Typography color="error" variant="subtitle2">{errors.title}</Typography>
                                        </Grid>
                                        <Grid xs={12} style={{marginTop:"10px",padding:"0px 7px"}}>
                                            <TextField
                                                id="outlined-multiline-static"
                                                className={languageCondition(language,"placeHolderAlignmentSpace","")}
                                                label={t("Description")}
                                                multiline
                                                fullWidth
                                                style={{border:"1px solid #ECECEC",borderRadius:"10px",backgroundColor:"#f9f9f9",marginRight:"10px"}}
                                                rows={5}
                                                variant="outlined"
                                                value={values.description}
                                                onChange={(e) => setFieldValue("description",e.target.value)}
                                            />
                                            <Typography color="error" variant="subtitle2">{errors.description}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} style={{display:'flex',justifyContent:"flex-end",marginTop:"20px"}}>
                                        <Box>
                                            <DeclineButton variant="contained" style={{marginRight:"15px"}} onClick={this.handleClose}>{t("Cancel")}</DeclineButton>
                                            <ChairmanButton type="submit" variant="contained">{t("Create")}</ChairmanButton>
                                        </Box>
                                    </Grid>
                                </Form>
                    )}
                    </Formik>
                </div>
            </Fade>
        </Modal>
    </GeneralHeader>
    <Loader loading={this.state.loading} />
     </>
      );
  }
}


const ticketManagement = {
    nav: {
        display: "flex",
        justifyContent: "space-between",
    },
    subHed: {
        fontWeight: 600,
    },
    cancelButton:{
        top:15,
        right:15,
        float:"right",
        cursor:"pointer"
    },
    createTask:{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalPaper: {
        backgroundColor: "#fff",
        borderRadius: '10px',
        padding: "16px 24px 5px",
        width:"700px"
    },
};

const AcceptButton = withStyles((theme:any) => ({
    root: {
        color: "white",
        backgroundColor: "#fc8434",
        fontWeight:"bold",
        borderRadius:"10px",
        padding:"10px 20px",
        fontSize:"15px",
        '&:hover': {
            backgroundColor: "#fc8434",
        },
    },
}))(Button);

const DeclineButton = withStyles((theme:any) => ({
    root: {
        color: "#2b6fed",
        backgroundColor: "white",
        border:"1px solid #2b6fed",
        fontWeight:"bold",
        borderRadius:"10px",
        padding:"10px 20px",
        height:"55px",
        width:"150px",
        fontSize:"15px",
        marginLeft:"10px",
        '&:hover': {
            backgroundColor: "white",
        },
    },
}))(Button);

const ChairmanButton = withStyles((theme:any) => ({
    root: {
        color: "white",
        backgroundColor: "#2b6fed",
        fontWeight:"bold",
        borderRadius:"10px",
        padding:"10px 20px",
        height:"55px",
        width:"150px",
        fontSize:"15px",
        '&:hover': {
            backgroundColor: "#2b6fed",
        },
    },
}))(Button);


//@ts-ignore
export default withTranslation()(withStyles(ticketManagement)(withRouter(TaskManagement)));

// Customizable Area End
