//@ts-ignore
//@ts-nocheck
import React from "react";
// Customizable Area Start


//components
import {
 Box,
 Button,
 Typography,
 Grid,
 Card,
 CardContent,
 CardActions,
 Dialog,
 DialogTitle,
} from "@material-ui/core";
//resources
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import { withRouter } from 'react-router';
import Loader from "../../../components/src/Loader.web";
import IncidentController, { Props } from "./IncidentController.web";


//resorces
import {
 Building1,
 User_Icon,
 Info_Icon,
 Close_Icon,
 calenderBlueIcon
}
 from "../src/assets";
import {useTranslation, withTranslation} from "react-i18next";
import {languageCondition,ArrowBackIcon} from "../../../components/src/ConditionManager.web";
class IncidentDetails extends IncidentController {
 constructor(props: Props) {
   super(props);
 }
 render() {


   const id = this.state.getIncidentDetails?.id;
   const attributes = this.state.getIncidentDetails?.attributes;
   // @ts-ignore
   const {t,i18n} = this.props;
   const language = i18n.language;
   return (
     <>
       <Box className="login-wrapper incident-wrapper incident-details-wrapper-owner">
         <Grid container spacing={2} className="auth-container">
           <Grid item xs={12} md={7} className="auth-cols">
             <Box className="content-block"  dir={languageCondition(language,"rtl","ltr")}>
               <Box className="content-header">
                 <Box className="left-block blocks" style={{gap:"1rem"}}>
                   <ArrowBackIcon className="arrowBack-testcases" onClick={() => window.history.back()} />
                   <h4 className="bold-text" style={{ fontSize: "18px" }}>{attributes?.incident_title}</h4>
                 </Box>
               </Box>
               <Box className="content-block-wrapper common-incident-block" style={{ backgroundColor: "F7F9FE" }}>
                 <Box className="incident-content-wrapper">
                   {
                     attributes?.incident_status === 'Pending Confirmation' ?
                       <Card className="incident-card confirmation-card card">
                         <CardContent className="confirmation-card-content">
                           <Box className="info-row">
                             <img src={Info_Icon} className="info-icon" alt="info-icon" />
                           </Box>
                           <Typography component="h4" className="bold-text">
                             {t("Is your raised incident")}<br></br>{t("resolved ?")}
                           </Typography>
                           <Typography style={{color:"#000000d1"}}>
                             {attributes.incident_related.name} {t("is claiming to have resolved")} <br/>
                             {t("you incident for ticket id")} {id}. <br/>
                             {t("Please confirm if it is resolved")}.
                           </Typography>
                           <Box className="customButton" style={{marginTop:"10px"}}>
                             <Box className="formGroup">
                               <Button variant="outlined" className="reject-closure-btn incidentConfirmationReject" onClick={() => this.confirmOrRejectIncident(id, "reject")} >{t("Reject Closure")}</Button>
                               <Button variant="contained"  className="confirm-closure-btn incidentConfirmationApprove" onClick={() => this.confirmOrRejectIncident(id, "confirm")} >{t("Confirm Closure")}</Button>
                             </Box>
                           </Box>
                         </CardContent>
                       </Card> :
                       null
                   }
                   <Box className="incident-rows mt-15">
                     <h4 className="bold-text">{t("Incident Details")}</h4>
                     <ButtonStatus attributes={attributes}></ButtonStatus>
                   </Box>
                   <Card className="incident-card card">
                     <CardContent>
                       <Typography className="title-span" component="span">
                         {("Affected Area")}:
                       </Typography>
                       <Typography className="sub-title bold-text" component="h5">
                         {attributes?.common_area?.name}
                       </Typography>
                       <Typography className="title-span" component="span">
                         {t("Incident is related to")}:
                       </Typography>
                       <Typography className="sub-title bold-text" component="h5">
                         {attributes?.incident_related?.name}
                       </Typography>
                       <Typography className="title-span" component="span">
                         {t("Incident Number")}:
                       </Typography>
                       <Typography className="sub-title bold-text" component="h5">
                         {id}
                       </Typography>
                       <Typography component="span">
                         {t("Building")}:
                       </Typography>
                       <Typography className="sub-title bold-text" component="h5">
                         {attributes?.apartment_management?.building_name}
                       </Typography>
                       <Typography component="span">
                         {t("Unit")}:
                       </Typography>
                       <Typography className="sub-title bold-text" component="h5">
                         {attributes?.apartment_management?.apartment_name}
                       </Typography>
                       <Typography className="title-span" component="span">
                         {t("Latest update from management")}:
                       </Typography>
                       <Typography className="sub-title bold-text" component="h5">
                         {attributes?.last_update_from_management || "-"}
                       </Typography>
                       <Typography className="title-span" component="span">
                         {t("Ackwnolodged by Manager")}:
                       </Typography>
                       <Typography className="sub-title bold-text" component="h5">
                         {attributes?.acknoledged_by_manager || "-"}
                       </Typography>
                       {
                         attributes?.attachments.length !== 0 &&
                           <>
                             <Typography className="title-span" component="span">
                               {t("Photos")}
                             </Typography>
                             <CardActions className="card-img-row">
                               {
                                 attributes?.attachments?.map((val: any, index: any) => (
                                   val?.content_type === "video/mp4" || val?.content_type === "video/x-m4v" ?
                                     <Box className="video-img" key={index} onClick={() => { this.setState({ showDialog: true, file: { url: val.url, type: val?.content_type, name: val?.file_name } }) }}>
                                       <Box className="img-layer"></Box>
                                       <video className="incident-dialog-video"  >
                                         <source src={val?.url} type={val?.file?.type} />
                                       </video>
                                       <PlayCircleOutlineIcon className="play-icon" />
                                     </Box>
                                     :
                                     <Box 
                                     className="video-img" key={index} 
                                     onClick={() => { this.setState(
                                      { showDialog: true, file: 
                                      { url: val.url, type: val?.content_type, name: val?.file_name }
                                       }) }}>
                                       <Box 
                                       className="img-layer"></Box>
                                       <img src={val.url}
                                        className="card-img" alt="card-img" />
                                       <FullscreenIcon 
                                       className="play-icon" />
                                     </Box>
                                 ))
                               }
                             </CardActions>
                             <hr />
                           </>
                       }
                       <Typography className="title-span" component="span">
                         {t("Description")}:
                       </Typography>
                       <Typography className="sub-title bold-text" component="h5">
                         {attributes?.description}
                       </Typography>
                     </CardContent>
                   </Card>
                   <Box className="incident-rows mt-20">
                     <h4 className="bold-text">{t("Reporting Details")}</h4>
                   </Box>
                   <Card className="incident-card reporting-card card">
                     <CardContent>
                       <Box className="reporting-row">
                         <img src={User_Icon.default} className="icons" alt="" />
                         <Box className="reporting-right-block">
                           <h5>{t("Reported By")}:</h5>
                           <h4 className="bold-text">{attributes?.reported_by?.full_name}</h4>
                         </Box>
                       </Box>
                       <Box className="reporting-row">
                         <img src={calenderBlueIcon.default} className="icons" alt="" />
                         <Box className="reporting-right-block">
                           <h5>{t("Reported On")}:</h5>
                           <h4 className="bold-text">{attributes?.reported_on}</h4>
                         </Box>
                       </Box>
                       {attributes?.resolved_on ?
                         <Box className="reporting-row">
                           <img src={calenderBlueIcon.default} className="icons" alt="" />
                           <Box className="reporting-right-block">
                             <h5>{t("Resolved On")}:</h5>
                             <h4 className="bold-text">{attributes?.resolved_on}</h4>
                           </Box>
                         </Box> : null
                       }
                     </CardContent>
                   </Card>
                 </Box>
                 <Box className="customButton ticket-conversion">
                   <Button data-test-id="incidentTicketConversation" variant="contained" className="incidentTicketConversation" onClick={() => this.createChatRoom(id)}>{attributes?.incident_status === 'Resolved' ? t('View ticket conversation') : t('Start/ View ticket conversation')}</Button>
                 </Box>
               </Box>
             </Box>
           </Grid>
           {/* desktop footer block */}
           <Grid item
            xs={12} md={5} className="auth-cols">
             <Box className="right-block" display={
              { xs: 'none', md: 'flex'
               }}>
               <img 
               src={
                Building1.default
              } 
               className="building-logo" alt="" />
             </Box>
           </Grid>
         </Grid>
         <Dialog
         data-test-id="showDialog"
           open={
            this.state.showDialog
          }
           onClose={
            () => 
            this.setState({ showDialog: false })
          }
          aria-describedby="alert-dialog-description"
          className="diloag-wrapper"
           aria-labelledby="alert-dialog-title"
           PaperProps={{
             style: {
               borderRadius: '15px',
             },
           }}
         >
           <Box className="diloag-body">
             <Box className="diloag-header">
               <DialogTitle className="alert-dialog-title" id="alert-dialog-title">
                 {
                 this.state?.file?.name
                 }
               </DialogTitle>
               <Button 
                data-test-id="showDialogs"
               onClick={
                () => { 
                  this.setState({ showDialog: false 
                  }) 
                  }}>
                 <img src={Close_Icon} data-test-id="closeIcon" className="close-icon" onClick={() => { this.setState({ showDialog: false }) }} />
               </Button>
             </Box>
             <Box className="diloag-content-body">
               {
                 this.state.file.type === "video/mp4" 
                 ||
                  this.state.file.type === "video/x-m4v" 
                  ?
                   <video className="incident-dialog-video" controls >
                     <source 
                     src={
                      this.state.file.url
                      } type={
                        this.state.file.type
                        } />
                   </video>
                   :
                   <Box>
                     <img src={this.state?.file?.url} className="incident-dialog-photo" alt="card-img" />
                   </Box>
               }
             </Box>
           </Box>
         </Dialog>
       </Box>
       <Loader loading={this.state.loading} />
     </>
   )
 }
}


const ButtonStatus = (props: any) => {
 const attributes = props?.attributes
 const {t} = useTranslation()
 const checkCl = () => {
   if (attributes?.incident_status === 'Unresolved') {
     return "contain danger"
   } else if (attributes?.incident_status === 'Resolved') {
     return 'contain warning'
   } else if (attributes?.incident_status === 'Ongoing') {
     return 'incident-details-ongoing';
   }else if (attributes?.incident_status === 'Pending Confirmation') {
     return 'contain warning';
   } else {
     return 'contain success';
   }
 }
 return (
   <>
     <Box className="customButton">
       <Button variant="contained" className={checkCl()}  > {t(attributes?.incident_status)}</Button>
     </Box>
   </>
 )
}


export default withTranslation()(withRouter(IncidentDetails));

// Customizable Area End
