import React from "react";
// Customizable Area Start
import { withTranslation } from "react-i18next";
import {
  Box,
  Breadcrumbs,
  Button,
  Chip,
  Grid,
  MenuItem,
  Tab,
  Tabs,
  Typography,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
  Table,
  Divider,
  Paper,
  Dialog,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { callIcon, mailIcon, accountIcon,facebook,twiter,snap, instagram,Noprofile} from './assets'
import SearchIcon from "@material-ui/icons/Search";
import MoreIcon from "@material-ui/icons/MoreVert";
import AlertError from "../../../components/src/AlertError.web";
import { languageCondition } from "../../../components/src/ConditionManager.web";
import PaginationModule from "../../StoreCredits/src/PaginationModule.web";
import Loader from "../../../components/src/Loader.web";
import MapDialog from "../../../components/src/MuiDialog/MapDialog.web";
import CEHeaderComponentWeb from "../../../components/src/HeaderComponent/CEHeaderComponent.web";
import FilterSelect from "../../../components/src/CommonComponents/FilterSelect.web";
import SearchInput from "../../../components/src/CommonComponents/SearchInput.web";
import { Menu } from "@szhsin/react-menu";
import { Link as NavLink } from "react-router-dom";
import AlertDialog from "../../../components/src/MuiDialog/AlertDialog.web";
import { tickImg } from "../../FriendList/src/CompanyEmployee/CEDeleteRevokeMember.web";
import DialogHeader from "../../../components/src/MuiDialog/DialogHeader.web";
import NormalTextField from "../../../components/src/CommonComponents/NormalTextField.web";
import NormalSelect from "../../../components/src/CommonComponents/NormalSelect.web";
import NormalTextArea from "../../../components/src/CommonComponents/NormalTextArea.web";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { ErrorHandler } from "../../../components/src/UtilFunction.web";
// Customizable Area End

import SalesManagerProfileController, { Props, } from "./SalesManagerProfileController.web";
import SuccessAlert from "../../../components/src/AlertSuccess.web";

class SalesManagerProfile extends SalesManagerProfileController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

        // Customizable Area Start
  renderTabPanel = (children: React.ReactNode, value: number, childrenIndex: number) => {
    return (
      <Box role="tabpanel" hidden={value !== childrenIndex}>
        {value === childrenIndex && <Box>{children}</Box>}
      </Box>
    );
  };

  renderComplexCooTable = (t: any, language: any) => {
    return (
      <CooComplexDetails>
        <Grid container xs={12} spacing={2}>
          <Box sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <Box className="filter-head-box">
              <FilterSelect
                option={[
                  { label: "All", value: "all" },
                  ...this.state.companyList.map((item: any) => ({ label: item.attributes.company_name, value: item.id })),
                ]}
                label="Select Company"
                data-testId="companyTestId"
                language={language}
                onChange={this.handleCompanyChange}
                t={t}
                value={this.state.companyId}
              />
              <FilterSelect
                onChange={this.handleCountryChange}
                value={this.state.country}
                option={[
                  { label: "All", value: "all" },
                  ...this.state.countryList.map((item: any) => ({ label: item.attributes.name, value: item.attributes.name })),
                ]}
                t={t}
                language={language}
                label="Select Country"
                data-testId="countryTestId"
              />
              <FilterSelect
                option={[
                  { label: "All", value: "all" },
                  ...this.state.cityList.map((item: any) => ({ label: item.name, value: item.name })),
                ]}
                label="Select City"
                language={language}
                t={t}
                onChange={this.handleCityChange}
                value={this.state.city}
                data-testId="cityTestId"
              />
              <Button
                data-testId="cooComplexSearchBtnTestId"
                startIcon={<SearchIcon />}
                onClick={() => this.getComplexTabData("1")}
              >
                {t("Search")}
              </Button>
            </Box>
          </Box>
        </Grid>

        <Grid container xs={12} spacing={2}>
          <Grid className="table-box-card sales-complex-table-card">
            <Box className="table-top">
              <Box className="table-main-heading">
                <h4 className="bold-text">{t("List of Assigned Complexes")}</h4>
              </Box>
              <Box className="filter-head-box" style={{ margin: "0" }}>
                <SearchInput
                  t={t}
                  language={language}
                  data-testId="complexTabSeachTestId"
                  onChange={this.handleSearchInputChange}
                  label="Search by complex name"
                />
              </Box>
            </Box>
            <Divider />
            <TableContainer component={Paper} className="table-container">
              <Table style={{ minWidth: 900 }}>
                <TableHead>
                  <TableRow>
                    <TableCell align={languageCondition(language, "right", "left")}>{t("#")}</TableCell>
                    <TableCell className="bold-text table-heading" align={languageCondition(language, "right", "left")}>
                      {t("Complex Name")}
                    </TableCell>
                    <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                      {t("Account Manager")}
                    </TableCell>
                    <TableCell className="bold-text table-heading" align={languageCondition(language, "right", "left")}>
                      {t("Total Units")}
                    </TableCell>
                    <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                      {t("Subscription Ends in")}
                    </TableCell>
                    <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                      {t("Subscription Type")}
                    </TableCell>
                    <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
                      {t("Paying Month")}
                    </TableCell>
                    <TableCell align={languageCondition(language, "right", "left")} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.cooComplexData.map((item: any, index) => (
                    <TableRow key={index}>
                      <TableCell className="tableContent" align={languageCondition(language, "right", "left")}>
                        {index + 1}
                      </TableCell>
                      <TableCell className="tableContent" align={languageCondition(language, "right", "left")}>
                        <Box className="tableContentComplex">
                          {this.handleNullValue(item.attributes.complex_name)}
                          <img className="accountIcon" src={accountIcon} />
                        </Box>
                      </TableCell>
                      <TableCell className="tableContent" align={languageCondition(language, "right", "left")}>
                        {this.handleNullValue(item.attributes.account_manager)}
                      </TableCell>
                      <TableCell className="tableContent" align={languageCondition(language, "right", "left")}>
                        {this.handleNullValue(item.attributes.totle_unit)}
                      </TableCell>
                      <TableCell className="tableContent" align={languageCondition(language, "right", "left")}>
                        {this.handleNullValue(item.attributes.subscription_ends)}
                      </TableCell>
                      <TableCell className="tableContent" align={languageCondition(language, "right", "left")}>
                        {this.handleNullValue(item.attributes.subscription_plan)}
                      </TableCell>
                      <TableCell className="tableContent" align={languageCondition(language, "right", "left")}>
                        {this.handleNullValue(item.attributes.paying_month)}
                      </TableCell>
                      <TableCell className="tableContent" align={languageCondition(language, "right", "left")}>
                        <Menu  menuButton={<MoreIcon />}>
                          <MenuItem data-test-id="view-location" onClick={()=>{this.handleMapvalues(item)}} >
                            {t("View Location on Map")}
                          </MenuItem>
                          <MenuItem data-test-id="menu-complex-page"><NavLink to={`/CompanyEmployee/ComplexDetails/${item.id}`}>{t("Go to Complex Page")}</NavLink></MenuItem>
                          <MenuItem data-test-id="menu-login-permission" onClick={() => this.handleOpenRevokeModal(item)}>{t("Revoke Login Permission")}</MenuItem>
                          <MenuItem data-test-id="menu-assign-manager" onClick={() => this.handleReassignModal(item)}>{t("Assign to another Manager")}</MenuItem>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box className="table-bottom">
              <PaginationModule
                handlePagination={this.handleCooComplexPagination}
                page={this.state.paginationData?.current_page}
                data-testId={"cooComplexPaginationTestId"}
                pagination={this.state.paginationData}
              />
            </Box>
          </Grid>
        </Grid>
      </CooComplexDetails>
    );
  };

  renderIndividualBuildingCooTable = (t: any, language: any) => {
    return (
      <CooComplexDetails dir={languageCondition(language, "rtl", "ltr")}>
        <Grid container xs={12} spacing={2}>
          <Box sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <Box className="filter-head-box">
              <FilterSelect
                label="Select Company"
                option={[
                  { label: "All", value: "all" },
                  ...this.state.companyList.map((item: any) => ({ label: item.attributes.company_name, value: item.id })),
                ]}
                onChange={this.handleCompanyChange}
                language={language}
                value={this.state.companyId}
                t={t}
                data-testId="companyTestId"
              />
              <FilterSelect
                option={[
                  { label: "All", value: "all" },
                  ...this.state.salesManagerList.map((item: any) => ({ label: item.full_name, value: item.id })),
                ]}
                label="Select Sales Manager"
                language={language}
                t={t}
                onChange={this.handleSalesManagerChange}
                value={this.state.salesManagerId}
                data-testId="cooSalesManagerSelectTestId"
              />
              <FilterSelect
                option={[
                  { label: "All", value: "all" },
                  ...this.state.countryList.map((item: any) => ({ label: item.attributes.name, value: item.attributes.name })),
                ]}
                label="Select Country"
                language={language}
                t={t}
                onChange={this.handleCountryChange}
                value={this.state.country}
                data-testId="cooCountrySelectTestId"
              />
              <FilterSelect
                option={[
                  { label: "All", value: "all" },
                  ...this.state.cityList.map((item: any) => ({ label: item.name, value: item.name })),
                ]}
                label="Select City"
                language={language}
                t={t}
                onChange={this.handleCityChange}
                value={this.state.city}
                data-testId="cooCitySelectTestId"
              />
              <FilterSelect
                option={[{ label: "All", value: "all" }]}
                label="Select Subscription ends in"
                language={language}
                t={t}
                onChange={this.handleSubscriptionChange}
                value={this.state.subscriptionEndsIn}
                data-testId="cooSubscriptionSelectTestId"
              />
              <Button
                startIcon={<SearchIcon />}
                onClick={() => this.getIndividualBuildingListing("1")}
                data-testId="searchButtonTestId"
              >
                {t("Search")}
              </Button>
            </Box>
          </Box>
        </Grid>

        <Grid container xs={12} spacing={2}>
          <Grid className="table-box-card">
            <Box className="table-top sales-building-table-top">
              <Box className="table-main-heading">
                <h4 className="building-heading bold-text">{t("Total assigned individual building")}</h4>
              </Box>
              <Box className="filter-head-box" style={{ margin: "0" }}>
                <SearchInput
                  t={t}
                  onChange={this.handleSearchInputChange}
                  language={language}
                  label="Search by building name"
                  data-testId="individualBuildingSearchTestId"
                />
              </Box>
            </Box>
            <Divider />
            <TableContainer component={Paper} className="table-container">
              <Table style={{ minWidth: 900 }}>
                <TableHead>
                  <TableRow className="building-table">
                    <TableCell align={languageCondition(language, "right", "left")}>{t("#")}</TableCell>
                    <TableCell
                      className="bold-text building-table-th"
                      align={languageCondition(language, "right", "left")}
                    >
                      {t("Building")}
                    </TableCell>
                    <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                      {t("Account Manager")}
                    </TableCell>
                    <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
                      {t("No. of floors")}
                    </TableCell>
                    <TableCell
                      align={languageCondition(language, "right", "left")}
                      className="bold-text building-table-th"
                    >
                      {t("Subscription Ends in")}
                    </TableCell>
                    <TableCell className="bold-text" align={languageCondition(language, "rtl", "ltr")}>
                      {t("Total Units")}
                    </TableCell>
                    <TableCell
                      className="bold-text building-table-th"
                      align={languageCondition(language, "rtl", "ltr")}
                    >
                      {t("Country")}
                    </TableCell>
                    <TableCell className="bold-text" align={languageCondition(language, "rtl", "ltr")}>
                      {t("City")}
                    </TableCell>
                    <TableCell align={languageCondition(language, "right", "left")} />
                  </TableRow>
                </TableHead>
                <TableBody className="building-body">
                  {this.state.individualBuildingList.map((item: any, index) => (
                    <TableRow key={index}>
                      <TableCell align={languageCondition(language, "right", "left")}>{index + 1}</TableCell>
                      <TableCell align={languageCondition(language, "right", "left")}>
                        {item.attributes.building_name}
                      </TableCell>
                      <TableCell align={languageCondition(language, "right", "left")}>
                        {item.attributes.account_manager}
                      </TableCell>
                      <TableCell align={languageCondition(language, "right", "left")}>
                        {item.attributes.no_of_floor}
                      </TableCell>
                      <TableCell align={languageCondition(language, "right", "left")}>
                        {item.attributes.subscription_ends}
                      </TableCell>
                      <TableCell align={languageCondition(language, "right", "left")}>
                        {item.attributes.totle_unit}
                      </TableCell>
                      <TableCell align={languageCondition(language, "right", "left")}>
                        {item.attributes.country}
                      </TableCell>
                      <TableCell align={languageCondition(language, "right", "left")}>{item.attributes.city}</TableCell>
                      <TableCell align={languageCondition(language, "right", "left")}>
                        <Menu  menuButton={<MoreIcon/>}>
                          <MenuItem data-test-id="view-location-details" onClick={()=>{this.handleMapvalues(item)}} >
                            {t("View Location on map")}
                          </MenuItem>
                          <MenuItem data-test-id="view-building"><NavLink to={`/CompanyEmployee/BuildingDetailsPage/${item.id}`}>{t("Go to Building Page")}</NavLink></MenuItem>
                          <MenuItem data-test-id="view-location" onClick={() => this.handleOpenBuildingRevokeModal(item)}>{t("Revoke Login Permission")}</MenuItem>
                          <MenuItem data-test-id="view-assign" onClick={() => this.handleReassignBuildingModal(item)}>{t("Assign to another Manager")}</MenuItem>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box className="table-bottom">
              <PaginationModule
                data-testId={"cooComplexPaginationTestId"}
                pagination={this.state.paginationData}
                handlePagination={this.handlePagination}
                page={this.state.paginationData?.current_page}
              />
            </Box>
          </Grid>
        </Grid>
      </CooComplexDetails>
    );
  };

  renderOwnerTable = (t: any, language: any) => {
    return (
      <CooComplexDetails>
        <Grid container xs={12} spacing={2}>
          <Box sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <Box className="filter-head-box">
              <FilterSelect
                option={[
                  { label: "All", value: "all" },
                  { label: "Accepted", value: "Accepted" },
                  { label: "Invited", value: "Invited" },
                  { label: "Awaited Approval", value: "Awaited Approval" },
                  { label: "On Board", value: "On Board" },
                ]}
                label={t("Select Owner Status")}
                language={language}
                t={t}
                onChange={this.handleOwnerStatusChange}
                value={this.state.ownerStatus}
                data-testId="ownerStatusTestId"
              />
              <FilterSelect
                option={[
                  { label: "All", value: "all" },
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]}
                label={t("Select Paying Clients")}
                language={language}
                t={t}
                onChange={this.handlePayingClientsChange}
                value={this.state.payingClient}
                data-testId="payingClientTestId"
              />
              <Button
                data-testId="ownerSearchBtnTestId"
                onClick={() => this.getRegisteredOwner("1")}
                startIcon={<SearchIcon />}
              >
                {t("Search")}
              </Button>
            </Box>
          </Box>
        </Grid>

        <Grid container xs={12} spacing={2}>
          <Grid className="table-box-card">
            <Box className="table-top">
              <Box className="table-main-heading">
                <h4 className="bold-text">{t("List of registered owners")}</h4>
              </Box>
              <Box className="filter-head-box" style={{ margin: "0" }}>
                <FilterSelect
                  label="Sort By"
                  value={this.state.sortBy}
                  onChange={this.handleSortByChange}
                  language={language}
                  option={[
                    { label: "Ascending", value: "asc" },
                    { label: "Descending", value: "desc" },
                  ]}
                  t={t}
                  data-testId="sortBySelectTestId"
                />
                <SearchInput
                  t={t}
                  data-testId="searchInputTestId"
                  label={t("Search by owner name or id")}
                  language={language}
                  onChange={this.handleSearchInputChange}
                />
              </Box>
            </Box>
            <Divider />
            <TableContainer component={Paper} className="table-container">
              <Table style={{ minWidth: 900 }}>
                <TableHead className="owner-table-head">
                  <TableRow className="owner-table">
                    <TableCell align={languageCondition(language, "right", "left")}>{t("#")}</TableCell>
                    <TableCell className="bold-text table-th" align={languageCondition(language, "right", "left")}>
                      {t("Owner Name")}
                    </TableCell>
                    <TableCell className="table-th bold-text" align={languageCondition(language, "right", "left")}>
                      {t("Owner ID")}
                    </TableCell>
                    <TableCell className="table-th bold-text" align={languageCondition(language, "right", "left")}>
                      {t("Total Unit")}
                    </TableCell>
                    <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
                      {t("Paying Clients")}
                    </TableCell>
                    <TableCell className="bold-text table-th" align={languageCondition(language, "right", "left")}>
                      {t("Managed By")}
                    </TableCell>
                    <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                      {t("Owner Status")}
                    </TableCell>
                    <TableCell
                      align={languageCondition(language, "right", "left")}
                      className="bold-text table-th"
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="owner-table-body">
                  {this.state.registeredOwner.map((item: any, index: number) => (
                    <TableRow key={index}>
                      <TableCell align={languageCondition(language, "right", "left")}>{index + 1}</TableCell>
                      <TableCell align={languageCondition(language, "right", "left")}>
                        {item.attributes.owner_name}
                      </TableCell>
                      <TableCell align={languageCondition(language, "right", "left")}>
                        {item.attributes.owner_id}
                      </TableCell>
                      <TableCell align={languageCondition(language, "right", "left")}>
                        {item.attributes.Total_units}
                      </TableCell>
                      <TableCell align={languageCondition(language, "right", "left")}>
                        {item.attributes.paying_clients}
                      </TableCell>
                      <TableCell align={languageCondition(language, "right", "left")}>
                        {item.attributes.managed_by}
                      </TableCell>
                      <TableCell align={languageCondition(language, "right", "left")}>
                        {item.attributes.status === "Awaited Approval" && (
                          <Chip label={item.attributes.status} className="ownerStatusOrange" />
                        )}
                        {item.attributes.status === "On Board" && (
                          <Chip label={item.attributes.status} className="ownerStatusGreen" />
                        )}
                        {item.attributes.status === "Accepted" && (
                          <Chip label={item.attributes.status} className="ownerStatusGreen" />
                        )}
                        {item.attributes.status === "Invited" && (
                          <Chip label={item.attributes.status} className="ownerStatusBlue" />
                        )}
                      </TableCell>
                      <TableCell className="tableContent" align={languageCondition(language, "right", "left")}>
                        <Menu menuButton={<MoreIcon />}>
                          <MenuItem data-test-id="menu-view-details">{t("View Details")}</MenuItem>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box className="table-bottom">
              <PaginationModule
                data-testId={"cooPaginationTestId"}
                page={this.state.paginationData?.current_page}
                pagination={this.state.paginationData}
                handlePagination={this.handleCooPagination}
              />
            </Box>
          </Grid>
        </Grid>
      </CooComplexDetails>
    );
  };

  renderPropertyTable = (t: any, language: any) => {
    return (
      <CooComplexDetails>
        <Grid container xs={12} spacing={2}>
          <Box sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <Box className="filter-head-box">
              <FilterSelect
                option={[
                  { label: "All", value: "all" },
                  ...this.state.countryList.map((item: any) => ({ label: item.attributes.name, value: item.attributes.name })),
                ]}
                label="Select Country"
                language={language}
                t={t}
                onChange={this.handleCountryChange}
                value={this.state.country}
                data-testId="countrySelectTestId"
              />
              <FilterSelect
                option={[
                  { label: "All", value: "all" },
                  ...this.state.cityList.map((item: any) => ({ label: item.name, value: item.name })),
                ]}
                label="Select City"
                language={language}
                t={t}
                onChange={this.handleCityChange}
                value={this.state.city}
                data-testId="citySelectTestId"
              />
              <FilterSelect
                option={[
                  { label: "All", value: "all" },
                  ...this.state.propertyManagerList.map((item: any) => ({
                    label: item.attributes.name,
                    value: item.id,
                  })),
                ]}
                label="Select Property Manager"
                language={language}
                t={t}
                onChange={this.handlePropertyManagerIdChange}
                value={this.state.propertyManagerId}
                data-testId="propertyManagerIdSelectTestId"
              />
              <Button
                startIcon={<SearchIcon />}
                onClick={() => this.getPropertyManagerList("1")}
                data-testId="searchButtonTestId"
              >
                {t("Search")}
              </Button>
            </Box>
          </Box>
        </Grid>

        <Grid container xs={12} spacing={2}>
          <Grid className="table-box-card">
            <Box className="table-top">
              <Box className="table-main-heading">
                <h4 className="bold-text">{t("List of assigned property developer")}</h4>
              </Box>
              <Box className="filter-head-box" style={{ margin: "0" }}>
                <FilterSelect
                  value={this.state.sortBy}
                  t={t}
                  language={language}
                  option={[
                    { label: "Ascending", value: "asc" },
                    { label: "Descending", value: "desc" },
                  ]}
                  onChange={this.handleSortByChange}
                  label="Sort By"
                  data-testId="sortBySelectTestId"
                />
                <SearchInput
                  t={t}
                  label={t("Search by name or id")}
                  language={language}
                  data-testId="searchInputTestId"
                  onChange={this.handleSearchInputChange}
                />
              </Box>
            </Box>
            <Divider />
            <TableContainer component={Paper} className="table-container">
              <Table style={{ minWidth: 900 }}>
                <TableHead className="sales-property-table">
                  <TableRow>
                    <TableCell align={languageCondition(language, "right", "left")}>{t("#")}</TableCell>
                    <TableCell
                      className="bold-text sales-property-table-head"
                      align={languageCondition(language, "right", "left")}
                    >
                      {t("Property Manager Name")}
                    </TableCell>
                    <TableCell
                      className="bold-text sales-property-table-head"
                      align={languageCondition(language, "right", "left")}
                    >
                      {t("Property Manager ID")}
                    </TableCell>
                    <TableCell
                      className="sales-property-table-head bold-text"
                      align={languageCondition(language, "right", "left")}
                    >
                      {t("Managed Units")}
                    </TableCell>
                    <TableCell align={languageCondition(language, "right", "left")} />
                  </TableRow>
                </TableHead>
                <TableBody className="sales-property-body">
                  {this.state.propertyManagerList.map((item: any, index: number) => (
                    <TableRow key={index}>
                      <TableCell align={languageCondition(language, "right", "left")}>{index + 1}</TableCell>
                      <TableCell align={languageCondition(language, "right", "left")}>{item.attributes.name}</TableCell>
                      <TableCell align={languageCondition(language, "right", "left")}>{item.id}</TableCell>
                      <TableCell align={languageCondition(language, "right", "left")}>
                        {item.attributes.managed_units}
                      </TableCell>
                      <TableCell align={languageCondition(language, "right", "left")}>
                        <Menu menuButton={<MoreIcon />}>
                          <MenuItem data-test-id="view-location">{t("View Details")}</MenuItem>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box className="table-bottom">
              <PaginationModule
                pagination={this.state.paginationData}
                page={this.state.paginationData?.current_page}
                data-testId={"cooComplexPaginationTestId"}
                handlePagination={this.handlePagination}
              />
            </Box>
          </Grid>
        </Grid>
      </CooComplexDetails>
    );
  };

  renderAccountTable = (t: any, language: any) => {
    return (
      <CooComplexDetails>
        <Grid container xs={12} spacing={2}>
          <Box sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <Box className="filter-head-box">
              <FilterSelect
                t={t}
                label="Select Company"
                language={language}
                onChange={this.handleCompanyChange}
                value={this.state.companyId}
                option={[
                  { label: "All", value: "all" },
                  ...this.state.companyList.map((item: any) => ({ label: item.attributes.company_name, value: item.id })),
                ]}
                data-testId="companyTestId"
              />
              <FilterSelect
                label="Select Country"
                language={language}
                t={t}
                option={[
                  { label: "All", value: "all" },
                  ...this.state.countryList.map((item: any) => ({ label: item.attributes.name, value: item.attributes.name })),
                ]}
                onChange={this.handleCountryChange}
                data-testId="accountTabCountryChangeTestId"
                value={this.state.country}
              />
              <FilterSelect
                option={[
                  { label: "All", value: "all" },
                  ...this.state.cityList.map((item: any) => ({ label: item.name, value: item.name })),
                ]}
                value={this.state.city}
                language={language}
                t={t}
                label="Select City"
                onChange={this.handleCityChange}
                data-testId="accountTabCityChangeTestId"
              />
              <Button
                onClick={() => this.getAccountManagerTabData("1")}
                data-testId="accontTabSearchBtTestId"
                startIcon={<SearchIcon />}
              >
                {t("Search")}
              </Button>
            </Box>
          </Box>
        </Grid>

        <Grid container xs={12} spacing={2}>
          <Grid className="table-box-card">
            <Box className="table-top">
              <Box className="table-main-heading sales-account-manager-table-heading">
                <h4 className="bold-text">{t("List of assigned account managers")}</h4>
              </Box>
              <Box className="filter-head-box" style={{ margin: "0" }}>
                <FilterSelect
                  label="Sort By"
                  t={t}
                  value={this.state.sortBy}
                  option={[
                    { label: "Ascending", value: "asc" },
                    { label: "Descending", value: "desc" },
                  ]}
                  language={language}
                  data-testId="accountTabSortByChangeTestId"
                  onChange={this.handleSortByChange}
                />
                <SearchInput
                  label="Search by name or id"
                  t={t}
                  onChange={this.handleSearchInputChange}
                  language={language}
                  data-testId="accounTabSearchInput"
                />
              </Box>
            </Box>
            <Divider />
            <TableContainer component={Paper} className="table-container">
              <Table style={{ minWidth: 900 }}>
                <TableHead>
                  <TableRow>
                    <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                      {t("#")}
                    </TableCell>
                    <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                      {t("Account Manager")}
                    </TableCell>
                    <TableCell align={languageCondition(language, "right", "left")} className="bold-text am-table-th">
                      {t("ID")}
                    </TableCell>
                    <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                      {t("Email Address")}
                    </TableCell>
                    <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                      {t("Assigned Complexes")}
                    </TableCell>
                    <TableCell align={languageCondition(language, "right", "left")} className="am-table-th bold-text">
                      {t("Assigned Buildings")}
                    </TableCell>
                    <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                      {t("Assigned Owners")}
                    </TableCell>
                    <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                      {t("Assigned Property Developers")}
                    </TableCell>
                    <TableCell align={languageCondition(language, "right", "left")} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.cooAccountData.map((item: any, index) => (
                    <TableRow key={index}>
                      <TableCell align={languageCondition(language, "right", "left")}>{index + 1}</TableCell>
                      <TableCell className="tableContent" align={languageCondition(language, "right", "left")}>
                        {this.handleNullValue(item.attributes.full_name)}
                      </TableCell>
                      <TableCell align={languageCondition(language, "right", "left")}>
                        {this.handleNullValue(item.id)}
                      </TableCell>
                      <TableCell align={languageCondition(language, "right", "left")} className="tableContent">
                        {this.handleNullValue(item.attributes.email)}
                      </TableCell>
                      <TableCell align={languageCondition(language, "right", "left")} className="tableContent">
                        {this.handleNullValue(item.attributes.assigend_complexes)}
                      </TableCell>
                      <TableCell align={languageCondition(language, "right", "left")}>
                        {this.handleNullValue(item.attributes.assigend_buildings)}
                      </TableCell>
                      <TableCell align={languageCondition(language, "right", "left")} className="tableContent">
                        {this.handleNullValue(item.attributes.assigend_owners)}
                      </TableCell>
                      <TableCell align={languageCondition(language, "right", "left")} className="tableContent">
                        {this.handleNullValue(item.attributes.assigend_property_developers)}
                      </TableCell>
                      <TableCell align={languageCondition(language, "right", "left")}>
                        <Menu menuButton={<MoreIcon />}>
                          <MenuItem data-test-id="menu-view-details">{t("View Details")}</MenuItem>
                          <MenuItem data-test-id="menu-sales-assign" onClick={() => this.openAssignSalesManager(item)}>{t("Move to another sales manager")}</MenuItem>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box className="table-bottom">
              <PaginationModule
                page={this.state.paginationData?.current_page}
                data-testId={"cooAccountPaginationTestId"}
                handlePagination={this.handleCooAccountPagination}
                pagination={this.state.paginationData}
              />
            </Box>
          </Grid>
        </Grid>
      </CooComplexDetails>
    );
  };

  renderReassignMainForm = (t: any, language: any) => {
    return (
      <Dialog
        dir={languageCondition(language, "rtl", "ltr")}
        maxWidth="md"
        open={this.state.openReassignModal}
        scroll="paper"
        fullWidth
        data-testId="reassignModal"
        className="reassignModal"
      >
        <Formik
          validationSchema={Yup.object().shape({
            saleNewAccountManager: Yup.string().required("Account manager is required"),
            saleNote: Yup.string().required("Note is required"),
          })}
          onSubmit={(values, { resetForm }) => {
            this.setState({ loader: true, openReassignModal: false }, () => {
              this.submitToAnotherAccountManager(values);
              resetForm();
            });
          }}
          initialValues={this.state.reassignForm}
          enableReinitialize
        >
          {({ values, getFieldProps, handleSubmit, errors, touched, setFieldValue }) => {
            return (
              <Form translate="yes" onSubmit={handleSubmit}>
                <DialogHeader t={t} title="Assign to another Manager" onClose={this.handleCloseReassignModal} />
                <DialogContent dividers>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <NormalTextField
                        language={language}
                        isLabel={true}
                        value={values.complexId}
                        t={t}
                        label={t("Complex ID")}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <NormalTextField
                        language={language}
                        disabled
                        isLabel={true}
                        value={values.complexName}
                        label={t("Complex Name")}
                        t={t}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <NormalTextField
                        t={t}
                        label={t("Sales Manager")}
                        isLabel={true}
                        language={language}
                        value={values.salesManager}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <NormalTextField
                        language={language}
                        label={t("Current Account Manager")}
                        isLabel={true}
                        value={values.accountManager}
                        t={t}
                        disabled
                      />
                    </Grid>
                    {this.state.userRole === "coo" && (
                      <Grid item xs={12}>
                        <NormalTextField
                          language={language}
                          label={t("New Sales Manager")}
                          isLabel={true}
                          value={values.saleNewSalesManager}
                          t={t}
                          disabled
                        />
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <NormalSelect
                        {...getFieldProps("saleNewAccountManager")}
                        t={t}
                        language={language}
                        label={t("New Account Manager")}
                        option={this.state.accountManagerList.map((item: any) => ({
                          label: item.attributes.employee_name,
                          value: item.attributes.employee_account_id,
                        }))}
                        onChange={(event: any) => {
                          const value = event.target.value;
                          setFieldValue("saleNewAccountManager", value);

                          const reportPerson = this.state.accountManagerList.find(
                            (item: any) => Number(item.attributes.employee_account_id) === Number(event.target.value)
                          );

                          setFieldValue("saleNewSalesManager", reportPerson?.attributes?.reporting_person?.full_name);
                        }}
                        id="reassignAccountManagerChangeTestId"
                      />
                      {ErrorHandler(t, touched.saleNewAccountManager, errors.saleNewAccountManager)}
                    </Grid>
                    <Grid item xs={12}>
                      <NormalTextArea
                        {...getFieldProps("saleNote")}
                        t={t}
                        language={language}
                        label="Notes"
                        maxRows={5}
                        minRows={5}
                        data-testId="noteInputTestId"
                      />
                      {ErrorHandler(t, touched.saleNote, errors.saleNote)}
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions className="dialog__button_group">
                  <Button
                    className="cancel-button" onClick={this.handleCloseReassignModal}
                  >
                    {t("Cancel")}
                  </Button>
                  <Button type="submit" className="add-button">
                    {t("Reassign")}
                  </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
    );
  };

  renderReassignAccountManagerForm = (t: any, language: any) => {
    return (
      <Dialog
        dir={languageCondition(language, "rtl", "ltr")}
        maxWidth="md"
        open={this.state.openAccountReassignModal}
        scroll="paper"
        fullWidth
        className="reassignAccountModal"
      >
        <Formik
          validationSchema={Yup.object().shape({
            new: Yup.string().required("Sales manager is required"),
          })}
          onSubmit={(values, { resetForm }) => {
            this.setState({ loader: true, openAccountReassignModal: false }, () => {
              this.submitAssignSalesAccountManager(values);
              resetForm();
            });
          }}
          initialValues={this.state.reassignSales}
          enableReinitialize
        >
          {({ values, getFieldProps, handleSubmit, errors, touched, setFieldValue }) => {
            return (
              <Form translate="yes" onSubmit={handleSubmit}>
                <DialogHeader t={t} title="Assign to another manager" onClose={this.closeAssignSalesManager} />
                <DialogContent dividers>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <NormalTextField
                        language={language}
                        isLabel={true}
                        value={values.id}
                        t={t}
                        label={t("Account Manager ID")}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <NormalTextField
                        language={language}
                        disabled
                        isLabel={true}
                        value={values.name}
                        label={t("Name")}
                        t={t}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <NormalTextField
                        t={t}
                        label={t("Current Sales Manager")}
                        isLabel={true}
                        language={language}
                        value={values.current}
                        disabled
                      />
                    </Grid>
                    {this.state.userRole === "coo" && (
                      <Grid item xs={6}>
                       <NormalSelect
                          {...getFieldProps("new")}
                          t={t}
                          language={language}
                          label={t("New Sales Manager")}
                          option={this.state.salesAssignManagerList.map((item: any) => ({
                            label: item.full_name,
                            value: item.id,
                          }))}
                        />
                        {ErrorHandler(t, touched.new, errors.new)}
                      </Grid>
                    )}
                  </Grid>
                </DialogContent>
                <DialogActions className="dialog__button_group">
                  <Button className="cancel-button" onClick={this.closeAssignSalesManager}>
                    {t("Cancel")}
                  </Button>
                  <Button type="submit" className="add-button">
                    {t("Reassign")}
                  </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
    );
  };
        // Customizable Area End

    render() {
        // Customizable Area Start
    const { t, i18n }: any = this.props;
    const language = i18n.language;

    return (
      <CEHeaderComponentWeb>
        <CooOwnerDetails>
        <Box>
                    <Breadcrumbs>
                        <Typography className="topHeading" data-testId={"navigatetodashboard"} onClick={this.handleNavigatedashboard}>{t("My Dashboards")}</Typography>
                        <Typography className="topHeading" onClick={this.handleNavigatedashboard} >{t("General Dashboard")}</Typography>
                        {this.state.profileRole==="Sales Manager"?
                        <Typography className="topHeading" data-testId={"navigateSalesmanager"} onClick={this.handleNavigateSalesManager}>{t("Assigned Sales Managers")}</Typography>
                        :
                        <Typography className="topHeading" data-testId={"navigateAccountmanager"} onClick={this.handleNavigateAccountManager}>{t("Assigned Account Managers")}</Typography>
                        }
                        <Typography color="primary">{this.state.salesManagerProfileDetails?.full_name?.value!==null?this.state.salesManagerProfileDetails?.full_name?.value:"-"}</Typography>
                    </Breadcrumbs>
                </Box>
                <Typography className="salesManagerName" data-testId={"salesManagerName"}>
                    {this.state.salesManagerProfileDetails?.full_name?.value!==null?this.state.salesManagerProfileDetails?.full_name?.value:"-"}
                </Typography>
                <Typography className="generalDetails">
                {this.state.salesManagerProfileDetails?.id}
                </Typography>
                <Box>
                    <Grid container spacing={1}>
                        <Grid item lg={4}>
                            <Box className='profileSection'>
                                <img src={this.state.salesManagerProfileDetails?.profile_pic!==null?this.state.salesManagerProfileDetails?.profile_pic?.url:Noprofile} className="userProfile" />
                                <Typography className="generalDetails"> {this.state.salesManagerProfileDetails?.full_name?.value!==null?this.state.salesManagerProfileDetails?.full_name?.value:"-"}</Typography>
                                <Chip className="payingLabel" label={t(this.state.profileRole==="Sales Manager"?"Sales Manager":"Account Manager")} />
                                <Box className="profileIcons">
                                    <a href={`tel:${this.state.salesManagerProfileDetails?.full_phone_number?.full_phone_number}`}>
                                    <img className="socialIcons"   src={callIcon} />
                                    </a>
                                    <img className="socialIcons" data-testId={"Email"} onClick={() =>this.handleSocialicons(`mailto:${this.state.salesManagerProfileDetails?.email?.value}`)} src={mailIcon} />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item lg={8}>
                            <Box className="aboutSection">
                                <Typography className="aboutHeading">{t("About")}</Typography>
                                <Typography className="aboutPara">
                                {this.state.salesManagerProfileDetails?.bio?.value!==null?this.state.salesManagerProfileDetails?.bio?.value:"No"}
                                </Typography>
                                <Grid container>
                                    <Grid item lg={4}>
                                        <Box className="aboutDetails">
                                            <Typography component={"p"} className="aboutHeading">{t("Gender")}</Typography>
                                            <Typography component={"p"}>{ this.state.salesManagerProfileDetails?.gender?.value!==null? this.state.salesManagerProfileDetails?.gender?.value:"NO"}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={4}>
                                        <Box className="aboutDetails">
                                            <Typography component={"p"} className="aboutHeading">{t("DOB")}</Typography>
                                            <Typography component={"p"}>{this.state.salesManagerProfileDetails?.date_of_birth?.date_of_birth!==null?this.state.salesManagerProfileDetails?.date_of_birth?.date_of_birth:"No"}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={4}>
                                        <Box className="aboutDetails">
                                            <Typography component={"p"} className="aboutHeading">{t("Hobbies")}</Typography>
                                            {this.state.salesManagerProfileDetails?.hobbies?.hobbies.length!==0?
                                            <>
                                            {this.state.salesManagerProfileDetails?.hobbies?.hobbies.map((hobby:any)=>{
                                                return(
                                                 <>
                                              <Chip className="hobbiesList" label={hobby} />
                                                 </>
                                                )
                                            })}
                                            </>
                                            :
                                            <Chip className="hobbiesList" label="No" />
                                            }
                                          
                                        </Box>
                                    </Grid>
                                </Grid>
                               
                                    <Box>
                                        <Typography className="aboutHeading">{t("Social")}</Typography>
                                        <Box className="profileIcons">
                                            <img className="socialIcons" data-testId={"twitter"} onClick={() => this.handleSocialicons(this.state.link.twitter)} src={twiter} />
                                            <img className="socialIcons" data-testId={"instagram"} onClick={() => this.handleSocialicons(this.state.link.instagram)} src={instagram} />
                                            <img className="socialIcons" data-testId={"facebook"} onClick={() => this.handleSocialicons(this.state.link.facebook)} src={facebook} />
                                            <img className="socialIcons" data-testId={"snapchat"} onClick={() => this.handleSocialicons(this.state.link.snapchat)} src={snap} />
                                        </Box>
                                    </Box>
                               
                            </Box>
                        </Grid>
                    </Grid>
                </Box>

          <Box className="tabSection">
            <TabsHeader value={this.state.tabValue} onChange={this.handleTabChange} data-testId={"cooTabChangeTestId"}>
              <Tab label={t("complex")} className="tabsItem" value={0}></Tab>
              <Tab label={t("individual buildings")} className="tabsItem" value={1}></Tab>
              <Tab label={t("owners")} className="tabsItem" value={2}></Tab>
              <Tab label={t("property developers")} className="tabsItem" value={3}></Tab>
              {this.state.profileRole==="Sales Manager" &&
                <Tab label={t("account managers")} className="tabsItem" value={4}></Tab>
              }
            </TabsHeader>
          </Box>
          <Box className="tabsChild2">
            {this.renderTabPanel(this.renderComplexCooTable(t, language), this.state.tabValue, 0)}
            {this.renderTabPanel(this.renderIndividualBuildingCooTable(t, language), this.state.tabValue, 1)}
            {this.renderTabPanel(this.renderOwnerTable(t, language), this.state.tabValue, 2)}
            {this.renderTabPanel(this.renderPropertyTable(t, language), this.state.tabValue, 3)}
            {this.state.profileRole==="Sales Manager" && this.renderTabPanel(this.renderAccountTable(t, language), this.state.tabValue, 4)}
          </Box>
        </CooOwnerDetails>

        {this.renderReassignMainForm(t, language)}
        {this.renderReassignAccountManagerForm(t, language)}

        <AlertError
          show={this.state.showError}
          handleClose={this.handleErrorAlert}
          message={this.state.showErrorMessage}
          data-testId={"errorAlertTestId"}
        />

        <SuccessAlert show={this.state.showSuccess} message={this.state.showSuccessMessage} handleClose={this.handleSuccessAlert}/>

        <Loader loading={this.state.loader} />

        <MapDialog
          t={this.props.t}
          IsOpen={this.state.openBuildingModal}
          CloseDialog={this.handleCloseViewBuilding}
          lat={this.state.latitude}
          long={this.state.longitude}
          data-testId={"viewBuildingModalTestId"}
        />

        <AlertDialog
          IsOpen={this.state.isRevokePermissionOpen}
          Image={tickImg}
          CloseDialog={this.handleCloseRevokePermissionModal}
          Header={t("Revoke") +this.state.revokeComplexName +t("Permission")}
          Content={t("Are you sure want to revoke ") +this.state.revokeComplexName+t("s permission from ") +this.state.salesManagerProfileDetails?.full_name?.value+"?"}
          DeclineText={t("CLOSE")}
          AcceptText={t("CONFIRM")}
          DeclineFun={this.handleCloseRevokePermissionModal}
          AcceptFun={this.handleRevokePermission}
        />
      </CEHeaderComponentWeb>
    );
        // Customizable Area End
    }
}

// Customizable Area Start
export default withTranslation()(SalesManagerProfile);
export { SalesManagerProfile };

const TabsHeader = styled(Tabs)({
  marginTop: "20px",
  overflow: "auto",
  "& .MuiTabs-indicator": {
    background: "none !important",
  },
  "& Button": {
    fontSize: 16,
    borderRadius: "25px !important",
    lineHeight: "24px !important",
    marginRight: "20px",
    textTransform: "capitalize",
  },
  "& .MuiTab-textColorInherit": {
    color: "#181D25 !important",
    background: "#C8C8C81F !important",
  },
  "& .MuiTab-textColorInherit.Mui-selected": {
    background: "#2B6FED !important",
    color: "white !important",
    fontWeight: 600,
  },
  "& .MuiTabs-scroller": {
    overflow: "auto !important",
  },
});

const CooOwnerDetails = styled(Box)({
  padding: "0 30px",
  "& .topHeading": {
    fontSize: "16px",
    color: "black",
    fontWeight: 400,
  },
  "& .navigateRouteActive": {
    color: "#4480ec",
  },
  "& .userProfile": {
    height: "90px",
    borderRadius: "50%",
    width: "90px",
  },
  "& .salesManagerName": {
    fontSize: "28px",
    fontWeight: 700,
    marginBottom: "15px",
    marginTop: "15px",
    lineHeight: "27px",
  },
  "& .generalDetails": {
    fontSize: "20px",
    marginBottom: "15px",
    marginTop: "15px",
    lineHeight: "27px",
  },
  "& .addUnit": {
    background: "#FC8434",
    fontWeight: 700,
    color: "white",
    "&.MuiButton-text": {
      padding: 0,
    },
  },
  "& .hobbiesList": {
    background: "#FFF6EF",
    color: "#FC8434",
    marginRight: "10px",
    fontWeight: 600,
    "&.MuiChip-root": {
      width: "95px",
      height: "25px",
    },
  },
  "& .payingLabel": {
    background: "#EEF4FE",
    fontWeight: 600,
    color: "#3779EE",
    "&.MuiChip-root": {
      height: "25px",
    },
  },
  "& .aboutHeading": {
    fontWeight: 600,
    color: "#A4A5A6",
    marginBottom: "5px",
    fontSize: "16px",
  },
  "& .aboutPara": {
    marginTop: "10px",
    textAlign: "justify",
  },
  "& .socialIcons": {
    marginTop: "15px",
    background: "#FFF6EF",
    padding: "5px",
    borderRadius: "10px",
    marginBottom: "15px",
  },
  "& .tabsChild1": {
    paddingLeft: 0,
    padding: 10,
  },
  "& .profileSection": {
    padding: "20px",
    background: "white",
    borderBottomLeftRadius: "10px",
    height: "250px",
    borderTopLeftRadius: "10px",
  },
  "& .profileIcons": {
    gap: 10,
    display: "flex",
  },
  "& .aboutSection": {
    padding: "20px",
    background: "white",
    borderBottomRightRadius: "10px",
    height: "250px",
    borderTopRightRadius: "10px",
  },
  "& .aboutDetails": {
    marginTop: "15px",
    marginBottom: "15px",
  },
});

const CooComplexDetails = styled(Box)({
  "& .tableContentComplex": {
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },
});

const SalesManagerReassignForm = styled(Box)({
  alignItems: "center",
  display: "flex",
  height: "100vh",
  margin: "auto",
  borderRadius: "20px",
  width: "50%",
  "& .reassignModalHeading": {
    fontWeight: 900,
    fontSize: "25px",
    margin: "20px 0px 20px 20px",
    textAlign: "left",
  },
  "& .MuiDivider-root": {
    width: "100%",
  },
  "& .reassignForm": {
    padding: "20px",
  },
  "& .modalMainInputField": {
    border: "1px solid rgba(0, 0, 0, 0.12)",
    backgroundColor: "#f0f0f0",
    borderRadius: "8px",
  },
  "& .noteInput": {
    padding: "10px",
    width: "100%",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    backgroundColor: "#f0f0f0",
    borderRadius: "10px",
  },
  "& .selectAccountManager": {
    backgroundColor: "#f0f0f0",
    borderRadius: "10px",
    border: "1px solid rgba(0, 0, 0, 0.12)",
  },
  "& .inputModalLabel": {
    color: "black",
    marginBottom: "10px",
  },
  "& .mainModalContainer": {
    borderRadius: "20px",
    overflow: "scroll",
    height: "95%",
  },
  "& .buttonContainer": {
    justifyContent: "flex-end",
    alignItems: "center",
    gap: "20px",
    display: "flex",
  },
  "& .reassignBtn": {
    width: "150px",
    textTransform: "capitalize",
    backgroundColor: "#2B6FED",
    padding: "9px 0px",
    color: "#fff",
    borderRadius: "7px",
    fontWeight: 500,
    fontSize: "18px",
  },
  "& .cancelBtn": {
    borderRadius: "7px",
    border: "1px solid #2B6FED",
    width: "150px",
    textTransform: "capitalize",
    padding: "9px 0px",
    color: "#2B6FED",
    fontSize: "18px",
    fontWeight: 500,
  },
});
// Customizable Area End