//@ts-ignore
//@ts-nocheck

import * as React from "react";
// Customizable Area Start
// custom components
import {Box, Button, Card, FormControl, Grid, IconButton, Input, withStyles} from "@material-ui/core";
import {withRouter} from 'react-router';
import IssueAReportController, {Props} from "./IssueAReportController.web";
import {expenseDownloadButton} from "../../StoreCredits/src/assets"
import {Calender_Icon} from "../../ContentManagement/src/assets"
import {withTranslation} from "react-i18next";
import moment from "moment/moment";
import DashboardBigCard from "../../../components/src/DashboardCard/DashboardBigCard.web";
import {DashboardStyleWeb} from "./DashboardStyle.web";
import {moneySpendIcon, ProfitLossIcon, RentIcon, rentLedgerIcon} from "./assets";
import DashboardCard from "../../../components/src/DashboardCard";
import {languageCondition,ArrowBackIcon} from "../../../components/src/ConditionManager.web";
class IssueAReport extends IssueAReportController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        //@ts-ignore
        const {t, classes} = this.props
        //@ts-ignore
        const language = this.props.i18n.language;
        const is_mobile_issue = !!navigator.userAgent.match(/iphone|android|blackberry/ig) || false;
        return (
            <div className={classes.ownerDashboard} id="issueAreportMainDiv" style={{background: "#F7F9FE"}} dir={languageCondition(language,"rtl","ltr")}>
                <Grid item xs={12} md={12} className="auth-cols issueAReport" id="issueAreportMainGrid">
                    <Grid container style={{padding: '1rem', width: '100%', backgroundColor: "white"}}
                          id="issueAreportMainGridContainer">
                        <Grid xs={12} style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "1rem",
                            justifyContent: "space-between"
                        }} id="issueAreportMainGridItem">
                            <Box style={{display: "flex", alignItems: "center", gap: "1rem"}}
                                 id="issueAreportMainGridBox">
                                <ArrowBackIcon className="backIconIssueAReport" onClick={() => window.history.back()}
                                               id="issueAreportMainGridBoxBackArrow"/>
                                <p className='bold-text' id="issueAreportMainGridBoxP"
                                   style={{fontSize: '1.2rem', fontWeight: 600}}>
                                    {t("Issue a Report")}
                                </p>
                            </Box>
                            <IconButton style={{padding: "0px"}} id="issueAreportMainGridBoxDownloadIcon"
                                        onClick={this.issueAReportDownload}>
                                <img src={expenseDownloadButton} style={{width: "24px", height: "24px"}}
                                     id="issueAreportMainGridBoxDownloadIconImg"/>
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Box className="issueAreportSecondBox">
                        <Box style={{
                            width: "100%",
                            display: 'flex',
                            flexDirection: "column",
                            alignItems: 'center',
                            marginBottom: "50px"
                        }} className="issueAreportSecondBoxBox">
                            <Grid container spacing={2} style={{width: "95%"}}
                                  className={"commonForm issueAreportSecondBoxBoxMainGrid"}>
                                <Grid className="add-visitor issueAreportSecondBoxBoxMainGridItem" item xs={6}>
                                    <FormControl fullWidth className="issueAreportSecondBoxBoxMainGridForm">
                                        
                                        <div className="date-time formGroup classifiedFormGroup"
                                             id="IssueAReportDateDiv1" style={{
                                            border: "0.1px solid rgb(209 209 209 / 100%)",
                                            borderRadius: "25px",
                                            width: "auto",
                                            marginBottom: "5px",
                                            backgroundColor: "white"
                                        }}>
                                            <Input value={this.state.startDate}
                                                   style={{color: "gray", minWidth: "150px"}} onChange={(e: any) => {
                                                this.setState({startDate: e.target.value}, () => this.manageSearchByDate())
                                            }} id={"VisitorTimeBox"} name="date" placeholder={t("From")}
                                                   className={(this.state.startDate === "" && is_mobile_issue) ? "date placeHolderForDate startDateIssueAReport" : "date visitorDateInput startDateIssueAReport"}
                                                   inputProps={{min: moment().format("YYYY-MM-DD")}} type={"text"} onFocus={(e: any) => (e.target.type = "date")}/>
                                            <span className="frmLeftIcons" style={{left: "9px"}} id="CalanderIcon1">
                                        <img src={Calender_Icon} className="frm-icons" alt="Warning Icon"
                                             id="CalanderIcon1img"/>
                                    </span>
                                        </div>
                                    </FormControl>
                                </Grid>
                                <Grid className="add-visitor issueAreportSecondBoxBoxMainGridItem1" item xs={6}>
                                    <FormControl fullWidth className="issueAreportSecondBoxBoxMainGridForm1">
                                        <div className="date-time formGroup classifiedFormGroup"
                                             id="IssueAReportDateDiv2" style={{
                                            border: "0.1px solid rgb(209 209 209 / 100%)",
                                            borderRadius: "25px",
                                            width: "auto",
                                            marginBottom: "5px",
                                            backgroundColor: "white"
                                        }}>
                                            <Input value={this.state.endDate} style={{color: "gray", minWidth: "150px"}}
                                                   onChange={(e: any) => {
                                                       this.setState({endDate: e.target.value}, () => this.manageSearchByDate())
                                                   }} id={"VisitorTimeBox"} placeholder={t("To")}
                                                   className={(this.state.endDate === "" && is_mobile_issue) ? "date placeHolderForDate endDateIssueAReport" : "date visitorDateInput endDateIssueAReport"}
                                                   inputProps={{min: moment().format("YYYY-MM-DD")}} type={"text"} onFocus={(e: any) => (e.target.type = "date")}/>
                                            <span className="frmLeftIcons" style={{left: "9px"}} id="CalanderIcon2">
                                        <img src={Calender_Icon} className="frm-icons" alt="Warning Icon"
                                             id="CalanderIcon2img"/>
                                    </span>
                                        </div>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} className="dashboard issueAReportGrid1" style={{height: "auto"}}>
                                    <DashboardBigCard
                                        FHeader={t("Income")}
                                        fTitle={t("Income")}
                                        fValue={this.state?.reportData?.income?.income?.toLocaleString() || 0}
                                        sHeader={t("Expenses")}
                                        sTitle={t("Expenses")}
                                        sValue={this.state?.reportData?.expenses?.expense?.toLocaleString() || 0}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Card className="big-box issueAReportCard">
                                        <div className="content-box issueArepotRentpayment"
                                             style={{flexDirection: "column", alignItems: "center"}}>
                                            <div className="image issueAReportImage">
                                                <img src={RentIcon} alt="keyhand" className="issueAreportimg"/>
                                            </div>
                                            <div className={"RentPaymentDiffrenceDiv"}>
                                                <h4 className="bold-text rentPaymentDiffrenceh">{t("Rent Payment Difference")}</h4>
                                            </div>
                                            <div className="rentPaymentDiffenceExpectedRent" style={{
                                                width: "100%",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-around",
                                                marginBottom: "20px"
                                            }}>
                                                <div className="state RentPaymentExpectedRent">
                                                    <p className={"ExpectedRentRentPayment"}>{t("Expected Rent")}</p>
                                                    <Button
                                                        className="yellow ExpectedRentRentPaymentButton">{this.state?.reportData?.rent_payment_difference?.expected_rent?.toLocaleString() || 0}</Button>
                                                </div>
                                                <div className="state ReceivedRentPaymentBlock">
                                                    <p className={"ReceivedRentRentPayment"}>{t("Received Rent")}</p>
                                                    <Button
                                                        className="yellow ReceivedRentRentPaymentButton">{this.state?.reportData?.rent_payment_difference?.received_rent?.toLocaleString() || 0}</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                </Grid>
                                <Grid item xs={6} id="issueAReportGirdCard1">
                                    <DashboardCard
                                        image={ProfitLossIcon}
                                        heading={t("Profit / Loss")}
                                        title={t("Profit / Loss")}
                                        value={this.state?.reportData?.profilt_and_loss?.toLocaleString() || 0}
                                    />
                                </Grid>
                                <Grid item xs={6} id="issueAReportGirdCard2">
                                    <DashboardCard
                                        image={moneySpendIcon}
                                        heading={t("Money spent on fixing things")}
                                        title={t("Total")}
                                        value={this.state?.reportData?.money_sped_on_fixing?.toLocaleString() || 0}
                                    />
                                </Grid>
                                <Grid item xs={6} id="issueAReportGirdCard3" style={{marginBottom: "30px"}}>
                                    <DashboardCard
                                        image={rentLedgerIcon}
                                        heading={t("Rent Ledger")}
                                        title={t("Total Rent Received")}
                                        value={this.state?.reportData?.rent_ledger?.toLocaleString() || 0}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
            </div>
        );
    }
}

export default withTranslation()(withStyles(DashboardStyleWeb)(withRouter(IssueAReport)))

// Customizable Area End
