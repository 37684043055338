// Customizable Area Start
import React from "react";
import CompEmpFaqController, { Props } from "./CompEmpFaqController.web";
import "./CompEmpFaq.web.css";
import {
  Backdrop,
  Button,
  Fade,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Modal,
  withStyles,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
} from "@material-ui/core";
import { Upload, Down, Close, Msg, Bin, Edit, SearchIcon, QuestionImage } from "../assets";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AlertError from "../../../../components/src/AlertError.web";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Loader from "../../../../components/src/Loader.web";
import CEHeaderComponentWeb from "../../../../components/src/HeaderComponent/CEHeaderComponent.web";
import { FaqChairmanStyleWeb } from "../FaqChairmanStyle.web";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { Form, Formik } from "formik";
import { ErrorHandler } from "../../../../components/src/UtilFunction.web";
import { languageCondition } from "../../../../components/src/ConditionManager.web";
import { navigationFunc } from "../../../../components/src/helperFunctions.web";
import FilterSelect from "../../../../components/src/CommonComponents/FilterSelect.web";
import AlertDialog from "../../../../components/src/MuiDialog/AlertDialog.web";
import DialogHeader from "../../../../components/src/MuiDialog/DialogHeader.web";
import NormalSelect from "../../../../components/src/CommonComponents/NormalSelect.web";
import NormalTextField from "../../../../components/src/CommonComponents/NormalTextField.web";
// Customizable Area End

export class CompEmpFaq extends CompEmpFaqController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }

  countrySelect = (t: any, language: any) => {
    return (
      <FilterSelect
        t={t}
        data-test-id="specificCountryID"
        label="Country"
        language={language}
        value={this.state.specificCountry}
        option={[{ label: "All", value: "all" }, ...this.state.countryList.map((item: any) => ({ label: item, value: item }))]}
        onChange={this.specificCountyData}
      />
    );
  };

  citySelect = (t: any, language: any) => {
    return (
      <FilterSelect
        t={t}
        data-test-id="specificCityDataID"
        label="City"
        language={language}
        value={this.state.specificCity}
        option={[
          { label: "All", value: "all" },
          ...(Array.isArray(this.state.cityList)
            ? this.state.cityList.map((item: any) => ({ label: item.name, value: item.name }))
            : [])
        ]}
        onChange={this.specificCityData}
      />
    );
  };

  complexSelect = (t: any, language: any) => {
    return (
      <FilterSelect
        t={t}
        data-test-id="specificComplexDataID"
        label="Complex"
        language={language}
        value={this.state.specificComplex}
        option={[{ label: "All", value: "all" }, ...this.state.complexDataForDropdown.map((item: any) => ({ label: item.attributes.name, value: item.id }))]}
        onChange={this.specificComplexData}
      />
    );
  };

  userTypeSelect = (t: any, language: any) => {
    return (
      <FilterSelect
        t={t}
        data-test-id="specificUserTypeDataID"
        label="User Type"
        language={language}
        value={this.state.specificUserType}
        option={[{ label: "All", value: "all" }, ...this.state.userData.map((item: any) => ({ label: item.attributes.title, value: item.attributes.title }))]}
        onChange={this.specificUserTypeData}
      />
    );
  };

  categoryList = () => {
    return this.state.categoryData.map((item: any) => {
      return (
        <button
          itemID="showAllQuestionsID"
          className={`ManageFAQ_Button ${this.state.faqCategoryId === item.attributes.id ? "ManageFAQ_Button_active" : ""
            }`}
          data-testid="handlecategorybutton"
          id="handlecategorybutton"
          onClick={() =>
            this.showAllQuestions(item.attributes.id, item.attributes.name, item.attributes.society_management, item.attributes.society_id)
          }
        >
          {item.attributes.name}
        </button>
      );
    })
  };

  questionList = (language: any) => {
    return (
      this.state.questionData.map((item: any) => {
        return (
          <>
            <div className="Accordion_Container">
              <Accordion className="Accordion_Border">
                <div className="Accordion_Settings">
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} className="Accordion_All_Component">
                    <p className="Question_Format bold-text">{item.attributes.title}</p>
                  </AccordionSummary>
                  <div className={languageCondition(language, "Edit_Delete Edit_Delete_Left", "Edit_Delete")}>
                    <img
                      id="deleteBtn"
                      data-testid="deleteBtn"
                      src={Bin}
                      alt="Bin"
                      className="Bin_Component"
                      onClick={() => this.deleteQueOpen(item.attributes.id)}
                    />
                    <img
                      id="editBtn"
                      src={Edit}
                      alt="Edit"
                      className="Edit_Component"
                      onClick={() =>
                        this.editQueOpen(item.attributes.title, item.attributes.content, item.attributes.id)
                      }
                    />
                  </div>
                </div>
                <AccordionDetails className="faq_answer_accordion">
                  <p className="Answer_Format">{item.attributes.content}</p>
                  <Box display="flex">
                    {item.attributes.images.length > 0 &&
                      item.attributes.images.map((image: any, i: number) => {
                        return (
                          <div className="faq_image_pad_accordion" key={i}>
                            <img src={image.url} className="faq_preview_image" />
                          </div>
                        );
                      })}
                  </Box>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className="QueHR" />
          </>
        );
      })
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { t, i18n }: any = this.props;
    const language = i18n.language;

    return (
      <>
        <Loader loading={this.state.loading} />
        <CEHeaderComponentWeb>
          <Container>
            <div className="ManageFAQ_Container">
              <div>
                <p className="ManageFAQ_Link">
                  <span onClick={() => navigationFunc(this.props, "DashboardCompanyEmployee")}>
                    <span className="ManageFAQ_Link_Grey">{t("Home")}</span>
                  </span>{" "}
                  / <span className="ManageFAQ_Link_Blue">{t("Manage FAQ")}</span>
                </p>
                <p className="ManageFAQ_Tag bold-text ">{t("Manage FAQ")}</p>
              </div>
              <div className="top-bar-filter filter-head-box" style={{ marginBottom: "15px" }}>
                <div className="filter-filter">{this.countrySelect(t, language)}</div>
                <div className="filter-filter">{this.citySelect(t, language)}</div>
                <div className="filter-filter">{this.complexSelect(t, language)}</div>
                <div className="filter-filter">{this.userTypeSelect(t, language)}</div>
                <Box className="filter-filter">
                  <Button
                    className="handleSearchFilterForInvoices search-btn-estate"
                    onClick={this.showAllCategoryData}
                    startIcon={<img src={SearchIcon} />}
                  >
                    {t("Search")}
                  </Button>
                </Box>
              </div>
              <div className="ManageFAQ_Button_Container ">
                <div className="ManageFAQ_Button_DIV">{this.categoryList()}</div>
                <div className="">
                  <div
                    data-testid="addNewCategory"
                    className="ManageFAQ_ADD_Button bold-text"
                    onClick={this.handleOpen}
                    id="addNewCategory"
                  >
                    {t("+ ADD NEW CATEGORY")}
                  </div>
                </div>
              </div>
              <div
                className={
                  this.state.Show ? "ManageFAQ_Question_Show" : "ManageFAQ_Question_Show ManageFAQ_Question_empty"
                }
              >
                <div className={this.state.Show ? "Hide" : "Show"}>
                  <div className="Question_Img_div">
                    <img src={QuestionImage} alt="Question" />
                  </div>
                  <p className="ManageFAQ_Empty_Text bold-text">{t("No question added")}</p>
                </div>
                <div className={this.state.Show ? "Show" : "Hide"}>{this.questionList(language)}</div>
              </div>
              {this.state.categoryData.length > 0 && (
                <div className="ManageFAQ_Final_button_Container ">
                  <div id="removeVehiclesBtn" className="Remove_Vehicles_FAQ bold-text" onClick={this.deleteCateOpen}>
                    {t("Remove")} {this.state.categoryName} {t("FAQ")}
                  </div>
                  <div id="addQuestionBtn" className="ADD_QUESTION_Btt bold-text" onClick={this.addQueOpen}>
                    {t("ADD QUESTION")}
                  </div>
                </div>
              )}
            </div>
          </Container>
        </CEHeaderComponentWeb>

        <AlertError
          show={this.state.alertError}
          handleClose={this.handleAlertErrorClose}
          message={this.state.alertMessage}
        />

        <Dialog
          dir={languageCondition(language, "rtl", "ltr")}
          open={this.state.setOpen}
          className="edit-profile edit-unit"
          maxWidth="md"
          scroll="paper"
          fullWidth
          data-test-id="add-category-modal"
        >
          <DialogHeader t={t} title={t("Add Category")} onClose={this.handleClose} />
          <Formik
            onSubmit={(values: any, { resetForm }) => {
              this.addCategoryList(values);
              resetForm();
              this.handleClose();
            }}
            initialValues={{
              society_id: "", dashboard_type: "", name: "",
            }}
            enableReinitialize
            validationSchema={this.categoryFormSchema}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
              return (
                <Form onSubmit={handleSubmit} translate="yes">
                  <DialogContent dividers className={languageCondition(language, "arabic-grid", "")}>
                    <Grid container spacing={3} className="edit-building">
                      <Grid item xs={12}>
                        <NormalSelect
                          t={t}
                          language={language}
                          value={values.society_id}
                          name="society_id"
                          label="Complex / Building"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          option={this.state.complexData.map((item: any) => ({ label: item.attributes.name, value: item.id }))}
                        />
                        {ErrorHandler(t, touched.society_id, errors.society_id)}
                      </Grid>
                      <Grid item xs={12}>
                        <NormalSelect
                          t={t}
                          language={language}
                          value={values.dashboard_type}
                          name="dashboard_type"
                          label="Category"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          option={this.state.userData.map((item: any) => ({ label: item.attributes.title, value: item.attributes.title }))}
                        />
                        {ErrorHandler(t, touched.dashboard_type, errors.dashboard_type)}
                      </Grid>
                      <Grid item md={12}>
                        <NormalTextField
                          t={t}
                          language={language}
                          label="Category Title"
                          value={values.name}
                          name="name"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          isLabel
                        />
                        {ErrorHandler(t, touched.name, errors.name)}
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions className="dialog-button-group" style={{ gap: "10px" }}>
                    <Button style={{ height: "50px" }} onClick={this.handleClose} className="cancel-button">
                      {t("Cancel")}
                    </Button>
                    <Button type="submit" className="add-button" style={{ height: "50px", margin: "0" }}>
                      {t("Save")}
                    </Button>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </Dialog>

        <Modal
          dir={languageCondition(language, "rtl", "ltr")}
          className="modal"
          open={this.state.addQue}
          onClose={this.addQueClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.addQue}>
            <div className="addQuePaper">
              <div className="modal_Heading_Container">
                <p className="modalHeading bold-text">{t("Add Question")}</p>
                <img
                  id="questionCloseBtn"
                  src={Close}
                  alt="Close"
                  className="Close_Button"
                  onClick={this.addQueClose}
                />
              </div>
              <div className="HR" />
              <div className="modal_Heading_Container">
                <FormControl variant="outlined" className="Modal_Select_Field">
                  <InputLabel className="Modal_Select_Field_Text" id="1">
                    {this.state.questionData[0]?.attributes?.society_name}
                  </InputLabel>
                  <Select
                    itemID="showallComplexId"
                    className="Modal_Select_Field2"
                    id="1"
                    labelId="1"
                    disabled
                    open={this.state.complex}
                    onClick={() => this.showAllComplex()}
                    onOpen={(edd: any) => this.complexOpen2(edd)}
                    value={this.state.activeComplex}
                    onClose={this.complexClose}
                    label="Select Complex / Building"
                    displayEmpty
                  >
                    <MenuItem disabled value="">
                      {t("Select Complex / Building")}
                    </MenuItem>
                    {this.state.complexData.map((item: any, index: any) => {
                      return (
                        <MenuItem
                          id="complexquestionID"
                          value={item.attributes.name}
                          onClick={() => this.complexquestionid(item.id)}
                        >
                          {item.attributes.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <img className="Down2" id="openDropDownBtn" alt="Down" src={Down} />
                </FormControl>
              </div>
              <div className="modal_Heading_Container">
                <FormControl variant="outlined" className="Modal_Select_Field">
                  <InputLabel className="Modal_Select_Field_Text" id="2">
                    {this.state.questionData[0]?.attributes?.category_name}
                  </InputLabel>
                  <Select
                    itemID="showAllCategoryID"
                    className="Modal_Select_Field"
                    id="2"
                    disabled
                    data-testid="selectCategoryFaq"
                    open={this.state.category}
                    onOpen={(erw) => this.categoryOpen1(erw)}
                    onClick={() => this.showAllCategory}
                    onClose={this.categoryClose}
                    value={this.state.categoryName}
                    label="Select Category"
                  >
                    {this.state.categoryData.map((item: any) => {
                      return (
                        <MenuItem
                          id="categoryQuestionId"
                          value={item.attributes.name}
                          onClick={() => this.categoryquestionid(item.id)}
                        >
                          {item.attributes.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <img id="questionCategoryOpenBtn1" src={Down} alt="Down" className="Down" />
                </FormControl>
              </div>
              <div>
                <form autoComplete="off" className="modal_Heading_Container">
                  <TextField
                    itemID="titleQuestionID"
                    id="outlined-basic"
                    variant="outlined"
                    className="Modal_Text_Field"
                    placeholder={t("Title Question")}
                    onChange={(text: any) => this.setState({ questionTitle: text?.target.value })}
                  />
                </form>
              </div>
              <div>
                <form autoComplete="off" className="modal_Heading_Container">
                  <textarea
                    itemID="answerID"
                    className="Add_Que_Text_Area"
                    placeholder={t("Answer")}
                    onChange={(text: any) => this.setState({ answerTitle: text?.target.value })}
                  />
                </form>
              </div>
              <div>
                {this.state.previewImage.length > 0 ? (
                  <>
                    {this.state.previewImage.length < 4 ? (
                      <Box display="flex" className="faq_image_box faq_image_box_preview1">
                        <form autoComplete="off" className="modal_Heading_Container modal_Heading_Container1">
                          <input
                            type="file"
                            name="myfile"
                            accept="image/*"
                            data-testid="previewImageFaq"
                            onChange={this.uploadImage}
                            id="faq_image_upload"
                          />
                          <label htmlFor="faq_image_upload" className="faq_preview_label faq_preview_label1">
                            <div className="Add_Que_Imge_Div_upload Add_Que_Imge_Div_upload1">
                              <div>
                                <div className="Question_Img_div Question_Img_div1">
                                  <img src={Upload} alt="Upload" className="Upload" />
                                </div>
                                <p className="Upload_Image_Text Upload_Image_Text1">{t("Upload Image")}</p>
                              </div>
                            </div>
                          </label>
                        </form>
                        {this.state.previewImage.map((image: any, i: number) => {
                          return (
                            <div className="faq_image_pad faq_image_pad1" key={i}>
                              <img src={image} className="faq_preview_image faq_preview_image1" />
                              <HighlightOffIcon
                                data-testid="removePreviewFaq"
                                className="faq_image_close_icon faq_image_close_icon1"
                                onClick={() => this.handleImageRemove(i)}
                              />
                            </div>
                          );
                        })}
                      </Box>
                    ) : (
                      <Box display="flex" className="faq_image_box faq_image_box2">
                        {this.state.previewImage.map((image: any, i: number) => {
                          return (
                            <div className="faq_image_pad faq_image_pad2" key={i}>
                              <img src={image} className="faq_preview_image faq_preview_image2" />
                              <HighlightOffIcon
                                data-testid="removePreviewFaq"
                                className="faq_image_close_icon faq_image_close_icon2"
                                onClick={() => this.handleImageRemove(i)}
                              />
                            </div>
                          );
                        })}
                      </Box>
                    )}
                  </>
                ) : (
                  <form autoComplete="off" className="modal_Heading_Container modal_Heading_Container2">
                    <input
                      type="file"
                      name="myfile"
                      accept="image/*"
                      data-testid="previewImageFaq"
                      onChange={this.uploadImage}
                      id="faq_image_upload"
                    />
                    <label htmlFor="faq_image_upload" className="faq_upload_label faq_upload_label2">
                      <div className="Add_Que_Imge_Div Add_Que_Imge_Div2">
                        <div>
                          <div className="Question_Img_div Question_Img_div2">
                            <img src={Upload} alt="Upload" className="Upload" />
                          </div>
                          <p className="Upload_Image_Text Upload_Image_Text2">{t("Upload Image (Optional)")}</p>
                        </div>
                      </div>
                    </label>
                  </form>
                )}
              </div>
              <div className="Modal_Button_Container">
                <div id="questionCancelBtn" className="ModalCancelDiv bold-text" onClick={this.addQueClose}>
                  {t("Cancel")}
                </div>
                <div
                  className="ModalConfirmDiv bold-text"
                  itemID="updateAddQuestionID"
                  onClick={() => {
                    if (this.handleCheckAddForm()) {
                      this.addFAQList();
                    }
                  }}
                >
                  {t("ADD")}
                </div>
              </div>
            </div>
          </Fade>
        </Modal>

        <AlertDialog
          IsOpen={this.state.deleteCate}
          Image={Msg}
          CloseDialog={this.deleteCateClose}
          Header={t("Do you want to delete the category?")}
          Content={`<span>${t("Are you sure you want to delete the category")} ${this.state.categoryName}?</span><p>${t("All FAQs related to this category will be deleted permanently.")}</p>`}
          DeclineText={t("No, Don't Delete")}
          AcceptText={t("Yes Delete")}
          DeclineFun={this.deleteCateClose}
          AcceptFun={this.deleteCategoryAPI}
          language={language}
        />

        <AlertDialog
          IsOpen={this.state.deleteQue}
          Image={Msg}
          CloseDialog={this.deleteQueClose}
          Header={t("Do you want to delete the question?")}
          Content={`<p>${t("Are you sure that you want to delete this question?")}</p>`}
          DeclineText={t("No, Don't Delete")}
          AcceptText={t("Yes Delete")}
          DeclineFun={this.deleteQueClose}
          AcceptFun={this.deleteFAQAPI}
          language={language}
        />

        <Modal
          dir={languageCondition(language, "rtl", "ltr")}
          className="modal"
          open={this.state.editQue}
          onClose={this.editQueClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.editQue}>
            <div className="addQuePaper">
              <div className="modal_Heading_Container">
                <p className="modalHeading bold-text">{t("Edit Question")}</p>
                <img
                  id="editQuestionBtn"
                  src={Close}
                  alt="Close"
                  className="Close_Button"
                  onClick={this.editQueClose}
                />
              </div>
              <div className="HR" />
              <div className="modal_Heading_Container">
                <FormControl variant="outlined" className="Modal_Select_Field">
                  <InputLabel className="Modal_Select_Field_Text" id="1">
                    {this.state.editQuestionData?.attributes.society_name}
                  </InputLabel>
                  <Select
                    className="Modal_Select_Field"
                    open={this.state.complex}
                    onClose={this.complexClose}
                    onOpen={this.complexOpen}
                    value={this.state.editQuestionData?.attributes.society_name}
                  >
                    {this.state.complexData.map((item: any, index: any) => {
                      return (
                        <MenuItem
                          id="complexquestionID"
                          value={item.attributes.name}
                          onClick={() => this.complexquestionid(item.id)}
                        >
                          {item.attributes.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <img id="editDropDownBtn" src={Down} alt="Down" className="Down" />
                </FormControl>
              </div>
              <div className="modal_Heading_Container">
                <FormControl variant="outlined" className="Modal_Select_Field">
                  <InputLabel className="Modal_Select_Field_Text" id="2">
                    {this.state.editQuestionData?.attributes.category_name}
                  </InputLabel>
                  <Select
                    className="Modal_Select_Field"
                    open={this.state.category}
                    onClose={this.categoryClose}
                    onOpen={this.categoryOpen}
                    value={this.state.editQuestionData?.attributes.category_name}
                  >
                    {this.state.categoryData.map((item: any, index: any) => {
                      return (
                        <MenuItem
                          id="categoryQuestionId"
                          value={item.attributes.name}
                          onClick={() => this.categoryquestionid(item.id)}
                        >
                          {t(item.attributes.name)}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <img id="editDropDownBtn" src={Down} alt="Down" className="Down" />
                </FormControl>
              </div>
              <div>
                <form autoComplete="off" className="modal_Heading_Container">
                  <TextField
                    itemID="questionEditID"
                    id="outlined-basic"
                    variant="outlined"
                    className="Modal_Text_Field"
                    value={this.state.questionEdit}
                    placeholder={t("Please add your questions")}
                    onChange={(e) => this.editQuestion(e)}
                  />
                </form>
              </div>
              <div>
                <form autoComplete="off" className="modal_Heading_Container">
                  <textarea
                    className="Add_Que_Text_Area"
                    itemID="questionContentID"
                    value={this.state.questionContent}
                    placeholder={t("Please add your answer")}
                    onChange={(e) => this.editContent(e)}
                  />
                </form>
              </div>
              {this.state.editPreviewImage.length ? (
                <>
                  {this.state.editPreviewImage.length < 4 ? (
                    <Box display="flex" className="faq_image_box faq_image_box3">
                      <form autoComplete="off" className="modal_Heading_Container modal_Heading_Container3">
                        <input
                          type="file"
                          name="myfile"
                          accept="image/*"
                          data-testid="previewImageFaqEdit"
                          onChange={this.uploadEditImage}
                          id="faq_image_upload"
                        />
                        <label htmlFor="faq_image_upload" className="faq_preview_label faq_preview_label3">
                          <div className="Add_Que_Imge_Div_upload Add_Que_Imge_Div_upload3">
                            <div>
                              <div className="Question_Img_div Question_Img_div3">
                                <img src={Upload} alt="Upload" className="Upload" />
                              </div>
                              <p className="Upload_Image_Text Upload_Image_Text3">{t("Upload Image")}</p>
                            </div>
                          </div>
                        </label>
                      </form>
                      {this.state.editPreviewImage.map((image: any, i: number) => {
                        return (
                          <div className="faq_image_pad faq_image_pad4" key={i}>
                            <img src={image.preview} className="faq_preview_image faq_preview_image4" />
                            <HighlightOffIcon
                              data-testid="removePreviewFaqEdit"
                              className="faq_image_close_icon faq_image_close_icon4"
                              onClick={() => this.handleEditImageRemove(image.id, i)}
                            />
                          </div>
                        );
                      })}
                    </Box>
                  ) : (
                    <Box display="flex" className="faq_image_box faq_image_box5">
                      {this.state.editPreviewImage.map((image: any, i: number) => {
                        return (
                          <div className="faq_image_pad faq_image_pad5" key={i}>
                            <img src={image.preview} className="faq_preview_image faq_preview_image5" />
                            <HighlightOffIcon
                              data-testid="removePreviewFaqEdit"
                              className="faq_image_close_icon faq_image_close_icon5"
                              onClick={() => this.handleEditImageRemove(image.id, i)}
                            />
                          </div>
                        );
                      })}
                    </Box>
                  )}
                </>
              ) : (
                <form autoComplete="off" className="modal_Heading_Container modal_Heading_Container5">
                  <input
                    type="file"
                    name="myfile"
                    accept="image/*"
                    data-testid="previewImageFaqEdit"
                    onChange={this.uploadEditImage}
                    id="faq_image_upload"
                  />
                  <label htmlFor="faq_image_upload" className="faq_upload_label faq_upload_label5">
                    <div className="Add_Que_Imge_Div Add_Que_Imge_Div5">
                      <div>
                        <div className="Question_Img_div Question_Img_div5">
                          <img src={Upload} alt="Upload" className="Upload" />
                        </div>
                        <p className="Upload_Image_Text Upload_Image_Text5">{t("Upload Image (Optional)")}</p>
                      </div>
                    </div>
                  </label>
                </form>
              )}
              <div className="Modal_Button_Container">
                <div id="editCancelBtn" className="ModalCancelDiv bold-text" onClick={this.editQueClose}>
                  {t("Cancel")}
                </div>
                <div
                  className="ModalConfirmDiv bold-text"
                  itemID="updateQuestionID"
                  onClick={() => {
                    if (this.handleCheckEditForm()) {
                      this.updateQuestionData();
                    }
                  }}
                >
                  {t("SAVE")}
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default withTranslation()(withStyles(FaqChairmanStyleWeb)(withRouter(CompEmpFaq)));
// Customizable Area End
