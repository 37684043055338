import React from "react";
// Customizable Area Start
import MainScreen from "./Company Employee/MainScreen.web";
import { Box, styled, Typography, Select, MenuItem, Button, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Chip, Input, Modal, Divider, Breadcrumbs, Grid, Dialog, DialogContent, DialogActions, } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import { TFunction, CompanyItem, SalesManagerItem, AccountMangerListItem, TotalComplexListItem, I18nInterface, ComplexListItem } from './TotalComplexController.web';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { SearchOutlined } from "@material-ui/icons";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import CloseIcon from "@material-ui/icons/Close";
import VisibilityIcon from "@material-ui/icons/Visibility";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import PaginationModule from "../../StoreCredits/src/PaginationModule.web";
import AlertError from "../../../components/src/AlertError.web";
import { languageCondition } from "../../../components/src/ConditionManager.web";
import Loader from "../../../components/src/Loader.web";
import SuccessAlert from "../../../components/src/AlertSuccess.web";
import MapDialog from "../../../components/src/MuiDialog/MapDialog.web";
import DialogHeader from "../../../components/src/MuiDialog/DialogHeader.web";
import NormalTextField from "../../../components/src/CommonComponents/NormalTextField.web";
import NormalSelect from "../../../components/src/CommonComponents/NormalSelect.web";
import NormalTextArea from "../../../components/src/CommonComponents/NormalTextArea.web";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { ErrorHandler } from "../../../components/src/UtilFunction.web";
import { Menu } from "@szhsin/react-menu";
import CommonButton from "../../../components/src/CommonComponents/Button.web";
// Customizable Area End

import TotalComplexController, { Props, } from "./TotalComplexController.web";
import SearchInput from "../../../components/src/CommonComponents/SearchInput.web";

class TotalComplex extends TotalComplexController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderLoginModal = () => {
        const { t }: { t: TFunction } = this.props;
        return (
            <LoginModalStyle>
                <Box className="notificationHeading">
                    <Typography className="villacomplex" data-testId={"loginModalHeadingTestId"}>{t("Villa Complex")}</Typography>
                    <IconButton onClick={this.handlecloseLoginModal} data-testId={"loginModalCloseIconTestId"}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box className="searchLoginStyle">
                    <Input
                        placeholder={t("Email")}
                        value={this.state.email}
                        onChange={this.handleEmailChange}
                        data-testId={"emailChangeTestId"}
                        disableUnderline
                        name="search"
                        startAdornment={<MailOutlineIcon className="inputStartAdorment" />}
                        className="searchInputStyle"
                        fullWidth
                    />
                    <Input
                        placeholder={t("Password")}
                        disableUnderline
                        data-testId={"passwordChangeTestId"}
                        name="search"
                        type={this.state.showPassword ? "text" : "password"}
                        startAdornment={<LockOutlinedIcon className="inputStartAdorment" />}
                        endAdornment={
                            this.state.showPassword ? (
                                <IconButton onClick={this.handlePassWordShow} data-testId={"hidePasswordTestId"}>
                                    <VisibilityOffIcon className="passWordAdormnet" />
                                </IconButton>
                            ) : (
                                <IconButton className="passWordAdormnet" data-testId={"showPasswordTestId"} onClick={this.handlePassWordShow}>
                                    <VisibilityIcon />
                                </IconButton>
                            )
                        }
                        onChange={this.handlePasswordText}
                        value={this.state.loginTextPassword}
                        className="searchInputStyle"
                        fullWidth
                    />
                    <Button
                        variant="contained"
                        fullWidth
                        className="modalLoginButton"
                    >
                        {t("Login")}
                    </Button>
                </Box>
            </LoginModalStyle>
        );
    };

    renderMapmodal=()=>{
        return(
            <MapDialog
            t={this.props.t}
            IsOpen={this.state.openBuildingModal}
            CloseDialog={this.handleCloseMap}
            lat={this.state.latitude}
            long={this.state.longitude}
            data-testId={"viewBuildingModalTestId"}
        />
        )
    }

    tabelOfAccountManagerComplexex = () => {
        const { t, i18n }: { t: TFunction, i18n: I18nInterface } = this.props;
        const language = i18n.language;
        return (
            <>
                <TableContainer className="tableStyle" dir={languageCondition(language, "rtl", "ltr")}>
                    <Table dir={languageCondition(language, "rtl", "ltr")}>
                        <TableHead>
                            <TableRow>
                                <TableCell className="tableHeaderStyle" align="left">{t("#")}</TableCell>
                                <TableCell className="tableHeaderStyle" align="left">{t("Complex")}</TableCell>
                                <TableCell className="tableHeaderStyle" align="left">{t("Company")}</TableCell>
                                <TableCell className="tableHeaderStyle" align="left">{t('Chairman')}</TableCell>
                                <TableCell className="tableHeaderStyle" align="left">{t("Total Building")}</TableCell>
                                <TableCell className="tableHeaderStyle" align="left">{t("Total Unit")}</TableCell>
                                <TableCell className="tableHeaderStyle" align="left">{t("Subscription End In")}</TableCell>
                                <TableCell className="tableHeaderStyle" align="left">{t("Country")}</TableCell>
                                <TableCell className="tableHeaderStyle" align="left">{t("City")}</TableCell>
                                <TableCell className="tableHeaderStyle" align="left"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.totalComplexList.map((totalComplex: TotalComplexListItem, totalComplexIndex: number) => (
                                <TableRow key={totalComplexIndex}>
                                    <TableCell>{totalComplexIndex + 1}</TableCell>
                                    <TableCell className={`totalComplex${totalComplexIndex}`}>{this.handleNullValues(totalComplex.attributes.building_name)}</TableCell>
                                    <TableCell>{this.handleNullValues(totalComplex.attributes.building_name)}</TableCell>
                                    <TableCell>{this.handleNullValues(totalComplex.attributes.totle_building)}</TableCell>
                                    <TableCell>{this.handleNullValues(totalComplex.attributes.totle_unit)}</TableCell>
                                    <TableCell>{this.handleNullValues(totalComplex.attributes.subscription_ends)}</TableCell>
                                    <TableCell>{this.handleNullValues(totalComplex.attributes.country)}</TableCell>
                                    <TableCell data-testId={`totalComplexCity${totalComplexIndex}`}>{this.handleNullValues(totalComplex.attributes.city)}</TableCell>
                                    <TableCell>
                                    <Menu
                                        menuButton={
                                            <IconButton>
                                                <MoreVertIcon />
                                            </IconButton>
                                        }
                                        >
                                        <MenuItem onClick={() => {this.handleMapvalues(totalComplex); this.handleOpenmapModal()}}>{t("View Location on Map")}</MenuItem>
                                        <MenuItem onClick={this.onHandleLoginModal}>{t("Login")} <Chip label="Action Required" className="chipStyle" /></MenuItem>
                                    </Menu>
                                    </TableCell>
                                </TableRow>
                            ))} 
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box dir={languageCondition(language, "rtl", "ltr")} className="paginationStyle">
                    <PaginationModule handlePagination={this.handlePagination} pagination={this.state.paginationData} page={this.state.paginationData?.current_page} data-testId={"contractListPagintionTestId"} />
                </Box>

                <Modal
                    open={this.state.loginModal}
                    onClose={this.handleLoginModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    data-testId={"loginModalTestId"}
                >
                    {this.renderLoginModal()}
                </Modal>
            </>
        );
    };

    accountManagerComplex = () => {
        const { t, i18n }: { t: TFunction, i18n: I18nInterface } = this.props;
        const language = i18n.language;
        return (
            <>
                <AccountManagerComplexStyle>
                    <Box>
                        <Breadcrumbs dir={languageCondition(language, "rtl", "ltr")}>
                            <Typography className="mangingDashboard" onClick={() => this.handleNavigation("DashboardCompanyEmployee")} data-testId={"myDashboardHeadingTestId"} dir={languageCondition(language, "rtl", "ltr")}>{t("My Dashboards")}</Typography>
                            <Typography className="mangingDashboard" onClick={() => this.handleNavigation("DashboardCompanyEmployee")} data-testId={"totalComplexHeadingTestId"} dir={languageCondition(language, "rtl", "ltr")}>{t("General Dashboard")}</Typography>
                            <Typography className="assignedAccount">{t("Total Complexes")}</Typography>
                        </Breadcrumbs>
                    </Box>
                    <Typography className="headingStyle" dir={languageCondition(language, "rtl", "ltr")}>{t("Total Complexes")}</Typography>
                    <Box className="selectComplexes" dir={languageCondition(language, "rtl", "ltr")}>
                    <Select
                            value={this.state.country}
                            onChange={this.handlecountryChange}
                            data-testId={"countrySelectTestId"}
                            className="selectManger"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            IconComponent={KeyboardArrowDownIcon}
                            dir={languageCondition(language, "rtl", "ltr")}
                            displayEmpty
                            MenuProps={{
                                style: { marginTop: "50px" },
                            }}
                        >
                            <MenuItem value="" className="defaultSelect" dir={languageCondition(language, "rtl", "ltr")}>{t("Select Country")}</MenuItem>
                            {this.state.countryList.map((country: any, index: number) => {
                                return <MenuItem className="defaultSelect" key={index} value={country.attributes.name} data-testId={`countryItem${index}`}>{country.attributes.name}</MenuItem>
                            })}
                        </Select>
                        <Select
                            onChange={this.handleCityChange}
                            value={this.state.city}
                            data-testId={"citySelectTestId"}
                            className="selectManger"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            IconComponent={KeyboardArrowDownIcon}
                            dir={languageCondition(language, "rtl", "ltr")}
                            displayEmpty
                            MenuProps={{
                                style: { marginTop: "50px" },
                            }}
                        >
                            <MenuItem value="" className="defaultSelect" dir={languageCondition(language, "rtl", "ltr")}>{t("Select City")}</MenuItem>
                            {this.state.cityList.map((city, cityIndex: number) => {
                                return <MenuItem value={city.name} className="defaultSelect" key={cityIndex} data-testId={`cityListItem${cityIndex}`}>{city.name}</MenuItem>
                            })}
                        </Select>
                        <Select
                            value={this.state.company}
                            onChange={this.handleCompanyChange}
                            data-testId={"companySelectTestId"}
                            className="selectManger"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            IconComponent={KeyboardArrowDownIcon}
                            displayEmpty
                            dir={languageCondition(language, "rtl", "ltr")}
                            MenuProps={{
                                style: { marginTop: "50px" },
                            }}
                        >
                            <MenuItem value="" className="defaultSelect" dir={languageCondition(language, "rtl", "ltr")}>{t("Select Company")}</MenuItem>
                            {this.state.companyList.map((company: CompanyItem, companyIndex: number) => {
                                return <MenuItem value={company.company_name} key={companyIndex} className="defaultSelect" data-testId={`companyListItem${companyIndex}`}>{company.company_name}</MenuItem>
                            })}
                        </Select>
                        <Select
                            value={this.state.complex}
                            data-testId={"complexSelectTestId"}
                            onChange={this.handleComplexChange}
                            labelId="demo-simple-select-label"
                            className="selectManger"
                            displayEmpty
                            IconComponent={KeyboardArrowDownIcon}
                            id="demo-simple-select"
                            MenuProps={{
                                style: { marginTop: "50px" },
                            }}
                            dir={languageCondition(language, "rtl", "ltr")}
                        >
                            <MenuItem value="" className="defaultSelect" dir={languageCondition(language, "rtl", "ltr")}>{t("Select Complex")}</MenuItem>
                            {this.state.complexList.filter((complex: any) => !complex.attributes.is_building).map((complex: ComplexListItem, complexIndex: number) => {
                                return <MenuItem value={this.handleNullValues(complex.attributes.name)} key={complexIndex} data-testId={`complexListItem${complexIndex}`}>{complex.attributes.name}</MenuItem>
                            })}
                        </Select>
                        <Select
                            value={this.state.subscriptionEndsIn}
                            onChange={this.handleSubcriptionChange}
                            data-testId={"subscriptionEndsInSelectTestId"}
                            className="selectManger"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            IconComponent={KeyboardArrowDownIcon}
                            displayEmpty
                            MenuProps={{
                                style: { marginTop: "50px" },
                            }}
                            dir={languageCondition(language, "rtl", "ltr")}
                        >
                            <MenuItem value="" className="defaultSelect" dir={languageCondition(language, "rtl", "ltr")}>
                                {t("Subscription End In")}
                            </MenuItem>
                            {this.renderMenu(t)}
                        </Select>
                        <CommonButton startIcon={<SearchOutlined />} onClick={() => this.getTotalComplexList("1")} t={t} label="Search" color="blue" data-testId={"searchBtnTestId"} />
                        <CommonButton onClick={this.handleClearFiltersClick} t={t} label="Clear Filters" color="white" data-testId={"clearBtnTestId"} />
                    </Box>
                    <Paper className="paperStyle">
                        <Box className="managerStyle" dir={languageCondition(language, "rtl", "ltr")}>
                            <Typography className="headingManger" dir={languageCondition(language, "rtl", "ltr")}>
                                {t("List of Manage Complex")}
                            </Typography>
                            <Box className="selectStyles" dir={languageCondition(language, "rtl", "ltr")}>
                                 <SearchInput
                                    label="Search By Complex Name"
                                    onChange={this.handleSearchInput}
                                    t={t}
                                    data-testId="searchInputTestId"
                                    language={language}
                                />
                            </Box>
                        </Box>
                        <Divider className="dividerStyle" />
                        {this.tabelOfAccountManagerComplexex()}
                    </Paper>
                </AccountManagerComplexStyle>
            </>
        );
    };

    renderMenu = (t: any) => {
        return [
            <MenuItem value="30">{t("Next 30 days")}</MenuItem>,
            <MenuItem value="60">{t("Next 60 days")}</MenuItem>,
            <MenuItem value="90">{t("Next 90 days")}</MenuItem>,
            <MenuItem value="180">{t("Next 6 months")}</MenuItem>,
            <MenuItem value="365">{t("Next 1 year")}</MenuItem>
        ];
    }

    totalComplexRender = () => {
        const { t, i18n }: { t: TFunction, i18n: I18nInterface } = this.props;
        const language = i18n.language;
        return (
            <>
                <TotalComplexStyle>
                    <Breadcrumbs dir={languageCondition(language, "rtl", "ltr")}>
                        <Typography className="mangingDashboard" onClick={() => this.handleNavigation("DashboardCompanyEmployee")} data-testId={"myDashboardHeadingTestId"} dir={languageCondition(language, "rtl", "ltr")}>{t("My Dashboards")}</Typography>
                        <Typography className="mangingDashboard" onClick={() => this.handleNavigation("DashboardCompanyEmployee")} data-testId={"totalComplexHeadingTestId"} dir={languageCondition(language, "rtl", "ltr")}>{t("General Dashboard")}</Typography>
                        <Typography className="assignedAccount">{t("Total Complexes")}</Typography>
                    </Breadcrumbs>
                    <Typography className="headingStyle" dir={languageCondition(language, "rtl", "ltr")}>{t("Total Complexes")}</Typography>
                    <Box className="selectComplexes" dir={languageCondition(language, "rtl", "ltr")}>
                    <Select
                            value={this.state.country}
                            onChange={this.handlecountryChange}
                            data-testId={"countrySelectTestId"}
                            className="selectManger"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            IconComponent={KeyboardArrowDownIcon}
                            displayEmpty
                            MenuProps={{
                                style: { marginTop: "50px" },
                            }}
                            dir={languageCondition(language, "rtl", "ltr")}
                        >
                            <MenuItem value="" className="defaultSelect" dir={languageCondition(language, "rtl", "ltr")}>{t("Select Country")}</MenuItem>
                            {this.state.countryList.map((country: any, index: number) => {
                                return <MenuItem className="defaultSelect" key={index} value={country.attributes.name} data-testId={`countryItem${index}`}>{country.attributes.name}</MenuItem>
                            })}
                        </Select>
                        <Select
                            value={this.state.city}
                            onChange={this.handleCityChange}
                            data-testId={"citySelectTestId"}
                            className="selectManger"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            IconComponent={KeyboardArrowDownIcon}
                            displayEmpty
                            MenuProps={{
                                style: { marginTop: "50px" },
                            }}
                            dir={languageCondition(language, "rtl", "ltr")}
                        >
                            <MenuItem value="" className="defaultSelect" dir={languageCondition(language, "rtl", "ltr")}>
                                {t("Select City")}
                            </MenuItem>
                            {this.state.cityList.map((city,cityIndex:number) => {
                                return <MenuItem value={city.name} className="defaultSelect" key={cityIndex} data-testId={`cityListItem${cityIndex}`}>{city.name}</MenuItem>
                            })}
                        </Select>
                        <Select
                            value={this.state.company}
                            onChange={this.handleCompanyChange}
                            data-testId={"companySelectTestId"}
                            className="selectManger"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            IconComponent={KeyboardArrowDownIcon}
                            dir={languageCondition(language, "rtl", "ltr")}
                            displayEmpty
                            MenuProps={{
                                style: { marginTop: "50px" },
                            }}
                        >
                            <MenuItem value="" className="defaultSelect" dir={languageCondition(language, "rtl", "ltr")}>{t("Select Company")}</MenuItem>
                            {this.state.companyList.map((companyItem: CompanyItem, companyIndex: number) => {
                                return <MenuItem key={companyIndex} value={companyItem.company_name} className="defaultSelect" data-testId={`companyListItem${companyIndex}`}>{companyItem.company_name}</MenuItem>
                            })}
                        </Select>
                        <Select
                            onChange={this.handleComplexChange}
                            value={this.state.complex}
                            className="selectManger"
                            data-testId={"complexSelectTestId"}
                            id="demo-simple-select"
                            labelId="demo-simple-select-label"
                            IconComponent={KeyboardArrowDownIcon}
                            dir={languageCondition(language, "rtl", "ltr")}
                            displayEmpty
                            MenuProps={{
                                style: { marginTop: "50px" },
                            }}
                        >
                            <MenuItem value="" className="defaultSelect" dir={languageCondition(language, "rtl", "ltr")}>{t("Select Complex")}</MenuItem>
                            {this.state.complexList.filter((complex: any) => !complex.attributes.is_building).map((complex: ComplexListItem, complexIndex: number) => {
                                return <MenuItem value={this.handleNullValues(complex.attributes.name)} key={complexIndex}>{complex.attributes.name}</MenuItem>
                            })}
                        </Select>
                        <Select
                            value={this.state.salesManager}
                            onChange={this.handleSalesManagerChange}
                            data-testId={"salesManagerSelectTestId"}
                            className="selectManger"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            IconComponent={KeyboardArrowDownIcon}
                            dir={languageCondition(language, "rtl", "ltr")}
                            displayEmpty
                            MenuProps={{
                                style: { marginTop: "50px" },
                            }}
                        >
                            <MenuItem value="" className="defaultSelect" dir={languageCondition(language, "rtl", "ltr")}>{t("Select Sales Manager")}</MenuItem>
                            {this.state.salesManagerList.map((salesManager: SalesManagerItem, salesManagerIndex: number) => {
                                return <MenuItem value={this.handleNullValues(salesManager.full_name)} className="defaultSelect" key={salesManagerIndex} data-testId={`salesManagerItemtestId${salesManagerIndex}`}>{salesManager.full_name}</MenuItem>
                            })}
                        </Select>
                        <Select
                            value={this.state.subscriptionEndsIn}
                            onChange={this.handleSubcriptionChange}
                            data-testId={"subscriptionEndsInSelectTestId"}
                            className="selectManger"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            IconComponent={KeyboardArrowDownIcon}
                            displayEmpty
                            dir={languageCondition(language, "rtl", "ltr")}
                            MenuProps={{
                                style: { marginTop: "50px" },
                            }}
                        >
                            <MenuItem value="" className="defaultSelect" dir={languageCondition(language, "rtl", "ltr")}>{t("Subscription End In")}</MenuItem>
                            {this.renderMenu(t)}
                        </Select>
                        <CommonButton startIcon={<SearchOutlined />} onClick={() => this.getTotalComplexList("1")} t={t} label="Search" color="blue" data-testId={"searchBtnTestId"} />
                        <CommonButton onClick={this.handleClearFiltersClick} t={t} label="Clear Filters" color="white" data-testId={"clearBtnTestId"} />
                    </Box>
                    <Paper className="paperStyle">
                        <Box className="managerStyle">
                            <Typography className="headingManger" dir={languageCondition(language, "rtl", "ltr")}>
                                {t("Total assigned Complexes")}
                            </Typography>
                            <Box className="selectStyles" dir={languageCondition(language, "rtl", "ltr")}>
                                 <SearchInput
                                    label="Search By Complex Name"
                                    onChange={this.handleSearchInput}
                                    t={t}
                                    data-testId="searchInputTestIdCoo"
                                    language={language}
                                />
                            </Box>
                        </Box>
                        <Divider className="dividerStyle" />
                        {this.tableOfComplexRender()}
                    </Paper>
                </TotalComplexStyle>
            </>
        );
    };

    tableOfComplexRender = () => {
        const { t, i18n }: { t: TFunction, i18n: I18nInterface } = this.props;
        const language = i18n.language;
        const currentPage=this.state.paginationData?.current_page as number
        return (
            <>
                <TableContainer className="tableStyle" dir={languageCondition(language, "rtl", "ltr")}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" className="tableHeaderStyle">
                                    #
                                </TableCell>
                                <TableCell className="tableHeaderStyle" align="left">{t("Complex")}</TableCell>
                                <TableCell className="tableHeaderStyle" align="left">{t("Company")}</TableCell>
                                <TableCell className="tableHeaderStyle" align="left">{t("Account Manager")}</TableCell>
                                <TableCell className="tableHeaderStyle" align="left">{t("Total Building")}</TableCell>
                                <TableCell className="tableHeaderStyle" align="left">{t("Total Unit")}</TableCell>
                                <TableCell className="tableHeaderStyle" align="left">{t("Subscrption End In")}</TableCell>
                                <TableCell className="tableHeaderStyle" align="left">{t("Country")}</TableCell>
                                <TableCell className="tableHeaderStyle" align="left">{t("City")}</TableCell>
                                <TableCell className="tableHeaderStyle" align="left"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.totalComplexList.map((totalComplex: TotalComplexListItem, totaComplexIndex: number) => (
                                <TableRow key={totaComplexIndex}>
                                    <TableCell>{(currentPage - 1) * 10 + totaComplexIndex + 1}</TableCell>
                                    <TableCell>{this.handleNullValues(totalComplex.attributes.building_name)}</TableCell>
                                    <TableCell>{this.handleCompanyNameValue(totalComplex.attributes.company_name)}</TableCell>
                                    <TableCell>{this.handleNullValues(totalComplex.attributes.account_manager)}</TableCell>
                                    <TableCell>{this.handleNullValues(totalComplex.attributes.totle_building)}</TableCell>
                                    <TableCell>{this.handleNullValues(totalComplex.attributes.totle_unit)}</TableCell>
                                    <TableCell>{this.handleNullValues(totalComplex.attributes.subscription_ends)}</TableCell>
                                    <TableCell>{this.handleNullValues(totalComplex.attributes.country)}</TableCell>
                                    <TableCell data-testId={`totalComplexCity${totaComplexIndex}`}>{this.handleNullValues(totalComplex.attributes.city)}</TableCell>
                                    <TableCell>
                                        <Menu
                                            menuButton={
                                                <IconButton>
                                                    <MoreVertIcon />
                                                </IconButton>
                                            }
                                            >
                                            <MenuItem onClick={() => {this.handleMapvalues(totalComplex);this.handleOpenmapModal()}}>{t("View Location on Map")}</MenuItem>
                                            <MenuItem onClick={() => this.handleOpenReassignModal(totalComplex)}>{t("Reassign")}</MenuItem>
                                        </Menu>
                                    </TableCell>
                                </TableRow>
                            ))} 
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box className="paginationStyle" dir={languageCondition(language, "rtl", "ltr")}>
                    <PaginationModule handlePagination={this.handlePagination} pagination={this.state.paginationData} page={this.state.paginationData?.current_page} data-testId={"contractListPagintionTestId"} />
                </Box>
                <Modal
                    open={this.state.loginModal}
                    onClose={this.handleLoginModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {this.renderLoginModal()}
                </Modal>
            </>
        );
    };

    managedComplexesRender = () => {
        const { t, i18n }: { t: TFunction, i18n: I18nInterface } = this.props;
        const language = i18n.language;
        return (
            <>
                 <MangedComplexStyle dir={languageCondition(language, "rtl", "ltr")}>
                    <Box dir={languageCondition(language, "rtl", "ltr")}>
                        <Breadcrumbs dir={languageCondition(language, "rtl", "ltr")}>
                            <Typography className="mangingDashboard" onClick={() => this.handleNavigation("DashboardCompanyEmployee")} data-testId={"myDashboardHeadingTestId"} dir={languageCondition(language, "rtl", "ltr")}>{t("My Dashboards")}</Typography>
                            <Typography className="mangingDashboard" onClick={() => this.handleNavigation("DashboardCompanyEmployee")} data-testId={"totalComplexHeadingTestId"} dir={languageCondition(language, "rtl", "ltr")}>{t("General Dashboard")}</Typography>
                            <Typography className="assignedAccount" dir={languageCondition(language, "rtl", "ltr")}>{t("Total Complexes")}</Typography>
                        </Breadcrumbs>
                    </Box>
                    <Typography className="headingStyle" dir={languageCondition(language, "rtl", "ltr")}>{t("Total Complexes")}</Typography>
                    <Box className="selectComplexes" dir={languageCondition(language, "rtl", "ltr")}>
                        <Select
                            value={this.state.country}
                            onChange={this.handlecountryChange}
                            className="selectManger"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            IconComponent={KeyboardArrowDownIcon}
                            displayEmpty
                            MenuProps={{
                                style: { marginTop: "50px" },
                            }}
                            data-testId={"countrySelectTestId"}
                            dir={languageCondition(language, "rtl", "ltr")}
                        >
                            <MenuItem value="" className="defaultSelect" dir={languageCondition(language, "rtl", "ltr")}>{t("Select Country")}</MenuItem>
                            {this.state.countryList.map((country: any, index: number) => {
                                return <MenuItem className="defaultSelect" key={index} value={country.attributes.name} data-testId={`countryItem${index}`}>{country.attributes.name}</MenuItem>
                            })}
                        </Select>
                        <Select
                            onChange={this.handleCityChange}
                            value={this.state.city}
                            labelId="demo-simple-select-label"
                            className="selectManger"
                            displayEmpty
                            IconComponent={KeyboardArrowDownIcon}
                            id="demo-simple-select"
                            MenuProps={{
                                style: { marginTop: "50px" },
                            }}
                            data-testId={"citySelectTestId"}
                            dir={languageCondition(language, "rtl", "ltr")}
                        >
                            <MenuItem value="" className="defaultSelect" dir={languageCondition(language, "rtl", "ltr")}>{t("Select City")}</MenuItem>
                            {this.state.cityList.map((city, cityIndex: number) => {
                                return <MenuItem value={city.name} className="defaultSelect" key={cityIndex} data-testId={`cityListItem${cityIndex}`}>{city.name}</MenuItem>
                            })}
                        </Select>
                        <Select
                            value={this.state.company}
                            onChange={this.handleCompanyChange}
                            className="selectManger"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            IconComponent={KeyboardArrowDownIcon}
                            displayEmpty
                            MenuProps={{
                                style: { marginTop: "50px" },
                            }}
                            data-testId={"companySelectTestId"}
                            dir={languageCondition(language, "rtl", "ltr")}
                        >
                            <MenuItem value="" className="defaultSelect" dir={languageCondition(language, "rtl", "ltr")}>{t("Select Company")}</MenuItem>
                            {this.state.companyList.map((companyItemData: CompanyItem, companyIndex: number) => {
                                return <MenuItem value={companyItemData.company_name} key={companyIndex} className="defaultSelect" data-testId={`companyListItem${companyIndex}`}>{companyItemData.company_name}</MenuItem>
                            })}
                        </Select>

                        <Select
                            value={this.state.complex}
                            onChange={this.handleComplexChange}
                            className="selectManger"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            IconComponent={KeyboardArrowDownIcon}
                            displayEmpty
                            MenuProps={{
                                style: { marginTop: "50px" },
                            }}
                            data-testId={"complexSelectTestId"}
                            dir={languageCondition(language, "rtl", "ltr")}
                        >
                            <MenuItem value="" className="defaultSelect" dir={languageCondition(language, "rtl", "ltr")}>{t("Select Complex")}</MenuItem>
                            {this.state.complexList.filter((complex: any) => !complex.attributes.is_building).map((complex: ComplexListItem, complexIndex: number) => {
                                return <MenuItem value={this.handleNullValues(complex.attributes.name)} key={complexIndex} data-testId={`complexListItem${complexIndex}`}>{complex.attributes.name}</MenuItem>
                            })}
                        </Select>
                        <Select
                            value={this.state.accountManager}
                            onChange={this.handleAccountManagerChange}
                            className="selectManger"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            IconComponent={KeyboardArrowDownIcon}
                            displayEmpty
                            MenuProps={{
                                style: { marginTop: "50px" },
                            }}
                            data-testId={"accountManagerSelectTestId"}
                            dir={languageCondition(language, "rtl", "ltr")}
                        >
                            <MenuItem value="" className="defaultSelect" dir={languageCondition(language, "rtl", "ltr")}>{t("Account Manager")}</MenuItem>
                            {this.state.accountManagerList.map((accountManager: AccountMangerListItem, accountManagerIndex: number) => {
                                return <MenuItem key={accountManagerIndex} value={accountManager.full_name} className="defaultSelect" data-testId={`accountManagerListItem${accountManagerIndex}`}>{accountManager.full_name}</MenuItem>
                            })}
                        </Select>
                        <Select
                            value={this.state.subscriptionEndsIn}
                            onChange={this.handleSubcriptionChange}
                            data-testId={"subscriptionEndsInSelectTestId"}
                            className="selectManger"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            IconComponent={KeyboardArrowDownIcon}
                            displayEmpty
                            MenuProps={{
                                style: { marginTop: "50px" },
                            }}
                            dir={languageCondition(language, "rtl", "ltr")}
                        >
                            <MenuItem value="" className="defaultSelect" dir={languageCondition(language, "rtl", "ltr")}>{t("Subscription End In")}</MenuItem>
                            {this.renderMenu(t)}
                           
                        </Select>
                        <CommonButton startIcon={<SearchOutlined />} onClick={() => this.getTotalComplexList("1")} t={t} label="Search" color="blue" data-testId={"searchBtnTestId"} />
                        <CommonButton onClick={this.handleClearFiltersClick} t={t} label="Clear Filters" color="white" data-testId={"clearBtnTestId"} />
                    </Box>
                    <Paper className="paperStyle" dir={languageCondition(language, "rtl", "ltr")}>
                        <Box className="managerStyle" dir={languageCondition(language, "rtl", "ltr")}>
                            <Typography className="headingManger" dir={languageCondition(language, "rtl", "ltr")}>
                                {t("List of Manage Complex")}
                            </Typography>
                            <Box className="selectStyles" dir={languageCondition(language, "rtl", "ltr")}>
                                  <SearchInput
                                    label="Search By Complex Name"
                                    onChange={this.handleSearchInput}
                                    t={t}
                                    data-testId="searchInputTestIdsales"
                                    language={language}
                                />
                            </Box>
                        </Box>
                        <Divider className="dividerStyle" />
                        {this.tableOfmangedComplexes()}
                    </Paper>
                </MangedComplexStyle>
            </>
        );
    };

    tableOfmangedComplexes = () => {
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);
        const { t, i18n }: { t: TFunction, i18n: I18nInterface } = this.props;
        const language = i18n.language;
        const currentPage=this.state.paginationData?.current_page as number
        return (
            <>
                <TableContainer className="tableStyle" dir={languageCondition(language, "rtl", "ltr")}>
                    <Table dir={languageCondition(language, "rtl", "ltr")}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" className="tableHeaderStyle">#</TableCell>
                                <TableCell align="left" className="tableHeaderStyle">{t("Complex")}</TableCell>
                                <TableCell align="left" className="tableHeaderStyle">{t("Company")}</TableCell>
                                <TableCell align="left" className="tableHeaderStyle">{t("Chairman")}</TableCell>
                                <TableCell align="left" className="tableHeaderStyle">{t("Account Manager")}</TableCell>
                                <TableCell align="left" className="tableHeaderStyle">{t("Total Building")}</TableCell>
                                <TableCell align="left" className="tableHeaderStyle">{t("Total Unit")}</TableCell>
                                <TableCell align="left" className="tableHeaderStyle">{t("Subscrption End In")}</TableCell>
                                <TableCell align="left" className="tableHeaderStyle">{t("Country")}</TableCell>
                                <TableCell align="left" className="tableHeaderStyle">{t("City")}</TableCell>
                                <TableCell align="left" className="tableHeaderStyle"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.totalComplexList.map((totalComplex: TotalComplexListItem, totalComplexIndex: number) => (
                                <TableRow key={totalComplexIndex}>
                                    <TableCell align="left">{(currentPage - 1) * 10 + totalComplexIndex + 1}</TableCell>
                                    <TableCell align="left">{this.handleNullValues(totalComplex.attributes.building_name)}</TableCell>
                                    <TableCell align="left">{this.handleCompanyNameValue(totalComplex.attributes.company_name)}</TableCell>
                                    <TableCell align="left">{this.handleNullValues(totalComplex.attributes.account_manager)}</TableCell>
                                    <TableCell align="left">{this.handleNullValues(totalComplex.attributes.totle_building)}</TableCell>
                                    <TableCell align="left">{this.handleNullValues(totalComplex.attributes.totle_unit)}</TableCell>
                                    <TableCell align="left">{this.handleNullValues(totalComplex.attributes.subscription_ends)}</TableCell>
                                    <TableCell align="left">{this.handleNullValues(totalComplex.attributes.country)}</TableCell>
                                    <TableCell align="left" data-testId={`totalComplexCity${totalComplexIndex}`}>{this.handleNullValues(totalComplex.attributes.city)}</TableCell>
                                    <TableCell>
                                        <Menu
                                            menuButton={
                                                <IconButton>
                                                    <MoreVertIcon />
                                                </IconButton>
                                            }
                                            >
                                            <MenuItem onClick={() => {this.handleMapvalues(totalComplex);this.handleOpenmapModal()}}>{t("View Location on Map")}</MenuItem>
                                            <MenuItem onClick={() => this.handleOpenReassignModal(totalComplex)}>{t("Reassign")}</MenuItem>
                                            <MenuItem onClick={this.onHandleLoginModal}>{t("Login")} <Chip label={t("Action Required")}className="chipStyle" /></MenuItem>
                                        </Menu>
                                    </TableCell>
                                </TableRow>
                            ))} 
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box dir={languageCondition(language, "rtl", "ltr")} className="paginationStyle">
                    <PaginationModule page={this.state.paginationData?.current_page} pagination={this.state.paginationData} handlePagination={this.handlePagination}  data-testId={"contractListPagintionTestId"} />
                </Box>               
                <Modal
                    open={this.state.loginModal}
                    onClose={this.handleLoginModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    data-testId={"closeLoginModalTestId"}
                >
                    {this.renderLoginModal()}
                </Modal>
            </>
        );
    };

    renderReassignFormMain = () => {
        const { t, i18n }: { t: any; i18n: any } = this.props;
        const language = i18n.language;
    
        return (
          <Dialog
            dir={languageCondition(language, "rtl", "ltr")}
            maxWidth="md"
            open={this.state.openReassignModal}
            scroll="paper"
            fullWidth
            data-testId="reassignModal"
          >
            <Formik
              validationSchema={yup.object().shape({
                reassignNewAccountManager: yup.string().required("Account manager is required"),
                reassignNote: yup.string().required("Note is required"),
              })}
              onSubmit={(values, { resetForm }) => {
                this.setState({ loader: true, openReassignModal: false }, () => {
                  this.reassignToAnotherAccountManager(values);
                  resetForm();
                });
              }}
              initialValues={this.state.reassignForm}
              enableReinitialize={true}
            >
              {({ values, getFieldProps, handleSubmit, errors, touched, setFieldValue }) => {
                return (
                  <Form translate="yes" onSubmit={handleSubmit}>
                    <DialogHeader t={t} title={t("Assign to another Manager")} onClose={this.handleCloseReassignModal} />
                    <DialogContent dividers>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <NormalTextField
                            language={language}
                            isLabel={true}
                            value={values.reassignBuildingId}
                            t={t}
                            label={t("Building ID")}
                            disabled
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <NormalTextField
                            language={language}
                            disabled
                            isLabel={true}
                            value={values.reassignBuildingName}
                            label={t("Building Name")}
                            t={t}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <NormalTextField
                            t={t}
                            label={t("Sales Manager")}
                            isLabel={true}
                            language={language}
                            value={values.reassignSalesManager}
                            disabled
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <NormalTextField
                            language={language}
                            label={t("Current Account Manager")}
                            isLabel={true}
                            value={values.reassignAccountManager}
                            t={t}
                            disabled
                          />
                        </Grid>
                        {this.state.userRole === "coo" && (
                          <Grid item xs={12}>
                            <NormalTextField
                              language={language}
                              label={t("New Sales Manager")}
                              isLabel={true}
                              value={values.reassignNewSalesManager}
                              t={t}
                              disabled
                            />
                          </Grid>
                        )}
                        <Grid item xs={12}>
                          <NormalSelect
                            {...getFieldProps("reassignNewAccountManager")}
                            t={t}
                            language={language}
                            label={t("New Account Manager")}
                            option={this.state.accountManagerList.map((item: any) => ({
                                label: item.attributes.employee_name,
                                value: item.attributes.employee_account_id,
                              }))}
                              onChange={(event: any) => {
                                const value = event.target.value;
                                setFieldValue("reassignNewAccountManager", value);
      
                                const reportPerson = this.state.accountManagerList.find(
                                  (item: any) => Number(item.attributes.employee_account_id) === Number(event.target.value)
                                );
                                //@ts-ignore
                                setFieldValue("reassignNewSalesManager", reportPerson?.attributes?.reporting_person?.full_name);
                              }}
                            id="reassignAccountManagerChangeTestId"
                          />
                          {ErrorHandler(t, touched.reassignNewAccountManager, errors.reassignNewAccountManager)}
                        </Grid>
                        <Grid item xs={12}>
                          <NormalTextArea
                            {...getFieldProps("reassignNote")}
                            t={t}
                            language={language}
                            label={t("Notes")}
                            maxRows={5}
                            minRows={5}
                            data-testId={"noteInputTestId"}
                          />
                          {ErrorHandler(t, touched.reassignNote, errors.reassignNote)}
                        </Grid>
                      </Grid>
                    </DialogContent>
                    <DialogActions className="dialog__button_group">
                      <Button
                        className="cancel-button"
                        data-testId="reassignCancelBtnTestId"
                        onClick={this.handleCloseReassignModal}
                      >
                        {t("Cancel")}
                      </Button>
                      <Button type="submit" className="add-button">
                        {t("Reassign")}
                      </Button>
                    </DialogActions>
                  </Form>
                );
              }}
            </Formik>
          </Dialog>
        );
      };
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <MainScreen>
                 {this.renderMapmodal()}
                <AccountAssignedStyle>
                    {this.handleUserType('coo') && this.totalComplexRender()}
                    {this.handleUserType('sales_manager') && this.managedComplexesRender()}
                    {this.handleUserType('account_manager') && this.accountManagerComplex()}
                    {this.renderReassignFormMain()}
                </AccountAssignedStyle>
                <AlertError show={this.state.showError} handleClose={this.handleErrorAlert} message={this.state.showErrorMessage} data-testId={"errorAlertTestId"} />
                <Loader loading={this.state.loader} />
                <SuccessAlert show={this.state.showSuccess} message={this.state.showSuccessMessage} handleClose={this.handleSuccessAlert} data-testId={"successAlertTestId"}/>
            </MainScreen>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
export default withTranslation()(TotalComplex);
export { TotalComplex };
const AccountAssignedStyle = styled(Box)({
    backgroundColor: "#F4F7FF",
    padding: "35px",
});

const AccountManagerComplexStyle = styled(Box)({
    "& .headingManger": {
        fontSize: "24px",
        fontWeight: 900,
    },
    "& .mangingDashboard": {
        fontSize: "18px",
        fontWeight: 400,
        color: "#3D424B",
        cursor: "pointer"
    },
    "& .threeDotsStyle": {
        color: "grey",
    },
    "& .assignedAccount": {
        color: "#2b6fed",
    },
    "& .paginationStyle": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "20px",
        marginLeft: "10px",
    },

    "& .headingStyle": {
        fontSize: "30px",
        fontWeight: "bold",
        marginTop: "10px",
    },

    "& .showingResult": {
        marginLeft: "10px",
    },
    "& .tablePagination": {
        color: "#EBEBEC",
        "& .MuiPaginationItem-page.Mui-selected": {
            background: "#FC8E45",
            color: "white",
        },
        "& .MuiPaginationItem-outlined": {
            border: " 1px solid #EBEBEC",
            color: "grey",
        },
    },
    "& .resultShowingOutOf": {
        fontWeight: 600,
    },
    "& .resultShowing": {
        color: "#FC8E45",
        fontWeight: 600,
    },
    "& .defaultSelect": {
        color: "#EBEBEC",
    },
    "& .paperStyle": {
        width: "100%",
        marginTop: "20px",
        borderRadius: "10px",
    },
    "& .managerStyle": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "20px",
        gap: "20px"
      },
    "& .selectManger": {
        background: "white",
        display: "flex",
        width: "min-content",
        flexWrap: "wrap",
        color: "grey",
        border: "1px solid #EBEBEC",
        borderRadius: "10px",
        height: "45px",
        padding: "0px 20px 0px 10px",
        "& .MuiSelect-outlined.MuiSelect-outlined": {},
        "&.MuiInput-underline::after": {
            borderBottom: "0px",
        },
        "&.MuiOutlinedInput-root": {
            borderRadius: "20px",
        },
        "&.MuiInput-underline::before": {
            borderBottom: "0px",
        },
        "& .MuiSelect-select.MuiSelect-select": {
            paddingRight: "10px",
        },
    },
    "& .searchInputStyle": {
        height: 40,
        width: "300px",
        backgroundColor: "#fafafa",
        borderRadius: "10px",
        padding: "0px 15px 0px 15px",
        border: "1px solid #EBEBEC",
    },
    "& .selectComplexes": {
        display: "flex",
        flexWrap: "wrap",
        marginTop: "10px",
    },
    "& .searchButton": {
        height: "38px",
        backgroundColor: "#2b70ed",
        color: "white",
        borderRaduis: "10px",
        margin: "10px 20px 5px 3px",
        textTransform: "capitalize",
    },
    "& .tableHeaderStyle": {
        fontWeight: 900,
        fontSize: "14px",
    },
});

const LoginModalStyle = styled(Box)({
    height: "340px",
    maxWidth: "400px",
    width: "calc(100% - 35px)",
    marginRight: "40px",
    backgroundColor: "white",
    overflowY: "scroll",
    position: "relative",
    padding: "20px",
    borderRaduis: "20px",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "13px",
    "& .searchLoginStyle": {
        padding: "16px 24px",
    },
    "& .notificationHeading": {
        height: "70px",
        padding: "16px 24px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    "& .villacomplex": {
        fontSize: "28px",
        fontWeight: "900",
    },
    "& .passWordAdormnet": {
        cursor: "pointer",
    },
    "& .searchInputStyle": {
        height: 56,
        width: "100%",
        backgroundColor: "#f9f9f9",
        borderRadius: "12px",
        padding: "0px 15px 0px 15px",
        marginBottom: "20px",
        border: "1px solid #D8DADC",
        marginRight: "25px",
    },
    "& .modalLoginButton": {
        margin: "3px 0px 22px 0px",
        borderRadius: "8px",
        height: "48px",
        backgroundColor: "#2b70ed",
        color: "white",
    },
    "& .inputStartAdorment": {
        marginRight: "10px",
    },
});

const TotalComplexStyle = styled(Box)({
    "& .searchIcon": {
        color: "gray",
        margin: "0px"
    },
    "& .tableHeaderStyle": {
        fontSize: "14px",
        fontWeight: 900,
    },
    "& .threeDotsStyle": {
        color: "grey",
    },
    "& .showingResult": {
        marginLeft: "10px",
    },
    "& .tablePagination": {
        color: "#EBEBEC",
        "& .MuiPaginationItem-outlined": {
            border: " 1px solid #EBEBEC",
            color: "grey",
        },
        "& .MuiPaginationItem-page.Mui-selected": {
            background: "#FC8E45",
            color: "white",
        },
    },
    "& .resultShowing": {
        color: "#FC8E45",
        fontWeight: 600,
    },
    "& .resultShowingOutOf": {
        fontWeight: 600,
    },
    "& .paginationStyle": {
        justifyContent: "space-between",
        display: "flex",
        marginLeft: "10px",
        padding: "20px",
        alignItems: "center",
    },
    "& .paperStyle": {
        width: "100%",
        marginTop: "20px",
    },
    "& .searchInputStyle": {
        height: 40,
        width: 270,
        backgroundColor: "#fafafa",
        borderRadius: "5px",
        padding: "0px 15px 0px 15px",
        border: "1px solid #EBEBEC",
        "& .MuiInputBase-input": {
            paddingLeft: "30px",
        },
        "& ::placeholder": {
        color: "#3D424B !important",
        },
    },
    "& .managerStyle": {
        justifyContent: "space-between",
        padding: "20px",
        display: "flex",
        alignItems: "center",
    },
    "& .selectStyles": {
        alignItems: "center",
        justifyContent: "space-Between",
        display: "flex",
    },
    "& .mangingDashboard": {
        fontWeight: 400,
        color: "#3D424B",
        fontSize: "16px",
        cursor: "pointer"
    },
    "& .dividerStyle": {
        backgroundColor: "grey",
        height: "1px",
    },
    "& .headingManger": {
        fontWeight: 600,
        fontSize: "24px",
    },
    "& .assignedAccount": {
        color: "#2b6fed",
    },
    "& .reassignStyle": {
        borderTop: "1px solid #f1f1f1",
    },

    "& .headingStyle": {
        fontSize: "30px",
        fontWeight: "bold",
        marginTop: "10px",
    },
    "& .selectComplexes": {
        display: "flex",
        flexWrap: "wrap",
        marginTop: "10px",
        alignItems: "center",
        gap: "20px"
    },
    "& .selectManger": {
        display: "flex",
        flexWrap: "wrap",
        background: "white",
        width: "min-content",
        padding: "0px 20px 0px 10px",
        color: "grey",
        border: "1px solid #EBEBEC",
        height: "45px",
        borderRadius: "10px",
        "& .MuiSelect-outlined.MuiSelect-outlined": {},
        "&.MuiOutlinedInput-root": {
            borderRadius: "20px",
        },
        "&.MuiInput-underline::after": {
            borderBottom: "0px",
        },
        "& .MuiSelect-select.MuiSelect-select": {
            paddingRight: "10px",
        },
        "&.MuiInput-underline::before": {
            borderBottom: "0px",
        },
    },
    "& .searchButton": {
        backgroundColor: "#2b70ed",
        height: "38px",
        borderRaduis: "10px",
        color: "white",
        textTransform: "capitalize",
        margin: "10px 20px 5px 3px",
    },
});

const MangedComplexStyle = styled(Box)({
    "& .tableHeaderStyle": {
        fontWeight: 900,
        fontSize: "14px",
    },
    "& .threeDotsStyle": {
        color: "grey",
    },
    "& .showingResult": {
        marginLeft: "10px",
    },
    "& .tablePagination": {
        color: "#EBEBEC",
        "& .MuiPaginationItem-outlined": {
            color: "grey",
            border: "1px solid #EBEBEC",
        },
        "& .MuiPaginationItem-page.Mui-selected": {
            color: "white",
            background: "#FC8E45",
        },
    },
    "& .resultShowingOutOf": {
        fontWeight: 600,
    },
    "& .resultShowing": {
        fontWeight: 600,
        color: "#FC8E45",
    },
    "& .paginationStyle": {
        justifyContent: "space-between",
        display: "flex",
        marginLeft: "10px",
        padding: "20px",
        alignItems: "center",
    },
    "& .paperStyle": {
        marginTop: "20px",
        width: "100%",
    },
    "& .searchInputStyle": {
        width: "280px",
        backgroundColor: "#fafafa",
        borderRadius: "5px",
        border: "1px solid #EBEBEC",
        height: 40,
        padding: "0px 15px 0px 15px",
    },
    "& .managerStyle": {
        justifyContent: "space-between",
        padding: "20px",
        display: "flex",
        alignItems: "center",
    },
    "& .selectStyles": {
        alignItems: "center",
        display: "flex",
        justifyContent: "space-Between",
    },
    "& .mangingDashboard": {
        fontWeight: 400,
        color: "#3D424B",
        fontSize: "18px",
        cursor: "pointer"
    },
    "& .dividerStyle": {
        backgroundColor: "grey",
        height: "1px",
    },
    "& .headingManger": {
        fontWeight: 600,
        fontSize: "24px",
    },
    "& .reassignStyle": {
        borderTop: "1px solid #f1f1f1",
    },
    "& .assignedAccount": {
        color: "#2b6fed",
    },

    "& .headingStyle": {
        fontWeight: "bold",
        marginTop: "10px",
        fontSize: "30px",
    },
    "& .selectComplexes": {
        flexWrap: "wrap",
        marginTop: "10px",
        display: "flex",
        alignItems: "center",
        gap: "20px"
    },
    "& .selectManger": {
        background: "white",
        display: "flex",
        flexWrap: "wrap",
        width: "min-content",
        border: "1px solid #EBEBEC",
        color: "grey",
        height: "45px",
        padding: "0px 20px 0px 10px",
        borderRadius: "10px",
        "&.MuiInput-underline::after": {
            borderBottom: "0px",
        },
        "& .MuiSelect-outlined.MuiSelect-outlined": {},
        "&.MuiOutlinedInput-root": {
            borderRadius: "20px",
        },
        "& .MuiSelect-select.MuiSelect-select": {
            paddingRight: "10px",
        },
        "&.MuiInput-underline::before": {
            borderBottom: "0px",
        },
    },
    "& .searchButton": {
        borderRaduis: "10px",
        backgroundColor: "#2b70ed",
        height: "38px",
        color: "white",
        padding: "15px 25px",
        textTransform: "capitalize",
        margin: "10px 20px 5px 3px",
    },
});
// Customizable Area End