//Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../components/src/ApiCallCommon.web";
import { RouteComponentProps } from 'react-router-dom';
import { t } from "i18next";
import { Message } from "../../../framework/src/Message";
import { apiCallWithToken as apiCall } from "../../../components/src/APICallComponent/index.web";
import { setStorageData } from "../../../framework/src/Utilities";
export const configJSON = require("./config");
export interface Props extends RouteComponentProps {
  navigation: any;
  id: string;
  classes?: any;
  t: (value: string) => string;
}

interface S {
  tabNo: number,
  tabs: any[],
  accountType: string,
  registerModal: boolean,
  openOptions: number,
  pagination: any,
  page: number,
  countryList: any[],
  cityList: any[],
  endsInList: any[],
  complexList: any[],
  buildingList: any[],
  planList: any[],
  statusList: any[],
  countryFilter: any;
  cityFilter: any;
  endsInFilter: any;
  complexFilter: any;
  buildingFilter: any;
  planFilter: any;
  statusFilter: any;
  filterList: string;
  titleTable: string;
  searchBy:string;
  loading: boolean;
  searchQuery:string,
  subscriptionList: any[];
  configureModal: boolean,
  configureValue: string,
  openBuildingMap:boolean,
  userrole:string|null
  openBuilding:boolean
  // new modal

}

interface SS {
  id: any;
}

export default class SubscriptionListingController extends CommonApiCallForBlockComponent<Props, S, SS> {

  GetSubscriptionList: any;
  GetCountryFilterList: any;
  GetCityFilterList: any;
  GetComplexFilterList: any;
  GetBuildingFilterList: any;
  GetPlanFilterList: any;
  GetEndsInFilterList: any;
  GetStatusFilterList: any;
  GetSubscriptionSearchFilter: any;
  GetConfigureNotification:any;
  Getplanlistdata:string=""



  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
    ];
    this.state = {
      tabNo: 0,
      tabs: [
        {
          label: t("Property Developers"),
          testId: 'tabcDeveloper'
        },
        {
          label: t("General Assemblies"),
          testId: 'tabBuildings'
        },
        {
          label: t("Owners"),
          testId: 'tabOwners'
        },
        {
          label: t("Property Managers"),
          testId: 'tabManagers'
        },
      ],
      accountType: '',
      openOptions: 0,
      registerModal: false,
      pagination: {
        current_page: 1,
        total_count: 1,
        total_pages: 1,
      },
      subscriptionList: [],
      page: 1,
      countryList: [],
      cityList: [],
      endsInList: [
        {
          label: 'Ascending',
          value: "ascending",
        },
        {
          label: 'Descending',
          value: "descending",
        },
      ],
      complexList: [],
      buildingList: [],
      planList: [],
      statusList: [
        {
          label: 'Active',
          value: "active",
        },
        {
          label: 'Extend Request',
          value: "extend_request",
        },
        {
          label: 'Suspend',
          value: "suspend",
        },
        {
          label: 'Deactivated',
          value: "deactive",
        },
        {
          label: 'Deactive Request',
          value: "deactive_request",
        },
        {
          label: 'Frozen',
          value: "freeze",
        },
        {
          label: 'Pending',
          value: "pending",
        },
        {
          label: 'Freeze Request',
          value: "freeze_request",
        },
        {
          label: 'Upgrade Request',
          value: "upgrade_request",
        },
      ],
      searchQuery:"",
      countryFilter: '',
      cityFilter: '',
      endsInFilter: '',
      complexFilter: '',
      buildingFilter: '',
      planFilter: '',
      statusFilter: '',
      loading: false,
      openBuildingMap:false,
      filterList: "property_developer",
      titleTable: "List of Property Developers",
      searchBy:"Company",
      configureModal: false,
      configureValue: '',
      userrole:"",
      openBuilding:false
    };/*  */
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async componentDidMount() {
    this.getSubscriptionList();
    const getAllFilter = [
      {
        name: 'GetCountryFilterList',
        endPoint: 'bx_block_address/country_list',
      },
      {
        name: 'GetComplexFilterList',
        endPoint: `bx_block_address/housing_complex_list?city=${this.state.cityFilter}`
      },
      {
        name: 'GetPlanFilterList',
        endPoint: 'bx_block_custom_user_subs/plans',
      },
    ]
    const accountRole = localStorage.getItem("role")
    this.setState({
      userrole:accountRole
    })
    if (accountRole) {
      const accountRoleName = accountRole.replace(/"/g, '')
      this.setState({ accountType: accountRoleName })
    }

    getAllFilter.forEach((filter) => {
      this.getFilterListTable(filter.name, filter.endPoint)
    })

  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      switch (apiRequestCallId) {
        case this.GetSubscriptionList:
          this.handleSubscriptionList(responseJson);
          break;
        case this.GetSubscriptionSearchFilter:
          this.handleSubscriptionList(responseJson);
          break;
        case this.GetCountryFilterList:
          this.handleFiltersList(responseJson, 'countryList', "countries")
          break;
        case this.GetCityFilterList:
          this.handleFiltersList(responseJson, 'cityList', null)
          break;
        case this.GetComplexFilterList:
          this.handleFiltersList(responseJson, 'complexList', 'data')
          break;
        case this.GetBuildingFilterList:
          this.handleFiltersList(responseJson, 'buildingList', 'buildings')
          break;
        case this.GetPlanFilterList:
          this.handleFiltersList(responseJson, 'planList', 'data')
          break;
        case this.GetConfigureNotification:
          this.handleConfigureNotification(responseJson)
           break;
        default:
          break;
      }
      this.setState({ loading: false })
    }
  }
  handleConfigureNotification=(responseJson:any)=>{
    this.setState({configureModal:false, loading:false})
  }
  handleSubscriptionList = (responseJson: any) => {
        if (responseJson) {
      this.setState({
        subscriptionList: responseJson.data,
        pagination:responseJson?.meta?.pagination
      })
    }
  }
  handleFiltersList = (responseJson: any, stateParam: string, resParam:any) => {
    if(stateParam === "cityList"){
      this.setState({ ...this.state, [stateParam]:responseJson });
    return
    }
    const data = stateParam === "countryList"
      ? responseJson?.data?.[resParam] 
      : responseJson?.[resParam];
  
    if (data) {
      this.setState({ ...this.state, [stateParam]: data });
    }
  };

  handleTabChange = (event: any, newValue: number) => {
    switch (newValue) {
      case 0: {
        this.getSubscriptionListTab("property_developer")
        this.setState({ filterList: "property_developer", titleTable: 'List of Property Developers', searchBy:"Company", page: 1 })
        break;
      }
      case 1: {
          this.getSubscriptionListTab("general_assembly")
        this.setState({ filterList: "general_assembly", titleTable: "List of General Assemblies" ,searchBy:"Complex" , page: 1 })
        break;
      }
      case 2: {
        this.getSubscriptionListTab("owner")
        this.setState({ filterList: "owner", titleTable: 'List of Owners',searchBy:"Name", page: 1  })
        break;
      }
      case 3: {
        this.getSubscriptionListTab("property_manager")
        this.setState({ filterList: "property_manager", titleTable: 'List of Property Managers',searchBy:"Name", page: 1  })
        break;
      }
    }
    this.setState({ tabNo: newValue })
  }

  getCityList = async (country: any) => {
    this.GetCityFilterList = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_address/city_by_state?country_name=${this.state.countryFilter}`
    })
  }

  handleCloseRegisterModal = () => {
    this.setState({ registerModal: false })
  }

  handleOpenRegisterModal = () => {
    this.setState({ registerModal: true })
  }

  handleConfigureModal = () => {
    this.setState({ configureModal: !this.state.configureModal, configureValue: '' })
  }

  handleOpenOptions = (id: number) => {
    this.setState({ openOptions: id })
  }

  handleVistorPagination = (e: any, value: any) => {
    this.setState({
      page: value
    },()=>{
      this.getSubscriptionSearchFilter()
    })
  }

  getBuildingList = async (society_management_id: any) => {
    this.GetBuildingFilterList = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_announcement/announcements/building_list?society_management_id=${society_management_id}`
    })
  }
  onChange = (e: any) => {
    this.setState({ searchQuery: e.target.value },()=> this.getSubscriptionSearchList());  
  }

  handleFilterChange = (event: any, stateParam: string) => {
    let value = event.target.value;
    if (value === "all") {
      value = "";
    }
    if (stateParam == "countryFilter") {
      this.setState({ [stateParam]: value },()=>{this.getCityList(value)})
    }
    if (stateParam == "cityFilter") {
      this.setState({ [stateParam]: value })
      this.getComplexList(value)
    }
    if (stateParam == "complexFilter") {
      this.getBuildingList(value)
      this.setState({ [stateParam]: value })
    }
    this.setState({ ...this.state, [stateParam]: value })
  }

  handleSubmit = (event: any) => {
    this.setState({ page: 1 })
    event.preventDefault()
    this.getSubscriptionSearchFilter()
  }

  handleBuildingMap = () => {
    this.setState({
      openBuildingMap: !this.state.openBuildingMap,
    });
  };

  handleCloseBuildingMap = () => {
    this.setState({ openBuildingMap: !this.state.openBuildingMap });
  };



  getFilterListTable = async (method: string, endPoint: string) => {
    this.setState({ loading: true })
    //@ts-ignore
    this[method] = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint,
    });
  }

  getSubscriptionList = async () => {
    this.setState({ loading: true })
    this.GetSubscriptionList = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_custom_user_subs/subscriptions?user_role=${this.state.filterList}&page=${this.state.page}&per_page=10`,
    });
  }
  getSubscriptionSearchList = async () => {
    this.setState({ loading: true })
    this.GetSubscriptionList = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_custom_user_subs/subscriptions?user_role=${this.state.filterList}&search_term=${this.state.searchQuery}&page=${this.state.page}&per_page=10`,
    });
  }
  
  getSubscriptionListTab = async (userRole: string) => {
    this.setState({ loading: true })
    this.GetSubscriptionList = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_custom_user_subs/subscriptions?user_role=${userRole}&page=${this.state.page}&per_page=10`,
    });
  }

  getSubscriptionSearchFilter = async () => {
    this.setState({ loading: true })
    this.GetSubscriptionSearchFilter = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_custom_user_subs/subscriptions?user_role=${this.state.filterList}&plan_id=${this.state.planFilter}&country=${this.state.countryFilter}&city=${this.state.cityFilter}&status=${this.state.statusFilter}&sort_by_expiry=${this.state.endsInFilter}&subscriber_id=${this.state.complexFilter}&building_id=${this.state.buildingFilter}&page=${this.state.page}&per_page=10`,
    });
  }

  getComplexList = async (city?: string) => {
    this.GetComplexFilterList = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_address/housing_complex_list?city=${city}`
    })
  }

  convertDate(dateString: string) {
    const p = dateString.split(/\D/g)
    return [p[2], p[1], p[0]].join("-")
  }

  changeConfigureData = (value: string) => {
    this.setState({ configureValue: value })
  }

  handleProfilenavigation=(row:any)=>{
     setStorageData("profileId",row?.attributes?.subscriber_id)
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'Profile'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message);
  }

  handlePropertynavigation=(row:any)=>{
    setStorageData("profileId",row?.attributes?.subscriber_id)
    const propertMessage: Message = new Message(getName(MessageEnum.NavigationMessage))
    propertMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'Profile'
    );
    propertMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(propertMessage);
  }
  
  submitConfigureModal =async () => {
    this.setState({loading:true})
    this.GetConfigureNotification = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiPUTMethodType,
      endPoint: configJSON.setConfigureNotificationEndPoint+`?end_in_days=${this.state.configureValue}&user_role=${this.state.filterList}`
    })
  }
}
// Customizable Area End

