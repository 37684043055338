// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
import { Message } from "../../../../framework/src/Message";
import { apiCallWithToken } from "../../../../components/src/APICallComponent/index.web";

export interface Props {
  navigation?: any;
  id?: string;
  classes: any;
  history: any;
  match: any;
  location: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
}

interface S {
  page: number;
  selectedCountry: string;
  selectedMemberId: string;
  selectedMemberRole: string;
  selectedRole: string;
  searchQuery: string;
  data: any;
  rowsPerPage: number;
  empformModal: boolean;
  menuopen: boolean;
  menufield: any;
  employeeID: number | null;
  AssignObjOpen: boolean;
  pagination: {
    current_page: number;
    total_count: number;
    total_pages: number;
  };
  countryList: any;
  roleList: any;
  memberRoleList: any;
  memberIdList: any;
  selectedSortBy: any;
  role: string;
}

interface SS {
  id: any;
}

export default class CEMyTeamTableController extends CommonApiCallForBlockComponent<
  Props,
  S,
  SS
> {
  teamMemberList: string = '';
  roleList: string = '';
  countryList: string = '';
  searchFilter: string = '';
  cityList: string = '';
  memberIdList: string = "";
  memberRoleList: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
    ];
    this.state = {
      page: 0,
      selectedCountry: '',
      selectedMemberId: '',
      selectedMemberRole: '',
      selectedRole: '',
      searchQuery: '',
      selectedSortBy: '',
      data: [],
      rowsPerPage: 10,
      empformModal: false,
      menuopen: false,
      menufield: "",
      employeeID: null,
      AssignObjOpen: false,
      pagination: {
        current_page: 1,
        total_count: 1,
        total_pages: 1,
      },
      countryList: [],
      roleList: [],
      memberIdList: [],
      memberRoleList: [],
      role: '',
    };

    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.teamMemberList || apiRequestCallId === this.searchFilter) {
          this.handleTeamMeberList(responseJson)
        } else if (apiRequestCallId === this.roleList) {
          this.handleRoleList(responseJson)
        } else if (apiRequestCallId === this.countryList) {
          this.handleCountryList(responseJson)
        } else if (apiRequestCallId === this.memberIdList) {
          this.handleMemberIdList(responseJson)
        } else if (apiRequestCallId === this.memberRoleList) {
          this.handleMemberRoleList(responseJson)
        }
      }
    }
  }

  async componentDidMount() {
    const userRole = localStorage.getItem('role') ?? ''
    this.setState({ role: userRole }, () => {
      this.getRoleListAPI();
      this.getCountryList();
      this.getMemberIdList();
      this.getMemberRoleList();
      this.applySearchFilter(false);
    })
  }

  async componentDidUpdate(prevProps: any, prevState: any): Promise<void> {
    if (prevState.searchQuery !== this.state.searchQuery) {
      await this.applySearchFilter(true, 1);
    } else if (prevState.selectedSortBy !== this.state.selectedSortBy) {
      await this.applySearchFilter(true, 1)
    }
  }

  handleRoleList = (responseJson: any) => {
    if (!responseJson.errors) {
      const roleList = responseJson.status;
      if (roleList && roleList.length > 0) {
        this.setState({ roleList: [...roleList] })
      }
    }
  }

  handleCountryList = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({ countryList: responseJson.data.map((item: any) => ({ label: item.attributes.name, value: item.attributes.name })) });
    }
  }

  handleMemberIdList = (responseJson: any) => {
    if (!responseJson.errors) {
      const idList = responseJson.status.map((item: any) => ({ label: item.label, value: item.label }));
      this.setState({ memberIdList: idList })
    }
  }

  handleMemberRoleList = (responseJson: any) => {
    if (!responseJson.errors) {
      const roleList = responseJson.status.map((item: any) => ({ label: item.name, value: item.id }));
      this.setState({ memberRoleList: roleList })
    }
  }

  getFontWeight = (value: string) => value === "" ? "normal" : "bold";

  getColSpanValue = () => this.state.role !== 'sales_manager' ? 8 : 7

  handleTeamMeberList = (responseJson: any) => {
    let pagination = {
      current_page: NaN,
      total_count: NaN,
      total_pages: NaN
    }
    if (responseJson.team_members.length > 0) {
      pagination = {
        current_page: responseJson.meta.pagination.current_page,
        total_count: responseJson.meta.pagination.total_count,
        total_pages: responseJson.meta.pagination.total_pages,
      }
    }
    this.setState({ data: responseJson.team_members, pagination })
  }

  handleCountryChange = (event: any) => {
    this.setState({ selectedCountry: event.target.value });
  };

  handleMemberIdChange = (event: any) => {
    this.setState({ selectedMemberId: event.target.value });
  }

  handleMemberRoleChange = (event: any) => {
    this.setState({ selectedMemberRole: event.target.value });
  }

  handleDepartmentChange = (event: any) => {
    this.setState({ selectedRole: event.target.value })
  }

  handleSelectedSortBy = (event: any) => {
    this.setState({ selectedSortBy: event.target.value })
  }

  handleRoleChange = (event: any) => {
    this.setState({ selectedRole: event.target.value });
  };

  handleChangePage = (newPage: any, page: any) => {
    this.setState({ page });
    this.applySearchFilter(false, page);
  };

  handleFormModal = () => {
    this.setState({ empformModal: false });
    this.applySearchFilter(true, 1);
  };

  checkNameIsExit = (value: any) => {
    if (value == null) {
      return '-'
    } else {
      return value
    }
  }

  handleOpen = (e: any, index: number | null) => {
    this.setState({
      menuopen: !this.state.menuopen,
    });
    this.setState({
      menufield: e.currentTarget,
      employeeID: index,
    });
  };

  handleModalClose = () => {
    this.setState({ AssignObjOpen: false });
    this.applySearchFilter(false);
  }

  handleClick = () => {
    this.setState({ AssignObjOpen: !this.state.AssignObjOpen })
  }

  handleMainSearch = (value: string) => {
    this.setState({ searchQuery: value, page: 1 })
  }

  updatedRole = (isDepartment?: boolean) => {
    const newRole = this.state.roleList.map((item: any) => {
      const i = item.label.replace("_", ' ')
      const newI = i.charAt(0).toUpperCase() + i.slice(1)
      return { label: newI, value: isDepartment ? item.label : item.value + "" }
    });
    return newRole.filter((item: any) => item.label !== "Coo");
  }

  searchPayload = () => {
    let data = 'search'
    if (this.state.searchQuery.length > 0) {
      if (/^\d+$/.test(this.state.searchQuery) && this.state.role === "coo") {
        data = 'id'
      } else {
        data = 'name'
      }
    }
    return data
  }

  handleSelectedData = (value: any) => value == "all" ? '' : value;

  getData = (value: any) => value !== "" ? `=${value}` : "";

  applySearchFilter = async (isPageUpdate: boolean, pageNumber?: number) => {
    const data = this.searchPayload();
    const cValue = this.handleSelectedData(this.state.selectedCountry);
    const rValue = this.handleSelectedData(this.state.selectedRole);
    const memberId = this.handleSelectedData(this.state.selectedMemberId);
    const memberRole = this.handleSelectedData(this.state.selectedMemberRole);
    const sortBy = this.handleSelectedData(this.state.selectedSortBy);

    const baseUrl = `bx_block_my_team/company_employee/employee_team_members?page=${pageNumber ?? this.state.page}&per_page${this.getData(this.state.rowsPerPage)}&sort_by${this.getData(sortBy)}&${this.state.role === "account_manager" ? "department" : "role"}${this.getData(rValue)}&country${this.getData(cValue)}&${data}${this.getData(this.state.searchQuery)}&member_id${this.getData(memberId)}&employee_name${this.getData(memberRole)}`

    this.searchFilter = await apiCallWithToken({
      contentType: "application/json",
      method: "GET",
      endPoint: baseUrl,
    });
    if (isPageUpdate) {
      this.setState({ page: 1 })
    }
  }

  getCountryList = async () => {
    this.countryList = await apiCallWithToken({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_address/addresses`,
    });
  }

  getMemberIdList = async () => {
    this.memberIdList = await apiCallWithToken({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_my_team/company_employee/employee_team_members/member_id_list`,
    });
  }

  getMemberRoleList = async () => {
    this.memberRoleList = await apiCallWithToken({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_my_team/team_members/user_name_list`,
    });
  }

  getRoleListAPI = async () => {
    this.roleList = await apiCallWithToken({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_my_team/company_employee/employee_team_members/role_list`,
    });
  }
}
// Customizable Area End
