import React from "react";
// Customizable Area Start
import {
  Box,
  
  MenuItem,
  Typography,
  Button,
  TableContainer,
  TableHead,
  Table,
  Paper,
  TableCell,
  TableRow,
  TableBody,
  Divider,
  styled,
  Dialog,
  DialogContent,
  DialogActions,
  Breadcrumbs,
  Grid,
  FormControl,
} from "@material-ui/core";
import CEHeaderComponentWeb from "../../../components/src/HeaderComponent/CEHeaderComponent.web";
import SearchIcon from "@material-ui/icons/Search";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PaginationModule from "../../StoreCredits/src/PaginationModule.web";
import { languageCondition } from "../../../components/src/ConditionManager.web";
import { Menu } from "@szhsin/react-menu";
import { rightIcon, wrongIcon } from "./assets";
import FilterSelect from "../../../components/src/CommonComponents/FilterSelect.web";
import DialogHeader from "../../../components/src/MuiDialog/DialogHeader.web";
import Loader from "../../../components/src/Loader.web";
import AlertDialog from "../../../components/src/MuiDialog/AlertDialog.web";
import { Formik } from "formik";
import NormalTextArea from "../../../components/src/CommonComponents/NormalTextArea.web";
import { ErrorHandler } from "../../../components/src/UtilFunction.web";
import SearchInput from "../../../components/src/CommonComponents/SearchInput.web";


const tabs: { id: number; tab: string; title: string;value:string }[] = [
  {
    id: 1,
    tab: "Real Estate Company",
    title: "Real Estate Company",
    value:"real_estate_company"
  },
  {
    id: 2,
    tab: "Owner",
    title: "Name",
    value:"owner"
  },
  {
    id: 3,
    tab: "Individual Buildings",
    title: "Complex",
    value:"individual_building"
  },
  {
    id: 4,
    tab: "Property Manager",
    title: "Name",
    value:"property_manager"
  },
 
];
// Customizable Area End

import DashboardSalesApprovalController, {
    Props,
} from "./DashboardSalesApprovalController.web";

// Customizable Area Start
export class DashboardSalesApproval extends DashboardSalesApprovalController {
  constructor(props: Props) {
    super(props);
  }

  handleTabs = (t: (value: string) => string) => {
    return (
      <Grid container xs={12} spacing={2}>
        {tabs.map((item: { id: number; tab: string; title: string;value:string }) => {
          return (
            <Grid item>
              <Typography
                style={this.state.activeTab.id === item.id ? approveCss.selectTagSales : approveCss.unSelectTagSales}
                data-test-id="tabsalesId" onClick={() => this.handlesalesMangerTag(item)} className="bold-text"
              >
                {t(item.tab)}
              </Typography>
            </Grid>
          )
        })}
      </Grid>
    );
  };

  handleSalesManagerdashboard=(t: (value: string) => string,language:any)=>{
    const { userRole, pendingUnitsstate } = this.state;
    return(
  <>
     <Breadcrumbs className="salesHeading">
       <Typography className="myDashboardHeadsales"  data-testId={"dashboardHeadingTestId"} dir={languageCondition(language, "rtl", "ltr")}>
         {t("My Dashboards")}
       </Typography>
       <Typography color="textPrimary" data-test-id="navigateApprovaldashboard" onClick={this.handleNavigationApprovalDashboard}  dir={languageCondition(language, "rtl", "ltr")}>{t("Approval Dashboard")}</Typography>
       {this.state.userRole==="Pending Units Requests"?
       <Typography onClick={this.handleNavigateViewDetails} data-test-id="handleNavigateViewDetails" color="textPrimary" className="dashboardTextsales" dir={languageCondition(language, "rtl", "ltr")}>{t("Pending Units Requests")}</Typography>
       :
       <Typography color="textPrimary" className="dashboardTextsales" dir={languageCondition(language, "rtl", "ltr")}>{t("Pending Subscription Requests")}</Typography>
       }
        {
        (this.state.userRole === "Pending Units Requests" && this.state.pendingUnitsstate) &&
        <Typography color="textPrimary"  className="dashboardTextsales" dir={languageCondition(language, "rtl", "ltr")}>{t("Complex 1")}</Typography>
        }
     </Breadcrumbs>
        <Box>
          {(userRole === "Pending Units Requests" && !pendingUnitsstate) &&
            <Typography color="textPrimary" className="headingsales" dir={languageCondition(language, "rtl", "ltr")}>{t("Pending Units Requests")}</Typography>
          }
          {(userRole === "Pending Subscription Requests" && !pendingUnitsstate) &&
            <Typography color="textPrimary" className="headingsales" dir={languageCondition(language, "rtl", "ltr")}>{t("Pending Subscription Requests")}</Typography>
          }
          {
            (userRole === "Pending Units Requests" && pendingUnitsstate) &&
            <Typography color="textPrimary" className="headingsales" dir={languageCondition(language, "rtl", "ltr")}>{t("Complex 1")}</Typography>
          }
        </Box>
   </>
    )
  }

  handleSalesManagerApprovalSubscriptionTable=(t: (value: string) => string,language:any)=>{
    return(
      <>
      <TableContainer component={Paper} className="table-container">
      <Table style={{ minWidth: 900 }}>
        <TableHead>
          <TableRow>
            <TableCell align={languageCondition(language, "right", "left")}>#</TableCell>
              <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
                {t(this.state.activeTab.title)}
              </TableCell>
            <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
              {t("Country")}
            </TableCell>
            <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
              {t("City")}
            </TableCell>
            <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
              {t("Action Needed")}
            </TableCell>
            <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
              {t("Requested By")}
            </TableCell>
            <TableCell align={languageCondition(language, "right", "left")} />
          </TableRow>
        </TableHead>
        <TableBody>
        {this.state.pendingErrorMessage!==""?
        <>
         <TableRow>
              <TableCell colSpan={6}>{this.state.pendingErrorMessage}</TableCell>
            </TableRow>
        </>:
        <>
          {this.state.subscriptionList.map((item: {
            name:string,
            country:string,
            city:string,
            approval_type:string,
            requested_by:string,
            item: any
             },index:number) => {
            return (
              <TableRow>
                <TableCell align={languageCondition(language, "right", "left")}>{(this.state.subscriptionPagination?.current_page - 1) * 10 + index + 1}</TableCell>
                <TableCell align={languageCondition(language, "right", "left")}>{item.name}</TableCell>
                <TableCell align={languageCondition(language, "right", "left")}>{item.country}</TableCell>
                <TableCell align={languageCondition(language, "right", "left")}>{item.city}</TableCell>
                <TableCell align={languageCondition(language, "right", "left")}>{item.approval_type}</TableCell>
                <TableCell align={languageCondition(language, "right", "left")}>{item.requested_by}</TableCell>
                <TableCell align={languageCondition(language, "right", "left")} className="ce-pm-report-menu">
                  <Menu data-test-id="handleAcceptSubscription" onClick={()=>{this.handleAcceptSubscription(item)}} menuButton={<MoreVertIcon />}>
                    <MenuItem data-test-id="view-menu-sales" onClick={this.handlesalesViewDetails}>{t("View Details")}</MenuItem>
                    <MenuItem data-test-id="accept-menu-sales" onClick={this.handlesalesAcceptRequest}>{t("Accept Request")}</MenuItem>
                    <MenuItem data-test-id="reject-menu-sales" onClick={this.handlesalesRejectRequest}>{t("Reject Request")}</MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            );
          })}</>}
        </TableBody>
      </Table>
    </TableContainer>
     <Box className="paginationStyle" dir={languageCondition(language, "rtl", "ltr")}>
     <PaginationModule handlePagination={this.handlependingPagination} pagination={this.state.subscriptionPagination} page={this.state.subscriptionPagination?.current_page} data-testId={"subscriptionListPagintionTestId"} />
 </Box>
 </>
    )
  }

  handleDialogData = (t: (value: string) => string, label: string, value: string, isBlue: boolean = false) => {
    return (
      <Grid item xs={4}>
        <Box>
          <Typography style={approveCss.labelTextSales}>{t(label)}</Typography>
          {value ? <Typography className="bold-text" style={isBlue ? approveCss.blueValueSales : approveCss.blackValueSales}>{t(value)}</Typography> :
            <Typography className="bold-text" style={approveCss.noValueSales}>{t("(N/A)")}</Typography>
          }
        </Box>
      </Grid>
    )
  }

  handleSucessDialog=(t:any,language:any)=>{
    return(
    <Dialog
    className="delete-document delete-document-dialog"
    fullWidth
    maxWidth="xs"
    onClose={this.handlesalesDialogSucessstate}
    open={this.state.dialogSucessstate}
    dir={languageCondition(language, "rtl", "ltr")}
    style={approveCss.sucessDialogSales}
  >
    <DialogContent>
      <Box textAlign="center">
        <Typography variant="h6" className="bold-text">
         Request Updated Successfully
        </Typography>
        <DialogActions className="dialog-button-group">
            <Button style={{ height: "50px" }}  data-test-id="handleDialogSucessstate" className="add-button" onClick={this.handlesalesDialogSucessstate}>
              Okay
            </Button>
        </DialogActions>
      </Box>
    </DialogContent>
  </Dialog>)
  }

  handlePendingUnitsApprovalTable=(t: (value: string) => string,language:any)=>{
    return(
    <>
    <TableContainer component={Paper} className="table-container">
    <Table style={{ minWidth: 900 }}>
      <TableHead>
        <TableRow>
          <TableCell align={languageCondition(language, "right", "left")}>#</TableCell>
            <TableCell data-test-id="pending_complex" className="bold-text" align={languageCondition(language, "right", "left")}>
              {t("Complex")}
            </TableCell>
          <TableCell data-test-id="pending_building"  className="bold-text" align={languageCondition(language, "right", "left")}>
            {t("Building")}
          </TableCell>
          <TableCell data-test-id="pending_units"  className="bold-text" align={languageCondition(language, "right", "left")}>
            {t("Pending Units Requests")}
          </TableCell>
          <TableCell data-test-id="pending_type"  align={languageCondition(language, "right", "left")} className="bold-text">
            {t("Operation Type")}
          </TableCell>
          <TableCell data-test-id="pending_account"  className="bold-text" align={languageCondition(language, "right", "left")}>
            {t("Account Manager")}
          </TableCell>
          <TableCell data-test-id="pending_Country"  className="bold-text" align={languageCondition(language, "right", "left")}>
            {t("Country")}
          </TableCell>
          <TableCell data-test-id="pending_City"  className="bold-text" align={languageCondition(language, "right", "left")}>
            {t("City")}
          </TableCell>
          <TableCell align={languageCondition(language, "right", "left")} />
        </TableRow>
      </TableHead>
      <TableBody>
        {this.state.UnitErrorMessage!==""?
        <>
         <TableRow>
              <TableCell colSpan={6}>{this.state.UnitErrorMessage}</TableCell>
            </TableRow>
        </>:
        <>
        {this.state.pendingunitlist.map((item: { 
          complex:string,
          builing:string,
          pending_unit_request:string,
          operation_type:string,
          account_manager:string,
          country:string,
          city:string,},index:number
          ) => {
          return (
            <TableRow>
              <TableCell align={languageCondition(language, "right", "left")}>{(this.state.UnitPagination?.current_page - 1) * 10 + index + 1}</TableCell>
              <TableCell align={languageCondition(language, "right", "left")}>{item.complex}</TableCell>
              <TableCell align={languageCondition(language, "right", "left")}>{item.builing}</TableCell>
              <TableCell align={languageCondition(language, "right", "left")}>{item.pending_unit_request}</TableCell>
              <TableCell align={languageCondition(language, "right", "left")}>{item.operation_type}</TableCell>
              <TableCell align={languageCondition(language, "right", "left")}>{item.account_manager}</TableCell>
              <TableCell align={languageCondition(language, "right", "left")}>{item.country}</TableCell>
              <TableCell align={languageCondition(language, "right", "left")}>{item.city}</TableCell>
              <TableCell align={languageCondition(language, "right", "left")} className="ce-pm-report-menu">
                <Menu  data-test-id="pendingUnitsdata" onClick={()=>{this.handleAcceptData(item)}} menuButton={<MoreVertIcon />}>
                  <MenuItem data-test-id="view-menu-units" onClick={()=>{this.handleunitsViewDetails(item)}}>{t("View All Units")}</MenuItem>
                  <MenuItem data-test-id="accept-menu-units" onClick={this.handleunitsAcceptRequest}>{t("Approve All Unit")}</MenuItem>
                  <MenuItem data-test-id="reject-menu-units" onClick={this.handleunitsRejectRequest}>{t("Reject All Unit")}</MenuItem>
                </Menu>
              </TableCell>
            </TableRow>
          );
        })}</>}
      </TableBody>
    </Table>
  </TableContainer>
   <Box className="paginationStyle" dir={languageCondition(language, "rtl", "ltr")}>
   <PaginationModule handlePagination={this.handleUnitPagination} pagination={this.state.UnitPagination} page={this.state.UnitPagination?.current_page} data-testId={"unitListPagintionTestId"} />
</Box>
</>
 ) }

 handleApproveAllUnitsDialog=(t: (value: string) => string,language:any)=>{
  return(
    <AlertDialog 
    IsOpen={this.state.approveAllunitsState}
    Image={rightIcon}
    CloseDialog={this.handleunitsAcceptRequest}
    Header={t("Approve All Units?")}
    Content={t("Are you sure want to approve all pending units?")}
    DeclineText={t("Close")}
    AcceptText={t("Confirm")}
    DeclineFun={this.handleunitsAcceptRequest}
    AcceptFun={()=>{this.handlesalesRecordActionSuccess("approved")}}
    language={language}
    data-test-id="acceptallunits-dialog-sales"
  />
  )
 }

 handleRejectAllUnitsDialog=(t: (value: string) => string,language:any)=>{
  return(
    <AlertDialog 
    IsOpen={this.state.rejectAllunitsState}
    Image={rightIcon}
    CloseDialog={this.handleunitsRejectRequest}
    Header={t("Reject All Units?")}
    Content={t("Are you sure want to reject all pending units?")}
    DeclineText={t("Close")}
    AcceptText={t("Confirm")}
    DeclineFun={this.handleunitsRejectRequest}
    AcceptFun={()=>{this.handlesalesRecordActionSuccess("rejected")}}
    language={language}
    data-test-id="Rejectallunits-dialog-sales"
  />
  )
 }

 handlependingUnitsTable=(t: (value: string) => string,language:any,currentpage:number)=>{
 return(
  <>
  <TableContainer component={Paper} className="table-container">
  <Table style={{ minWidth: 900 }}>
    <TableHead>
      <TableRow>
        <TableCell align={languageCondition(language, "right", "left")}>#</TableCell>
          <TableCell data-test-id="unit_number" className="bold-text" align={languageCondition(language, "right", "left")}>
            {t("Unit Number")}
          </TableCell>
        <TableCell  data-test-id="floor_number" className="bold-text" align={languageCondition(language, "right", "left")}>
          {t("Floor Number")}
        </TableCell>
        <TableCell  data-test-id="resident_number" className="bold-text" align={languageCondition(language, "right", "left")}>
          {t("Resident Name")}
        </TableCell>
        <TableCell  data-test-id="owner_number" className="bold-text" align={languageCondition(language, "right", "left")}>
          {t("Owner")}
        </TableCell>
        <TableCell align={languageCondition(language, "right", "left")} />
      </TableRow>
    </TableHead>
    <TableBody>
      {this.state.ErrorMessage!==""?
      <>
       <TableRow>
              <TableCell colSpan={6}>{this.state.ErrorMessage}</TableCell>
            </TableRow>
      </>:
      <>
      {this.state.complexunitlist.map((item: { 
          unit_number:number,
          floor_number:string,
          resident_name:string,
          owner_name:string,
      },index:number
        ) => {
        return (
          <TableRow>
            <TableCell align={languageCondition(language, "right", "left")}>{(currentpage - 1) * 10 + index + 1}</TableCell>
            <TableCell align={languageCondition(language, "right", "left")}>{item.unit_number}</TableCell>
            <TableCell align={languageCondition(language, "right", "left")}>{item.floor_number}</TableCell>
            <TableCell align={languageCondition(language, "right", "left")}>{item.resident_name}</TableCell>
            <TableCell align={languageCondition(language, "right", "left")}>{item.owner_name}</TableCell>
            <TableCell align={languageCondition(language, "right", "left")} className="ce-pm-report-menu">
              <Menu data-test-id="listUnitsdata" onClick={()=>{this.handleAcceptunitData(item)}} menuButton={<MoreVertIcon  />}>
                <MenuItem data-test-id="accept-menu-unit" onClick={this.handleComplexunitsAcceptRequest}>{t("Approve Unit")}</MenuItem>
                <MenuItem data-test-id="reject-menu-unit" onClick={this.handleComplexunitsRejectRequest}>{t("Reject Unit")}</MenuItem>
              </Menu>
            </TableCell>
          </TableRow>
        );
      })}</>}
    </TableBody>
  </Table>
</TableContainer>
 <Box className="paginationStyle" dir={languageCondition(language, "rtl", "ltr")}>
 <PaginationModule handlePagination={this.handlesalesPagination} pagination={this.state.pagination} page={this.state.pagination?.current_page} data-testId={"unitListPagintionTestId"} />
</Box>
</>
 )
 }


 handleApproveUnitDialog=(t: (value: string) => string,language:any)=>{
  return(
    <AlertDialog 
    IsOpen={this.state.approveComplexAllunitsState}
    Image={rightIcon}
    CloseDialog={this.handleComplexunitsAcceptRequest}
    Header={t("Approve Pending Unit?")}
    Content={t("Are you sure want to approve pending unit?")}
    DeclineText={t("Close")}
    AcceptText={t("Confirm")}
    DeclineFun={this.handleComplexunitsAcceptRequest}
    AcceptFun={()=>{this.handlesalesRecordActionSuccess("approved")}}
    language={language}
    data-test-id="pendingunitsaccept-dialog-accept"
  />
  )
 }

 handleRejectUnitDialog=(t: (value: string) => string,language:any)=>{
  return(
    <AlertDialog 
    IsOpen={this.state.rejectComplexAllunitsState}
    Image={rightIcon}
    CloseDialog={this.handleComplexunitsRejectRequest}
    Header={t("Reject Pending Unit?")}
    Content={t("Are you sure want to reject pending unit?")}
    DeclineText={t("Close")}
    AcceptText={t("Confirm")}
    DeclineFun={this.handleComplexunitsRejectRequest}
    AcceptFun={()=>{this.handlesalesRecordActionSuccess("rejected")}}
    language={language}
    data-test-id="pendingunitsreject-dialog-reject"
  />
  )
 }

// Customizable Area End

    render() {
        // Customizable Area Start
    const { t, i18n }: any = this.props;
    const { userRole, pendingUnitsstate } = this.state;
    const currentPage=this.state.pagination?.current_page
    const language = i18n.language;
        // Customizable Area End
            return (
                // Customizable Area Start
      <CEHeaderComponentWeb>
        <Loader loading={this.state.loading} />
        <DashboardApprovalCss>
                  <Box className="containerBox">
                    {this.handleSalesManagerdashboard(t, language)}
                    <Box>
                      {this.state.userRole === "Pending Subscription Requests" &&
                        <Box >
                          {this.handleTabs(t)}
                        </Box>}
                       {(!pendingUnitsstate) &&
                      <Grid container xs={12} spacing={2}>
                        <Box className="filter-head-box" style={{gap:"10px"}}>
                        {this.state.userRole === "Pending Units Requests" && (
                            <FilterSelect
                              language={language}
                              option={this.state.complexlist}
                              label="Select Building Type"
                              t={t}
                              value={this.state.filter.complex_type}
                              data-test-id="selectComplex/buildingsalesId"
                              onChange={(event: React.ChangeEvent<{ value: unknown | string }>) =>
                                this.handleFilterChange(event, "complex/building")
                              }
                            />
                          )}
                          <FilterSelect
                            label="Select Country"
                            language={language}
                            option={[{ label: "All", value: "all" }, ...this.state.countryList]}
                            t={t}
                            value={this.state.filter.country}
                            data-test-id="selectCountrysalesId"
                            onChange={(event: React.ChangeEvent<{ value: unknown | string }>) =>
                              this.handleFilterChange(event, "country")
                            }
                          />
                          <FilterSelect
                            language={language}
                            option={[{ label: "All", value: "all" }, ...this.state.cityList]}
                            t={t}
                            label="Select City"
                            data-test-id="selectCitysalesId"
                            value={this.state.filter.city}
                            onChange={(event: React.ChangeEvent<{ value: unknown | string }>) =>
                              this.handleFilterChange(event, "city")
                            }
                          />
                          {this.state.userRole === "Pending Units Requests" && (
                            <FilterSelect
                              language={language}
                              option={[{ label: "All", value: "all" }, ...this.state.ComplexDataList]}
                              label="Select Complex"
                              t={t}
                              value={this.state.filter.complex}
                              data-test-id="selectComplexsalesId"
                              onChange={(event: React.ChangeEvent<{ value: unknown | string }>) =>
                                this.handleFilterChange(event, "complex")
                              }
                            />
                          )}
                          {this.state.userRole === "Pending Units Requests" && (
                            <>
                              <FilterSelect
                                language={language}
                                t={t}
                                option={[{ label: "All", value: "all" }, ...this.state.buildingList]}
                                value={this.state.filter.building}
                                label="Select Building"
                                data-test-id="selectBuildingsalesId"
                                onChange={(event: React.ChangeEvent<{ value: unknown | string }>) =>
                                  this.handleFilterChange(event, "building")
                                }
                              />
                            </>
                          )}
                          {this.state.userRole === "Pending Subscription Requests" && (
                            <>
                              <FilterSelect
                                t={t}
                                language={language}
                                option={[{ label: "All", value: "all" }, ...this.state.companyList]}
                                label={`Select ${this.state.activeTab.title}`}
                                data-test-id="selectTypesalesId"
                                value={this.state.filter.realCompany}
                                onChange={(event: React.ChangeEvent<{ value: unknown | string }>) =>
                                  this.handleFilterChange(event, "realCompany")
                                }
                              />
                            </>
                          )}
                          <FilterSelect
                            data-test-id="selectAccountManagersalesId"
                            language={language}
                            option={[{ label: "All", value: "all" }, ...this.state.managerList]}
                            label="Select Account Manager"
                            t={t}
                            value={this.state.filter.requester_id}
                            onChange={(event: React.ChangeEvent<{ value: unknown | string }>) =>
                              this.handleFilterChange(event, "accountManager")
                            }
                          />
                          <Button data-test-id="searchfiltersId" onClick={this.handleSearchfilters} startIcon={<SearchIcon />}>
                            {t("Search")}
                          </Button>
                          <Button
                          style={approveCss.clearButton}
                    className="handleSearchFilterForInvoices clear-filter"
                    data-test-id="ClearFiltersApproval"
                    onClick={this.handleClearFiltersApproval}
                  >
                    {t("Clear Filters")}
                  </Button>
                        </Box>
                      </Grid>}
                      <Grid className="table-box-card">
                        <Grid item sm={12} md={12} xs={12}>
                          {(userRole === "Pending Units Requests" && !pendingUnitsstate) &&
                            <h4 className="bold-text table-top" data-test-id="mainTextheadingunit">{t("Pending Units Requests")}</h4>
                          }
                          {(userRole === "Pending Subscription Requests" && !pendingUnitsstate) &&
                            <h4 className="bold-text table-top" data-test-id="mainTextheading">{t("Pending Subscription Requests")}</h4>
                          }
                          {
                            (userRole === "Pending Units Requests" && pendingUnitsstate) &&

                            <Box className="table-top" style={{padding:"10px"}}>
                            <Box className="table-main-heading">
                            <h4 className="bold-text table-top" data-test-id="mainTextheading">{t("List of Units")}</h4>
                            </Box>
                            <Box className="filter-head-box" style={{ margin: "0"}}>
                                <SearchInput
                                    width="400px"
                                    label="Search by Unit number or Owner's name"
                                    t={t}
                                    data-testId="searchFieldTestId"
                                    language={language}
                                    onChange={(event: React.ChangeEvent<{
                                      value:
                                      unknown;
                                  }>)=>this.handlesearchFilter(event)}
                                />
                            </Box>
                        </Box>
                          }
                          <Divider />
                          {(userRole === "Pending Units Requests"&&!pendingUnitsstate)&&
                          this.handlePendingUnitsApprovalTable(t,language)
                            }
                            {(userRole === "Pending Subscription Requests"&&!pendingUnitsstate) &&
                            this.handleSalesManagerApprovalSubscriptionTable(t, language)
                          }
                          {
                            (userRole === "Pending Units Requests"&&pendingUnitsstate)&& 
                           this.handlependingUnitsTable(t,language,currentPage)
                          }
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
        </DashboardApprovalCss>

        <AlertDialog 
          IsOpen={this.state.isAcceptModalOpen}
          Image={rightIcon}
          CloseDialog={this.handlesalesAcceptModal}
          Header={t("Accept Request?")}
          Content={`<p>${t("Are you sure want to accept")} ${this.state.subscriptionListData.approval_type} for ${this.state.subscriptionListData.name}?</p>`}
          DeclineText={t("Close")}
          AcceptText={t("Confirm")}
          DeclineFun={this.handlesalesAcceptModal}
          AcceptFun={()=>{this.handlesalesRecordActionSuccess("approved")}}
          language={language}
          data-test-id="accept-dialog-sales"
        />

        <AlertDialog
          IsOpen={this.state.isRejectModalOpen}
          Image={wrongIcon}
          CloseDialog={this.handlesalesRejectModal}
          Header={t("Reject Request?")}
          Content={`<p>${t("Are you sure want to reject")} ${this.state.subscriptionListData.approval_type} for ${this.state.subscriptionListData.approval_type}?</p>`}
          DeclineText={t("Close")}
          AcceptText={t("Confirm")}
          DeclineFun={this.handlesalesRejectModal}
          AcceptFun={()=>{this.handlesalesRecordActionSuccess("rejected")}}
          language={language}
          data-test-id="reject-dialog-sales"
        />
        {this.handleApproveAllUnitsDialog(t,language)}
        {this.handleRejectAllUnitsDialog(t,language)}
        {this.handleApproveUnitDialog(t,language)}
        {this.handleRejectUnitDialog(t,language)}

        <Dialog
          open={this.state.isSubscriptionViewOpen}
          dir={languageCondition(language, "rtl", "ltr")}
          maxWidth="md"
          scroll="paper"
          fullWidth
          className="edit-profile"
          data-test-id="view-subscription-modal-sales"
        >
          <DialogHeader data-test-id="sub-close-sales" t={t} title={this.state.subscriptionListData.approval_type} onClose={this.handlesalesDialogClose} />
          <Box style={approveCss.dialogActionsSales}></Box>
          <DialogContent>
            <Grid container spacing={2}>
              {this.handleDialogData(t, this.state.activeTab.title, this.state.subscriptionListData.name)}
              {this.handleDialogData(t, "Country", this.state.subscriptionListData.country)}
              {this.handleDialogData(t, "City", this.state.subscriptionListData.city)}
              {this.handleDialogData(t, "Current Plan", this.state.subscriptionListData.subscription_plan)}
              {this.handleDialogData(t, "Upgrade To", this.state.subscriptionListData.upgratde_to)}
              {this.handleDialogData(t, "Payment Reference Number", this.state.subscriptionListData.payment_reference_number)}
            </Grid>
          </DialogContent>
          <DialogActions  className="dialog__button_group">
            <Button style={approveCss.dialogActionrejectSales} className="cancel-button" data-test-id="reject-btn-sales" onClick={this.handlesalesRejectRequest}>
              {t("Reject")}
            </Button>
            <Button style={approveCss.dialogActionrejectSales} className="add-button" data-test-id="accept-btn-sales" onClick={this.handlesalesAcceptRequest}>
              {t("Accept")}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.isRejectReasonModalOpen}
          scroll="paper"
          fullWidth
          maxWidth="sm"
          dir={languageCondition(language, "rtl", "ltr")}
          data-test-id="reject-reason-modal"
        >
          <DialogHeader t={t} title={`${t("Rejection Reason")}`} onClose={this.handlesalesRejectReasonModal} data-test-id="reason-close" />
          <Formik
            enableReinitialize={true}
            initialValues={{ note: "" }}
            // validationSchema={this.rejectValidation}
            onSubmit={(values, { resetForm }) => {
              this.handlesalesRecordActionSuccess("rejected");
              resetForm();
            }}
            data-test-id="reject-reason-formik-sales"
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <DialogContent dividers>
                    <FormControl fullWidth>
                      <NormalTextArea
                        t={t}
                        language={language}
                        minRows={10}
                        onBlur={handleBlur}
                        label="Add Notes"
                        value={values.note}
                        maxRows={10}
                        name="note"
                        onChange={handleChange}
                      />
                      {ErrorHandler(t, touched.note, errors.note)}
                    </FormControl>
                  </DialogContent>
                  <DialogActions className="dialog__button_group">
                    <Button data-test-id="close-cancel-sales" className="cancel-button" onClick={this.handlesalesRejectReasonModal}>
                      {t("Close")}
                    </Button>
                    <Button type="submit" data-test-id="submit-sales" className="add-button">
                      {t("Confirm")}
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          </Formik>
        </Dialog>
        {this.handleSucessDialog(t,language)}
      </CEHeaderComponentWeb>
             
                // Customizable Area End
            );
        }
}

// Customizable Area Start
export const DashboardApprovalCss = styled(Box)({
  "& .dashboardCardIconContainer": {
    border: "1px solid #F8FAFF",
    display: "flex",
    borderRadius: "50%",
    width: "35px",
    height: "35px",
    padding: "10px",
    justifyContent: "center",
  },
  "& .cardText": {
    fontSize: "20px",
    color:" #fc8434",
    fontWeight: "700"
  },
 
  "& .myDashboardHeadsales": {
    cursor: 'pointer'
  },
  "& .dashboardMaincard":{
    display:"flex"
  },
  "& .dashboardCard": {
    backgroundColor: "#fff",
    alignItems: "flex-start",
    height: "180px",
    width:"350px",
    flexDirection: "column",
    boxShadow: "0px 0px 10px 2px #eee",
    display: "flex",
    borderRadius: "10px",
    padding: "20px",
    cursor: "pointer"
  },
  "& .dashboardCardfirst": {
    flexDirection: "column",
    alignItems: "flex-start",
    boxShadow: "0px 0px 10px 2px #eee",
    backgroundColor: "#fff",
    height: "180px",
    display: "flex",
    cursor: "pointer",
    borderRadius: "10px",
    padding: "20px",
    marginRight:"2%",
    width:"350px",
  },
  "& .dashboardTextsales": {
    color: "#537ad2"
  },
  "& .headingsales": {
    fontSize: "26px",
    color: "#Dashboard",
    fontWeight: 900,
    padding: "20px 0px"
  },
 
  "& .tabColor": {
    color: "rgb(43, 111, 237)",
  },
  "& .cardTextmain": {
    color: "#181d25",
    fontSize: "20px",
    fontWeight: 800,
    margin:"5% 0%"
  },
  "& .containerBox": {
    padding: "0px 40px",
  },
  "& .salesHeading": {
    color: "#181d25"
  },
  "& .table-top": {
    margin: "0px 20px 0px auto",
  },
  "& .paginationStyle": {
    alignItems: "center",
    display: "flex",
    padding: "20px",
    justifyContent: "space-between",
    marginLeft: "10px",
  },
  "& .header-title": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .table-container": {
    boxShadow: "none",
    marginTop: "20px",
  },
});

export const approveCss: any = {
 
  labelTextSales: {
    color: "#fc8434",
    fontSize: "14px",
  },
  clearButton:{
    backgroundColor:" #dbdee5",
    color:" #000",
    borderRadius: "8px",
    fontWeight: "600",
  },
  blueValueSales: {
    color: "#2b6fed",
    fontSize: "16px",
  },
  blackValueSales: {
    fontSize: "16px",
    color: "black"
  },
  selectTagSales: {
    borderRadius: "20px",
    background: "#2B6FED",
    color: "#fff",
    padding: "10px 20px",
    cursor: "pointer",
  },
  unSelectTagSales: {
    color: "#8D8F98",
    background: "#EEF0F8",
    borderRadius: "20px",
    padding: "10px 20px",
    cursor: "pointer",
  },
  noValueSales: {
    fontSize: "16px",
    color: "lightgray"
  },
  sucessDialogSales:{
    padding:"1%",
    borderRadius:"10px"
  },
  dialogActionsSales:{
    height:"0px",
    border:"1px solid lightgrey",margin:"1% 0%"
  },
  dialogActionrejectSales:{
    padding:"3%"
  }
};

export default withTranslation()(withRouter(DashboardSalesApproval));
// Customizable Area End
