import React from "react";
// Customizable Area Start
import { Box, Breadcrumbs, Button, Chip, Divider, FormControl, Grid, IconButton, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, styled } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { BuildingListItem, I18nInterface, RegisterOwnerResponseItem, TFunction } from './RegisteredResidentsController.web';
import MainScreen from "./Company Employee/MainScreen.web";
import { withTranslation } from "react-i18next";
import PaginationModule from "../../StoreCredits/src/PaginationModule.web";
import { ComplexListItem } from './RegisteredResidentsController.web';
import AlertError from "../../../components/src/AlertError.web";
import FilterSelect from "../../../components/src/CommonComponents/FilterSelect.web";
import { languageCondition } from "../../../components/src/ConditionManager.web";
import { Menu } from "@szhsin/react-menu";
import SearchInput from "../../../components/src/CommonComponents/SearchInput.web";

interface CountryList {
    id: string;
    type: string;
    attributes:
    {
        name: string
    }
}
// Customizable Area End

import RegisteredResidentsController, { Props, } from "./RegisteredResidentsController.web";

class RegisteredResidents extends RegisteredResidentsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderRegisteredResidents = () => {
        const { t, i18n }: { t: TFunction, i18n: I18nInterface } = this.props;
        const currentPage=this.state.paginationData?.current_page as number
        const language = i18n.language;

        return <Box className='registeredOwnerTopSectionContainer'>
            <Box>
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography className="residentsTopheading" data-testId={"myDashboardTestId"} onClick={()=>this.handleNavigation("DashboardCompanyEmployee")}>
                        {t("My Dashboard")}
                    </Typography>
                    <Typography className="residentsTopheading" data-testId={"generalDashboardTestId"} onClick={()=>this.handleNavigation("DashboardCompanyEmployee")}>
                        {t("General Dashboard")}
                    </Typography>
                    <Typography className="textActive">{t("Registered Residents")}</Typography>
                </Breadcrumbs>
            </Box>
            <Box>
                <Typography variant='h4' className='registerHeading'>{t("Registered Residents")}</Typography>
            </Box>
            <Box className='searchFilterContainer'>
                <FormControl variant="filled">
                    <Select
                        value={this.state.country}
                        onChange={this.handleCountryChange}
                        displayEmpty
                        data-testId="selectCountryId"
                        className={"payingClientsSelect"}
                        variant='outlined'
                        placeholder='Select Country'
                        IconComponent={ExpandMoreIcon}
                        MenuProps={{
                            style: {
                                marginTop: "50px",
                            },
                        }}
                    >
                        <MenuItem value="">{t("Select Country")}</MenuItem>
                        {this.state.countryList.map((country: CountryList, countryIndex: number) => {
                            return (<MenuItem value={country.attributes.name} key={countryIndex} data-testId={`countryListItem${countryIndex}`}>{country.attributes.name}</MenuItem>)
                        })}
                    </Select>
                </FormControl>
                <FormControl variant="filled">
                    <Select
                        value={this.state.city}
                        onChange={this.handleCityChange}
                        data-testId="selectCityId"
                        displayEmpty
                        className={"payingClientsSelect"}
                        variant='outlined'
                        placeholder='Select City'
                        IconComponent={ExpandMoreIcon}
                        MenuProps={{
                            style: {
                                marginTop: "50px",
                            },
                        }}
                    >
                        <MenuItem value="">{t("Select City")}</MenuItem>
                        {this.state.cityList.map((city , cityIndex: number) => {
                            return <MenuItem value={city.name} key={cityIndex}>{city.name}</MenuItem>
                        })}
                    </Select>
                </FormControl>
                <FormControl variant="filled">
                    <Select
                        value={this.state.complex}
                        onChange={this.handleComplexChange}
                        data-testId="selectComplexId"
                        displayEmpty
                        className={"payingClientsSelect"}
                        variant='outlined'
                        placeholder='Paying Clients'
                        IconComponent={ExpandMoreIcon}
                        MenuProps={{
                            style: {
                                marginTop: "50px",
                            },
                        }}
                    >
                        <MenuItem value="">{t("Select Complex")}</MenuItem>
                        <MenuItem value="Complex">{t("Complex")}</MenuItem>
                        <MenuItem value="Individual Building">{t("Individual Building")}</MenuItem>
                    </Select>
                </FormControl>
                <FormControl variant="filled">
                    <Select
                        value={this.state.selectComplex}
                        onChange={this.handleSelectComplex}
                        data-testId="selectComplexTestId"
                        displayEmpty
                        className={"payingClientsSelect"}
                        variant='outlined' placeholder='Paying Clients'
                        IconComponent={ExpandMoreIcon}
                        MenuProps={{
                            style: {
                                marginTop: "50px",
                            },
                        }}
                    >
                        <MenuItem value="">{t("Select Complex")}</MenuItem>
                        {this.state.complexList.map((complex: ComplexListItem, complexIndex: number) => {
                            return <MenuItem value={complex.attributes.name} key={complexIndex}>{complex.attributes.name}</MenuItem>
                        })}
                    </Select>
                </FormControl>
                <FormControl variant="filled">
                    <Select
                        value={this.state.building}
                        onChange={this.handleBuildingChange}
                        data-testId="buildingTestId"
                        displayEmpty
                        className={"payingClientsSelect"}
                        variant='outlined'
                        placeholder='Owner Status'
                        IconComponent={ExpandMoreIcon}
                        MenuProps={{
                            style: {
                                marginTop: "50px",
                            },
                        }}
                    >
                        <MenuItem value="">{t("Select Building")}</MenuItem>
                        {this.state.buildingList.map((building: BuildingListItem, buildingIndex: number) => {
                            return <MenuItem value={building.name} key={buildingIndex}>{building.name}</MenuItem>
                        })}
                    </Select>
                </FormControl>
                <FormControl variant="filled">
                    <Select
                        value={this.state.status}
                        onChange={this.handleStatusChange}
                        data-testId="statusTestId"
                        displayEmpty
                        className={"payingClientsSelect"}
                        variant='outlined'
                        placeholder='Paying Clients'
                        IconComponent={ExpandMoreIcon}
                        MenuProps={{
                            style: {
                                marginTop: "50px",
                            },
                        }}
                    >
                        <MenuItem value="">{t("Residents Status")}</MenuItem>
                    </Select>
                </FormControl>
                <Button variant='contained' data-testId="cooSearchBtnTestId" className={"searchBtn"} startIcon={<SearchIcon className='searchbtnIcon' />} onClick={()=>this.getRegisteredResidents("")}>{t("Search")}</Button>
            </Box>
            <Box>
                <Grid container className="table-box-card">
                    <Grid item sm={12} md={12} xs={12}>
                        <Box className="table-top coo-table-head-box">
                            <h4 className="bold-text coo-table-heading">{t("List of registered residents")}</h4>
                            <Box className="filter-head-box" style={{ margin: "0" }}>
                                <FilterSelect
                                    t={t}
                                    language={language}
                                    label="Sort By"
                                    option={[
                                        { label: "Ascending", value: "asc" },
                                        { label: "Descending", value: "desc" },
                                    ]}
                                    data-testId="sortByTestId"
                                    onChange={this.handleSortByChange}
                                    value={this.state.sortBy}
                                />
                                <SearchInput
                                    onChange={this.handleSearchName}
                                    label="Search by Resident Name or ID"
                                    t={t}
                                    language={language}
                                    data-testId="searchTestId"
                                />
                            </Box>
                        </Box>
                        <Divider />
                        <TableContainer className="table-container" style={{ boxShadow: "none" }}>
                            <Table className="table-box" style={{ minWidth: 900 }}>
                                <TableHead>
                                    <TableRow className="resident-table-head">
                                        <TableCell align={languageCondition(language, "right", "left")}>{t("#")}</TableCell>
                                        <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
                                            {t("Resident Name")}
                                        </TableCell>
                                        <TableCell className="bold-text resident-table-id" align={languageCondition(language, "right", "left")}>
                                            {t("Resident ID")}
                                        </TableCell>
                                        <TableCell className="bold-text resident-table-complex" align={languageCondition(language, "right", "left")}>
                                            {t("Complex Name")}
                                        </TableCell>
                                        <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                                            {t("Building Name")}
                                        </TableCell>
                                        <TableCell className="resident-table-status bold-text" align={languageCondition(language, "right", "left")}>
                                            {t("Resident Status")}
                                        </TableCell>
                                        <TableCell align={languageCondition(language, "right", "left")} />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.residentsData.map((item: RegisterOwnerResponseItem, index: number) => {
                                        return (
                                            <TableRow key={index} className="resident-table-body">
                                                <TableCell align={languageCondition(language, "right", "left")}>
                                                    {(currentPage - 1) * 10 + index + 1}
                                                </TableCell>
                                                <TableCell align={languageCondition(language, "right", "left")} data-testId={`residentsName${index}`}>
                                                    {item.attributes.resident_name === null ? "-" : item.attributes.resident_name}
                                                </TableCell>
                                                <TableCell className="ellipse resident-table-id" align={languageCondition(language, "right", "left")}>
                                                    {item.attributes.resident_id}
                                                </TableCell>
                                                <TableCell className="resident-table-complex ellipse" align={languageCondition(language, "right", "left")}>
                                                    {item.attributes.complex_name.name}
                                                </TableCell>
                                                <TableCell align={languageCondition(language, "right", "left")} className="ellipse">
                                                    {item.attributes.building_name.name}
                                                </TableCell>
                                                <TableCell className="resident-table-status" align={languageCondition(language, "right", "left")}>
                                                    {item.attributes.status === "Accept" && <Chip className='ownerStatusBlue' label={item.attributes.status} />}
                                                    {item.attributes.status === "Accepted" && <Chip className='ownerStatusGreen' label={item.attributes.status} />}
                                                    {item.attributes.status === "Pending" && <Chip className='ownerStatusOrange' label={item.attributes.status} />}
                                                </TableCell>
                                                <TableCell align={languageCondition(language, "right", "left")}>
                                                    <Menu
                                                        data-testId="menuTestId"
                                                        menuButton={
                                                            <IconButton>
                                                                <MoreVertIcon />
                                                            </IconButton>
                                                        }
                                                    >
                                                        <MenuItem data-testId="detailsTestId" onClick={() => this.handleViewDetails()}>{t("View Details")}</MenuItem>
                                                    </Menu>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                            <Divider />
                        </TableContainer>
                        <Box className="table-bottom">
                            <PaginationModule
                                pagination={this.state.paginationData}
                                handlePagination={this.handlePagination}
                                page={this.state.paginationData?.current_page}
                                data-testId={"PaginationTestId"}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <MainScreen>
                <RegisteredResidentsContainer>
                    {this.renderRegisteredResidents()}
                </RegisteredResidentsContainer>
                <AlertError show={this.state.showError} handleClose={this.handleErrorAlert} message={this.state.errorMessage} data-testId={"errorAlertTestId"}/>
            </MainScreen>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
export default withTranslation()(RegisteredResidents);
export { RegisteredResidents };
const RegisteredResidentsContainer = styled(Box)({
    padding: "50px",
    backgroundColor: "#F4F7FF",
    "& .residentsTopheading": {
        cursor: "pointer",
    },
    "& .registeredOwnerTopSectionContainer": {
        display: "flex",
        flexDirection: "column",
        gap: "15px"
    },
    "& .registerHeading": {
        fontWeight: "900"
    },
    "& .textActive": {
        color: "#3f7cef"
    },
    "& .payingClientsSelect": {
        width: "180px",
        backgroundColor: "white",
        height: "51px",
        borderRadius: "10px",
        border: "1px solid #EAEAEA"
    },
    "& .searchIcon": {
        color: "#777a7f",
        marginLeft: '-5px'
    },
    "& .tableContainer": {
        overflow: "scroll"
    },
    "& .ownerStatusBlue": {
        backgroundColor: "#e6edfc",
        color: "#3f7dee",
        fontWeight: "700",
        width: "100px"
    },
    "& .ownerStatusOrange": {
        backgroundColor: "#fef0e6",
        color: "#fc924b",
        fontWeight: "700",
        width: "100px"
    },
    "& .ownerStatusGreen": {
        backgroundColor: "#e4f8eb",
        color: "#40ce74",
        fontWeight: "700",
        width: "100px"
    },
    "& .searchFilterContainer": {
        display: "flex",
        gap: "20px",
        flexWrap: "wrap"
    },
    "& .searchBtn": {
        backgroundColor: "#2a6fed",
        height: "45px",
        color: "#fff",
        borderRadius: "10px"
    },
    "& .searchbtnIcon": {
        color: "#ffff"
    }
});
// Customizable Area End