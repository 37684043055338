// Customizable Area Start
import React from "react";
import { Grid, Box, Typography, Button, Avatar, Modal, Link, TextField, InputAdornment, } from "@material-ui/core";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import InboxController, { Props, MessageEntry, Medias } from "./inboxController.web";
import { AttachFile, Close, GetApp } from "@material-ui/icons";
import { Sendbutton } from "./assets";
import moment from "moment";
import ChatDrawerContent from "./components/ChatDrawerContent.web";
import { NoProfile_Img } from "../../user-profile-basic/src/assets";
// Customizable Area End

// Customizable Area Start
// Customizable Area End
class IncidentChatBox extends InboxController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    let selectedChat = localStorage.getItem('selectedChat');
    if (selectedChat) {
      this.item = JSON.parse(selectedChat);
    }
    // Customizable Area End
  }
  // Customizable Area Start
  item: any;
  interval!: ReturnType<typeof setInterval>;

  

  async componentDidMount(): Promise<void> {
    this.getAllChat();
    this.interval = setInterval(() => {
      this.getSingleInbox();
    }, 3000);
  }

  renderChatStatus() {
    const status = `${this.item?.attributes?.chatable?.attributes?.incident_status} `;
    return (
      <Box className={`chat-status ${status.trim().replace(/ /g, "-").toLowerCase()}`}>
        <Typography className="chat-status-text">{status || "NA"}</Typography>
      </Box>
    )
  }

  renderDateSeparator(date: string, index: number) {
    const formattedDate = index > 1 ? moment.utc(date).fromNow() : moment.utc(date).format('MMM-DD-YYYY');
    return (
      <div className="chat-date-separator">
        <span className="bg-line"></span>
        <Typography className="chat-date">{formattedDate}</Typography>
      </div>
    )
  }

  renderChatMedia(media: Medias[]) {
    const url = media[0].url;
    if (url.includes(".pdf")) {
      return (
        <>
          <Typography>PDF</Typography>
          <Link href={url} target="_blank"><GetApp /></Link>
        </>
      )
    }
    return (<img className="chat-message-image" alt="uploaded-image" src={url} />)
  }

  renderMessages(date: string) {
    let currentAccountId = localStorage.getItem('userId');
    if (currentAccountId) {
      currentAccountId = JSON.parse(currentAccountId);
    }

    if (!this.state.singleChatRoom) {
      return (<></>)
    }

    const messages: (MessageEntry[]) = (this.state.singleChatRoom && this.state.singleChatRoom[date]);
    return (
      <Grid container className="chat-message-list">
        {(messages && messages.length > 0)
          ? (messages.map((messageEntry: MessageEntry) => {
            const message = messageEntry.message;
            const isMessageFromCurrentUser = message.account_id == Number(currentAccountId);
            return (
              <Grid key={message.id} item xs={12} className="chat-message-box">
                {!isMessageFromCurrentUser && <Avatar alt="user-image" src={message.profile_pic.url || NoProfile_Img} />}
                <Box className={`message-wrapper ${isMessageFromCurrentUser ? ("is-right") : ("is-left")}`}>
                  <Box className="chat-message">
                    {(message.message && <Typography className="chat-message-text">{message.message}</Typography>)}
                    {(message.images.length > 0 && <Box data-test-id="chat-message-media" className="chat-media" onClick={() => this.setState({ selectedMedia: message.images[0] })}>{this.renderChatMedia(message.images)}</Box>)}
                  </Box>
                  <Box className="chat-time">
                    <Typography className="chat-time-text">{this.formatISODateToTime(message.created_at)}</Typography>
                  </Box>
                </Box>
              </Grid>
            )
          }))
          : (<></>)
        }
      </Grid>
    )
  }

  renderModalBody(media: any) {
    if (media?.mimetype !== "application/pdf" && media?.url.indexOf(".pdf") === -1) {
      return <img src={media?.url} style={{ width: '300px', objectFit: 'contain', height: '26rem', borderRadius: 0 }} />;
    } else {
      return <iframe src={media?.url} style={{ width: '300px', height: '26rem' }} />;
    }
  }

  render() {
    const { t }: any = this.props;
    return (
      <>
        <ChatDrawerContent>
          <Grid item xs={12} className="chat-header">
            <Box className="chat-nav">
              <Typography className="header-title">{t("Tickets")}</Typography>
              <Button type="button" data-test-id="header-close" className="header-close" onClick={() => this.redirectToList()}>
                <Close />
              </Button>
            </Box>
            <Box className="chat-detail">
              <Box className="chat-info">
                <Typography className="chat-title">{this.item?.attributes?.chatable?.attributes?.incident_title}</Typography>
                <Typography className="chat-id">{t("Ticket ID")}: {this.item?.attributes?.chatable?.id}</Typography>
              </Box>
              {this.renderChatStatus()}
            </Box>
          </Grid>
          <Grid item xs={12} data-test-id="chat-body" className="chat-body" ref={(div: HTMLDivElement) => { this.messageList = div; }}>
            <Box className="chat-messages">
              {this.state.allInboxKey.length > 0
                ? (this.state.allInboxKey.map((date: string, idx: number) => {
                  return (
                    <Box className="chat-date-box" key={date}>
                      {this.renderDateSeparator(date, idx)}
                      {this.renderMessages(date)}
                    </Box>
                  )
                }))
                : (
                  <Box className="chat-empty">
                    <Box className="chat-empty-message">
                      <Typography className="chat-empty-text">{t('Send a message to start a conversation')}</Typography>
                    </Box>
                  </Box>
                )}
            </Box>
          </Grid>
          <Grid item xs={12} className="chat-footer">
            <Box className="chat-input-wrapper">
              <input type="file" data-test-id="upload-file" className="hidden" id="uploadFile" onChange={this.handleFileUpload} accept="image/png, image/jpeg, image/jpg, application/pdf" />
              <TextField variant="filled" type="text" className="chat-input" placeholder={t("Type your message here")}
                data-test-id="chat-message-input"
                value={this.state.newMessage}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleChatInputChange(e)}
                onKeyPress={event => (event.key === 'Enter' && (this.createMessages()))}
                InputProps={{
                  disableUnderline: true,
                  // startAdornment: (
                  //   <InputAdornment position="start">
                  //     <EmojiEmotionsOutlined className="chat-input-icon" />
                  //   </InputAdornment>
                  // ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <label htmlFor="uploadFile">
                        <AttachFile className="chat-input-icon" />
                      </label>
                    </InputAdornment>
                  ),
                }} />
            </Box>
            <Button data-test-id="chat-send-button" className="chat-send-button" variant="contained" onClick={() => this.createMessages()}>
              <img src={Sendbutton} alt="send" />
            </Button>
          </Grid>
          <Grid>
          </Grid>
        </ChatDrawerContent>
        <Modal
          data-test-id="media-modal"
          open={this.state.selectedMedia != null}
          style={{ display: 'flex', alignItems: 'center', flexDirection: 'column-reverse', justifyContent: 'center' }}
          onClose={() => this.setState({ selectedMedia: null, accept: false })}
          aria-labelledby="alert-Modal-title"
          aria-describedby="alert-dialog-description"
        >
          <div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              <Button style={{ color: '#FFF', minWidth: '50px', width: '50px', height: '50px' }} onClick={() => this.setState({ selectedMedia: null, accept: false })}>
                <Close />
              </Button>
            </div>
            {this.renderModalBody(this.state.selectedMedia)}
            {
              this.state.accept &&
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <button onClick={() => this.setState({ selectedMedia: null, accept: false })} style={{
                  backgroundColor: 'rgb(241, 78, 36)',
                  border: '1px solid rgb(217, 219, 233)',
                  borderRadius: '16px',
                  height: 35,
                  boxShadow: 'none',
                  color: ' rgb(247, 247, 252)',
                  fontFamily: 'Poppins',
                  fontSize: 13,
                  marginTop: 10,
                  marginRight: 10,
                  width: 150
                }}>
                  {t("Cancel")}
                </button>
                <button onClick={() => this.setState({ selectedMedia: null, accept: false }, () => this.handleSelectFile(this.state.file))} style={{
                  backgroundColor: '#ffff',
                  border: '1px solid red',
                  borderRadius: '16px',
                  height: 35,
                  boxShadow: 'none',
                  color: ' red',
                  fontFamily: 'Poppins',
                  fontSize: 13,
                  marginTop: 10,
                  marginRight: 10,
                  width: 150
                }}>
                  {t("Send")}
                </button>
              </div>
            }
          </div>
        </Modal>
      </>
    );
  }
  // Customizable Area End

}
// Customizable Area Start
export default withTranslation()(withRouter(IncidentChatBox));
// Customizable Area End
