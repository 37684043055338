// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Divider,
  IconButton,
  MenuItem,
  Modal,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
} from "@material-ui/core";
import { withTranslation } from "react-i18next";
import LeaseManagementMainController, { DraftListItem, TFunction, Props, ContractListItem, SavedTemplateItem, ComplexItem } from "./LeaseManagementMainController.web";
import MainScreen from "../../dashboard/src/Company Employee/MainScreen.web";
import SearchIcon from "@material-ui/icons/Search";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Info_Icon, TemplateIcon } from "./assets";
import AlertErrorWeb from "../../../components/src/AlertError.web";
import Loader from '../../../components/src/Loader.web'
import SuccessAlert from "../../../components/src/AlertSuccess.web";
import MapDialog from "../../../components/src/MuiDialog/MapDialog.web";
import PaginationModule from "../../StoreCredits/src/PaginationModule.web";
import FilterSelect from "../../../components/src/CommonComponents/FilterSelect.web";
import SearchInput from "../../../components/src/CommonComponents/SearchInput.web";
import CommonButton from "../../../components/src/CommonComponents/Button.web";
import Tip from "../../../components/src/CommonComponents/Tip";
// Customizable Area End

class LeaseManagementMain extends LeaseManagementMainController {
  constructor(props: Props) {
    // Customizable Area Start
    super(props);
    // Customizable Area Start
  }

  // Customizable Area Start 
  renderTopFilterContainer = () => {
    const { t, i18n }: any= this.props;
    const language = i18n.language;
    return (
      <Box className="filter-head-box">
        {this.state.tabItem === "Saved Templates" &&
          <FilterSelect
            t={t}
            language={language}
            label="Select Company"
            value={this.state.company}
            onChange={this.hanldeCompanyChange}
            option={this.state.companyList.map((item: any) => ({ label: item.attributes.name, value: item.attributes.name }))}
            data-testId="comapanySelectTestId"
          />
        }
        <FilterSelect
          t={t}
          language={language}
          label="Select Building Type"
          value={this.state.complex}
          onChange={this.hanldeComplexChange}
          option={[{ label: "Complex", value: "Complex" }, { label: "Individual Building", value: "Individual Building" }]}
          data-testId="complexSelectTestId"
          style={{ fontWeight: "bold", color: "black" }}
        />
        <FilterSelect
          t={t}
          language={language}
          label="Select Country"
          value={this.state.country}
          onChange={this.hanldeCountryChange}
          option={this.state.countryList.map((item: any) => ({ label: item, value: item }))}
          data-testId="selectCountryTestID"
        />
        <FilterSelect
          t={t}
          language={language}
          label="Select City"
          value={this.state.city}
          onChange={this.hanldeCityChange}
          option={this.state.cityList.map((item: any) => ({ label: item.name, value: item.name }))}
          data-testId="selectCityTestId"
        />
        <FilterSelect
          t={t}
          language={language}
          label={this.state.complex !== "Individual Building" ? "Select Complex" : "Select Individual Building"}
          value={this.state.selectComplex}
          onChange={this.hanldeSelectComplexChange}
          option={this.state.selectComplexList.map((item: any) => ({ label: item.attributes.name, value: item.attributes.name }))}
          data-testId="selectFromComplexListTextId"
        />
        {this.state.complex !== "Individual Building" &&
          <FilterSelect
            t={t}
            language={language}
            label="Select Building"
            value={this.state.building}
            onChange={this.hanldeBuildingChange}
            option={this.state.buildingList.map((item: any) => ({ label: item.name, value: item.name }))}
            data-testId="selectBuildingTestId"
          />
        }
        <FilterSelect
          t={t}
          language={language}
          label="Select Unit"
          value={this.state.unitNumber}
          onChange={this.hanldeUnitChange}
          option={this.state.unitNumberList.map((item: any) => ({ label: item.apartment_name, value: item.apartment_name }))}
          data-testId="selectUnitTestID"
        />
        {this.state.tabItem === "Contract Listing" &&
          <>
            <FilterSelect
              t={t}
              language={language}
              label="Select Contract Status"
              value={this.state.contractStatus}
              onChange={this.hanldeContractStatusChange}
              option={[
                { label: "Active", value: "Active" },
                { label: "Terminated", value: "Terminated" },
                { label: "Pending", value: "Pending" },
                { label: "Closed", value: "Closed" },
              ]}
              data-testId="selectContracStatusTestId"
            />
            <FilterSelect
              t={t}
              language={language}
              label="Select Owner"
              value={this.state.owner}
              onChange={this.hanldeOwnerChange}
              option={this.state.ownerList.map((item: any) => ({ label: item.full_name, value: item.full_name }))}
              data-testId="selectOwerTestId"
            />
            <FilterSelect
              t={t}
              language={language}
              label="Select Contract Issuer"
              value={this.state.contractIssuer}
              onChange={this.hanldeContractIssuerChange}
              option={[
                { label: "Owner", value: "Owner" },
                { label: "Owner Resident", value: "Owner Resident" },
                { label: "Chairman", value: "Chairman" },
                { label: "Vice Chairman", value: "Vice Chairman" },
                { label: "Property Manager", value: "Property Manager" },
                { label: "Manager", value: "Manager" },
                { label: "COO", value: "coo" },
                { label: "Account Manager", value: "account_manager" },
                { label: "Sales Manager", value: "sales_manager" },
              ]}
              data-testId="selectContractIssuerTestId"
            />
          </>
        }
        <CommonButton
          isVariant={false}
          data-testId="searchBtnTestId"
          t={t}
          color="blue"
          startIcon={<SearchIcon />}
          label="Search"
          onClick={this.searchListData}
        />
      </Box>
    );
  };
  renderTopNav = () => {
    const { t }: { t: TFunction } = this.props;
    return (
      <Box className="topNavContainer">
        <Typography className="topNavtitle">{t("Lease Management")}</Typography>
        <Typography className="topNavHeading">{t("Lease Management")}</Typography>
        <Box className="tabIssueLeaseContainer">
          <Box className="topNavTabContainer">
            <Box
              data-testId="contractTabTestID"
              className={
                this.state.tabItem === "Contract Listing"
                  ? "topNavTabItemActive"
                  : "topNavTabItem"
              }
              onClick={this.handleContractTab}
            >

              {t("Contracts Listing")}
            </Box>
            <Box
              data-testId="SavedTemplateTestID"
              className={
                this.state.tabItem === "Saved Templates"
                  ? "topNavTabItemActive"
                  : "topNavTabItem"
              }
              onClick={this.handleSavedTemplate}
            >
              {t("Saved Templates")}
            </Box>
            <Box
              data-testId="draftTabTestId"
              className={
                this.state.tabItem === "Drafts"
                  ? "topNavTabItemActive"
                  : "topNavTabItem"
              }
              onClick={this.handleDraft}
            >
              {t("Drafts")}
            </Box>
          </Box>
          <Tip text={this.state.isLeaseCreateAccess ? "Issue a New Lease" : "You don't have permission to issue a new lease"}>
            <CommonButton
              disabled={!this.state.isLeaseCreateAccess}
              onClick={this.handleIssueNewLeaseRedirect}
              t={t}
              label="Issue a New Lease"
              color="orange"
              data-testId="issueNewLeaseTestId"
            />
          </Tip>
        </Box>
        {this.renderTopFilterContainer()}
      </Box>
    );
  };
  renderContractListing = () => {
    const { t }: { t: TFunction } = this.props;
    return (
      <>
        <Box className="contractListingContainer">
          <Box className="contractListHeadingContainer">
            <h4 className="bold-text">{t("Contracts Listing")}</h4>
            <Box className="contractListHeadingSearchContainer">
              <Box className="filter-head-box" style={{ margin: "0" }}>
                <FilterSelect
                  t={t}
                  label="Sort By"
                  value={this.state.contractSortBy}
                  option={[
                    { label: "Ascending", value: "ascending" },
                    { label: "Descending", value: "descending" },
                  ]}
                  onChange={this.hanldeContractSortByChange}
                />
                <SearchInput
                  onChange={this.handleContractSearchChange}
                  t={t}
                  value={this.state.contractSearchText}
                  label="Search by owner's name"
                  data-testId="contractSearchInputTestId"
                />
              </Box>
            </Box>
          </Box>
          <Divider className="dividerLine" />
          <TableContainer >
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="contractListingTableHeading1" align = 'left'>
                    #
                  </TableCell>
                  <TableCell className="contractListingTableHeading2" align = 'left'>
                    {t("Owner Name")}
                  </TableCell>
                  <TableCell className="contractListingTableHeading3" align = 'left'>
                    {t("Complex")}
                  </TableCell>
                  <TableCell className="contractListingTableHeading4" align = 'left'>
                    {t("Building")}
                  </TableCell>
                  <TableCell className="contractListingTableHeading5" align = 'left'>
                    {t("Unit Number")}
                  </TableCell>
                  <TableCell className="contractListingTableHeading6" align = 'left'>
                    {t("Lease Issuer")}
                  </TableCell>
                  <TableCell className="contractListingTableHeading7" align = 'left'>
                    {t("Tenant Name")}
                  </TableCell>
                  <TableCell className="contractListingTableHeading8" align = 'left'>
                    {t("Days to Expire")}
                  </TableCell>
                  <TableCell align = 'left'></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.contractListData.length === 0 ? <TableRow data-testId={"noContractListTestId"}><TableCell colSpan={6}>{t("No Contracts found")}</TableCell></TableRow> : this.state.contractListData.map((contractItem:ContractListItem, index:number) => (
                  <TableRow key={index}>
                    <TableCell align = 'left' key={index}>{(((this.state.contractPaginationData.current_page || 0) - 1) * 10) + (index + 1)}</TableCell>
                    <TableCell align = 'left' component="th" scope="row">
                      {this.handleNullValue(contractItem.attributes.owner?.full_name)}
                    </TableCell>
                    <TableCell align = 'left'>
                    {this.handleComplexNaValue(contractItem.attributes.society_management)}
                      </TableCell>
                    <TableCell align = 'left'>
                      
                    {this.state.complex === "Individual Building"?this.handleNullObject(contractItem.attributes.society_management):this.handleNullValue(contractItem.attributes.building_name)}
                      </TableCell>
                    <TableCell align = 'left'>
                    {this.handleNullValue(contractItem.attributes.unit_name)}
                      </TableCell>
                    <TableCell align = 'left'>
                      {Array.isArray(contractItem.attributes.lease_issuer)?<>
                        {contractItem.attributes.lease_issuer.map((leaseIsser, leaseIssuerIndex) => {
                          return <Typography key={leaseIssuerIndex}>{leaseIsser}</Typography>
                        })}
                      </> : <Typography>{contractItem.attributes.lease_issuer}</Typography>}
                    </TableCell>
                    <TableCell align = 'left'>
                    {this.handleNullValue(contractItem.attributes.tenant_name)}
                    </TableCell>
                    <TableCell align = 'left'>
                      <Box className="contractDaystoExploreContainer">
                        <Typography>{contractItem.attributes.status === "Active" ? contractItem.attributes.expire_on : contractItem.attributes.status}</Typography>
                      </Box>
                    </TableCell>
                    <TableCell align = 'left'>
                      <IconButton onClick={(event) => this.handleClick(event, index)} data-testId={`contractMenu${index}`}>
                        <MoreVertIcon />
                      </IconButton>
                      <ContractListPopover
                        open={this.state.openPopoverIndex === index}
                        anchorEl={this.state.anchorEl}
                        onClose={this.handleClose}
                        data-testId={`menuPopoverCloseTestId${index}`}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        elevation={1}
                      >
                        <MenuItem onClick={()=>this.handleViewContract(contractItem.id)} data-testId={`viewContract${index}`}>
                          {t("View Lease")}
                        </MenuItem>
                        <Divider className="dividerLine" />
                        <MenuItem onClick={() => this.handleBuildingModal(contractItem)} data-testId={`viewBuildingBtn${index}`}>
                          {t("View building on map")}
                        </MenuItem>
                      </ContractListPopover>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box className="tablePaginationContainer">
            <PaginationModule pagination={this.state.contractPaginationData} handlePagination={this.handleContractListPagination} page={this.state.contractPaginationData.current_page} data-testId={`contractListPagintionTestId`} />
          </Box>
        </Box>
      </>
    );
  };
  renderDraftListing = () => {
    const open = Boolean(this.state.anchorEl);
    const popoverId = open ? "simple-popover" : undefined;
    const { t }: { t: TFunction } = this.props;

    return (
      <>
        <Box className="contractListingContainer">
          <Box className="contractListHeadingContainer">
            <Typography className="contractListingHeading" data-testId={"draftListingHeadingTestId"}>
              {t("Listing of Drafts")}
            </Typography>
            <Box className="contractListHeadingSearchContainer">
              <TextField
                variant="outlined"
                InputProps={{
                  startAdornment: <SearchIcon style={{ color: "#8b8b8b" }} />,
                }}
                placeholder={t("Search by owner's name")}
                className="searchInputDraft"
                value={this.state.draftSearchText}
                onChange={this.handleDraftSearchChange}
                data-testId={"draftSearchInputTestId"}
              />
            </Box>
          </Box>
          <Divider />
          <TableContainer component={Paper}>
            <Table className={""} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="draftListingTableHeading">
                    #
                  </TableCell>
                  <TableCell className="draftListingTableHeading">
                    {t("Owner Name")}
                  </TableCell>
                  <TableCell className="draftListingTableHeading">
                    {t("Complex")}
                  </TableCell>
                  <TableCell className="draftListingTableHeading">
                    {t("Building")}
                  </TableCell>
                  <TableCell className="draftListingTableHeading">
                    {t("Unit Number")}
                  </TableCell>
                  <TableCell className="draftListingTableHeading">
                    {t("Last Updated On")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.draftListData.map((draftItem: DraftListItem, index: number) => (
                  <TableRow key={index}>
                    <TableCell key={index}>{index + 1}</TableCell>
                    <TableCell component="th" scope="row">
                      {this.handleOwnerNullValue(draftItem.attributes.owner)}
                    </TableCell>
                    <TableCell>
                    {this.handleComplexNaValue(draftItem.attributes.society_management)}
                    </TableCell>
                    <TableCell>
                      {this.state.complex === "Individual Building" ? this.handleSocietyNullValue(draftItem.attributes?.society_management) : draftItem.attributes.building_name}
                      </TableCell>
                    <TableCell>{draftItem.attributes.unit_name}</TableCell>

                    <TableCell>
                      <Box className="contractDaystoExploreContainer">
                        <Typography>
                          {draftItem.attributes.updated_at?.split('T')[0]}
                        </Typography>
                        <IconButton onClick={(event) => this.handleClick(event, index)} data-testId={`draftListItemMenuBtnTestId${index}`}>
                          <MoreVertIcon />
                        </IconButton>
                        <ContractListPopover
                          data-testId={`closeTestID${index}`}
                          id={popoverId}
                          open={this.state.openPopoverIndex === index}
                          anchorEl={this.state.anchorEl}
                          onClose={this.handleClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          elevation={1}
                        >
                          <MenuItem
                            data-testId={`useTableBtnTestId${index}`}
                            className="popoverMenuItem"
                            onClick={() => this.handleViewDraft("DraftContractDetails", draftItem.id)}
                          >
                            {t("Use")}
                          </MenuItem>
                          <Divider />
                          <MenuItem className="popoverMenuItem" onClick={() => this.handleViewDraft("ReviewDraft", draftItem.id)} data-testId={`draftViewBtnTestid${index}`}>
                            {t("View")}
                          </MenuItem>
                          <Divider />
                          <MenuItem className="popoverMenuItem" onClick={() => this.handleDeleteDraftModal(draftItem.id)} data-testId={`draftDelteBtnTestId${index}`}>
                            {t("Delete")}
                          </MenuItem>
                        </ContractListPopover>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box className="tablePaginationContainer">
            <PaginationModule pagination={this.state.draftPaginationData} handlePagination={this.handleDraftListPagination} page={this.state.draftPaginationData.current_page} data-testId={`draftListPagintionTestId`}/>
          </Box>
        </Box>

      </>
    );
  };
  renderSavedTemplatesListing = () => {
    const { t }: { t: TFunction } = this.props;
        return (
      <>
        <Box className="contractListingContainer">
          <Box className="contractListHeadingContainer">
            <Typography className="contractListingHeading" data-testId={"SavedTemplateHeadingTestId"}>
              {t("Saved Templates")}
            </Typography>
            <Box className="contractListHeadingSearchContainer">
              <TextField
                variant="outlined"
                InputProps={{
                  startAdornment: <SearchIcon style={{ color: "#8b8b8b" }} />,
                }}
                placeholder={t("Search by owner's name")}
                className="searchInputDraft"
                value={this.state.savedTemplateSearchText}
                onChange={this.handleSavedTemplateSearchChange}
                data-testId={"savedTemplateSearchInputTestId"}
              />
            </Box>
          </Box>
          <Divider className="dividerLine" />
          <TableContainer component={Paper}>
            <Table className={""} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="savedTemplateListingTableHeading">
                    #
                  </TableCell>
                  <TableCell className="savedTemplateListingTableHeading">
                    {t("Owner Name")}
                  </TableCell>
                  <TableCell className="savedTemplateListingTableHeading">
                    {t("Complex")}
                  </TableCell>
                  <TableCell className="savedTemplateListingTableHeading">
                    {t("Building")}
                  </TableCell>
                  <TableCell className="savedTemplateListingTableHeading">
                    {t("Unit Number")}
                  </TableCell>
                  <TableCell className="savedTemplateListingTableHeading">
                    {t("Templates")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                    {this.state.savedTemplatesListData.length === 0 ? <span data-testId={"noDataTextTestId"}>{t("No Contracts Found")}</span> : this.state.savedTemplatesListData.map((contractItem: SavedTemplateItem, contractItemIndex: number) => (
                  <>
                     <TableRow>
                        <TableCell>{contractItemIndex+1}</TableCell>
                        <TableCell component="th" scope="row">
                          {contractItem.attributes.owner_name}
                        </TableCell>
                          <TableCell>
                            <MenuItem >
                            {this.handleComplexNaSavedTemplateValue(contractItem.attributes.complex[contractItemIndex])}
                            </MenuItem>
                          </TableCell>
                          <TableCell>
                            <MenuItem >{this.state.complex === "Individual Building" ? contractItem.attributes.complex[0].name : contractItem.attributes.building[0].name}</MenuItem>
                          </TableCell>
                          <TableCell>
                          <MenuItem >{contractItem.attributes.unit[0].apartment_name}</MenuItem>
                        </TableCell>
                        <TableCell>
                          <Typography><Box className="templatesCountWrapper" onClick={()=>this.handleViewDraft("SavedTemplateDetails",contractItem.id)}> <img src={TemplateIcon} alt="" className="templateIcon" /><span> {contractItem.attributes.templates}{" "} Templates</span></Box></Typography>
                        </TableCell>
                      </TableRow>
                  </>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box className="tablePaginationContainer">
            <PaginationModule pagination={this.state.savedTemplatePaginationData} handlePagination={this.handleSavedTemplateListPagination} page={this.state.savedTemplatePaginationData.current_page} data-testId={`savedTempaltesListPagintionTestId`}/>
          </Box>
        </Box>
      </>
    );
  };

  renderLeaseContentContainer = () => {
    return (
      <Box>
        {this.renderTopNav()}
        <Paper elevation={3} className="leaseContentContainer">
          {this.state.tabItem === "Contract Listing" &&
            this.renderContractListing()}
          {this.state.tabItem === "Saved Templates" &&
            this.renderSavedTemplatesListing()}
          {this.state.tabItem === "Drafts" && this.renderDraftListing()}
        </Paper>
      </Box>
    );
  };

  renderTerminateContractModal = () => {
    const { t }: { t: TFunction } = this.props;
    return <Modal open={this.state.deleteDraftModal}>
      <ContractDetailsTerminateModal >
        <Paper className='TerminateModalChild'>
          <img src={Info_Icon} alt="" className="terminateModalIcon" />
          <Typography className='TerminateModalChildHeading' data-testId={"terminateContractModalHeadingTestID"}>{t("Delete Draft")}</Typography>
          <Typography className='TerminateModalChildText'>{t("Are you sure you want to delete the draft")}</Typography>
          <Box className='TerminateModalChildButton'>
            <Button variant='outlined' className='TerminateModalChildButton1' onClick={() => this.handleDeleteDraftModal("")} data-testId={"cancelBtnTerminateContractModalTestId"}>{t("Cancel")}</Button>
            <Button variant='contained' className='TerminateModalChildButton2' onClick={this.deleteDraftApiCall} data-testId={"terminateContractConfirmBtnTestId"}>{t("Confirm")}</Button>
          </Box>
        </Paper>
      </ContractDetailsTerminateModal>
    </Modal>
  };

  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <MainScreen>
        <LeaseManagementContainer>
        <Loader loading={this.state.loader} />
          {this.renderLeaseContentContainer()}
          {this.renderTerminateContractModal()}
        </LeaseManagementContainer>
        <AlertErrorWeb show={this.state.showError} handleClose={this.handleCloseAlertError} message={this.state.error} data-testId={"alertErrorTestId"} />
        <SuccessAlert show={this.state.showSuccessMessage} handleClose={this.handleSuccessAlert} message={this.state.successAlertMessage} data-testId={"successAlertTestId"} />
        <MapDialog
          t={this.props.t}
          IsOpen={this.state.openBuildingModal}
          CloseDialog={this.handleCloseViewBuilding}
          lat={this.state.latitude}
          long={this.state.longitude}
          data-testId={"viewBuildingModal"}
        />
      </MainScreen>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default withTranslation()(LeaseManagementMain);
export { LeaseManagementMain };

const LeaseManagementContainer = styled(Box)({
  padding: "50px",
  backgroundColor: "#f4f7ff",
    "& .topNavContainer": {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  "& .topNavtitle": {
    color: "#5b86e2",
    fontSize: "18px",
  },
  "& .topNavHeading": {
    color: "#191b24",
    fontSize: "30px",
    fontWeight: 800,
  },
  "& .topNavTabContainer": {
    display: "flex",
    flexWrap: "wrap",
    gap: "20px",
  },
  "& .topNavTabItem": {
    padding: "10px 35px",
    color: "#adb1b7",
    backgroundColor: "#edf0f7",
    borderRadius: "50px",
    fontWeight: 700,
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .topNavTabItemActive": {
    padding: "10px 35px",
    color: "#fff",
    backgroundColor: "#2c70e7",
    borderRadius: "50px",
    fontWeight: 700,
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .topFiltersContainer": {
    display: "flex",
    flexWrap: "wrap",
    gap: "20px",
    padding: "10px 0px",
    alignItems: "center",
  },

  "& .complexSelect": {
    width: "130px",
    border: "1px solid #f3f2f2",
    borderRadius: "8px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      background: "#fff",
      border: "1px solid #f3f2f2",

    },
  },
  "& .selectCompanySelect": {
    width: "200px",
    border: "1px solid #f3f2f2",
    borderRadius: "8px",
    background: "#fff",
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      background: "#fff",
      border: "1px solid #f3f2f2",

    },
  },
  "& .complexSelectLabel": {
    color: "#2a2b2d",
    fontWeight: 900,
  },
  "& .complexSelectLabel2": {
    color: "darkgray",
  },
  "& .selectCountry": {
    width: "180px",
    border: "1px solid #f3f2f2",
    borderRadius: "8px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      background: "#fff",
      border: "1px solid #f3f2f2",
    },
  },
  "& .selectCity": {
    width: "140px",
    border: "1px solid #f3f2f2",
    borderRadius: "8px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      background: "#fff",
      border: "1px solid #f3f2f2",
    },
  },
  "& .selectBuilding": {
    width: "170px",
    border: "1px solid #f3f2f2",
    borderRadius: "8px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      background: "#fff",
      border: "1px solid #f3f2f2",
    },
  },
  "& .selectUnitNumber": {
    width: "230px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      background: "#fff"

    },
  },
  "& .selectContractStatus": {
    width: "230px",
    border: "1px solid #f3f2f2",
    borderRadius: "8px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      background: "#fff",
      border: "1px solid #f3f2f2",
    },
  },
  "& .topNavFilterSearchBtn": {
    backgroundColor: "#2b6ef0",
    color: "#fff",
    height: "50px",
    borderRadius: "8px",
  },
  "& .leaseContentContainer": {
    backgroundColor: "#fff",
    padding: "20px ",
    marginTop: "20px",
  },
  "& .contractListingContainer": {
    display: "flex",
    flexWrap: "wrap",
  },
  "& .contractListingHeading": {
    fontSize: "25px",
    color: "#26272c",
    flex: 1,
    padding: "15px 20px",
    fontWeight: 800,
  },
  "& .dividerLine": {
    width: "100%"
  },
  "& .contractListingTableHeading1": {
    fontSize: "16px",
    fontWeight: 800,
    color: "#272a2b"
  },
  "& .contractListingTableHeading2": {
    fontWeight: 800,
    color: "#272a2b",
    fontSize: "16px",
  },
  "& .contractListingTableHeading3": {
    fontSize: "16px",
    fontWeight: 800,
    color: "#272a2b",
  },
  "& .contractListingTableHeading4": {
    color: "#272a2b",
    fontSize: "16px",
    fontWeight: 800,
  },
  "& .contractListingTableHeading5": {
    color: "#272a2b",
    fontWeight: 800,
    fontSize: "16px",
  },
  "& .contractListingTableHeading6": {
    fontWeight: 800,
    color: "#272a2b",
    fontSize: "16px",
  },
  "& .contractListingTableHeading7": {
    color: "#272a2b",
    fontSize: "16px",
    fontWeight: 800,
  },
  "& .contractListingTableHeading8": {
    color: "#272a2b",
    fontSize: "16px",
    fontWeight: 800,
  },
  "& .savedTemplateListingTableHeading": {
    fontSize: "16px",
    color: "#272a2b",
    fontWeight: 800,
  },
  "& .draftListingTableHeading": {
    color: "#272a2b",
    fontWeight: 800,
    fontSize: "16px",
  },
  "& .contractDaystoExploreContainer": {
    flexWrap: "wrap",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  "& .tabIssueLeaseContainer": {
    justifyContent: "space-between",
    display: "flex",
  },
  "& .issueaNewLeaseBtn": {
    fontWeight: 800,
    color: "#fff !important",
    height: "50px",
    backgroundColor: "#f88538",
    borderRadius: "8px",
    padding: "0px 20px",
    display: "flex",
    width: "200px",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    textTransform: "uppercase",
  },
  "& .contractListHeadingContainer": {
    flexWrap: "wrap",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "20px",
    width: "100%",
  },
  "& .contractListHeadingSearchContainer": {
    gap: "20px",
    display: "flex",
  },
  "& .searchInput": {
    borderRadius: "8px",
    border: "1px solid #f3f2f2",
    color: "darkgray",
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      border: "1px solid #f3f2f2",
      color: "darkgray"
    },
    "& .searchInput": {
      borderRadius: "8px",
      border: "1px solid #f3f2f2",
      color: "darkgray",
      "& .MuiOutlinedInput-root": {
        borderRadius: "8px",
        border: "1px solid #f3f2f2",
        color: "darkgray",
      },
    },
  },
  "& .searchInputDraft": {
    width: "300px",
    border: "1px solid #f4f5f8",
    borderRadius: "8px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      border: "1px solid #f4f5f8"
    },
  },
  "& .noDataContainer": {
    height: "50vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: "20px",
  },
  "& .requestAccessBtn": {
    width: "250px",
    backgroundColor: "#2b6fec",
    borderRadius: "8px",
    color: "#fff",
    fontWeight: 600,
  },
  "& .tablePaginationContainer": {
    paddingTop: "20px ",
    paddingLeft: "20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  "& .totalItemInOnePage": {
    color: "#f0a36e",
    fontWeight: 700,
  },
  "& .totalItems": {
    color: "#32333a",
    fontWeight: 900,
  },
  "& .paginationDescription": {
    fontSize: "18px",
    fontWeight: 400,
  },
  "& .tablePagination": {
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: "rgb(252, 132, 52) !important",
      color: "#fff",
      fontWeight: 700,
      margin: "0px 5px",
      borderColor: "rgb(252, 132, 52) !important"
    },
    "& .MuiPaginationItem-page.Mui-selected:hover": {
      backgroundColor: "rgb(252, 132, 52) !important",
      color: "#fff",
      fontWeight: 700,
      margin: "0px 5px",
      borderColor: "rgb(252, 132, 52) !important"
    },
    "& .MuiPaginationItem-rounded": {
      color: "#f4f3f3",
      margin: "0px 5px",
    },
    "& .MuiPaginationItem-outlined": {
      border: "1px solid rgba(0, 0, 0, 0.23)",
      color: "rgba(0, 0, 0, 0.23)"
    },
    "& .MuiPaginationItem-outlined:hover": {
      color: "#2b6fed",
      border: "1px solid #2b6fed"
    },
    "& .MuiPaginationItem-page.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.26)",
    },
  },

  "& .contractListPopover": {
    boxShadow: "0px 0px 5px 1px #f4f7f8 !important",
    "& .MuiPaper-elevation1": {
    }
  },
  "& .noDataHeading": {
    textAlign: "center",
    color: "#a2a2a5",
    fontSize: "18px",
  },
  "& .notDataICon": {
    width: "60px",
    height: "60px",
  },
  "& .templatesCountWrapper": {
    display: "flex",
    gap: "10px",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  "& .templateIcon": {
    width: "20px",
    height: "20px"
  },
});
const ContractListPopover = styled(Popover)({
  "& .MuiPaper-elevation1": {
    boxShadow: "0px 0px 5px 1px #f4f7f8 !important",
  },
  "& .popoverMenuItem": {
    width: "130px"
  }
})

const BuildingModal = styled(Modal)({

  "& .buildingModalContainer":{
    height:"100vh",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
  },
  "& .viewBuildingContainer":{
    backgroundColor:"#fff",
    width:"60%",
    height:"70%"
  },
  "& .modalHeadingContainer":{
    padding:"20px",
  }
});

const ContractDetailsTerminateModal = styled(Box)({
  justifyContent: 'center',
  display: 'flex',
  height: '100vh',
  alignItems: 'center',
  "& .terminateModalIcon": {
    marginBottom: "20px"
  },
  
  "& .issueNewLeaseText": {
    color: "#537ad2 !important"
  },

  '& .TerminateModalChild': {
    display: 'flex',
    padding: "50px",
    flexDirection: 'column',
    alignItems: 'center',
    paddingRight: '50px',
    justifyContent: 'center',
    borderRadius: "10px",
    paddingLeft: '50px',
  },

  '& .TerminateModalChildHeading': {
    fontSize: 25,
    marginBottom: 10,
    fontWeight: 800,
  },

  '& .TerminateModalChildButton': {
    marginTop: 40
  },

  '& .TerminateModalChildText': {
    textAlign: 'center',
    color: '#5f6062',
    width: "420px"
  },

  '& .TerminateModalChildButton2': {
    color: 'white',
    background: '#2f6ded',
    padding: '15px 40px 15px 40px',
    marginLeft: 15,
    borderRadius: 10,
    fontWeight: 700,
  },

  '& .TerminateModalChildButton1': {
    color: '#2f6ded',
    border: '1px solid #2f6ded',
    fontWeight: 700,
    borderRadius: 10,
    padding: '15px 40px 15px 40px',
  },
});
// Customizable Area End