import React from "react";
import {
  Select,
  MenuItem,
  Box, FormControl, styled
} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { languageCondition } from "../ConditionManager.web";

interface SelectFilterTable {
  label: string,
  testId: string,
  filterState: string,
  listState: string,
  selectStyle: string,
  selectFormStyle: string,
  allOption?: any
}

const selectFiltersTable: any = {
  'coo_real_estate_filter': [
    {
      label: 'Company Name',
      allOption: true,
      testId: 'companyList',
      filterState: 'companyFilter',
      listState: 'companiesList',
      selectStyle: 'selectBigForm',
      selectFormStyle: 'selectBigFormControl',
    },
    {
      label: 'Country',
      allOption: true,
      testId: 'countryList',
      filterState: 'countryFilter',
      listState: 'countriesList',
      selectStyle: 'selectForm',
      selectFormStyle: 'selectFormControl'
    },
    {
      label: 'Region',
      allOption: true,
      testId: 'regionList',
      filterState: 'regionFilter',
      listState: 'regionList',
      selectStyle: 'selectForm',
      selectFormStyle: 'selectFormControl'
    },
    {
      label: 'City',
      allOption: true,
      testId: 'cityList',
      filterState: 'cityFilter',
      listState: 'cityList',
      selectStyle: 'selectForm',
      selectFormStyle: 'selectFormControl'
    },
    {
      label: 'Status',
      allOption: true,
      testId: 'statusList',
      filterState: 'statusFilter',
      listState: 'statusList',
      selectStyle: 'selectForm',
      selectFormStyle: 'selectFormControl'
    },
    {
      label: 'Sales Manager',
      allOption: true,
      testId: 'salesManagerList',
      filterState: 'salesManagerFilter',
      listState: 'salesManagerList',
      selectStyle: 'selectBigForm',
      selectFormStyle: 'selectBigFormControl'
    },
    {
      label: 'Account Manager',
      allOption: true,
      testId: 'accountManagerList',
      filterState: 'accountManagerFilter',
      listState: 'accountManagerList',
      selectStyle: 'selectBigForm',
      selectFormStyle: 'selectBigFormControl'
    },
  ],
  'coo_complex_filter': [
    {
      label: 'Country',
      allOption: true,
      testId: 'countryList',
      filterState: 'countryFilter',
      listState: 'countriesList',
      selectStyle: 'selectForm',
      selectFormStyle: 'selectFormControl'
    },
    {
      label: 'City',
      allOption: true,
      testId: 'cityList',
      filterState: 'cityFilter',
      listState: 'cityList',
      selectStyle: 'selectForm',
      selectFormStyle: 'selectFormControl'
    },
    {
      label: 'Company Name',
      allOption: true,
      testId: 'companyList',
      filterState: 'companyFilter',
      listState: 'companiesList',
      selectStyle: 'selectBigForm',
      selectFormStyle: 'selectBigFormControl',
    },
    {
      label: 'Account Manager',
      allOption: true,
      testId: 'accountManagerList',
      filterState: 'accountManagerFilter',
      listState: 'accountManagerList',
      selectStyle: 'selectBigForm',
      selectFormStyle: 'selectBigFormControl'
    },

  ],
  'other_real_estate_filter': [
    {
      label: 'Company Name',
      allOption: true,
      testId: 'companyList',
      filterState: 'companyFilter',
      listState: 'companiesList',
      selectStyle: 'selectBigForm',
      selectFormStyle: 'selectBigFormControl',
    },
    {
      label: 'Country',
      allOption: true,
      testId: 'countryList',
      filterState: 'countryFilter',
      listState: 'countriesList',
      selectStyle: 'selectForm',
      selectFormStyle: 'selectFormControl'
    },
    {
      label: 'State',
      allOption: true,
      testId: 'regionList',
      filterState: 'regionFilter',
      listState: 'regionList',
      selectStyle: 'selectForm',
      selectFormStyle: 'selectFormControl'
    },
    {
      label: 'City',
      allOption: true,
      testId: 'cityList',
      filterState: 'cityFilter',
      listState: 'cityList',
      selectStyle: 'selectForm',
      selectFormStyle: 'selectFormControl'
    },
    {
      label: 'Status',
      allOption: true,
      testId: 'statusList',
      filterState: 'statusFilter',
      listState: 'statusList',
      selectStyle: 'selectForm',
      selectFormStyle: 'selectFormControl'
    },
  ],
  'account_manager_complex_filter': [
    {
      label: 'Company Name',
      allOption: true,
      testId: 'companyList',
      filterState: 'companyFilter',
      listState: 'companiesList',
      selectStyle: 'selectBigForm',
      selectFormStyle: 'selectBigFormControl',
    },
    {
      label: 'Country',
      allOption: true,
      testId: 'countryList',
      filterState: 'countryFilter',
      listState: 'countriesList',
      selectStyle: 'selectForm',
      selectFormStyle: 'selectFormControl'
    },
    {
      label: 'City',
      allOption: true,
      testId: 'cityList',
      filterState: 'cityFilter',
      listState: 'cityList',
      selectStyle: 'selectForm',
      selectFormStyle: 'selectFormControl'
    },
    {
      label: 'Status',
      allOption: true,
      testId: 'statusList',
      filterState: 'statusFilter',
      listState: 'statusList',
      selectStyle: 'selectForm',
      selectFormStyle: 'selectFormControl'
    },
  ],
  'sales_manager_complex_filter': [
    {
      label: 'Country',
      allOption: true,
      testId: 'countryList',
      filterState: 'countryFilter',
      listState: 'countriesList',
      selectStyle: 'selectForm',
      selectFormStyle: 'selectFormControl'
    },
    {
      label: 'City',
      allOption: true,
      testId: 'cityList',
      filterState: 'cityFilter',
      listState: 'cityList',
      selectStyle: 'selectForm',
      selectFormStyle: 'selectFormControl'
    },
    {
      label: 'Status',
      allOption: true,
      testId: 'statusList',
      filterState: 'statusFilter',
      listState: 'statusList',
      selectStyle: 'selectForm',
      selectFormStyle: 'selectFormControl'
    },
    {
      label: 'Account Manager',
      allOption: true,
      testId: 'accountManagerList',
      filterState: 'accountManagerFilter',
      listState: 'accountManagerList',
      selectStyle: 'selectBigForm',
      selectFormStyle: 'selectBigFormControl'
    },
  ],
  'dropdown_filter': [
    {
      label: 'Sort By',
      testId: 'sortByList',
      filterState: 'sortBy',
      listState: 'dropDownData',
      selectStyle: 'selectSmallForm',
      selectFormStyle: 'selectFormControl'
    },
  ],
  'coo_complex_details_filter': [
    {
      label: 'Country',
      allOption: true,
      testId: 'countryList',
      filterState: 'countryFilter',
      listState: 'countriesList',
      selectStyle: 'selectForm',
      selectFormStyle: 'selectFormControl'
    },
    {
      label: 'Region',
      allOption: true,
      testId: 'regionList',
      filterState: 'regionFilter',
      listState: 'regionList',
      selectStyle: 'selectForm',
      selectFormStyle: 'selectFormControl'
    },
    {
      label: 'City',
      allOption: true,
      testId: 'cityList',
      filterState: 'cityFilter',
      listState: 'cityList',
      selectStyle: 'selectForm',
      selectFormStyle: 'selectFormControl'
    },
    {
      label: 'Status',
      allOption: true,
      testId: 'statusList',
      filterState: 'statusFilter',
      listState: 'statusList',
      selectStyle: 'selectForm',
      selectFormStyle: 'selectFormControl'
    },
    {
      label: 'Account Manager',
      allOption: true,
      testId: 'accountManagerList',
      filterState: 'accountManagerFilter',
      listState: 'accountManagerList',
      selectStyle: 'selectBigForm',
      selectFormStyle: 'selectBigFormControl'
    },
  ],
  'sales_real_estate_filter': [
    {
      label: 'Company Name',
      allOption: true,
      testId: 'companyList',
      filterState: 'companyFilter',
      listState: 'companiesList',
      selectStyle: 'selectBigForm',
      selectFormStyle: 'selectBigFormControl',
    },
    {
      label: 'Country',
      allOption: true,
      testId: 'countryList',
      filterState: 'countryFilter',
      listState: 'countriesList',
      selectStyle: 'selectForm',
      selectFormStyle: 'selectFormControl'
    },
    {
      label: 'Region',
      allOption: true,
      testId: 'regionList',
      filterState: 'regionFilter',
      listState: 'regionList',
      selectStyle: 'selectForm',
      selectFormStyle: 'selectFormControl'
    },
    {
      label: 'City',
      allOption: true,
      testId: 'cityList',
      filterState: 'cityFilter',
      listState: 'cityList',
      selectStyle: 'selectForm',
      selectFormStyle: 'selectFormControl'
    },
    {
      label: 'Status',
      allOption: true,
      testId: 'statusList',
      filterState: 'statusFilter',
      listState: 'statusList',
      selectStyle: 'selectForm',
      selectFormStyle: 'selectFormControl'
    },
    {
      label: 'Account Manager',
      allOption: true,
      testId: 'accountManagerList',
      filterState: 'accountManagerFilter',
      listState: 'accountManagerList',
      selectStyle: 'selectBigForm',
      selectFormStyle: 'selectBigFormControl'
    },
  ],
  'account_real_estate_filter': [
    {
      label: 'Company Name',
      allOption: true,
      testId: 'companyList',
      filterState: 'companyFilter',
      listState: 'companiesList',
      selectStyle: 'selectBigForm',
      selectFormStyle: 'selectBigFormControl',
    },
    {
      label: 'Country',
      allOption: true,
      testId: 'countryList',
      filterState: 'countryFilter',
      listState: 'countriesList',
      selectStyle: 'selectForm',
      selectFormStyle: 'selectFormControl'
    },
    {
      label: 'Region',
      allOption: true,
      testId: 'regionList',
      filterState: 'regionFilter',
      listState: 'regionList',
      selectStyle: 'selectForm',
      selectFormStyle: 'selectFormControl'
    },
    {
      label: 'City',
      allOption: true,
      testId: 'cityList',
      filterState: 'cityFilter',
      listState: 'cityList',
      selectStyle: 'selectForm',
      selectFormStyle: 'selectFormControl'
    },
    {
      label: 'Status',
      allOption: true,
      testId: 'statusList',
      filterState: 'statusFilter',
      listState: 'statusList',
      selectStyle: 'selectForm',
      selectFormStyle: 'selectFormControl'
    },
    {
      label: 'Sales Manager',
      allOption: true,
      testId: 'salesManagerList',
      filterState: 'salesManagerFilter',
      listState: 'salesManagerList',
      selectStyle: 'selectBigForm',
      selectFormStyle: 'selectBigFormControl'
    },
  ],
}

export const statusMapping: any = {
  "pending_approval": "Pending Approval",
  "rejected": "Rejected",
  "approved": "Approved",
  "deactive": "Deactive",
  "pending_deletion": "Pending Deletion",
  "reject_deletion": "Reject Deletion",
  "approved_deletion": "Approved Deletion"
}

export const RealEstateFilter = (props: any) => {
  const { language, classComponent, filterList, t } = props;

  return <>
    {
      selectFiltersTable[filterList].map((select: SelectFilterTable, i: number) => {
        return <Box id="ChairmanInvoices" className={languageCondition(language, "arabic-grid filter-filter", "filter-filter")}>
          <RECContainer>
            <FormControl variant="filled">
          <Select
            displayEmpty
            data-testid={select.testId}
            label={select.label}
            className={"sortBySearch"}
            IconComponent={(props) => <ExpandMoreIcon style={webStyle.expandIcon} className="select-icon" {...props} />}
            //@ts-ignore
            value={classComponent.state[select.filterState] || ""}
            onChange={(e) => classComponent.handleFilterChange(e, select.filterState)}
            inputProps={{
              name: select.testId,
              id: 'outlined-age-native-simple',
            }}
            variant='outlined'
            MenuProps={{
              style: {
                marginTop: "50px",
                  },
            }}
          >
            <MenuItem value="" disabled style={{ color: '#848484' }} className={languageCondition(language, "select-arabic-menu", "")}>
              {t(select.label)}
            </MenuItem>
            {
              select.allOption && <MenuItem value="all" className={languageCondition(language, "select-arabic-menu", "")}>{t("All")}</MenuItem>
            }
            {
              //@ts-ignore
              classComponent.state[select.listState]?.length && classComponent.state[select.listState].map((val: any, j: number) => {
                let optionVal = val
                let setValue = val
                if (select.testId === "statusList") {
                  optionVal = statusMapping[val];
                }
                if (select.testId === "cityList") {
                  setValue = val.name;
                  optionVal = val.name;
                }
                if (select.testId === "countryList") {
                  setValue = val.attributes?.name;
                  optionVal = val.attributes?.name;
                }
                if (select.testId === "accountManagerList" || select.testId === "salesManagerList") {
                  setValue = val.id;
                  optionVal = val.name;
                }
                if (select.testId === "regionList") {
                  setValue = val.id;
                  optionVal = val.name;
                }
                if (select.testId === "sortByList") {
                  optionVal = val.label;
                  setValue = val.value;
                }
                if (select.testId === "companyList") {
                  optionVal = val.company_name;
                  setValue = val.company_name;
                }
                return <MenuItem className={languageCondition(language, "select-arabic-menu table_class_select text_capital", "table_class_select text_capital")} style={webStyle.menuOption} value={setValue} key={j}>
                  {t(optionVal)}
                </MenuItem>
              })
            }
          </Select>
        </FormControl>
      </RECContainer>
        </Box>
      })
    }
  </>
}

const webStyle: any = {
  selectFormControl: {
    width: '150px',
    marginRight: '10px',
    marginBottom: '10px',
    paddingRight: "30px",
  },
  selectBigFormControl: {
    width: '200px',
    marginRight: '10px',
    paddingRight: "30px",
    marginBottom: '10px',
  },
  menuOption: {
    textTransform: 'capitalize'
  },
  selectBigForm: {
    paddingRight: "30px",
    minWidth: '200px',
    color: '#000000',
    border: '1px solid #f0f0f0'
  },
  selectForm: {
    minWidth: '150px',
    color: '#000000',
    border: '1px solid #f0f0f0',
    paddingRight: "30px",
  },
  selectSmallForm: {
    borderRadius: '7px',
    padding: "4px",
    minWidth: '150px',
    minHeight: '40px',
    color: '#000000',
    background: '#ffffff',
    border: '1px solid #cccccc',
    paddingRight: "30px",
  },
  expandIcon: {
    color: 'darkgray',
    right: "5px",
    cursor: 'pointer'
  },
  sortLabel: {
    color: 'black',
    fontWeight: 'bold'
  }
};

const RECContainer = styled(Box)({
  "& .sortBySearch": {
      minWidth: "150px",
      backgroundColor: "white",
      height: "51px",
      borderRadius: "10px",
      border: "1px solid #EAEAEA"
  },
});