// Customizable Area Start
import React from "react";
import "./Dashboard.web.css";
import {
  Container,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles,
  TextField,
  InputAdornment,
  Divider,
  Button,
  Link,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Pagination from "@material-ui/lab/Pagination";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import DashboardTicketController, { Props } from "./DashboardTicketController.web";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import { DashboardStyleWeb } from "./DashboardStyle.web";
import { ROLE } from "../../../framework/src/Enum";
import SearchIcon from "@material-ui/icons/Search";
import moment from "moment";
import GeneralHeaderComponentWeb from "../../../components/src/HeaderComponent/GeneralHeaderComponent.web";
import { languageCondition } from "../../../components/src/ConditionManager.web";

class TicketGeneratedDays extends DashboardTicketController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount(): Promise<void> {
    const role = localStorage.getItem("userType");
    const society_id = localStorage.getItem("society_id");
    const days = this.props.navigation.getParam("days");
    this.setState({ tookDays: days, society_id: role === ROLE.SERVICE_PROVIDER ? "" : society_id  }, () => {
      this.getTicketDashboardYearList();
      this.getAllBuildingList();
      this.getIncidentCategoryList();
      this.getTicketByDays();
    });
  }

  async componentDidUpdate(prevProps: any, prevState: any): Promise<void> {
    if (
      prevState.searchResident !== this.state.searchResident ||
      prevState.status !== this.state.status ||
      prevState.category !== this.state.category ||
      prevState.filterYear !== this.state.filterYear ||
      prevState.filterStatus !== this.state.filterStatus ||
      prevState.filterBuilding !== this.state.filterBuilding ||
      prevState.page !== this.state.page ||
      prevState.sort !== this.state.sort
    ) {
      await this.getTicketByDays();
    }
  }

  render() {
    const userType = localStorage.getItem("userType");
    const { t, classes, i18n }: any = this.props;
    const language = i18n.language;

    return (
      <>
        <GeneralHeaderComponentWeb>
              <Container className={classes.generalDashboard}>
                <Box className="navigation ticket-days-content-box">
                  <Box className="ticket-days-heading-box">
                    <Typography variant="body1">
                      {t("My Dashboards")} / <Link href="/DashboardTicket">{t("Ticket Dashboard")}</Link> /{" "}
                      <Box component="span" style={{ color: "blue" }}>
                        {t("Tickets Took More Than")} {this.state.tookDays} {t("Days")}
                      </Box>
                    </Typography>
                  </Box>
                  <Box className="sub-heading-box">
                    <Typography variant="h5" className="bold-text">
                      {t("Tickets Took More Than")} {this.state.tookDays} {t("Days")}
                    </Typography>
                    <Box className="select-box">
                      {userType === ROLE.MANAGER && (
                        <>
                          <select
                            className="select-year ticket-days-filter-select"
                            value={this.state.filterStatus}
                            onChange={(e: any) => this.setState({ filterStatus: e.target.value })}
                          >
                            <option value="">{t("Status")}</option>
                            <option value="Resolved">{t("Resolved")}</option>
                            <option value="Unresolved">{t("Unresolved")}</option>
                            <option value="Pending Confirmation">{t("Pending Confirmation")}</option>
                            <option value="Open">{t("Open")}</option>
                            <option value="Ongoing">{t("Ongoing")}</option>
                          </select>
                          <select
                            className="select-year ticket-days-select-filter-building"
                            onChange={(e: any) => this.setState({ filterBuilding: e.target.value })}
                            value={this.state.filterBuilding}
                          >
                            <option value="" disabled>
                              {t("Select Building")}
                            </option>
                            {this.state.buildingList.map((building: any) => {
                              return (
                                <option value={building.id} key={building.id}>
                                  {building.attributes.name}
                                </option>
                              );
                            })}
                          </select>
                          <select
                            value={this.state.filterYear}
                            onChange={(e: any) => this.setState({ filterYear: e.target.value })}
                            className="select-year ticket-days-select-year-filter"
                          >
                            {this.state.yearList.map((year: any) => {
                              return (
                                <option value={year} key={year}>
                                  {year}
                                </option>
                              );
                            })}
                          </select>
                        </>
                      )}
                    </Box>
                  </Box>
                  {userType === ROLE.CHAIRMAN && (
                    <Box className="chairman-filter ticket-days-chairman-filter-box">
                      <Box className="action-filter-box ticket-days-action-filter-box">
                        <select
                          onChange={(e: any) => this.setState({ chairmanStatus: e.target.value })}
                          className="select-year ticket-days-status-filter"
                          value={this.state.chairmanStatus}
                        >
                          <option value="">{t("Status")}</option>
                          <option value="Resolved">{t("Resolved")}</option>
                          <option value="Unresolved">{t("Unresolved")}</option>
                          <option value="Pending Confirmation">{t("Pending Confirmation")}</option>
                          <option value="Open">{t("Open")}</option>
                          <option value="Ongoing">{t("Ongoing")}</option>
                        </select>
                        <select
                          className="select-year"
                          value={this.state.chairmanCategory}
                          onChange={(e: any) => this.setState({ chairmanCategory: e.target.value })}
                        >
                          <option value="">{t("Category")}</option>
                          {this.state.categoryList.map((category: any) => {
                            return (
                              <option value={category.id} key={category.id}>
                                {category.name}
                              </option>
                            );
                          })}
                        </select>
                        <Button
                          startIcon={<SearchIcon />}
                          onClick={() =>
                            this.setState({ status: this.state.chairmanStatus, category: this.state.chairmanCategory })
                          }
                        >
                          {t("Search")}
                        </Button>
                      </Box>
                      <Box className="right-filter-box">
                        <select
                          value={this.state.filterYear}
                          className="select-year"
                          onChange={(e: any) => this.setState({ filterYear: e.target.value })}
                        >
                          {this.state.yearList.map((year: any) => {
                            return (
                              <option value={year} key={year}>
                                {year}
                              </option>
                            );
                          })}
                        </select>
                      </Box>
                    </Box>
                  )}
                </Box>

                <Box className="content-boxes ticket-table">
                  <Box className="top-content">
                    <Box className="heading">
                      <h2 className="bold-text">{t("Tickets")}</h2>
                    </Box>
                    <Box className="right-content">
                      <TextField
                        className="search-unit"
                        value={this.state.searchResident}
                        onChange={(e: any) => this.setState({ searchResident: e.target.value })}
                        placeholder={t("Search by ticket number")}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchOutlinedIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                      {userType === ROLE.MANAGER && (
                        <select
                          className="unit-select"
                          value={this.state.sort}
                          onChange={(e: any) => this.setState({ sort: e.target.value })}
                        >
                          <option disabled value="">
                            {t("Sort By")}
                          </option>
                          <option value="asc">{t("Asc")}</option>
                          <option value="desc">{t("Desc")}</option>
                        </select>
                      )}
                    </Box>
                  </Box>
                  <Divider />
                  <TableContainer>
                    <Table className="unit-table">
                      <TableHead>
                        <TableRow>
                          <TableCell align={languageCondition(language,"right","left")} className="bold-text ticket-days-table-heading table-head-col" style={{ color: "black" }}>
                            {t("#")}
                          </TableCell>
                          <TableCell align={languageCondition(language,"right","left")} className="bold-text table-head-col ticket-days-table-heading" style={{ color: "black" }}>
                            {t("Ticket No")}
                          </TableCell>
                          <TableCell align={languageCondition(language,"right","left")} className="bold-text ticket-days-table-heading table-head-col" style={{ color: "black" }}>
                            {t("Raised By")}
                          </TableCell>
                          <TableCell align={languageCondition(language,"right","left")} className="bold-text table-head-col ticket-days-table-heading" style={{ color: "black" }}>
                            {t("Incident related to")}
                          </TableCell>
                          <TableCell align={languageCondition(language,"right","left")} className="bold-text table-head-col ticket-days-table-heading" style={{ color: "black" }}>
                            {t("Open Date")}
                          </TableCell>
                          <TableCell align={languageCondition(language,"right","left")} className="bold-text table-head-col ticket-days-table-heading table-head-col" style={{ color: "black" }}>
                            {t("Close Date")}
                          </TableCell>
                          <TableCell align={languageCondition(language,"right","left")} className="bold-text ticket-days-table-heading table-head-col" style={{ color: "black" }}>
                            {t("Days to Close")}
                          </TableCell>
                          <TableCell align={languageCondition(language,"right","left")} className="table-head-col bold-text ticket-days-table-heading" style={{ color: "black" }}>
                            {t("Status")}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.ticketList.length === 0 && (
                          <TableRow className="ticket-days-no-data-box">
                            <TableCell colSpan={8}>{t("No ticket found")}</TableCell>
                          </TableRow>
                        )}
                        {this.state.ticketList.map((incident: any, index: number) => {
                          return (
                            <TableRow key={incident.id} className="ticket-days-table-rows">
                              <TableCell align={languageCondition(language,"right","left")}>{index + 1}</TableCell>
                              <TableCell align={languageCondition(language,"right","left")}
                                className="ticket-number"
                                onClick={() => this.handleTicketDetails(incident.id)}
                              >
                                {incident.id}
                              </TableCell>
                              <TableCell align={languageCondition(language,"right","left")}>{incident.attributes.raised_by}</TableCell>
                              <TableCell align={languageCondition(language,"right","left")}>{incident.attributes.incident_related_to}</TableCell>
                              <TableCell align={languageCondition(language,"right","left")}>{moment(incident.attributes.open_date, "DD-MM-YYYY").format("DD MMM YYYY")}</TableCell>
                              <TableCell align={languageCondition(language,"right","left")}>
                                {incident.attributes.close_date ? moment(incident.attributes.close_date, "DD-MM-YYYY").format("DD MMM YYYY") : "Yet to be defined"}
                              </TableCell>
                              <TableCell align={languageCondition(language,"right","left")}>{incident.attributes.day_to_close || "Not defined"}</TableCell>
                              <TableCell align={languageCondition(language,"right","left")}>
                                <span className={incident.attributes.status}>{t(incident.attributes.status)}</span>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box className="unit-pagination ticket-days-pagination-box">
                    <p>
                      {t("Showing")} <span>{this.state.ticketList.length}</span> {t("of")}{" "}
                      <span>{this.state.pagination ? this.state.pagination.total_count : 0}</span> {t("results")}
                    </p>
                    {this.state.pagination && (
                      <Pagination
                        onChange={(event: any, value: any) => this.setState({ page: Number(value) })}
                        count={this.state.pagination.total_pages}
                        className="ticket-days-pagination"
                        page={this.state.pagination.current_page}
                        siblingCount={2}
                        variant="outlined"
                        shape="rounded"
                      />
                    )}
                  </Box>
                </Box>
              </Container>
        </GeneralHeaderComponentWeb>
      </>
    );
  }
}

// @ts-ignore
export default withTranslation()(withStyles(DashboardStyleWeb)(withRouter(TicketGeneratedDays)));
// Customizable Area End
