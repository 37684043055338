// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
import { Message } from "../../../../framework/src/Message";
export const configJSON = require("../config");
// @ts-ignore
import { baseURL } from "../../../../framework/src/config";
import { CEApiCall } from "../../../../components/src/APICallComponent/index.web";
import * as Yup from "yup";

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  history: any;
  match: any;
  location: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
  // Customizable Area End
}

interface S {
  loading: boolean;
  setOpen: boolean;
  addQue: boolean;
  category: boolean;
  complex: boolean;
  deleteCate: boolean;
  Show: boolean;
  deleteQue: boolean;
  editQue: boolean;
  editQuestionData: any;
  questionData: any;
  categoryCss: any;
  countryList: any;
  cityList: any;
  specificCountry: string;
  complexData: any;
  complexDataForDropdown: [],
  userData: any;
  categoryData: any;
  faqCategoryId: any;
  activeComplex:any;
  activeComplexId:any;
  questionId: any;
  questionID: any;
  deletedData: any;
  queCategoryData: any;
  addCategoryData: any;
  queComplexData: any;
  addQueCategoryData: any;
  categoryTitle: any;
  deletedData1: any;
  questionTitle: any;
  answerTitle: any;
  addFAQDataList: any;
  FAQDropdowndata: any;
  categoryName: any;
  complexSocietyID: any;
  compquestionID: any;
  catequestionID: any;
  imageState: any;
  previewImage: any;
  editPreviewImage: any;
  editImageState: any;
  questionEdit: any;
  questionContent: any;
  addCategoryDataList: any;
  updateFAQDataList: any;
  isCondition: boolean;
  selectedImages: any;
  specificCity: any;
  specificComplex: any;
  specificUserType: any;
  dataBlank: any;
  alertError: any;
  alertMessage: string;
  removeImageIds: number[]
}

interface SS {
  id: any;
}

export default class CompEmpFaqController extends CommonApiCallForBlockComponent<
  Props,
  S,
  SS
> {
  exampleAPICallId: string = "";
  // Customizable Area Start
  showAllQuestionAPICallId: string = "";
  showAllCountryAPICallId: string = "";
  showAllCityAPICallId: string = "";
  showAllComplexAPICallIdFilter: string = "";
  showAllComplexAPICallId: string = "";
  showAllUserAPICallId: string = "";
  showAllCategoryAPICallId: string = "";
  createFAQAPICallId: string = "";
  deleteFAQAPICallId: string = "";
  deleteCategoryAPICallId: string = "";
  updateFAQAPICallId: string = "";
  createCategoryAPICallId: string = "";
  afterDeleteAllCategoryAPICallId: string = "";
  showAllComplexAPICallIdForFilter: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.handleOpen = this.handleOpen.bind(this)

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage)
    ];

    this.state = {
      FAQDropdowndata: [],
      loading: false,
      isCondition: false,
      alertError: false,
      alertMessage: '',
      categoryName: "",
      deletedData1: "",
      setOpen: false,
      addQue: false,
      category: false,
      complex: false,
      Show: false,
      deleteCate: false,
      deleteQue: false,
      editQue: false,
      editQuestionData: null,
      questionData: [],
      countryList: [],
      cityList: [],
      specificCountry: "",
      complexData: [],
      complexDataForDropdown: [],
      userData: [],
      categoryData: [],
      faqCategoryId: "",
      activeComplex:"",
      activeComplexId:'',
      questionId: "",
      questionID: "",
      deletedData: "",
      categoryCss: "",
      addCategoryData: "",
      queCategoryData: "",
      compquestionID: "",
      catequestionID: "",
      queComplexData: "",
      addQueCategoryData: "",
      categoryTitle: "",
      questionTitle: "",
      complexSocietyID: "",
      answerTitle: "",
      addFAQDataList: [],
      imageState: "",
      previewImage: [],
      editPreviewImage: [],
      editImageState: [],
      questionEdit: "",
      questionContent: "",
      addCategoryDataList: "",
      updateFAQDataList: "",
      selectedImages: [],
      removeImageIds: [],
      specificCity: "",
      specificComplex: "",
      specificUserType: "",
      dataBlank: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    this.showAllCountryList()
    this.showAllComplex()
    this.showAllUser()
    this.showAllCategory({
      country: this.state.specificCountry,
      city: this.state.specificCity,
      complex: this.state.specificComplex,
      userType: this.state.specificUserType
    })
  }

  async componentDidUpdate(prevProps: any, prevState: any) {
    if (
      prevState.specificCity !== this.state.specificCity
    ) {
      this.showAllComplesForSearchDropDown(this.state.specificCity)
    }
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.faqSuccessCallBack(apiRequestCallId, responseJson)
      }
      if (responseJson && responseJson.errors) {
        this.faqFailureCallBack(apiRequestCallId, responseJson)
      }
      this.setState({ loading: false });
    }
    // Customizable Area End
  }

  // Customizable Area Start
  faqSuccessCallBack = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.showAllQuestionAPICallId) {
      this.showAllQuestionSuccesscallBack(responseJson);
    }
    if (apiRequestCallId === this.showAllCountryAPICallId) {
      this.showAllCountrySuccesscallBack(responseJson);
    }
    if (apiRequestCallId === this.showAllCityAPICallId) {
      this.showAllCitySuccesscallBack(responseJson);
    }
    if (apiRequestCallId === this.showAllComplexAPICallIdFilter) {
      // this.showAllComplexListSuccesscallBack(responseJson)
    }
    if (apiRequestCallId === this.showAllComplexAPICallId) {
      this.showAllComplexSuccesscallBack(responseJson);
    }
    if (apiRequestCallId === this.showAllUserAPICallId) {
      this.showAllUserSuccesscallBack(responseJson);
    }
    if (apiRequestCallId === this.showAllCategoryAPICallId) {
      this.showAllCategorySuccesscallBack(responseJson);
    }
    if (apiRequestCallId === this.createFAQAPICallId) {
      this.createFAQCSuccesscallBack(responseJson);
    }
    if (apiRequestCallId === this.deleteFAQAPICallId) {
      this.deleteFAQSuccesscallBack(responseJson);
    }
    if (apiRequestCallId === this.createCategoryAPICallId) {
      this.createCategorySuccesscallBack(responseJson);
    }
    if (apiRequestCallId === this.afterDeleteAllCategoryAPICallId) {
      this.afterDeleteAllCategorySuccesscallBack(responseJson);
    }
    if (apiRequestCallId === this.deleteCategoryAPICallId) {
      this.deleteCategorySuccesscallBack(responseJson);
    }
    if (apiRequestCallId === this.updateFAQAPICallId) {
      this.updateFAQSuccesscallBack(responseJson);
    }
    if (apiRequestCallId === this.showAllComplexAPICallIdForFilter) {
      this.showAllComplexSuccessForSearchDropDowncallBack(responseJson);
    }
  }

  faqFailureCallBack = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.showAllQuestionAPICallId) {
      this.showAllQuestionFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.showAllCountryAPICallId) {
      this.showAllCountryFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.showAllCityAPICallId) {
      this.showAllCityFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.showAllComplexAPICallId) {
      this.showAllComplexFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.showAllUserAPICallId) {
      this.showAllUserFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.showAllCategoryAPICallId) {
      this.showAllCategoryFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.createFAQAPICallId) {
      this.createFAQFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.deleteFAQAPICallId) {
      this.deleteFAQFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.createCategoryAPICallId) {
      this.createCategoryFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.deleteCategoryAPICallId) {
      this.deleteCategoryFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.updateFAQAPICallId) {
      this.updateFAQFailureCallBack(responseJson);
    }
  }
  // Customizable Area End

  // Customizable Area Start
  showAllQuestions = async (id: any, name: any, complex:any, complexId: any) => {
    this.setState({
      faqCategoryId: id,
      categoryName: name,
      activeComplex: complex,
      activeComplexId: complexId
    }, async () => {
      this.showAllQuestionAPICallId = await CEApiCall({
        contentType: "application/json",
        method: configJSON.apiMethodTypeGet,
        endPoint: `bx_block_interactive_faqs/faq_by_category?id=${this.state.faqCategoryId}`
      });
    });

  };

  showAllQuestionSuccesscallBack = (res: any) => {
    this.setState({ questionData: res.data ? res.data : [], loading: false }, () => {
      if (res.data.length > 0) {
        this.setState({ Show: true });
      } else {
        this.setState({ Show: false });
      }
    })
  }

  showAllQuestionFailureCallBack = (res: any) => {
    this.setState({ loading: false })
  }

  // Customizable Area End

  // Customizable Area Start
  showAllCountryList = async () => {
    this.showAllCountryAPICallId = await CEApiCall({
      contentType: "application/json",
      method: configJSON.apiMethodTypeGet,
      endPoint: configJSON.showAllCountryEndPoint
    });
  };

  showAllCountrySuccesscallBack = (res: any) => {
    this.setState({ countryList: res.data.countries })
  }

  showAllCountryFailureCallBack = (error: any) => {
    this.setState({ loading: false })
  }

  // Customizable Area End

  // Customizable Area Start
  showAllCityList = async () => {
    this.showAllCityAPICallId = await CEApiCall({
      contentType: "application/json",
      method: configJSON.apiMethodTypeGet,
      endPoint: `bx_block_address/city_by_state?country_name=${this.state.specificCountry}`
    });
  };

  showAllCitySuccesscallBack = (res: any) => {
    if(res){
    this.setState({ cityList: res  })}
  }

  showAllCityFailureCallBack = (error: any) => {
    this.setState({ loading: false })
  }
  // Customizable Area End

  // Customizable Area Start
  showAllComplex = async () => {
    this.showAllComplexAPICallId = await CEApiCall({
      contentType: "application/json",
      method: configJSON.apiMethodTypeGet,
      endPoint: configJSON.showAllComplexEndPoint,
    });
  };

  showAllComplesForSearchDropDown = async (cityValue: string) => {
    this.showAllComplexAPICallIdForFilter = await CEApiCall({
      contentType: "application/json",
      method: configJSON.apiMethodTypeGet,
      endPoint: `${configJSON.showAllComplexSearchEndPointFilter}?city=${cityValue}`,
    });
  };

  showAllComplexSuccesscallBack = (res: any) => {
    if (res?.complexes){
      this.setState({ complexData: res.complexes.data })
    }
  }

  showAllComplexSuccessForSearchDropDowncallBack = (res: any) => {
    if(res && res.data) {
      this.setState({ complexDataForDropdown: res.data })
    } else {
      this.setState({ complexDataForDropdown: [] })
    }
  }


  showAllComplexFailureCallBack = (res: any) => {
    this.setState({ loading: false })
  }

  // Customizable Area End

  // Customizable Area Start
  showAllUser = async () => {
    let token: any = localStorage.getItem("loginSuccessToken");
    this.showAllUserAPICallId = await CEApiCall({
      contentType: "application/json",
      method: configJSON.apiMethodTypeGet,
      endPoint: configJSON.showAllUserEndPoint,
    });
  };

  showAllUserSuccesscallBack = (res: any) => {
    this.setState({ userData: res.data })
  }

  showAllUserFailureCallBack = (res: any) => {
    this.setState({ loading: false })
  }

  // Customizable Area End

  // Customizable Area Start
  showAllCategory = async (data: any) => {
    const { country, city, complex, userType } = data
    this.showAllCategoryAPICallId = await CEApiCall({
      contentType: "application/json",
      method: configJSON.apiMethodTypeGet,
      endPoint: `${configJSON.showAllCategoryEndPoint}?country=${country}&city=${city}&complex_id=${complex}&user_type=${userType}`,
    });
  };

  afterDeleteAllCategory = async (data: any) => {
    const { country, city, complex, userType } = data
    this.afterDeleteAllCategoryAPICallId = await CEApiCall({
      contentType: "application/json",
      method: configJSON.apiMethodTypeGet,
      endPoint: `${configJSON.showAllCategoryEndPoint}?country=${country}&city=${city}&complex_id=${complex}&user_type=${userType}`,
    });
  };

  showAllCategorySuccesscallBack = async (res: any) => {
    if (res.code === 404) {
      this.setState({ categoryData: [], questionData: [] })
    } else {
      this.setState({ categoryData: res.interactive_faq_category.data, })
      await this.showAllQuestions(res.interactive_faq_category.data[0].attributes?.id, res.interactive_faq_category.data[0].attributes?.name, res.interactive_faq_category.data[0].attributes?.society_management, res.interactive_faq_category.data[0].attributes?.society_id)
    }
  }

  handleAlertErrorClose = () => {
    this.setState({ alertError: false })
  }

  afterDeleteAllCategorySuccesscallBack = async (res: any) => {
    this.setState({ categoryData: res?.interactive_faq_category?.data || [], }, () => {
      if (this.state.categoryData?.length) {
        this.showAllQuestions(this.state.categoryData[0].attributes.id, this.state.categoryData[0].attributes.name, this.state.categoryData[0].attributes.society_management, this.state.categoryData[0].attributes.society_id)
      }
    })
  }

  showAllCategoryFailureCallBack = (res: any) => {
    this.setState({ loading: false })
  }

  // Customizable Area End

  // Customizable Area Start
  deleteFAQAPI = async () => {
    this.deleteFAQAPICallId = await CEApiCall({
      contentType: "application/json",
      method: configJSON.apiMethodTypeDelete,
      endPoint: `bx_block_interactive_faqs/interactive_faqs/${this.state.questionId}`,
    });
  };

  deleteFAQSuccesscallBack = (res: any) => {
    this.setState({ deletedData: res.message, deleteQue: false }, () => { this.showAllQuestions(this.state.faqCategoryId, this.state.categoryName, this.state.activeComplex, this.state.activeComplexId) })
  }

  deleteFAQFailureCallBack = (res: any) => {
    this.setState({ loading: false })
  }
  // Customizable Area End

  // Customizable Area Start
  deleteCategoryAPI = async () => {
    this.deleteCategoryAPICallId = await CEApiCall({
      contentType: "application/json",
      method: configJSON.apiMethodTypeDelete,
      endPoint: `bx_block_interactive_faqs/destroy_faqcategory?id=${this.state.faqCategoryId}`,
    });
  };

  deleteCategorySuccesscallBack = (res: any) => {
    this.setState({ deletedData1: res.message, deleteCate: false }, () => {

      this.afterDeleteAllCategory({
      country: this.state.specificCountry,
      city: this.state.specificCity,
      complex: this.state.specificComplex,
      userType: this.state.specificUserType
    })})
  }

  deleteCategoryFailureCallBack = (res: any) => {
    this.setState({ loading: false })
  }
  // Customizable Area End

  // Customizable Area Start

  handleCheckAddForm = () => {
    const title = this.state.questionTitle;
    const ans = this.state.answerTitle;
    if (title?.trim()?.length <= 0 || ans?.trim()?.length <= 0) {
      this.setState({ alertError: true, alertMessage: "Please add required field" });
      return false;
    }
    return true
  }

  addFAQList = async () => {
    let formdata = new FormData();
    formdata.append("data[attributes][title]", this.state.questionTitle);
    formdata.append("data[attributes][interactive_faq_category_id]", this.state.faqCategoryId);
    if (this.state.imageState.length > 0) {
      this.state.imageState.forEach((element: any) => {
        formdata.append("data[attributes][images][]", element);
      });
    }
    formdata.append("data[attributes][society_management_id]", this.state.activeComplexId);
    formdata.append("data[attributes][content]", this.state.answerTitle);
    this.createFAQAPICallId = await CEApiCall({
      method: configJSON.apiMethodTypePost,
      endPoint: "bx_block_interactive_faqs/interactive_faqs/create_faqs_with_images",
      body: formdata,
    });
  };

  createFAQCSuccesscallBack = (res: any) => {
    // await this.showAllQuestions(this.state.faqCategoryId, this.state.categoryName)
    if (this.state.categoryData?.length) {
      const filterName = this.state.categoryData.find((item: any) => {
        return item.attributes.id === res.data.faqs[0].interactive_faq_category_id
      })
      this.setState({ addFAQDataList: res.data.faqs, addQue: false, faqCategoryId: res.data.faqs[0].interactive_faq_category_id, categoryName: filterName.attributes.name }, async () => {
        await this.showAllQuestions(this.state.faqCategoryId, this.state.categoryName, this.state.activeComplex, this.state.activeComplexId)
      }
      )
    }
  }

  createFAQFailureCallBack = (error: any) => {
    this.setState({ loading: false })
  }
  // Customizable Area End

  // Customizable Area Start
  addCategoryList = async (values: any) => {
    let addCategoryBody = {
      name: values.name,
      society_id: values.society_id,
      dashboard_type: values.dashboard_type
    }
    this.createCategoryAPICallId = await CEApiCall({
      contentType: "application/json",
      method: configJSON.apiMethodTypePost,
      endPoint: "bx_block_interactive_faqs/create_faq_category",
      body: JSON.stringify(addCategoryBody)
    });
  };

  createCategorySuccesscallBack = (res: any) => {
    if (res) {
      if (res.data.id !== this.state.categoryData[0]?.id) {
        let newList = [res.data, ...this.state.categoryData]
        this.setState({ categoryData: newList, setOpen: false }, () => this.showAllQuestions(res.data.attributes.id, res.data.attributes?.name, res.data.attributes?.society_management, res.data.attributes.society_id))
      }
    }
  }

  createCategoryFailureCallBack = (error: any) => {
    this.setState({ loading: false })
  }
  // Customizable Area End

  // Customizable Area Start
  handleCheckEditForm = () => {
    const title = this.state.questionEdit;
    const ans = this.state.questionContent;
    if (title?.trim()?.length <= 0 || ans?.trim()?.length <= 0) {
      this.setState({ alertError: true, alertMessage: "Please add required field" });
      return false;
    }
    return true
  }

  updateQuestionData = async () => {
    let formdata = new FormData();
    formdata.append("data[attributes][title]", this.state.questionEdit);
    formdata.append("data[attributes][content]", this.state.questionContent);
    formdata.append("data[attributes][interactive_faq_category_id]", this.state.catequestionID)
    formdata.append("data[attributes][society_management_id]", this.state.compquestionID)
    this.state.removeImageIds.forEach((element: any) => {
      formdata.append("data[attributes][remove_image_id][]", element)
    })

    // if (this.state.imageState && this.state.imageState[0].name)
    this.state.imageState.forEach((element: any) => {
      if (element.name) {
        formdata.append("data[attributes][images][]", element);
      }
    });

    this.updateFAQAPICallId = await CEApiCall({
      method: configJSON.apiMethodTypePut,
      endPoint: `bx_block_interactive_faqs/interactive_faqs/${this.state.questionID}/update_faqs_with_images`,
      body: formdata,
    });
  };

  updateFAQSuccesscallBack = (res: any) => {
    this.setState({ updateFAQDataList: res, editQue: false }, () => this.showAllQuestions(this.state.faqCategoryId, this.state.categoryName, this.state.activeComplex, this.state.activeComplexId))
  }

  updateFAQFailureCallBack = (error: any) => {
    this.setState({ loading: false })
  }
  // Customizable Area End

  // Customizable Area Start
  handleOpen = () => {
    this.setState({ setOpen: true });
  };

  handleClose = () => {
    this.setState({ setOpen: false });
  };

  addQueOpen = () => {
    this.setState({
      addQue: true, imageState: [],  previewImage: []
    });
  };

  addQueClose = () => {
    this.setState({ addQue: false });
  };

  categoryOpen = () => {
    this.setState({ category: true });
  };

  categoryOpen1 = (queCategorycomp: any) => {
    this.setState({
      category: true, addQueCategoryData: queCategorycomp
    });
  }

  categoryClose = () => {
    this.setState({ category: false });
  };

  complexOpen2 = (queCom: any) => {
    this.setState({
      complex: true,
      queComplexData: queCom
    });
  }

  complexOpen = () => {
    this.setState({
      complex: true,
    });
  };

  complexClose = () => {
    this.setState({
      complex: false
    });
  };

  deleteQueOpen = (queid: any) => {
    this.setState({
      deleteQue: true,
      questionId: queid
    });
  };

  deleteQueClose = () => {
    this.setState({
      deleteQue: false
    });
  };

  editQueOpen = (title: any, content: any, Qid: any) => {
    const questionData = this.state.questionData.find((question: any) => {
      return question.attributes.id === Number(Qid)
    })
    const previewArray = questionData?.attributes.images.map((image: any) => {
      return { preview: baseURL + image.url, id: image.id, type: 'url' }
    })
    this.setState({
      editQue: true,
      questionID: Qid,
      catequestionID: questionData?.attributes.interactive_faq_category_id,
      questionEdit: questionData?.attributes.title,
      questionContent: questionData?.attributes.content,
      editQuestionData: questionData,
      editPreviewImage: previewArray,
      imageState: questionData?.attributes.images
    });
  };

  editQueClose = () => {
    this.setState({
      editQue: false
    });
  };

  deleteCateOpen = () => {
    this.setState({
      deleteCate: true
    });
  };

  deleteCateClose = () => {
    this.setState({
      deleteCate: false
    });
  };

  specificCountyData = (e: any) => {
    const value = e.target.value;
    this.setState({
      specificCountry: value === "all" ? "" : value,
      specificCity: '',
      specificComplex: '',
      specificUserType: ''
    }, () => this.showAllCityList())
  }

  complexid = (res: number) => {
    this.setState({ complexSocietyID: res })
  }

  complexquestionid = (compqueid: any) => {
    this.setState({ compquestionID: compqueid })
  }

  categoryquestionid = (catequeid: any) => {
    this.setState({ catequestionID: catequeid })
  }
  uploadImage = (e: any) => {
    let imageArray = [...this.state.imageState]
    let previewImageArray = [...this.state.previewImage]
    imageArray.push(e.target.files[0])
    previewImageArray.push(URL.createObjectURL(e.target.files[0]))
    this.setState({ imageState: imageArray, previewImage: previewImageArray })
  }

  uploadEditImage = (e: any) => {
    let imageArray = [...this.state.imageState]
    let previewImageArray = [...this.state.editPreviewImage]
    imageArray.push(e.target.files[0])
    previewImageArray.push({ preview: URL.createObjectURL(e.target.files[0]), id: Date.now() })
    this.setState({ imageState: imageArray, editPreviewImage: previewImageArray })
  }

  handleImageRemove = (index: number) => {
    const imageArray = [...this.state.imageState]
    const previewImageArray = [...this.state.previewImage]
    imageArray.splice(index, 1);
    previewImageArray.splice(index, 1)
    this.setState({ imageState: imageArray, previewImage: previewImageArray })
  }

  handleEditImageRemove = (id: number, index: number) => {
    const previewArr = [...this.state.editPreviewImage]
    previewArr.splice(index, 1)
    this.setState({ removeImageIds: [...this.state.removeImageIds, id], editPreviewImage: previewArr })
  }

  specificCityData = (e: any) => {
    const value = e.target.value;
    this.setState({ specificCity: value === "all" ? "" : value, specificComplex: "", specificUserType: "" })
  }

  specificComplexData = (e: any) => {
    const value = e.target.value;
    this.setState({ specificComplex: value === "all" ? "" : value, specificUserType: "" })
  }

  specificUserTypeData = (speUserType: any) => {
    this.setState({ specificUserType: speUserType?.target.value })
  }

  editQuestion = (editQuestionData: any) => {
    this.setState({ questionEdit: editQuestionData?.target.value })
  }

  editContent = (editContentData: any) => {
    this.setState({ questionContent: editContentData?.target.value })
  }

  showAllCategoryData = () => {
    this.showAllCategory({
      blankData: this.state.dataBlank,
      country: this.state.specificCountry === "all" ? "" : this.state.specificCountry,
      city: this.state.specificCity === "all" ? "" : this.state.specificCity,
      complex: this.state.specificComplex === "all" ? "" : this.state.specificComplex,
      userType: this.state.specificUserType === "all" ? "" : this.state.specificUserType
    })
  }

  categoryFormSchema: any = Yup.object().shape({
    society_id: Yup.string().required("Required").matches(/\S/, "Required"),
    dashboard_type: Yup.string().required("Required").matches(/\S/, "Required"),
    name: Yup.string().required("Required").matches(/\S/, "Required"),
  });
  // Customizable Area End
}
// Customizable Area End