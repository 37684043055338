//@ts-ignore
//@ts-nocheck
import React from "react";
// Customizable Area Start
import {
  Container,
  Typography,
  Link,
  Card,
  CardContent,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Drawer,
  DialogContent,
  IconButton,
} from "@material-ui/core";
import '../../dashboard/src/Dashboard.web.css';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Box from '@material-ui/core/Box';
import Select from "@material-ui/core/Select";
import Grid from '@material-ui/core/Grid';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { withRouter } from 'react-router';
import Loader from "../../../components/src/Loader.web";
import IncidentManagementController, { Props } from "./IncidentManagementController.web";
import DashboardHeader from "../../dashboard/src/DashboardHeader.web";
import ChairmanSidebar from "../../dashboard/src/ChairmanSidebar.web";
import { Close_Icon, Tick_Circle_Icon } from "./assets";
import IncidentChatWeb from "../../customform/src/IncidentChatBox.web";
import AlertErrorWeb from "../../../components/src/AlertError.web";
import { withTranslation } from "react-i18next";
import moment from "moment/moment";
import GeneralHeader from "../../../components/src/HeaderComponent/GeneralHeaderComponent.web";
import {languageCondition} from "../../../components/src/ConditionManager.web";

class IncidentManagementDetail extends IncidentManagementController {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    const id = localStorage.getItem("incidentManagementDetailId");
    if (id)
      this.getIncidentDetailsById(id);
    else
      this.props.history.push("/IncidentManagement");
  }

  render() {
    const { t,i18n }: any = this.props;
    const language = i18n.language;
    const statusArray = ["Unresolved", "Resolved", "Ongoing","Pending Confirmation"];
    const id = this.state.getIncidentDetails?.id;
    const attributes = this.state?.getIncidentDetails?.attributes;
    const apartmentManagementId = attributes?.apartment_management?.apartment_management_id;
    return (
      <>
        <GeneralHeader>
              <Container className="incident-Listing-wrapper desktop-ui">
                <Box style={dashBoard.navigation}>
                  <Box>
                    <Typography variant="body1" >
                      <Link color="inherit">{t("My Dashboard")}</Link> /
                      <Link href="DashboardGeneral" color="inherit"> {t("General Dashboard")}</Link> /
                      <Link href="IncidentManagement" color="inherit"> {t("Incidents")}</Link> /
                      <Link href="IncidentManagementDetail" color="inherit">
                        <Box component="span" style={{ color: "#2c6fed" }}>
                          {t("Incident Details")}
                        </Box>
                      </Link>
                    </Typography>
                    <Typography variant="h5" className="bold-text" style={dashBoard.subHeading}>{t("Incident Details")}</Typography>
                  </Box>

                </Box>
                <Box className="content-block-wrapper incident-detail-card-block">
                  <Card className="incident-detail-card card">
                    <Box className="card-header">
                      <Typography component="h4" className="bold-text">
                        {attributes?.incident_title}
                      </Typography>
                      <SelectStatus this={this} statusArray={statusArray} t={t}></SelectStatus>
                    </Box>
                    <CardContent className="card-content">
                      <Box className="row-block">
                        <Box className="card-rows" style={{ borderBottom: '1px solid #f4f6fb', paddingBottom: '0.5rem', marginTop: "0" }}>
                          <Typography className="title-span" component="span">
                            {t("Affected Area")}:
                          </Typography>
                          <h4 className="bold-text">{attributes?.common_area?.name}</h4>
                        </Box>
                        <Box className="card-rows" style={{ borderBottom: '1px solid #f4f6fb', paddingBottom: '0.5rem', marginTop: "0" }}>
                          <Typography className="title-span" component="span">
                            {t("Incident is related to")}:
                          </Typography>
                          <h4 className="bold-text">{attributes?.incident_related?.name}</h4>
                        </Box>
                        <Box className="card-rows" style={{ borderBottom: '1px solid #f4f6fb', paddingBottom: '0.5rem', marginTop: "0" }}>
                          <Typography className="title-span" component="span">
                            {t("Incident Number")}:
                          </Typography>
                          <h4 className="bold-text">{id}</h4>
                        </Box>
                        <Box className="card-rows" style={{ borderBottom: '1px solid #f4f6fb', paddingBottom: '0.5rem', marginTop: "0" }}>
                          <Typography className="title-span" component="span">
                            {t("Building")}:
                          </Typography>
                          <h4 className="bold-text">{attributes?.apartment_management?.building_name}</h4>
                        </Box>
                        <Box className="card-rows" style={{ borderBottom: '1px solid #f4f6fb', paddingBottom: '0.5rem', marginTop: "0" }}>
                          <Typography className="title-span" component="span">
                            {t("Unit")}:
                          </Typography>
                          <h4 className="bold-text">{attributes?.apartment_management?.apartment_name}</h4>
                        </Box>
                        <Box className="card-rows" style={{ borderBottom: '1px solid #f4f6fb', paddingBottom: '0.5rem', marginTop: "0" }}>
                          <Typography className="title-span" component="span">
                            {t("Reported on")}:
                          </Typography>
                          <h4 className="bold-text">{attributes?.reported_on}</h4>
                        </Box>
                        <Box className="card-rows" style={{ borderBottom: '1px solid #f4f6fb', paddingBottom: '0.5rem', marginTop: "0" }}>
                          <Typography className="title-span" component="span">
                            {t("Acknowledge by Manager")}:
                          </Typography>
                          <Aknowledgement attributes={attributes}/>
                        </Box>
                        <Box className="card-rows" style={{ borderBottom: '1px solid #f4f6fb', paddingBottom: '0.5rem', marginTop: "0" }}>
                          <Typography className="title-span" component="span">
                            {t("Latest update from management")}:
                          </Typography>
                          <LastStatus attributes={attributes} />
                        </Box>
                        <Box className="card-rows" style={{ borderBottom: '1px solid #f4f6fb', paddingBottom: '0.5rem', marginTop: "0" }}>
                          <Typography className="title-span" component="span">
                            {t("Description")}:
                          </Typography>
                          <h4 className="bold-text">{attributes?.description}</h4>
                        </Box>
                      </Box>
                      {
                        attributes?.attachments.length !== 0 &&
                          <>
                            <Box className="card-rows">
                              <Typography className="title-span" component="span">
                                {t("Photos")}:
                              </Typography>
                            </Box>
                            <Box className="card-img-row photos-row">
                              {
                                attributes?.attachments?.map((val, index) => (
                                  val.content_type === "video/mp4" || val.content_type === "video/x-m4v" ?
                                    <Box className="video-img" key={index} 
                                    data-test-id="imageShowDialog"
                                    onClick={() => { this.setState({ imageShowDialog: true, file: { url: val.url, type: val.content_type, name: val.file_name } }); }}
                                    >
                                      <Box className="img-layer"></Box>
                                      <video className="incident-dialog-video"  >
                                        <source src={val.url} type={val.file?.type} />
                                      </video>
                                      <PlayCircleOutlineIcon className="play-icon" />
                                    </Box>
                                    :
                                    <Box className="video-img" key={index} 
                                    data-test-id="imageShowDialogs"
                                    onClick={() => { 
                                      this.setState(
                                        { imageShowDialog: 
                                          true, file: { url: val.url, type: val.content_type, name:
                                             val.file_name } }); }}
                                    >
                                      <Box className="img-layer">

                                      </Box>
                                      <img src={val.url} className="card-img" alt="card-img" />
                                      <FullscreenIcon 
                                      className="play-icon" />
                                    </Box>
                                )
                                )
                              }
                            </Box>
                          </>
                          
                      }

                    </CardContent>
                  </Card>
                </Box>

                <Box className="incident-button-row customButton" style={{ padding: "30px 0" }}>
                  {
                      (this.state.statusDetail === "Unresolved" || this.state.statusDetail === "Ongoing") &&
                      <>
                        {
                          attributes?.assign_incidents?.data === null ?
                              <Button variant="outlined" data-test-id="incident"
                                     onClick={() => this.providerList(apartmentManagementId)}
                              >{t("Assign incident to provider")}</Button>
                              :
                              <Box className="user-btn-box">
                                <h6 className="user-title bold-text" style={{ color: "#fc8434" }}>{attributes?.assign_incidents?.data?.attributes?.provider?.full_name}</h6>
                                <Button className="change-btn" style={{ color: "#3769fc" }} data-test-id="incidents" 
                                onClick={() => this.providerList(apartmentManagementId)}
                                >{t("change")}</Button>
                              </Box>
                        }
                      </>
                  }

                  <Button variant="contained" style={{ borderRadius: "8px" }}
                  data-test-id="createChatRoom"
                   onClick={() => this.createChatRoom(this.state.getIncidentDetails?.id)}
                   >{t("start/view ticket conversation")}</Button>
                </Box>
              </Container>
              {/* chat */}

              <Dialog
                className="add-faq-dialog assignProviderDailog"
                data-test-id="showDialog"
                onClose={() => this.setState({ showDialog: false })}
                open={this.state.showDialog}
                dir={languageCondition(language,"rtl","ltr")}
                fullWidth
              >
                <MuiDialogTitle disableTypography className="dialog-heading">
                  <Typography variant="h6" className="bold-text">
                    {t("Assign Incident to Provider")}
                  </Typography>
                  <IconButton 
                  data-test-id="showDialogs"
                  onClick={() => { this.setState({ showDialog: false }); }}
                  >
                    <CloseIcon />
                  </IconButton>
                </MuiDialogTitle>
                <DialogContent dividers>
                  <FormControl fullWidth style={{ marginBottom: "20px" }}>
                    <select
                      className="dialog-select-input"
                      data-test-id="assignProvider"
                      onChange={(e: any) => {
                        this.setState({ assignProvider: e.target.value }, () => {
                          this.getProviderName(apartmentManagementId, this.state.assignProvider);
                        });
                      }}
                      value={this.state.assignProvider}
                      name="providerWork"
                    >
                      <option aria-label="None" value="" disabled>
                        {t("Provider")}
                      </option>
                      {this.state?.providerListing?.data?.map((val, index) => (
                        <option key={index} value={`${val.id}`}>
                          {val.name}
                        </option>
                      ))}
                    </select>
                  </FormControl>
                  <FormControl fullWidth>
                    <select
                      className="dialog-select-input"
                      data-test-id="assignProviders"
                      onChange={(e: any) => {
                        this.setState({ assignProviderName: e.target.value,selectProviderError:""});
                      }}
                      value={this.state.assignProviderName}
                      name="providerName"
                    >
                      <option aria-label="None" value="" disabled>
                        {t("Provider Name")}
                      </option>
                      {this.state.providerNameListing?.map((val, index) => (
                        <option key={index} value={val?.id} >
                          {val?.full_name}
                        </option>
                      ))}
                    </select>
                  </FormControl>
                  <Typography color={"Error"} variant={"subtitle2"}>{t(this.state.selectProviderError)}</Typography>
                </DialogContent>
                <DialogActions className="dialog-button-group">
                  <Button className="cancel-button" data-test-id="cancelbtn"
                   onClick={() => { this.setState({ showDialog: false }); }}
                   >
                    {t("Cancel")}
                  </Button>
                  <Button
                    className="add-button assignProviderButton"
                    onClick={() => {
                      if(this.state.assignProviderName){
                        if (attributes?.assign_incidents?.data === null) {
                          this.assginProvider();
                        } else {
                          this.updateProvider(attributes?.assign_incidents?.data?.id);
                        }
                      }else{
                        this.setState({selectProviderError:"Please Select Provider"})
                      }
                    }}
                  >
                    {t("Assign Incident")}
                  </Button>
                </DialogActions>
              </Dialog>

              {/* view large image dialog */}
              <Dialog
                open={this.state.imageShowDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                dir={languageCondition(language,"rtl","ltr")}
                className="diloag-wrapper"
                PaperProps={{
                  style: {
                    borderRadius: '15px',
                  },
                }}
              >
                <Box className="diloag-body">
                  <Box className="diloag-header">
                    <DialogTitle className="alert-dialog-title" id="alert-dialog-title">
                      Image
                    </DialogTitle>
                    <Button data-test-id="imageShowDialogs"
                    onClick={() => { this.setState({ imageShowDialog: false }); }}
                    >
                      <img src={Close_Icon} className="close-icon" />
                    </Button>
                  </Box>
                  <ServiceImagePopup file={this.state.file}/>
                </Box>
              </Dialog>

              {/* view status dialog */}
              <Dialog
                fullWidth
                data-test-id="cancelMetting"
                onClose={() => this.setState({ statusShowDialog: false, statusDetail: attributes?.incident_status })}
                open={this.state.statusShowDialog}
                className="cancel-meeting-dialog"
                dir={languageCondition(language,"rtl","ltr")}
              >
                <DialogContent style={{ margin: "15px 0" }}>
                  <Box textAlign="center">
                    <img className="comment-image" src={Tick_Circle_Icon} alt="comment" />
                    <Typography variant="h6" className="bold-text">
                      {t("Update Status")}
                    </Typography>
                    <Typography variant="body1" style={{ marginBottom: "0px" }}>
                      {t("Are you sure you want to change the status to")} {this.state.statusDetail}?
                    </Typography>
                    <br />
                    <DialogActions className="dialog-button-group">
                      <Button
                        className="cancel-button"
                        style={{ width: "200px", height: "46px" }}
                        data-test-id="cancelbutton"
                        onClick={() => { this.setState({ statusShowDialog: false, statusDetail: attributes.incident_status }); }}
                      >
                        {t("Close")}
                      </Button>
                      <Button
                        style={{ width: "200px", height: "46px" }}
                        className="add-button changeStatusButton"
                        onClick={() => this.updateStatus(this.state?.statusDetail)}
                      >
                        {t("Confirm")}
                      </Button>
                    </DialogActions>
                  </Box>
                </DialogContent>
              </Dialog>
        </GeneralHeader>
        {/* <IncidentChatDrawer /> */}
        <Loader loading={this.state.loading} />
        <Drawer
          anchor='right'
          open={this.state.chatDrawer}
          data-test-id="chatDrawer"
          onClose={() => this.setState({ chatDrawer: false })}
          PaperProps={{ style: { width: '100%', maxWidth: '415px' } }}
        >
          <IncidentChatWeb 
          data-test-id="chatDrawers"
          onClose={() => this.setState({ chatDrawer: false })}
          navigation={this.props.navigation} id={"IncidentChatWeb"} classes={undefined} 
           />
        </Drawer>
        <AlertErrorWeb data-test-id="showError" show={this.state.showError} handleClose={() => this.setState({ showError: false, error: null })} message={this.state.error} />
      </>
    );
  }
}

const Aknowledgement = (props:any) => {
  const {attributes} = props
  return(
      <>
        {
          attributes?.acknoledged_by_manager !== null ?
              <h4 className="bold-text aknowledgeByIncident">{moment(attributes?.acknoledged_by_manager).format("DD/MM/YYYY hh:mm A")}</h4>
              :
              <h4 className="bold-text aknowledgeByIncidentDash">-</h4>
        }
      </>
  )
}

const LastStatus = (props: any) => {
  const {attributes} = props
  return(
      <>
        {
          attributes?.last_update_from_management !== null ?
              <h4 className="bold-text lastUpdateIncident">{moment(attributes?.last_update_from_management).format("DD/MM/YYYY hh:mm A")}</h4>
              :
              <h4 className="bold-text lastUpdateIncidentDash">-</h4>
        }
      </>
  )
}

const ServiceImagePopup = (props:any) => {
    const {file} = props
    return(
        <Box className="diloag-content">
          {
            file?.type === "video/mp4" || file?.type === "video/x-m4v" ?
                <video className="incident-dialog-video" controls >
                  <source src={file?.url} type={file?.type} />
                </video>
                :
                <Box>
                  <img src={file?.url} className="incident-dialog-photo" alt="card-img" />
                </Box>
          }
        </Box>
    )
}

const dashBoard = {
  
  subHeading: {
    fontWeight: 600,
    marginTop: 15,
  },
  cardBottom: {
    display: "flex",
    gap: 20,
    marginTop: 10
  },
  navigation: {
    display: "flex",
    justifyContent: "space-between",
  },
 
  bottomTwoSpan: {
    display: "flex",
    gap: 20,
    marginTop: 10
  },
  bottomColor: {
    color: "red"
  },
  
  CardsIcons: {
    border: "1px solid #d9d4d3",
    borderRadius: "50%",
    width: 25,
    height: 25,
    padding: 15,
    color: "#054c94",
  },
  Cards: {
    paddingTop: 30,
    paddingLeft: 15,
    paddingBottom: 25,
    background: "#fff",
    borderRadius: 10,
  },
  EventsHeading: {
    fontWeight: 600,
    marginTop: 50,
  },

  EventsIconsData: {
    display: "flex",
    alignItems: "center",
    gap: 25,
    marginTop: 15,
  },
  EventsIconsDataBox: {
    display: "flex",
    alignItems: "center",
    gap: 5,
  },
  YearMain: {
    background: "#fff",
    border: "none",
    borderRadius: 5,
    paddingLeft: 5,
    paddingRight: 5,
  },
  EventsCards: {
    paddingTop: 15,
    paddingLeft: 15,
    paddingBottom: 15,
    background: "#fff",
    borderRadius: 10,
  },
  EventsTitle: {
    fontWeight: 600,
    fontSize: 18,
    marginTop: 10,
  },
  EventsIconsText: {
    display: "flex",
    alignItems: "center",
    gap: 5,
    marginTop: 15,
    fontSize: 14,
  },
  facility: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
 
  SideBar: {
    background: "#f9f6f6",
    position: "relative",
    paddingBottom: 150,
  },
  PricePaid: {
    marginRight: 70,
    background: "#dcf5f0",
    padding: 6,
    borderRadius: 30,
    color: "green",
  },
};
const SelectStatus = (props: any) => {
  const checkCl = () => {
    if (props?.this.state?.statusDetail === 'Unresolved') {
      return "incident-details-unresolved";
    } else if (props?.this.state?.statusDetail === 'Resolved') {
      return 'incident-details-pending';
    } else if (props?.this.state?.statusDetail === 'Ongoing') {
      return 'incident-details-ongoing';
    } else if(props?.this.state?.statusDetail === 'Pending Confirmation') {
      return 'incident-details-pendingConfirmation';
    }else{
      return 'incident-details-resolved';
    }
  };

  return (
    <>
      <Box className={checkCl()}>
        <FormControl variant="outlined" >
          {
            props?.this.state?.statusDetail == "Closed" ?
              <Box style={{ fontWeight: "600", padding: "10px 60px 10px 14px", borderRadius: "14px" }}>
                {props.t("Closed")}
              </Box> :
              <Select
                name="statusDetail"
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                data-test-id="statusArrayTest"
                onChange={(e) => { props?.this.onChange(e); }}
                value={props?.this.state?.statusDetail}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
              >
                <MenuItem disabled value=" ">
                  {props.t("Select Status")}
                </MenuItem>
                {
                  props?.statusArray?.map((val, index) => (
                    <MenuItem
                      key={index}
                      value={val}
                    >
                      {props.t(val)}
                    </MenuItem>
                  ))
                }
              </Select>
          }
        </FormControl>
      </Box>
    </>
  );
};
// Customizable Area End

export default withTranslation()(withRouter(IncidentManagementDetail));
