//@ts-ignore
//@ts-nocheck
import React from "react";
// Customizable Area Start
// @ts-ignore
// @ts-nocheck
import {
    Container,
    Typography,
    Link,
    Card,
    CardContent,
    Button,
    Dialog,
    DialogTitle,
    Drawer,
    DialogContent,
    IconButton
} from "@material-ui/core";
import "./Dashboard.web.css"
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import { withRouter } from 'react-router';
import Loader from "../../../components/src/Loader.web";
import ServiceProviderController, { Props } from "../../dashboard/src/ServiceProviderController.web";
import { Close_Icon, Tick_Circle_Icon } from "../../ContentManagement/src/assets";
import IncidentChatWeb from "../../customform/src/IncidentChatBox.web";
import AlertErrorWeb from "../../../components/src/AlertError.web";
import { withTranslation } from "react-i18next";
import CloseIcon from "@material-ui/icons/Close";
import GoogleMapReact from "google-map-react";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import moment from "moment";
import {location,mapLocation} from "../../LeadManagement/src/assets";
import GeneralHeader from "../../../components/src/HeaderComponent/GeneralHeaderComponent.web";
import {languageCondition} from "../../../components/src/ConditionManager.web";
const LocationPin = () => <img src={mapLocation} />;
class ServiceProviderManagementDetail extends ServiceProviderController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        let attributes = this.state?.incidentDetails?.attributes
        let statusDetail = attributes?.incident_status
    // test case try code change
        const {t,i18n} = this.props
        const language = i18n.language
        return (
            <>
                <GeneralHeader  style={{ background: "#F7F9FE" }}>
                            <Container className="incident-Listing-wrapper desktop-ui">
                                <Box style={dashboardProvider.navigation}>
                                    <Box>
                                        <Typography variant="body1" >
                                            <Link color="inherit">{t("My Dashboard")}</Link> /
                                            <Link color="inherit"> {t("General Dashboard")}</Link> /
                                            <Link href="ServiceProviderDashboard" color="inherit"> {t("Open Tickets")}</Link> /
                                            <Link color="inherit">
                                                <Box component="span" style={{ color: "#2c6fed" }}>
                                                    {" "} {t("Ticket Details")}
                                                </Box>
                                            </Link>
                                        </Typography>
                                        <Typography variant="h5" className="bold-text" style={dashboardProvider.subHeading}>{t("Ticket Details")}</Typography>
                                        {
                                            statusDetail === "Pending Confirmation" &&
                                            <Typography style={{marginTop:"10px"}}>{t("This ticket has been resolved by you. Once it is confirmed by customer, ticket status will change from pending confirmation to Resolved. You will be notified about the same.")}</Typography>
                                        }
                                    </Box>
                                </Box>
                                <Box className="content-block-wrapper incident-detail-card-block">
                                    <Card className="incident-detail-card card">
                                        <Box className="card-header">
                                            <Typography component="h4" className="bold-text">
                                                {attributes?.incident_title}
                                            </Typography>
                                            <SelectStatus statusArray={statusDetail} t={t}/>
                                        </Box>
                                        <CardContent className="card-content">
                                            <Box className="row-block">
                                                <Box className="card-rows" style={{ borderBottom: '1px solid #f4f6fb', paddingBottom: '0.5rem', marginTop: "0" }}>
                                                    <Typography className="title-span" component="span">
                                                        {t("Reported By")}:
                                                    </Typography>
                                                    <h4 className="bold-text">{attributes?.reported_by?.full_name}</h4>
                                                </Box>
                                                <Box className="card-rows" style={{ borderBottom: '1px solid #f4f6fb', paddingBottom: '0.5rem', marginTop: "0" }}>
                                                    <Typography className="title-span" component="span">
                                                        {t("Mobile Number")}:
                                                    </Typography>
                                                    <h4 className="bold-text">{attributes?.reported_by?.full_phone_number}</h4>
                                                </Box>
                                                <Box className="card-rows" style={{ borderBottom: '1px solid #f4f6fb', paddingBottom: '0.5rem', marginTop: "0" }}>
                                                    <Typography className="title-span" component="span">
                                                        {t("Affected Area")}:
                                                    </Typography>
                                                    <h4 className="bold-text">{attributes?.common_area?.name}</h4>
                                                </Box>
                                                <Box className="card-rows" style={{ borderBottom: '1px solid #f4f6fb', paddingBottom: '0.5rem', marginTop: "0" }}>
                                                    <Typography className="title-span" component="span">
                                                        {t("Incident is related to")}:
                                                    </Typography>
                                                    <h4 className="bold-text">{attributes?.incident_related?.name}</h4>
                                                </Box>
                                                <Box className="card-rows" style={{ borderBottom: '1px solid #f4f6fb', paddingBottom: '0.5rem', marginTop: "0" }}>
                                                    <Typography className="title-span" component="span">
                                                        {t("Incident Number")}:
                                                    </Typography>
                                                    <h4 className="bold-text">{this.state?.incidentDetails?.id}</h4>
                                                </Box>
                                                <Box className="card-rows" style={languageCondition(language,{ borderBottom: '1px solid #f4f6fb', paddingBottom: '0.5rem', marginTop: "0",display:"flex",alignItems:"center",justifyContent:'space-between',paddingLeft:"30px"},{ borderBottom: '1px solid #f4f6fb', paddingBottom: '0.5rem', marginTop: "0",display:"flex",alignItems:"center",justifyContent:'space-between',paddingRight:"30px"})}>
                                                    <Box style={{display: "flex"}}>
                                                        <Typography className="title-span" component="span">
                                                            {t("Building")}:
                                                        </Typography>
                                                        <h4 className="bold-text">{attributes?.apartment_management?.building_name}</h4>
                                                    </Box>
                                                    <Box style={{display:"flex",alignItems:"center"}} id="openLocation" onClick={() => this.setState({isOpenMapModalOpen:true})}>
                                                        <img src={location} alt="|" style={{width:"17px",height:'20px'}}/>
                                                        <span className="bold-text" style={languageCondition(language,{marginRight:"5px",color:"#FC8434"},{marginLeft:"5px",color:"#FC8434"})}>{t("See building on map")}</span>
                                                    </Box>
                                                </Box>
                                                <Box className="card-rows" style={{ borderBottom: '1px solid #f4f6fb', paddingBottom: '0.5rem', marginTop: "0" }}>
                                                    <Typography className="title-span" component="span">
                                                        {t("Unit")}:
                                                    </Typography>
                                                    <h4 className="bold-text">{attributes?.apartment_management?.apartment_management_id}</h4>
                                                </Box>
                                                <Box className="card-rows" style={{ borderBottom: '1px solid #f4f6fb', paddingBottom: '0.5rem', marginTop: "0" }}>
                                                    <Typography className="title-span" component="span">
                                                        {t("Acknowledged by Manager")}:
                                                    </Typography>
                                                    {
                                                        attributes?.acknoledged_by_manager !== null ?
                                                        <h4 className="bold-text">{moment(attributes?.acknoledged_by_manager).format("DD/MM/YYYY hh:mm A")}</h4>
                                                            :
                                                        <h4 className="bold-text">-</h4>
                                                    }
                                                </Box>
                                                <Box className="card-rows" style={{ borderBottom: '1px solid #f4f6fb', paddingBottom: '0.5rem', marginTop: "0" }}>
                                                    <Typography className="title-span" component="span">
                                                        {t("Latest Update from management")}:
                                                    </Typography>
                                                    {
                                                        attributes?.last_update_from_management !== null ?
                                                        <h4 className="bold-text">{moment(attributes?.last_update_from_management).format("DD/MM/YYYY hh:mm A")}</h4>
                                                            :
                                                        <h4 className="bold-text">-</h4>
                                                    }
                                                </Box>
                                                <Box className="card-rows" style={{ borderBottom: '1px solid #f4f6fb', paddingBottom: '0.5rem', marginTop: "0" }}>
                                                    <Typography className="title-span" component="span">
                                                        {t("Description")}:
                                                    </Typography>
                                                    <h4 className="bold-text">{attributes?.description}</h4>
                                                </Box>
                                            </Box>
                                            {attributes?.attachments.length !== 0 ?
                                                <>
                                                    <Box className="card-rows">
                                                        <Typography className="title-span" component="span">
                                                            {t("Photos")}:
                                                        </Typography>
                                                    </Box>
                                                    <Box className="card-img-row photos-row">
                                                        {
                                                            attributes?.attachments?.map((val, index) => (
                                                                val?.content_type === "video/mp4" || val?.content_type === "video/x-m4v" ?
                                                                    <Box className="video-img" key={index} id="videoUrl"
                                                                        onClick={() => { this.setState({ imageShowDialog: true, file: { url: val.url, type: val?.content_type, name: val?.file_name } }); }}
                                                                    >
                                                                        <Box className="img-layer"></Box>
                                                                        <video className="incident-dialog-video"  >
                                                                            <source src={val?.url} type={val?.file?.type} />
                                                                        </video>
                                                                        <PlayCircleOutlineIcon className="play-icon" />
                                                                    </Box>
                                                                    :
                                                                    <Box className="video-img" key={index} id="imagePopup"
                                                                        onClick={() => { this.setState({ imageShowDialog: true, file: { url: val.url, type: val?.content_type, name: val?.file_name } }); }}
                                                                    >
                                                                        <Box className="img-layer"></Box>
                                                                        <img src={val.url} className="card-img" alt="card-img" />
                                                                        <FullscreenIcon className="play-icon" />
                                                                    </Box>
                                                            ))
                                                        }
                                                    </Box>
                                                </>
                                                : null
                                            }
                                        </CardContent>
                                    </Card>
                                </Box>
                                <Box className="incident-button-row customButton" style={{ padding: "30px 0" }}>
                                    {
                                        statusDetail == "Ongoing" &&
                                        <Button variant="outlined" id="showDialog" onClick={() => { this.setShowDialog() }}>{t("Confirm Incident Resolution")}</Button>
                                    }
                                    <Button variant="contained" id="ticketCoversation" style={{ borderRadius: "8px" }} onClick={() => this.chatRoomCreate(this.state?.incidentDetails?.id)} >
                                        {t("start/view ticket conversation")}
                                    </Button>
                                </Box>
                            </Container>
                            <Dialog
                                fullWidth
                                className="cancel-meeting-dialog"
                                open={this.state.showDialog}
                                dir={languageCondition(language,"rtl","ltr")}
                            >
                                <DialogContent style={{ margin: "25px 0" }}>
                                    <Box textAlign="center">
                                        <img className="comment-image" src={Tick_Circle_Icon} alt="comment" />
                                        <Typography variant="h6" className="bold-text" style={{fontSize:"22px",marginBottom:"20px"}}>
                                            {t("Confirm Resolution")}
                                        </Typography>
                                        <Typography variant="body1" style={{ marginBottom: "0px",fontSize:"16px" }}>
                                            {t("Are you confident that the incident raised by")} &nbsp; <br/>
                                            <b>{attributes?.reported_by?.full_name}</b>
                                            &nbsp; {t("is resolved ?")}
                                        </Typography>
                                        <br />
                                        <Box className="incident-button-row customButton" style={dashboardProvider.customButton}>
                                            <Button variant="outlined" id="resolvedClose" style={{...dashboardProvider.eachButton,color:"#2b6fed"}} onClick={() => this.setDialogClose()}>
                                                {t("Close")}
                                            </Button>
                                            <Button variant="contained" id="resolvedConfirm"  style={dashboardProvider.eachButton} onClick={() => this.updateConfirm()}>
                                                {t("Confirm")}
                                            </Button>
                                        </Box>
                                    </Box>
                                </DialogContent>
                            </Dialog>
                            <Dialog
                                open={this.state?.imageShowDialog}
                                onClose={() => this.setState({ imageShowDialog: false })}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                                className="diloag-wrapper"
                                PaperProps={{
                                    style: {
                                        borderRadius: '15px',
                                    },
                                }}
                            >
                                <Box className="diloag-body">
                                    <Box className="diloag-header">
                                        <DialogTitle className="alert-dialog-title" id="alert-dialog-title">
                                            {t("Image")}
                                        </DialogTitle>
                                        <Button id="imageShowDialog" onClick={() => { this.setState({ imageShowDialog: false }); }}>
                                            <img src={Close_Icon} className="close-icon" />
                                        </Button>
                                    </Box>
                                    <Box className="diloag-content">
                                        {this.state?.file?.type === "video/mp4" || this.state?.file?.type === "video/x-m4v" ?
                                            <video className="incident-dialog-video" controls >
                                                <source src={this.state?.file?.url} type={this.state?.file?.type} />
                                            </video>
                                            :
                                            <Box>
                                                <img src={this.state?.file?.url} className="incident-dialog-photo" alt="card-img" />
                                            </Box>
                                        }
                                    </Box>
                                </Box>
                            </Dialog>
                            <Dialog
                                fullWidth
                                className="cancel-meeting-dialog"
                                open={this.state.updateStatus}
                            >
                                <DialogContent style={{ margin: "30px 5px" }}>
                                    <Box textAlign="center">
                                        <img className="comment-image" src={Tick_Circle_Icon} alt="comment" />
                                        <Typography variant="h6" className="bold-text" style={{fontSize:"22px",marginBottom:"20px"}}>
                                            {t("Incident Resolved Successfully")}
                                        </Typography>
                                        <Typography variant="body1" style={{ marginBottom: "10px",fontSize:"16px" }}>
                                            {t("Your ticket status will be pending confirmation until we get")} &nbsp;<br/> {t("confirmation from")} {" "}
                                            <b>{attributes?.reported_by?.full_name}</b>
                                        </Typography>
                                        <br />
                                        <Box className="incident-button-row " style={dashboardProvider.customButton}>
                                            <Button variant="outlined" id="confrimClose"
                                                style={{ width: "100%", border: "1px solid #2c6fed", borderRadius: "10px", color: "#2c6fed", padding: "15px 0",fontWeight:"600" }}
                                                onClick={() => this.updateConfirmClose()}>
                                                {t("Okay")}
                                            </Button>
                                        </Box>
                                    </Box>
                                </DialogContent>
                            </Dialog>
                </GeneralHeader>
                <Loader providerLoading={this.state.providerLoading} />
                <Drawer
                    anchor={languageCondition(language,"left","right")}
                    open={this.state.drawerChat}
                    id="CloseChat"
                    onClose={() => this.setState({ drawerChat: false })}
                >
                    <IncidentChatWeb onClose={() => this.setState({ drawerChat: false })} />
                </Drawer>
                <Dialog
                    className="edit-profile chairman-map-modal"
                    open={this.state.isOpenMapModalOpen}
                    scroll="paper"
                    fullWidth
                    dir={languageCondition(language,"rtl","ltr")}
                    maxWidth="sm"
                >
                    <MuiDialogTitle disableTypography className="dialog-heading">
                        <Typography variant="h6" className="bold-text">
                            {t("Location")}
                        </Typography>
                        <IconButton id="closeLocation" onClick={() => this.setState({isOpenMapModalOpen:false})}>
                            <CloseIcon />
                        </IconButton>
                    </MuiDialogTitle>
                    {attributes?.lat && attributes?.long ? (
                        <Box className="google-map-box">
                            <GoogleMapReact
                                bootstrapURLKeys={{ key: "AIzaSyA1NvS9-cKp1dl_kMQDVFr4Gmbnv97MTtk" }}
                                defaultCenter={{
                                    lat: attributes?.lat,
                                    lng: attributes?.long,
                                }}
                                defaultZoom={14}
                            >
                                <LocationPin lat={attributes?.lat} lng={attributes?.long} />
                            </GoogleMapReact>
                        </Box>
                    ) : (
                        <Box className="no-google-map-box">{t("No Location Available")}</Box>
                    )}
                </Dialog>
                <AlertErrorWeb show={this.state.showError} handleClose={() => this.setState({ showError: false, error: null })} message={this.state.error} />
            </>
        );
    }
}

const dashboardProvider = {
    navigation: {
        display: "flex",
        justifyContent: "space-between",
    },
    subHeading: {
        fontWeight: 600,
        marginTop: 15,
    },
    customButton: {
        padding: "10px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center"
    },
    eachButton: {
        borderRadius: "8px",
        width: "200px",
        marginRight: "15px",
        borderColor:"#2b6fed",
    }
};

const SelectStatus = (props: any) => {

    const checkCl = () => {
        if (props?.statusArray === 'Unresolved') {
            return "incident-details-unresolved";
        } else if (props?.statusArray === 'Resolved') {
            return 'incident-details-pending';
        } else if (props?.statusArray === 'Ongoing') {
            return 'incident-details-ongoing';
        } else if (props?.statusArray === 'Pending Confirmation') {
            return 'incident-details-pending';
        }else {
            return 'incident-details-resolved';
        }
    };

    return (
        <>
            <Box className={checkCl()} style={{borderRadius:"50px"}}>
                    {props?.statusArray == "Closed" ?
                        <Box style={{ fontWeight: "600", padding: "10px 14px"}}>
                            {props.t("Closed")}
                        </Box> : 
                        <Box style={{ fontWeight: "600", padding: "10px 14px"}}>
                            {props.t(props?.statusArray)}
                        </Box>
                    }
            </Box>
        </>
    );
};
// Customizable Area End

export default withTranslation()(withRouter(ServiceProviderManagementDetail));
