// Customizable Area Start
import React from "react";
import { Box, Button, Paper, Typography } from "@material-ui/core";
import { withRouter } from "react-router";
import Loader from "../../../components/src/Loader.web";
import SuggestionController, { Props, SuggestionData } from "./SuggestionController.web";
import { Building1 } from "../../ContentManagement/src/assets";
import { Claender } from "./assets";
import { withTranslation } from "react-i18next";
import { languageCondition } from "../../../components/src/ConditionManager.web";
import moment from "moment";
import SuggestionListing from "./components/SuggestionListing.web";
import { ArrowBack } from "@material-ui/icons";
class Suggestion extends SuggestionController {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount(): any {
    this.getSuggtionListing();
  }

  renderSuggestion(suggestionData: SuggestionData) {
    const { t } = this.props;
    const { id, attributes } = suggestionData;
    const { title, description, created_at, response, society_management: { name } } = attributes;
    return (
      <Paper className="suggestion-card" data-test-id="suggestion-card" key={id} onClick={() => this.openSuggestion(suggestionData)}>
        <Box className="suggestion-card-body">
          <Typography variant="h4" className="suggestion-card-header">{title}</Typography>
          <Typography variant="body2">{description}</Typography>
        </Box>
        <Typography variant="body1" className="suggestion-card-info">
          <span className="building-text">{t("Building")}:</span>
          <span className="building-name">{name}</span>
        </Typography>
        <Box className="suggestion-card-footer">
          <Box className="suggestion-card-date">
            <img src={Claender} alt="calendar-icon" />
            <Typography variant="body1" className="suggestion-card-date-text">{created_at ? moment(created_at).format("DD-MM-YYYY") : "-"}</Typography>
          </Box>
          <Box className="suggestion-card-response-wrapper">
            <Box className={`suggestion-card-response ${(response && response.data.length > 0) ? 'has-response' : 'no-response'}`}>
              <Typography className="response-text">{`${(response && response.data.length > 0) ? response.data.length : 0} ${t("Response")}`}</Typography>
            </Box>
          </Box>
        </Box>
      </Paper>
    )
  }

  render() {
    const { t, i18n } = this.props;
    const language = i18n.language;
    return (
      <>
        <SuggestionListing dir={languageCondition(language, "rtl", "ltr")}>
          <Box className="suggestion-header">
            <Box className="suggestion-header-wrapper">
              <Button className="suggestion-header-button" data-test-id="suggestion-header" onClick={this.redirectToDashboard}>
                <ArrowBack />
              </Button>
              <Typography className="suggestion-header-text">{t("My Suggestions")}</Typography>
            </Box>
          </Box>
          <Box className="suggestion-body">
            <Box className="suggestion-content">
              <Box className="suggestion-list">
                {
                  this.state.suggestionList.length > 0
                    ? (this.state.suggestionList.map((suggestion: SuggestionData) => (this.renderSuggestion(suggestion))))
                    : (
                      <Paper className="suggestion-card">
                        <Box>
                          <Typography className="suggestion-header-empty">{t('No suggestions to show')}</Typography>
                        </Box>
                      </Paper>
                    )
                }
              </Box>
              <Box className="suggestion-cta">
                <Button className="suggestion-add" data-test-id="add-new-suggestion" onClick={this.handleAddNewSuggestion}>
                  {t("Add New Suggestion")}
                </Button>
              </Box>
            </Box>
            <Box className="suggestion-common-image">
              <img src={Building1.default} className="right-image" alt="Tall building with black windows" />
            </Box>
          </Box>
        </SuggestionListing>
        <Loader loading={this.state.loading} />
      </>
    )
  }
}

export default withTranslation()(withRouter(Suggestion));
// Customizable Area End