import React from "react";
// Customizable Area Start
import {
    Box,
    InputAdornment,
    Button,
    TextField,
    Typography,
    Grid,
    CardContent,
    MenuItem,
    Select,
    withStyles,
    Avatar,
    Divider,
    InputBase,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Menu,
    styled,
    TableContainer,
    TableCell,
    Table,
    TableHead,
    TableRow,
    TableBody,
    Popover,
    Card,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import MainScreen from "./Company Employee/MainScreen.web";
import SearchIcon from "@material-ui/icons/Search";
import CECommunityManagementController, {
    Props,
} from "./CECommunityManagementController.web";
import { ReportsStyleWeb } from "../../StoreCredits/src/ReportsStyle.web";
import { chat, deleteIcon, delete_icon, facebook, instagram, message, phone, snap, successIcon, twiter, uploadImage } from "./assets";
export const image_realEstateCompany = require("../assets/pollsurvey.png");
export const image_complexBuilding = require("../assets/B&A.png");
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Formik } from "formik";
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import {languageCondition} from "../../../components/src/ConditionManager.web";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import moment from "moment";

const CustomMenu = styled(Menu)({
    "& .MuiList-padding": {
      paddingBottom: "0px",
    },
  });

  const StyledPopover = styled(Popover)({
    '& .MuiPopover-paper': {
      padding: '0px',
      backgroundColor: 'white',
      boxShadow: 'none',
      border: '1px solid #E2E8F0',
      '& .actionIcon': {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 20,
        paddingRight: 60,
        paddingTop: 10,
        paddingBottom: 10,
        cursor: 'pointer'
      },
    },
  });
  
// Customizable Area End


// Customizable Area Start
export class CECommunityManagement extends CECommunityManagementController {
    constructor(props: Props) {
        super(props);

    }

// Customizable Area End

    // Customizable Area Start
    
    getViewUserProfile = () => {
        const { t }: any = this.props;
        const BoxView = Box as any
        return (
            <BoxView>
                <BoxView style={userProfileView.details}>
                    <Typography variant="h5" className="bold-text">
                        {t("General Details")}
                    </Typography>
                    <CustomMenu
                        anchorEl={this.state.moreAnchorEl}
                        open={Boolean(this.state.moreAnchorEl)}
                        onClose={this.handleClose}
                        data-test-id="moreOptionId"
                        style={{
                            position: "absolute",
                            top: "35px",
                            left: "-35px",
                        }}
                    >
                        <MenuItem
                            data-test-id="handleMenuClick"
                        >
                            Suspend User
                        </MenuItem>
                    </CustomMenu>
                </BoxView>

                <Grid item xs={12} >
                    <BoxView style={userProfileView.viewCard}>
                        <BoxView style={userProfileView.profileImage}>
                            <Avatar alt="Remy Sharp" src="" style={userProfileView.image} />
                            <Typography
                                className="bold-text"
                                style={userProfileView.cardText2}
                                variant="h6"
                            >
                                {"Jk"}
                            </Typography>
                            <Typography variant="h6" style={userProfileView.cardsubtext}>
                            {"A-202"}
                            </Typography>
                            <div> "Admin"  </div>
                            <div style={userProfileView.contactIconmui}>
                                <div style={userProfileView.relatedMembersCard}>
                                    <img style={userProfileView.userImage} src={message} className="admin-user-chat" />
                                    <a  className="admin-user-mail" >
                                        <img src={chat} style={userProfileView.adminUserImage} />
                                    </a>
                                    <a  className="admin-user-phone" data-test-id='nextRef'>
                                        <img src={phone} style={userProfileView.userPhoneImage} />
                                    </a>
                                </div>
                            </div>
                        </BoxView>
                        <Divider orientation="vertical" flexItem />
                        <BoxView style={userProfileView.division}>
                            <Typography style={userProfileView.about}>
                                {t("About")}
                            </Typography>
                            <Typography>
                                {"AAAA"}
                            </Typography>
                            <BoxView style={userProfileView.userDetails}>
                                <BoxView>
                                    <Typography style={userProfileView.status}>{t("Gender")}</Typography>
                                    <Typography> {"Male"}</Typography>
                                </BoxView>
                                <BoxView>
                                    <Typography style={userProfileView.dob}>{t("DOB")}</Typography>
                                    <Typography>{"01/01/0000"}</Typography>
                                </BoxView>
                                <BoxView>
                                    <Typography style={userProfileView.hobbies}>{t("Hobbies")}</Typography>
                                    <Typography>{
                                   "Cricket"
                                    }</Typography>
                                </BoxView>
                            </BoxView>
                            <Typography style={userProfileView.media}>{t("Social Media")}</Typography>
                            <BoxView style={userProfileView.mediaImage}>

                                <Button href={"#"} target="_blank">
                                    <img src={twiter} className="icon" alt="FB_Icon" />
                                </Button>

                                <Button href={"#"} target="_blank">
                                    <img src={instagram} className="icon" alt="FB_Icon" />
                                </Button>

                                <Button href={"#"} target="_blank">
                                    <img src={facebook} className="icon" alt="FB_Icon" />
                                </Button>

                                <Button href={"#"} target="_blank">
                                    <img src={snap} className="icon" alt="FB_Icon" />
                                </Button>
                            </BoxView>
                        </BoxView>
                    </BoxView>
                </Grid>
                <BoxView style={userProfileView.details}>
                    <Typography variant="h5" className="bold-text">
                        {t("Related Units")}
                    </Typography>
                </BoxView>
                <BoxView style={{ margin: "10px 0px 50px" }}>
                </BoxView>
            </BoxView>
        )

    }
    getView = () => {
        const { t }: any = this.props;
        const BoxView = Box as any
        const { userInfo } = this.state;
        return (
            <BoxView>
                <BoxView style={userProfileView.details}>
                    <Typography variant="h5" className="bold-text">
                        {t("General Details")}
                    </Typography>
                    <Typography style={userProfileView.moreDetails} data-test-id="moreOptionText" onClick={this.handleMoreClick} className="bold-text">{t("MORE OPTIONS")}<ExpandMoreIcon /></Typography>
                    <CustomMenu
                        anchorEl={this.state.moreAnchorEl}
                        open={Boolean(this.state.moreAnchorEl)}
                        onClose={this.handleClose}
                        data-test-id="moreOptionId"
                        style={{
                            position: "absolute",
                            top: "35px",
                            left: "-35px",
                        }}
                    >
                        <MenuItem
                            data-test-id="handleMenuClick"
                            onClick={() => this.setState({ suspendUser: true, moreAnchorEl: null })}
                        >
                            Suspend User
                        </MenuItem>
                    </CustomMenu>
                </BoxView>

                <Grid item xs={12} >
                    <BoxView style={userProfileView.viewCard}>
                        <BoxView style={userProfileView.profileImage}>
                            <Avatar alt="Remy Sharp" src="" style={userProfileView.image} />
                            <Typography
                                className="bold-text"
                                style={userProfileView.cardText2}
                                variant="h6"
                            >
                                {userInfo?.attributes?.full_name?.name}
                            </Typography>
                            <Typography variant="h6" style={userProfileView.cardsubtext}>
                                {userInfo?.attributes?.apartment_number?.apartment_number}
                            </Typography>
                            <div>
                                {userInfo?.attributes?.role?.map((item: any) => {
                                    return (
                                        <Grid item className={"ProfileDetailsForUserList"} key={item}>
                                            <Typography variant="h6" style={userProfileView.cardusersType} >
                                                {item.name}
                                            </Typography>
                                        </Grid>
                                    );
                                })}
                            </div>
                            <div style={userProfileView.contactIconmui}>
                                <div style={userProfileView.relatedMembersCard}>
                                    <img
                                        style={userProfileView.userImage}
                                        src={message}
                                        className="admin-user-chat"
                                    />
                                    <a
                                        className="admin-user-mail"
                                    >
                                        <img src={chat} style={userProfileView.adminUserImage} />
                                    </a>
                                    <a
                                        data-test-id='nextRef'
                                        className="admin-user-phone"
                                    >
                                        <img src={phone} style={userProfileView.userPhoneImage} />
                                    </a>
                                </div>
                            </div>
                        </BoxView>
                        <Divider orientation="vertical" flexItem />
                        <BoxView style={userProfileView.division}>
                            <Typography style={userProfileView.about}>{t("About")}</Typography>
                            <Typography>
                                {userInfo?.attributes?.bio?.bio}
                            </Typography>
                            <BoxView style={userProfileView.userDetails}>
                                <BoxView>
                                    <Typography style={userProfileView.status}>{t("Gender")}</Typography>
                                    <Typography>{userInfo?.attributes?.gender?.gender}</Typography>
                                </BoxView>
                                <BoxView>
                                    <Typography style={userProfileView.dob}>{t("DOB")}</Typography>
                                    <Typography>{userInfo?.attributes?.date_of_birth?.date_of_birth}</Typography>
                                </BoxView>
                                <BoxView>
                                    <Typography style={userProfileView.hobbies}>{t("Hobbies")}</Typography>
                                    <Typography>{userInfo?.attributes?.hobbies?.hobbies?.map((item: string) => item).join(', ') || '-'}</Typography>
                                </BoxView>
                            </BoxView>
                            <Typography style={userProfileView.media}>{t("Social Media")}</Typography>
                            <BoxView style={userProfileView.mediaImage}>
                                {this.state.userInfo.attributes?.website?.map((item:any)=> {
                                    return (
                                        <>
                                        {item.twitter_link && (
                                        <Button href={item.twitter_link} target="_blank">
                                            <img src={twiter} className="icon" alt="FB_Icon" />
                                        </Button>
                                    )}
                                    {item.instagram_link && (
                                        <Button href={item.instagram_link} target="_blank">
                                            <img src={instagram} className="icon" alt="FB_Icon" />
                                        </Button>
                                    )}
                                    {item.fb_link && (
                                        <Button href={item.fb_link} target="_blank">
                                            <img src={facebook} className="icon" alt="FB_Icon" />
                                        </Button>
                                    )}

                                    {item.snapchat_link && (
                                        <Button href={item.snapchat_link} target="_blank">
                                            <img src={snap} className="icon" alt="FB_Icon" />
                                        </Button>
                                    )}
                                        </>
                                    )
                                     
                                })}
                               
                                
                                
                                
                            </BoxView>
                        </BoxView>

                    </BoxView>

                </Grid>
                <BoxView style={userProfileView.details}>
                    <Typography variant="h5" className="bold-text">
                        {t("Related People")}
                    </Typography>
                </BoxView>

                <Grid container spacing={2} style={userProfileView.generalCard}>
                    {userInfo?.attributes?.related_people?.length > 0 ? (
                        userInfo?.attributes?.related_people?.map((people: { profile_pic: string | undefined; apartment_name: string; full_name: string; role: [] }) => (
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <BoxView style={dashBoard.card} data-test-id="cardDetailsId">
                                    <Avatar alt="Remy Sharp" src={people?.profile_pic} style={dashBoard.avatar} />
                                    <CardContent
                                        style={dashBoard.cardContent}
                                    >
                                        <Typography
                                            className="bold-text"
                                            style={dashBoard.cardText}
                                            variant="h6"
                                        >
                                            {
                                                people?.apartment_name}
                                        </Typography>

                                        <Typography
                                            variant="h6"
                                            style={{ ...dashBoard.subText, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                                        >
                                            {people.full_name}
                                        </Typography>

                                        <div style={dashBoard.memberText}>
                                            <Typography variant="h6" style={dashBoard.usersType}>
                                                {people?.role}
                                            </Typography>
                                        </div>
                                        <div style={dashBoard.contactIconmui}>
                                            <div style={dashBoard.relatedMembersCard}>
                                                <img
                                                    src={message}
                                                    className="admin-user-chat"
                                                    style={{
                                                        margin: "0 auto",
                                                        width: "40px"
                                                    }}
                                                />
                                                <a
                                                    className="admin-user-mail"
                                                >
                                                    <img src={chat} style={{
                                                        width: "40px",
                                                        margin: "0 auto",
                                                    }} />
                                                </a>
                                                <a
                                                    className="admin-user-phone"
                                                    data-test-id='nextRef'
                                                >
                                                    <img src={phone} style={{
                                                        width: "40px",
                                                        margin: "0 auto"
                                                    }} />
                                                </a>
                                            </div>
                                        </div>
                                    </CardContent>
                                </BoxView>
                            </Grid>))
                    ) : (
                        <Typography style={{ marginLeft: "10px" }}>
                            No related people are added
                        </Typography>
                    )}
                </Grid>

                <BoxView style={userProfileView.details}>
                    <Typography variant="h5" className="bold-text">
                        {t("Related Units")}
                    </Typography>
                    
                    {this.state.selectedTab !== "resident" && <Typography variant="h6" className="bold-text" data-test-id="addAnotherId" style={{ color: 'orange', cursor: "pointer" }} onClick={() => this.handleAddAnother("Add Another Unit",)}>
                        {t("+ ADD ANOTHER UNIT")}

                    </Typography>}
                </BoxView>
                <BoxView style={{ margin: "10px 0px 50px" }}>
                    <BoxView style={dashBoard.gaMemberCard}>

                        {userInfo?.attributes?.related_units !== null ? (
                            userInfo.attributes?.related_units?.map((units: { building_name: string, unit_number: string, user_role: []; }, index: number) => (
                                <Card style={dashBoard.cardStyle} className="admin-user-card">
                                    <CardContent>
                                        <BoxView style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                            <BoxView style={{ width: "90%" }}>
                                                <Typography
                                                    variant="h6"
                                                    className={"bold-text"}
                                                    //@ts-ignore
                                                    style={{ ...dashBoard.relatedUnit, fontSize: "18px" }}
                                                >
                                                    {units.building_name}
                                                    {" Unit "}
                                                    {units.unit_number}
                                                </Typography>
                                            </BoxView>
                                            <BoxView style={{ width: "10%" }} className="admin-profile-de-link-menu">
                                                <MoreVertIcon />
                                            </BoxView>

                                        </BoxView>
                                        <div style={{ marginTop: "5px" }}>
                                            <Typography variant="h6" style={dashBoard.userType}>
                                                {units.user_role.map((item: string) => item).join(', ') || '-'}
                                            </Typography>
                                        </div>
                                    </CardContent>
                                </Card>))
                        ) : (
                            <Typography style={{ marginLeft: "10px" }}>
                                No Related Units are added
                            </Typography>
                        )}
                    </BoxView>
                </BoxView>
                {/* <Grid container spacing={3} xs={12} style={{ marginTop: "15px" }}>
                </Grid> */}

                <BoxView style={userProfileView.details}>
                    <Typography variant="h5" className="bold-text">
                        {t("Active Incidents")}
                    </Typography>
                </BoxView>
                <Grid container spacing={3} xs={12} style={{ marginTop: "15px" }}>
                    {userInfo?.attributes?.incidents?.length > 0 ? (
                        userInfo?.attributes?.incidents.map((item: { incident_title: string, incident_status: string, affected_area: string, incident_related_to: string, reported_on: string, building_name: string, unit_number: string }, index: number) => (
                            <Grid item key={index} xs={6}>
                                <BoxView style={userProfileView.generalCardDetail}>
                                    <BoxView style={userProfileView.generalCardDetailName}>
                                        <Typography variant="h6" className="bold-text">{item?.incident_title}</Typography>
                                        <BoxView style={userProfileView.generalCardDetailoption}>
                                            <Typography className="bold-text" variant="h6" style={dashBoard.usersType}>
                                                {item?.incident_status}
                                            </Typography>
                                        </BoxView>
                                    </BoxView>
                                    <BoxView style={{ marginTop: "15px" }}>
                                        <Typography variant="h6">{t("Affected Area")}:<span style={userProfileView.generalCardData} className="bold-text">{item?.affected_area} </span></Typography>
                                        <Typography variant="h6">{t("Incident is related to")}:<span style={userProfileView.generalCardData} className="bold-text"> {item.incident_related_to}</span></Typography>
                                        <Typography variant="h6">{t("Reported on")}:<span style={userProfileView.generalCardData} className="bold-text"> {moment(item.reported_on).format("MMMM DD,YYYY")}</span></Typography>
                                        <Typography variant="h6">{t("Building")}:<span style={userProfileView.generalCardData} className="bold-text"> {item.building_name}</span></Typography>
                                        <Typography variant="h6">{t("Unit")}:<span style={userProfileView.generalCardData} className="bold-text"> {item.unit_number}</span></Typography>
                                    </BoxView>

                                </BoxView>
                            </Grid>))
                    ) : (
                        <Typography style={{ marginLeft: "10px" }}>
                            No Active Incidents are added
                        </Typography>
                    )}
                </Grid>

                <BoxView style={userProfileView.details}>
                    <Typography variant="h5" className="bold-text">
                        {t("Vehicle Details")}
                    </Typography>
                </BoxView>
                <Grid container xs={12} spacing={3} style={{ marginTop: "10px" }}>
                    {userInfo?.attributes?.vehicles !== null ? (
                        userInfo?.attributes?.vehicles?.map((vehicle: { attributes: {car_color:{title: string}, car_model: { title: string }, owner_name: string, plate_number: string, description: string, building_management: [], apartment_management: { apartment_name: string }, car_manufacturer: { attributes: { image: { url: string } } } } }, index: number) => (
                            <Grid key={index} item xs={6}>
                                <BoxView style={userProfileView.cardMoreData}>
                                    <BoxView style={userProfileView.cardMoreDataName}>
                                        <Typography variant="h6" className="bold-text">{vehicle?.attributes?.car_model?.title}</Typography>
                                    </BoxView>
                                    <BoxView style={{ marginTop: "15px" }}>
                                        <BoxView style={userProfileView.cardMoreDataDetails}>
                                            <img src={vehicle?.attributes?.car_manufacturer?.attributes?.image?.url} width={105} height={70} />
                                        </BoxView>
                                        <Typography variant="h6">{t("Owner Name")}:<span style={userProfileView.cardMoreDataDetailsName} className="bold-text">{vehicle.attributes.owner_name}</span></Typography>
                                        <Typography variant="h6">{t("Registration Card Number")}:<span style={userProfileView.cardMoreDataDetailsName} className="bold-text"> {vehicle.attributes.plate_number}</span></Typography>
                                        <Typography variant="h6">{t("Car Details")}:<span style={userProfileView.cardMoreDataDetailsName} className="bold-text"> {vehicle.attributes.car_model.title}-{vehicle.attributes.car_color.title}</span></Typography>
                                        <Typography variant="h6">{t("Building")}:<span style={userProfileView.cardMoreDataDetailsName} className="bold-text"> {vehicle?.attributes?.building_management?.map((item: any) => item.name).join(', ') || '-'}</span></Typography>
                                        <Typography variant="h6">{t("Unit")}:<span style={userProfileView.cardMoreDataDetailsName} className="bold-text">{vehicle?.attributes?.apartment_management?.apartment_name}</span></Typography>
                                    </BoxView>

                                </BoxView>
                            </Grid>
                        ))
                    ) : (
                        <Typography style={{ marginLeft: "10px" }}>
                            No Vehicle Details are added
                        </Typography>
                    )
                    }</Grid>

                <BoxView style={userProfileView.details}>
                    <Typography variant="h5" className="bold-text">
                        {t(" Unanswered Suggestion")}
                    </Typography>
                </BoxView>
                <Grid container xs={12} spacing={3} style={{ marginTop: "10px" }}>
                    {userInfo?.attributes?.suggestions?.length > 0 ? (
                        userInfo?.attributes?.suggestions.map((suggestion: { status: string, title: string, related_to: string, description: string, created_at: string }, index: number) => (
                            <Grid key={index} item xs={6}>
                                <BoxView style={userProfileView.AdminData}>
                                    <BoxView style={userProfileView.AdminDataName}>
                                        <Typography variant="h6" className="bold-text">{suggestion?.title}</Typography>
                                        <BoxView style={userProfileView.AdminDataWait}>
                                            <Typography className="bold-text" variant="h6" style={dashBoard.usersType}>
                                                {suggestion?.status}
                                            </Typography>
                                        </BoxView>
                                    </BoxView>
                                    <BoxView style={{ marginTop: "15px" }}>
                                        <Typography variant="h6">{t("Related to")}:<span style={userProfileView.AdminDataDetails} className="bold-text">{suggestion.related_to}</span></Typography>
                                        <Typography variant="h6">{t("Desc")}:<span style={userProfileView.AdminDataDetails} className="bold-text"> {suggestion.description}</span></Typography>
                                        <Typography variant="h6">{t("Sent on")}:<span style={userProfileView.AdminDataDetails} className="bold-text"> {moment(suggestion.created_at).format("DD MMM YYYY hh:mm a")}</span></Typography>
                                    </BoxView>

                                </BoxView>
                            </Grid>
                        ))
                    ) : (
                        <Typography style={{ marginLeft: "10px" }}>
                            No  Unanswered Suggestion are added
                        </Typography>
                    )}
                </Grid>

            </BoxView>
        )

    }

    getAddUnit = () => {
        const BoxView = Box as any
        const { t }: any = this.props;   
        return (
            <BoxView>
                <BoxView>
                    <Formik
                        initialValues={{ email: '', conf_pass: '', vis1: false, vis2: false }}
                        data-test-id="Formik"
                        onSubmit={() => {

                        }}
                        validateOnChange={true}
                        validateOnBlur={true}
                    >
                        {(props) => (
                            <BoxView style={{ background: '#F4F7FF'}}>
                                <BoxView style={addAnotherUnit.container}>
                                    <Grid container xs={12} spacing={3}>
                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                            <BoxView>
                                                <Typography style={addAnotherUnit.selectText}>{t("Select Country")}</Typography>
                                                <Select
                                                    displayEmpty
                                                    disableUnderline
                                                    style={addAnotherUnit.selectmenu}
                                                    data-test-id="selectCountryId"
                                                    placeholder="Select Country"
                                                    value={this.state.selectedCountry}
                                                    className="select-input selectFloorChairmanInvoices"
                                                    onChange={(event) => this.handleSelectCountryChange(event)}
                                                    id="select-Country"
                                                >
                                                    <MenuItem
                                                        disabled
                                                        value=""
                                                        id="selectStatusBudgetReport"
                                                    >
                                                        {t("Select Country")}
                                                    </MenuItem>
                                                    {this.state.selectCountry?.length &&
                                                        this.state.selectCountry?.map((item: string) => {
                                                            return (
                                                                <MenuItem key={item} value={item}>
                                                                    {item}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    <MenuItem
                                                        id="selectStatusBudgetReportAll"
                                                        value="All"
                                                    >
                                                        {t("All")}
                                                    </MenuItem>
                                                </Select>
                                            </BoxView>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                            <Typography style={addAnotherUnit.selectText}>{t("Select City")}</Typography>
                                            <Select displayEmpty
                                                disableUnderline
                                                data-test-id="selectCityId"
                                                value={this.state.selectedCity}
                                                style={addAnotherUnit.selectmenu}
                                                className="select-input selectUnitChairmanInvoices complexS"
                                                onChange={(event) => this.handleSelectCityChange(event)}
                                                id="select-city1"
                                            >
                                                <MenuItem
                                                    value=""
                                                    disabled
                                                    id="selectStatusBudgetReport"
                                                >
                                                    {t("Select City")}
                                                </MenuItem>
                                                {this.state.selectCity?.length &&
                                                    this.state.selectCity?.map((item) => {
                                                        return (
                                                            <MenuItem key={item.id} value={item.name}>
                                                                {item.name}
                                                            </MenuItem>
                                                        );
                                                    })}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                            <Typography style={addAnotherUnit.selectText}>{t("Complex/Individual Buildings")}</Typography>
                                            <Select
                                                displayEmpty
                                                disableUnderline
                                                style={addAnotherUnit.selectmenu}
                                                id="select-city1"
                                                className="select-input selectFloorChairmanInvoices"
                                                data-test-id="buildingsdropId"
                                                value={this.state.individualbuilding}
                                                onChange={(event) => this.handleCountryFilterChange(event)}
                                            >
                                                <MenuItem disabled value="" id="selectStatusBudgetReport">
                                                    {t("Complex/Individual Buildings")}
                                                </MenuItem>
                                                <MenuItem value="Complex" id="selectStatusBudgetReport">
                                                    {t("Complex")}
                                                </MenuItem>
                                                <MenuItem value="Individualbuilding" id="selectStatusBudgetReportAll">
                                                    {t("Individual building")}
                                                </MenuItem>
                                            </Select>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                            <Typography style={addAnotherUnit.selectText}>{t("Select Complex")}</Typography>
                                            <Select displayEmpty
                                                disableUnderline
                                                value={this.state.selectedComplex}
                                                onChange={(event) => this.handleSelectComplexChange(event)}
                                                id="select-city1"
                                                style={addAnotherUnit.selectmenu}
                                                className="select-input selectUnitChairmanInvoices complexS"
                                                data-test-id="selectComplexId"
                                            >
                                                <MenuItem
                                                    value=""
                                                    id="selectStatusBudgetReport"
                                                    disabled
                                                >
                                                    {t("Select Complex")}
                                                </MenuItem>
                                                {this.state.selectComplex.length > 0 &&
                                                    this.state.selectComplex?.map((item: any) => {
                                                        return (
                                                            <MenuItem key={item} value={item.id}>
                                                                {item.name}
                                                            </MenuItem>
                                                        );
                                                    })}
                                            </Select>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                            <Typography style={addAnotherUnit.selectText}>{t("Select Building")}</Typography>
                                            <Select displayEmpty
                                                disableUnderline
                                                id="selectBuilding"
                                                value={this.state.selectedBuilding}
                                                className="select-input invoiceTypeFromChairmanInvoices"
                                                style={addAnotherUnit.selectmenu}
                                                data-test-id="slctBuildingId"
                                                onChange={(event) => this.handleSelectBuildingChange(event)}
                                            >
                                                <MenuItem
                                                    value=""
                                                    disabled
                                                    id="selectStatusBudgetReport"
                                                >
                                                    {t("Select Building")}
                                                </MenuItem>
                                                {this.state.selectBuilding?.length > 0 &&
                                                    this.state.selectBuilding?.map((item: any) => {
                                                        return (
                                                            <MenuItem key={item} value={item.id}>
                                                                {item.name}
                                                            </MenuItem>
                                                        );
                                                    })}
                                            </Select>
                                        </Grid>
                                    </Grid>
                                </BoxView>

                                <BoxView style={addAnotherUnit.subcontainer}>
                                    <Grid container xs={12} spacing={3}>
                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                            <BoxView>
                                                <Typography style={addAnotherUnit.selectText}>{t("Unit Number")}</Typography>

                                                <InputBase
                                                    style={addAnotherUnit.selectmenu}
                                                    data-test-id="unitNumberId"
                                                    value={this.state.unitNumber}
                                                    onChange={(event) => this.handleUnitNumber(event)}
                                                />
                                            </BoxView>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                            <Typography style={addAnotherUnit.selectText}>{t("Floor Number")}</Typography>

                                            <InputBase
                                                style={addAnotherUnit.selectmenu}
                                                onChange={(event) => this.handleFloorNumber(event)}
                                                data-test-id="floorNumberId"
                                                value={this.state.floorNumber}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                            <Typography style={addAnotherUnit.selectText}>{t("Owner ID")}</Typography>
                                            <InputBase
                                                style={addAnotherUnit.selectmenu}
                                                onChange={(event) => this.handleOwnerID(event)}
                                                data-test-id="ownerID"
                                                value={this.state.userInfo?.attributes?.id}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                            <Typography style={addAnotherUnit.selectText}>{t("Owner Name")}</Typography>
                                            <InputBase
                                                data-test-id="ownerNameId"
                                                style={addAnotherUnit.textName}
                                                value={this.state.userInfo?.attributes?.full_name?.name}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                            <Typography style={addAnotherUnit.selectText}>{t("Unit Status")}</Typography>
                                            <Select displayEmpty
                                                disableUnderline
                                                value={this.state.unitStatu}
                                                className="select-input invoiceTypeFromChairmanInvoices"
                                                style={addAnotherUnit.selectmenu}
                                                id="buildingId"
                                                onChange={(event) => this.handleUnitStatu(event)}
                                                data-test-id="buildingId"
                                            >
                                                <MenuItem
                                                    value=""
                                                    id="selectStatusBudgetReport"
                                                    disabled
                                                >
                                                    {t("Select Unit Status")}
                                                </MenuItem>
                                                <MenuItem
                                                    value="Rented"
                                                    id="selectStatusBudgetReport"
                                                >
                                                    {t("Rented")}
                                                </MenuItem>
                                                <MenuItem
                                                    value="Non-Rented"
                                                    id="selectStatusBudgetReport"
                                                >
                                                    {t("Non-Rented")}
                                                </MenuItem>

                                            </Select>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                            <Typography style={addAnotherUnit.selectText}>{t("Size")}</Typography>
                                            <InputBase
                                                style={addAnotherUnit.selectmenu}
                                                placeholder={t("Size")}
                                                endAdornment={<InputAdornment position="end" className="bold-text">Sqft</InputAdornment>}
                                                onChange={(event) => this.handleSize(event)}
                                                data-test-id="sizeid"
                                                value={this.state.size}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                            <Typography style={addAnotherUnit.selectText}>{t("Configuration")}{t("(optional)")}</Typography>
                                            <InputBase
                                                style={addAnotherUnit.selectmenu}
                                                placeholder={t("Configuration")}
                                                onChange={(event) => this.handleConfiguration(event)}
                                                data-test-id="configurationid"
                                                value={this.state.configuration}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                            <Typography style={addAnotherUnit.selectText}>{t("Purchase Price")} {t("(optional)")}</Typography>
                                            <InputBase
                                                style={addAnotherUnit.selectmenu}
                                                placeholder={t("Purchase Price")}
                                                onChange={(event) => this.handlePurchasePrice(event)}
                                                data-test-id="handlePurchasePriceid"
                                                value={this.state.purchasePrice}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                            <Typography style={addAnotherUnit.selectText}>{t("Purchase Date")} {t("(optional)")}</Typography>
                                            <InputBase
                                                style={addAnotherUnit.selectmenu}
                                                type="date"
                                                placeholder={t("Purchase Date")}
                                                onChange={(event) => this.handlePurchaseDate(event)}
                                                data-test-id="handlePurchaseDateid"
                                                value={this.state.purchaseDate}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12} lg={12} >
                                            <Typography style={addAnotherUnit.selectText}>{t("Current Valuation")} {t("(optional)")}</Typography>
                                            <InputBase
                                                style={addAnotherUnit.selectmenu}
                                                placeholder={t("Current Valuation")}
                                                onChange={(event) => this.handleCurrentValuation(event)}
                                                data-test-id="handleCurrentValuationid"
                                                value={this.state.crrentValuation}
                                            />
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <Typography style={addAnotherUnit.selectText}>{t("Upload Photos")} {t("(optional)")}</Typography>
                                            </Grid>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={6} md={3} lg={3}>
                                                    <div>
                                                        <input
                                                            id="fileInput"
                                                            type="file"
                                                            style={{ display: "none" }}
                                                            onChange={this.handleMultiImageChange}
                                                            data-test-id="multiImageChangeId"
                                                        />
                                                        <label htmlFor="fileInput">
                                                            <BoxView style={ addAnotherUnit.multiImage}>
                                                               <img src={uploadImage}/>
                                                            </BoxView>
                                                        </label>
                                                    </div>
                                                </Grid>
                                                {this.state.selectedImages.map((image, index) => (
                                                    <Grid item xs={12} sm={6} md={3} lg={3} key={index}>

                                                        <BoxView style={{
                                                            position: "relative",
                                                            width: "200px",
                                                            height: "120px"
                                                        }}>
                                                            <img src={image} alt={`Selected Image ${index + 1}`} style={{ width: '200px', height: '120px' }} />
                                                            <img src={deleteIcon} onClick={() => this.handleDeleteImage(index)} height={30} style={{
                                                                position: "absolute",
                                                                top: 0,
                                                                left: "165px",
                                                                width: "100 %",
                                                                height: "100 %",
                                                                cursor: "pointer"
                                                            }} />
                                                        </BoxView>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <BoxView style={userProfileView.details}>
                                        <Typography variant="h5" className="bold-text" style={{marginBottom:"20px"}}>
                                            {t("Family Members")} <span>{t("(optional)")}</span>
                                        </Typography>
                                        <BoxView style={userProfileView.moreoptionbox}>
                                            <Typography variant="h6" data-test-id="AddId" className="bold-text" style={userProfileView.moreDetails} onClick={() => this.setState({ open: true })}>
                                                + {t("Add")}
                                            </Typography>
                                        </BoxView>
                                    </BoxView>
                                    <Grid container xs={12} spacing={3}>
                                        {this.state?.userInfo?.attributes?.families?.families?.map((item: {
                                            attributes: {
                                                id_number: string,
                                                relation: {name: string},
                                                name:string
                                            }
                                        }, index: number) => {
                                            return (
                                                <Grid key={index} item xs={12} sm={12} md={6} lg={6}>
                                                    <BoxView style={userProfileView.famaliyCard}>
                                                        <BoxView style={userProfileView.famaliyBox}>
                                                            <Typography>{item?.attributes?.name}</Typography>
                                                            <div>
                                                                <Button
                                                                    aria-controls="simple-menu"
                                                                    aria-haspopup="true"
                                                                    onClick={(event) => this.handleClick(event, item.attributes)}
                                                                    data-test-id="modelId"
                                                                >
                                                                    <MoreVertIcon />
                                                                </Button>
                                                                <Menu
                                                                    id="simple-menu"
                                                                    anchorEl={this.state.anchorEl}
                                                                    keepMounted
                                                                    open={Boolean(this.state.anchorEl)}
                                                                    onClose={this.handleClose}
                                                                    data-test-id="menuItemId"
                                                                >
                                                                    <MenuItem data-test-id="editButtonId" onClick={this.handleEditData}>{t("Edit")}</MenuItem>
                                                                    <MenuItem data-test-id="DeleteButtonId" onClick={() => this.deleteFamilyApi()}>{t("Delete")}</MenuItem>
                                                                </Menu>
                                                            </div>
                                                        </BoxView>
                                                        <BoxView style={{
                                                            width: "100%",
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                        }}>
                                                            <BoxView style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                                                                <Typography style={{ color: "gray" }}>Relation:</Typography>
                                                                <Typography>{item?.attributes?.relation?.name}</Typography>
                                                            </BoxView>
                                                            <Typography style={{ display: "flex", flexDirection: "column", justifyContent: "end" }}>GCC - {item?.attributes?.id_number}</Typography>
                                                        </BoxView>
                                                    </BoxView>
                                                </Grid>
                                            )
                                        })}

                                    </Grid>
                                    <Typography variant="h5" className="bold-text" style={{ marginBottom: "20px" }}>
                                        {t("Rent Status")}
                                    </Typography>
                                    <Grid container xs={12} spacing={3}>
                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                            <Typography style={addAnotherUnit.selectText}>{t("Unit Status")}</Typography>
                                            <Select displayEmpty
                                                disableUnderline
                                                value={this.state.rentStatu}
                                                className="select-input invoiceTypeFromChairmanInvoices"
                                                style={addAnotherUnit.selectmenu}
                                                id="buildingId"
                                                onChange={(event) => this.handleRentStatu(event)}
                                                data-test-id="RentStatusid"
                                            >
                                                <MenuItem
                                                    value=""
                                                    id="selectStatusBudgetReport"
                                                    disabled
                                                >
                                                    {t("Select Rent Status")}
                                                </MenuItem>
                                                <MenuItem
                                                    value="Rented"
                                                    id="selectStatusBudgetReport"
                                                >
                                                    {t("Rented")}
                                                </MenuItem>
                                                <MenuItem
                                                    value="Non-Rented"
                                                    id="selectStatusBudgetReport"
                                                >
                                                    {t("Non-Rented")}
                                                </MenuItem>

                                            </Select>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                            <Typography style={addAnotherUnit.selectText}>{t("Tenant Name")}</Typography>

                                            <Typography
                                                style={addAnotherUnit.TenantName}
                                                data-test-id="tenantName"
                                                onClick={()=>this.setState({addTenant:true})}
                                            >
                                               {
                                                this.state.isSelect?this.state.findPerson.attributes.full_name:
                                                "+ Add Tenant"
                                               }
                                                
                                                </Typography>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                            <Typography style={addAnotherUnit.selectText}>{t("Rent Amount")}</Typography>

                                            <InputBase
                                                style={addAnotherUnit.selectmenu}
                                                placeholder="SR Rent Amount (per Month)"
                                                data-test-id="RentAmountId"
                                                onChange={(event)=> this.handleRentAmount(event)}
                                                value={this.state.rentAmount}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={3} lg={3}>
                                            <Typography style={addAnotherUnit.selectText}>{t("Rent Tenure")}</Typography>

                                            <InputBase
                                                style={addAnotherUnit.selectmenu}
                                                type="date"
                                                placeholder="Purchase Date"
                                                onChange={(event) => this.handleFromDate(event)}
                                                data-test-id="RentTenureId"
                                                value={this.state.fromDate}
                                            />
                                        </Grid>

                                        <Grid style={{display:'flex',flexDirection:"column",justifyContent:"end"}} item xs={12} sm={12} md={3} lg={3}>
                                        <InputBase
                                                style={addAnotherUnit.selectmenu}
                                                type="date"
                                                placeholder="Purchase Date"
                                                onChange={(event) => this.handleToDate(event)}
                                                data-test-id="RentTenureToId"
                                                value={this.state.toDate}
                                            />
                                        </Grid>
                                    </Grid>

                                </BoxView>

                            </BoxView>
                        )}
                    </Formik>

                </BoxView>
                <BoxView style={addAnotherUnit.buttonBox}>
                    <Button variant="outlined" style={addAnotherUnit.buttons}>
                        {t("CANCEL")}
                    </Button>
                    <Button variant="contained" style={addAnotherUnit.Approvalbuttons} data-test-id="handleSubmitid" onClick={()=>this.handleSubmit()} >
                        {t("SUBMIT FOR APPROVAL")}
                    </Button>
                </BoxView>
            </BoxView>
        )
    }

    getColor = (selectedUnit: string) => {
        return selectedUnit ? "#000" : "darkgray"
    }

    getcurentTabGallery = (t:any, BoxView:any) => (
        <div>
            <div className="top-bar ChairmanInvoices" style={{ display: "flex" }}>
                <div className="filter" style={{ display: "flex", flexWrap: "wrap" }}>
                    <Select
                        displayEmpty
                        style={{ width: "auto", color: this.getColor(this.state.selectedCompany) }}
                        value={this.state.selectedCompany}
                        id="select-Company"
                        data-test-id="selectCompanyId"
                        className="select-input selectUnitChairmanInvoices complexS"
                        onChange={(event) => this.handleSelectCompanyChange(event)}
                    >
                        <MenuItem value="" id="select-Company ">
                            {t("Select Company")}
                        </MenuItem>
                        <MenuItem value="all" id="select-Company ">
                            {t("All")}
                        </MenuItem>
                        {this.state.selectCompany?.length &&
                            this.state.selectCompany?.map((item: any, value: any) => {
                                return (
                                    <MenuItem key={item.company_name} value={value}>
                                        {item.company_name}
                                    </MenuItem>
                                );
                            })}
                    </Select>
                    <Select
                        displayEmpty
                        style={{ width: "auto", color: this.getColor(this.state.individualbuilding) }}
                        value={this.state.individualbuilding}
                        id="select-city1"
                        data-test-id="countryFilter"
                        className="select-input selectFloorChairmanInvoices"
                        onChange={(event) => this.handleCountryFilterChange(event)}
                    >
                        <MenuItem disabled value="" id="selectStatusBudgetReport">
                            {t("Select Complex / Building")}
                        </MenuItem>
                        <MenuItem value="Complex" id="selectStatusBudgetReport">
                            {t("Complex")}
                        </MenuItem>
                        <MenuItem value="Individualbuilding" id="selectStatusBudgetReport">
                            {t("Individual building")}
                        </MenuItem>
                    </Select>
                    <Select
                        displayEmpty
                        style={{ width: "auto", color: this.getColor(this.state.selectedCountry) }}
                        placeholder="Select Country"
                        value={this.state.selectedCountry}
                        id="select-Country"
                        data-test-id="complexIndividualbuilding"
                        className="select-input selectFloorChairmanInvoices"
                        onChange={(event) => this.handleSelectCountryChange(event)}
                    >
                        <MenuItem
                            disabled
                            value=""
                            id="selectStatusBudgetReport"
                        >
                            {t("Select Country")}
                        </MenuItem>
                        <MenuItem
                            value="All"
                        >
                            {t("All")}
                        </MenuItem>
                        {this.state.selectCountry.length &&
                            this.state.selectCountry.map((item: string) => {
                                return (
                                    <MenuItem key={item} value={item}>
                                        {item}
                                    </MenuItem>
                                );
                            })}
                    </Select>
                    <Select displayEmpty
                        value={this.state.selectedCity}
                        style={{ width: "auto", color: this.getColor(this.state.selectedCity) }}
                        id="select-city1"
                        data-test-id="selectCity"
                        className="select-input selectUnitChairmanInvoices complexS"
                        onChange={(event) => this.handleSelectCityChange(event)}
                    >
                        <MenuItem
                            value=""
                            id="selectStatusBudgetReport"
                            disabled
                        >
                            {t("Select City")}
                        </MenuItem>
                        <MenuItem value="all" id="select-Company ">
                            {t("All")}
                        </MenuItem>
                        {this.state.selectCity.length &&
                            this.state.selectCity.map((item) => {
                                return (
                                    <MenuItem key={item.name} value={item.name}>
                                        {item.name}
                                    </MenuItem>
                                );
                            })}
                    </Select>
                    {this.state.individualbuilding === "Complex" && <Select displayEmpty
                        value={this.state.selectedComplex}
                        style={{ width: "auto", color: this.getColor(this.state.selectedComplex) }}
                        id="select-city1"
                        className="select-input selectUnitChairmanInvoices complexS"
                        data-test-id="selectComplex"
                        onChange={(event) => this.handleSelectComplexChange(event)}
                    >
                        <MenuItem
                            value=""
                            id="selectStatusBudgetReport"
                            disabled
                        >
                            {t("Select Complex")}
                        </MenuItem>
                        <MenuItem value="all" id="select-Company ">
                            {t("All")}
                        </MenuItem>
                        {this.state.selectComplex.length > 0 &&
                            this.state.selectComplex.map((item: any) => {
                                return (
                                    <MenuItem key={item} value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                );
                            })}
                    </Select>}
                    <Select displayEmpty
                        value={this.state.selectedBuilding}
                        className="select-input invoiceTypeFromChairmanInvoices"
                        style={{ width: "auto", color: this.getColor(this.state.selectedBuilding) }}
                        id="selectBuilding"
                        onChange={(event) => this.handleSelectBuildingChange(event)}
                        data-test-id="slctBuilding"
                    >
                        <MenuItem
                            value=""
                            id="selectStatusBudgetReport"
                            disabled
                        >
                            {t("Select Building")}
                        </MenuItem>
                        <MenuItem value="all" id="select-Company ">
                            {t("All")}
                        </MenuItem>
                        {this.state.selectBuilding?.length > 0 &&
                            this.state.selectBuilding?.map((item: any) => {
                                return (
                                    <MenuItem key={item} value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                );
                            })}
                    </Select>
                    <Select displayEmpty
                        style={{ width: "auto", color: this.getColor(this.state.selectedUnit) }}
                        data-test-id="slctUnit"
                        onChange={(event) => this.handleSelectUnitChange(event)}
                        className="select-input invoiceTypeFromChairmanInvoices unitSelect"
                        value={this.state.selectedUnit}
                    >
                        <MenuItem
                            id="selectStatusBudgetReport"
                            value=""
                            disabled
                        >
                            {t("Select Unit")}
                        </MenuItem>
                        <MenuItem value="all" id="select-Company ">
                            {t("All")}
                        </MenuItem>
                        {this.state.selectUnit.length &&
                            this.state.selectUnit?.map((item: any) => {
                                return (
                                    <MenuItem key={item.apartment_name} value={item.apartment_name}>
                                        {item.apartment_name}
                                    </MenuItem>
                                );
                            })}
                    </Select>
                    <Select displayEmpty
                        value={this.state.selectUserType}
                        disableUnderline
                        data-test-id="slctUserType"
                        style={{ width: "auto", color: this.getColor(this.state.selectUserType) }}
                        className="select-input schedule-meeting-filter-status"
                        onChange={(event) => this.handleSelectUserTypeChange(event)}
                    >
                        <MenuItem
                            id="selectStatusBudgetReport"
                            value=""
                            disabled
                        >
                            {t("Select User Type")}
                        </MenuItem>
                        <MenuItem value="all" id="select-Company ">
                            {t("All")}
                        </MenuItem>
                        <MenuItem
                            id="selectStatusBudgetReportAll"
                            value="ga_member"
                        >
                            {t("ga_member")}
                        </MenuItem>GA Members
                        <MenuItem
                            id="selectStatusBudgetReportAll"
                            value="resident"
                        >
                            {t("resident")}
                        </MenuItem>
                        <MenuItem
                            id="selectStatusBudgetReportAll"
                            value="property_manager"
                        >
                            {t("property manager")}
                        </MenuItem>
                    </Select>
                </div>

            </div>
            <Grid>
                <BoxView style={dashBoard.searchButton}>
                    <BoxView>
                        <Button
                            variant="contained"
                            className="btnMy"
                            data-test-id="saveButton"
                            style={dashBoard.mybtn}
                            onClick={() => this.handleSearch()}
                        >
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                            {t("Search")}
                        </Button>
                    </BoxView>
                    <BoxView>
                        <TextField
                            className="input-with-icon-textfield-for-search-userProfile placeholderForClassifiedSearch"
                            style={dashBoard.searchButtons}
                            variant="outlined"
                            placeholder={t("Search by Name or ID")}
                            data-test-id="filterId"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon style={{ color: "gray" }} />
                                    </InputAdornment>
                                ),
                            }}
                            onChange={(event: any) => this.handleFilter(event.target.value)}
                        />
                    </BoxView>
                </BoxView>
            </Grid>
            {!this.state.ViewAll && <BoxView style={dashBoard.mainText} >
                <Typography
                    className="bold-text"
                    style={dashBoard.cardMainText}
                    variant="h6"
                >
                    {t("GA Members")}
                </Typography>
                <Typography
                    style={dashBoard.viewAll}
                    data-test-id="viewAllId"
                    onClick={() => this.handkeViewAll(this.state.userProfile?.ga_member?.data, "ga_member", "GA Members")}
                >{t("View All")}</Typography>
            </BoxView>}
            <BoxView style={{ display: "flex", justifyContent: "space-between", marginTop: this.state.ViewAll ? "40px" : "0" }}>
                <Grid container spacing={3}>
                    {this.state.userProfile?.ga_member?.data.slice(0, this.state.sliceLength)?.map((item: any) => {
                        return (
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <BoxView style={dashBoard.card} data-test-id="cardDetailsId" onClick={() => this.handleCard(item, item.attributes.full_name.name , "ga_member")}>
                                    <Avatar alt="Remy Sharp" src={item?.attributes?.profile_pic} style={dashBoard.avatar} />
                                    <CardContent
                                        style={dashBoard.cardContent}
                                    >
                                        <Typography
                                            className="bold-text"
                                            style={dashBoard.cardText}
                                            variant="h6"
                                        >
                                            {
                                                item?.attributes?.apartment_number?.apartment_number}
                                        </Typography>

                                        <Typography
                                            variant="h6"
                                            style={{ ...dashBoard.subText, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                                        >
                                            {item.attributes.full_name.name}
                                        </Typography>

                                        <div style={dashBoard.memberText}>
                                            <Typography variant="h6" style={dashBoard.usersType}>
                                                GA - Member
                                            </Typography>
                                        </div>
                                        <div style={dashBoard.contactIconmui}>
                                            <div style={dashBoard.relatedMembersCard}>
                                                <img
                                                    style={{ margin: "0 auto", width: "40px" }}
                                                    src={message}
                                                    className="admin-user-chat"
                                                />
                                                <a
                                                    className="admin-user-mail"
                                                    
                                                >
                                                    <img src={chat} style={{ margin: "0 auto", width: "40px" }} />
                                                </a>
                                                <a
                                                    data-test-id='nextRef'
                                                    className="admin-user-phone"
                                                >
                                                    <img src={phone} style={{ width: "40px", margin: "0 auto" }} />
                                                </a>
                                            </div>
                                        </div>
                                    </CardContent>
                                </BoxView>
                            </Grid>
                        )
                    })}
                </Grid>
            </BoxView>

            {!this.state.ViewAll && <BoxView style={dashBoard.mainText}>
                <Typography
                    style={dashBoard.cardMainText}
                    variant="h6"
                    className="bold-text"
                >
                    {t("Residents")}
                </Typography>
                <Typography style={dashBoard.viewAll} data-test-id="residentsId" onClick={() => this.handkeViewAll(this.state.userProfile?.resident?.data, "resident", "Residents")} >{t("View All")}</Typography>
            </BoxView>}
            <BoxView style={{ display: "flex", justifyContent: "space-between" }}>
                <Grid container spacing={3}>
                    {this.state.userProfile && this.state.userProfile.resident && this.state.userProfile.resident.data && this.state.userProfile.resident.data.slice(0, this.state.sliceLength).map((item: any) => {
                        return (
                            <Grid item xs={12} md={3} lg={3} >
                                <BoxView style={dashBoard.card} id="princeCardId" data-test-id="residentsCardId" onClick={() => this.handleCard(item, item.attributes?.full_name.name , "resident")}>
                                    <Avatar alt="Remy Sharp" src={item.attributes?.profile_pic} style={dashBoard.avatar} />
                                    <CardContent
                                        style={dashBoard.cardContent}
                                    >
                                        <Typography
                                            variant="h6"
                                            className="bold-text"
                                            style={dashBoard.cardText}
                                        >
                                            {item.attributes?.apartment_number.apartment_number}
                                        </Typography>

                                        <Typography
                                            variant="h6"
                                            style={{ ...dashBoard.subText, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                                        >
                                            {item.attributes?.full_name?.name}
                                        </Typography>
                                        <div style={dashBoard.memberText}>
                                            <Typography variant="h6" style={dashBoard.usersType}>
                                                Resident
                                            </Typography>
                                        </div>
                                        <div style={dashBoard.contactIconmui}>
                                            <div style={dashBoard.relatedMembersCard}>
                                                <img
                                                    style={{ width: "40px", margin: "0 auto" }}
                                                    className="admin-user-chat"
                                                    src={message}
                                                />
                                                <a
                                                    className="admin-user-mail"
                                                >
                                                    <img src={chat} style={{ width: "40px", margin: "0 auto" }} />
                                                </a>
                                                <a
                                                    className="admin-user-phone"
                                                    data-test-id='nextRef'
                                                >
                                                    <img src={phone} style={{ width: "40px", margin: "0 auto" }} />
                                                </a>
                                            </div>
                                        </div>
                                    </CardContent>
                                </BoxView>
                            </Grid>
                        )
                    })}
                </Grid>
            </BoxView>

            {!this.state.ViewAll && <BoxView style={dashBoard.mainText}>
                <Typography
                    style={dashBoard.cardMainText}
                    className="bold-text"
                    variant="h6"
                >
                    {t("Property Manager")}
                </Typography>
                <Typography style={dashBoard.viewAll} data-test-id="propertyManagerId" onClick={() => this.handkeViewAll(this.state.userProfile?.property_manager?.data, "property_manager", "Property Manager")} >{t("View All")}</Typography>
            </BoxView>}
            <BoxView style={{ display: "flex", gap: "20px" }}>
                <Grid container spacing={3} data-test-id="gridId">
                    {this.state.userProfile && this.state.userProfile.property_manager && this.state.userProfile.property_manager.data && this.state.userProfile.property_manager.data.slice(0, this.state.sliceLength).map((item: any) => {
                        return (
                            <Grid item xs={12} md={3} lg={3} data-test-id="gridId22">
                                <BoxView style={dashBoard.card} data-test-id="propertyManagerCardId" onClick={() => this.handleCard(item, item.attributes.full_name.name ,"property_manager")}>
                                    <Avatar alt="Remy Sharp" src={item.attributes.profile_pic} style={dashBoard.avatar} />
                                    <CardContent
                                        style={dashBoard.cardContent}
                                    >
                                        <Typography
                                            className="bold-text"
                                            variant="h6"
                                            style={dashBoard.cardText}
                                        >
                                            {item.attributes.apartment_number.apartment_number}
                                        </Typography>

                                        <Typography
                                            variant="h6"
                                            style={{ ...dashBoard.subText, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                                        >
                                            {item.attributes.full_name.name}
                                        </Typography>

                                        <div style={dashBoard.memberText}>
                                            <Typography variant="h6" style={dashBoard.usersType}>
                                                Property Manager
                                            </Typography>
                                        </div>
                                        <div style={dashBoard.contactIconmui}>
                                            <div style={dashBoard.relatedMembersCard}>
                                                <img
                                                    style={{ width: "40px", margin: "0 auto" }}
                                                    src={message}
                                                    className="admin-user-chat"
                                                />
                                                <a
                                                    className="admin-user-mail"
                                                >
                                                    <img src={chat} style={{ width: "40px", margin: "0 auto" }} />
                                                </a>
                                                <a
                                                    data-test-id='nextRef'
                                                    className="admin-user-phone"
                                                >
                                                    <img src={phone} style={{ width: "40px", margin: "0 auto" }} />
                                                </a>
                                            </div>
                                        </div>
                                    </CardContent>
                                </BoxView>
                            </Grid>
                        )
                    })}
                </Grid>
            </BoxView>

        </div>
    )
    // Customizable Area End

    render() {
        // Customizable Area Start
        const BoxView = Box as any
        const { t, classes,i18n }: any = this.props;   
        const language = i18n.language;              
        return (
            <MainScreen >
                <BoxView className={classes.reportList} sx={{ paddingRight: '40px', padding: { xs: "12px", md: "12px", lg: "40px" }, background: '#F4F7FF' }} dir={languageCondition(language, "rtl", "ltr")}>
                    <BoxView style={{ display: "flex" }}>
                        <Typography variant="body1">
                            {this.state.topMenu.map((tag, index) => {
                                if (index === this.state.topMenu.length - 1) {
                                    return <span style={{ color: "#2B6FED", cursor: "pointer" }}>{tag}</span>
                                } else {
                                    return <span style={{ cursor: "pointer" }} data-test-id="menuBarId" onClick={() => this.handleMenuBar(index, tag)}>{`${tag} / `}</span>
                                }
                            })}
                        </Typography>
                    </BoxView>
                    <br />
                    <Typography variant="h4" className="bold-text" >
                        {t(this.state.title)}
                    </Typography>
                    <br />
                    {this.state.currentTab === "gallery" && (
                        this.getcurentTabGallery(t,BoxView)
                    )}

                    {this.state.currentTab === "userDetail" && (
                        this.getView()
                    )}
                    {this.state.currentTab === "addUnit" &&
                        this.getAddUnit()
                    }
                    { this.state.currentTab ==="viewUserProfile" && (
                        this.getViewUserProfile()
                    )}
                </BoxView>


                <Dialog open={this.state.open} style={addAnotherUnit.popup}>
                    <DialogTitle id="customized-dialog-title" >
                        <div style={addAnotherUnit.arrowback}>
                            <ArrowBackOutlinedIcon data-test-id="closeID" onClick={this.handleDialogClose} />
                            <Typography>Family Members</Typography>
                        </div>
                    </DialogTitle>
                    <DialogContent dividers>
                        <BoxView style={{ display: "flex", flexDirection: "column", gap: "15px", marginBottom: "100px" }}>
                            <BoxView style={{ display: "flex", gap: "15px", alignItems: "center", width: "350px" }}>
                                <Avatar src={this.state.avatarImage} style={{ height: "70px", width: "70px" }} />
                                <div>
                                    <input
                                        type="file"
                                        id="UploadAvtar"
                                        style={{ display: "none" }}
                                        onChange={this.handleImageChange}
                                        data-test-id="ImageId"
                                    />
                                    <label htmlFor="UploadAvtar">
                                        <Typography style={{ color: "#ff8316", fontSize: "15px", }}>Change Profile Picture</Typography>
                                    </label>
                                </div>
                            </BoxView>
                            <InputBase
                                startAdornment={<PermIdentityIcon />}
                                style={addAnotherUnit.selectmenuPopup}
                                value={this.state.familiesName}
                                onChange={(event) => this.handleFamliyName(event)}
                                data-test-id="famliyNameId"
                                />
                                {this.state.NameError && <Typography style={{color:"red"}}>Please Enetr Name.</Typography>}
                            <Select
                                displayEmpty
                                disableUnderline
                                startAdornment={<PermIdentityIcon />}
                                style={addAnotherUnit.selectmenu}
                                data-test-id="RelationId"
                                placeholder="Relation"
                                value={this.state.relation}
                                className="select-input selectFloorChairmanInvoices"
                                onChange={(event) => this.handleRelationsChange(event)}
                                id="select-Country"
                            >
                                <MenuItem
                                    disabled
                                    value=""
                                    id="selectStatusBudgetReport"
                                >
                                    {t("relation")}
                                </MenuItem>
                                {this.state.relations?.map((item: any) => {
                                    return (
                                        <MenuItem key={item.id} value={item.id}>
                                            {item.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                            {this.state.relationError && <Typography style={{color:"red"}}>Please Select Relation.</Typography>}

                            <Select
                                displayEmpty
                                disableUnderline
                                startAdornment={<PermIdentityIcon />}
                                style={addAnotherUnit.selectmenu}
                                data-test-id="selectNationalID"
                                placeholder="Id Type"
                                value={this.state.nationalID}
                                className="select-input selectFloorChairmanInvoices"
                                onChange={(event) => this.handleNationalIDChange(event)}
                                id="select-Country"
                            >
                                <MenuItem
                                    disabled
                                    value=""
                                    id="selectStatusBudgetReport"
                                >
                                    {t("National ID Number")}
                                </MenuItem>
                                {this.state.national?.map((item: any) => {
                                    return (
                                        <MenuItem key={item.id} value={item.id}>
                                            {item.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                            {this.state.nationalError && <Typography style={{color:"red"}}>Please Select National ID Number.</Typography>}
                            <InputBase
                                startAdornment={<PermIdentityIcon />}
                                style={addAnotherUnit.selectmenuPopup}
                                placeholder="1234-4567-5678"
                                value={this.state.famliyId}
                                onChange={(event) => this.handleID(event)}
                                data-test-id="famliyId"

                            />
                        </BoxView>
                        {this.state.idError && <Typography style={{color:"red"}}>Please Enter ID.</Typography>}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleAddFamliy()} data-test-id="familyAddId" style={{ margin: "10px 25px", background: "#007cff", color: "white", fontWeight: "bold", height: "50px", width: "100%", borderRadius: "50px" }}>
                            Save changes
                        </Button>
                    </DialogActions>
                </Dialog>


                <Dialog aria-labelledby="customized-dialog-title" open={this.state.addTenant} maxWidth="lg">
                    <DialogTitle id="customized-dialog-title" >
                        <BoxView style={addAnotherUnit.titleBox}>
                            <Typography className="bold-text" variant="h6">Find Person</Typography>
                            <CloseIcon data-test-id="CloseModel" onClick={()=> this.setState({addTenant:false,tenantData:[]})} />
                        </BoxView>
                    </DialogTitle>
                    <DialogContent dividers>
                        <Typography style={{ marginBottom: "20px" }}>
                            File one of more of the field to the person,it two are prepard, any matching person will be shown
                        </Typography>
                        <Grid container xs={12} wrap="nowrap" spacing={2} alignItems="flex-end">
                            <Grid item >
                                <BoxView>
                                    <Typography style={addAnotherUnit.idnumText}>{t("ID Number")}</Typography>
                                    <InputBase
                                        style={addAnotherUnit.idnum}
                                        data-test-id="IdNumbeId"
                                        onChange={(event)=> this.handleIdNumber(event)}
                                        value={this.state.idNumber}
                                    />
                                </BoxView>
                            </Grid>

                            <Grid item >
                                <BoxView>
                                    <Typography style={addAnotherUnit.idnumText}>{t("Passport Number")}</Typography>
                                    <InputBase
                                        style={addAnotherUnit.idnum}
                                        data-test-id="PassportId"
                                        onChange={(event)=>this.handleChangePassport(event)}
                                        value={this.state.passport}

                                    />
                                </BoxView>
                            </Grid>

                            <Grid item >
                                <BoxView>
                                    <Typography style={addAnotherUnit.idnumText}>{t("Email ID")}</Typography>
                                    <InputBase
                                        style={addAnotherUnit.idnum}
                                        data-test-id="EmailId"
                                        name="Email"
                                        value={this.state.email}
                                        onChange={(event)=>this.handleChange(event)}
                                    />
                                </BoxView>
                            </Grid>

                            <Grid item >
                                <BoxView>
                                    <Typography style={addAnotherUnit.idnumText}>{t("Phone Number")}</Typography>
                                    <InputBase
                                        style={addAnotherUnit.idnum}
                                        data-test-id="PhoneId"
                                        value={this.state.phoneNumber}
                                        onChange={(event) => this.handleChangePhone(event)}
                                    />
                                </BoxView>
                            </Grid>

                            <Grid item >
                                <Button
                                    variant="contained"
                                    className="btnMy"
                                    data-test-id="searchButtonId"
                                    style={addAnotherUnit.mybtnSearch}
                                    onClick={()=>this.addTenantApi()}
                                >
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                    {t("Search")}
                                </Button>
                            </Grid>
                        </Grid>
                        <Divider style={{ margin: '15px 0px 10px 0px' }} />
                        <TableContainer>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left" className="bold-text">Name</TableCell>
                                        <TableCell align="left" className="bold-text">Id Number</TableCell>
                                        <TableCell align="left" className="bold-text">Phone Number</TableCell>
                                        <TableCell align="left" className="bold-text">Email</TableCell>
                                        <TableCell align="left"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                        {this.state.tenantData.map((item:any)=> (
                                            <TableRow>
                                            <TableCell align="left" style={{ fontWeight: 600 }}>{item.attributes.full_name}</TableCell>
                                            <TableCell align="left">{item?.id}</TableCell>
                                            <TableCell align="left">{item?.attributes.full_phone_number}</TableCell>
                                            <TableCell align="left">{item?.attributes.email}</TableCell>
                                            <TableCell align="left"><MoreVertIcon data-test-id="moreIconId" onClick={(event) => this.handleViweDetails(event)}/></TableCell>
                                            <StyledPopover
                                                        open={Boolean(this.state.viweEl)}
                                                        anchorEl={this.state.viweEl}
                                                        onClose={this.handleClose}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'left',
                                                        }}
                                                    >
    
                                                        <BoxView data-test-id={"btnTableEdit"} onClick={() => this.handleView(item.id)} className="actionIcon">
                                                            {("View user profile")}
                                                        </BoxView>
                                                    </StyledPopover>
                                        </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </DialogContent>
                    <DialogActions style={{ flexDirection: "unset", marginBottom: "10px" }}>
                        <Button color="primary" style={addAnotherUnit.cancleButton} data-test-id="cancleButtonId" onClick={()=> this.setState({addTenant:false,tenantData:[],email:"",phoneNumber:"",passport:"",idNumber:""})}>
                            CANCEL
                        </Button>
                    </DialogActions>
                </Dialog>




                <Dialog aria-labelledby="customized-dialog-title" open={this.state.viwePage} maxWidth="md">
                    <DialogTitle id="customized-dialog-title" >
                        <BoxView style={addAnotherUnit.titleBox}>
                            <Typography className="bold-text" variant="h6">Find Person</Typography>
                            <CloseIcon data-test-id="findPesoneId" onClick={()=>this.setState({viwePage:false,tenantData:[]})}/>
                        </BoxView>
                    </DialogTitle>
                    <DialogContent dividers>
                        <Grid container xs={12} spacing={3}>
                            <Grid item xs={3}>
                                <BoxView>
                                    <Typography style={addAnotherUnit.idnumText}>{t("Name")}</Typography>
                                    <Typography style={{ color: "gray" }}>{this.state.findPerson?.attributes?.full_name}</Typography>
                                </BoxView>
                            </Grid>

                            <Grid item xs={3}>
                                <BoxView>
                                    <Typography style={addAnotherUnit.idnumText}>{t("Complex")}</Typography>
                                    <Typography style={{ color: "gray" }}>Complex 1</Typography>
                                </BoxView>
                            </Grid>

                            <Grid item xs={3}>
                                <BoxView>
                                    <Typography style={addAnotherUnit.idnumText}>{t("ID Number")}</Typography>
                                    <Typography style={{ color: "gray" }}>{this.state.findPerson?.id}</Typography>
                                </BoxView>
                            </Grid>

                            <Grid item xs={3}>
                                <BoxView>
                                    <Typography style={addAnotherUnit.idnumText}>{t("Local Id")}</Typography>
                                    <Typography style={{ color: "gray" }}>jason asutin</Typography>
                                </BoxView>
                            </Grid>

                            <Grid item xs={3}>
                                <BoxView>
                                    <Typography style={addAnotherUnit.idnumText}>{t("Email")}</Typography>
                                    <Typography style={{ color: "gray" }}>{this.state.findPerson?.attributes?.email}</Typography>
                                </BoxView>
                            </Grid>

                            <Grid item xs={6}>
                                <BoxView>
                                    <Typography style={addAnotherUnit.idnumText}>{t("Address")}</Typography>
                                    <Typography style={{ color: "gray" }}>Unit Number, Building Name, Complex Name, City.Region, Country</Typography>
                                </BoxView>
                            </Grid>

                        </Grid>
                    </DialogContent>
                    <DialogActions style={{ flexDirection: "unset", marginBottom: "10px", gap: "15px" }}>
                        <Button color="primary" style={addAnotherUnit.cancleButton} data-test-id="findPersonCancle" onClick={()=>this.setState({viwePage:false,tenantData:[],email:"",phoneNumber:"",passport:"",idNumber:""})}>
                            CANCEL
                        </Button>
                        <Button color="primary" style={addAnotherUnit.selectButton} data-test-id="selectParsonId" onClick={()=>this.setState({viwePage:false ,tenantId:this.state.findPerson?.id,isSelect:true })} >
                            SELECT
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    fullWidth
                    dir={languageCondition(language, "rtl", "ltr")}
                    open={this.state.suspendUser}
                    className="cancel-meeting-dialog"
                >
                    <DialogContent style={{ margin: "15px 0" }}>
                        <BoxView textAlign="center">
                            <img className="comment-image" alt="check" src={delete_icon} />
                            <Typography variant="h6" className="bold-text" style={{ fontSize: "22px" }}>
                            Suspend User
                            </Typography>
                            <Typography variant="body1" style={{ marginBottom: "5px", fontSize: "16px" }}>
                            Are you sure you want to Suspend the user?</Typography>
                            <DialogActions className="dialog-button-group">
                                <Button
                                    className="cancel-button"
                                    style={{ width: "200px", height: "55px", marginRight: "10px" }}
                                    data-test-id="suspendCloseId"
                                    onClick={()=>this.setState({suspendUser:false})}
                                >
                                    CANCEL
                                </Button>
                                <Button style={{ width: "200px", height: "55px" }} className="add-button" data-test-id="suspensId" onClick={()=>this.handleSuspendUser(this.state.userInfo.id)} >
                                   CONFIRM
                                </Button>
                            </DialogActions>
                        </BoxView>
                    </DialogContent>
                </Dialog>

                <Dialog
                    dir={languageCondition(language, "rtl", "ltr")}
                    open={this.state.successModel}
                    className="cancel-meeting-dialog"
                >
                    <BoxView>
                    <CloseIcon data-test-id="succesPesoneId" style={{ float:"right",padding: "10px" , cursor:"pointer"}} onClick={()=>this.setState({successModel:false})}/>
                    </BoxView>
                    <DialogContent style={{ margin: "15px 0"}}>
                        <BoxView textAlign="center">
                            <img className="comment-image" alt="check" src={successIcon} />
                            <Typography variant="h6" style={{ fontSize: "20px" }}>
                                User Suspended Successfully
                            </Typography>
                        </BoxView>
                    </DialogContent>
                </Dialog>
            </MainScreen>
            );
    }
    // Customizable Area End
}

// Customizable Area Start

export const dashBoard = {
    navigation: {
        display: "flex",
        justifyContent: "space-between",
    },
    gaMemberCard: {
        display: "grid",
        gridTemplateColumns: "4fr 4fr 4fr",
        gap: 20,
      },
    cardStyle: {
        borderRadius: 10,
        maxWidth: "100%",
        boxShadow: "none",
        padding: "0px 0px 0px 0px",
      },
      userType: {
        backgroundColor: "aliceblue",
        borderRadius: 30,
        fontSize: 14,
        display: "inline-block",
        padding: "5px 10px",
        color: "#2D6EED",
        fontWeight: 600,
        marginTop: 5,
      },
    subHeadings: {
        fontWeight: 600,
        marginTop: 15,
        textTransform: "capitalize"
    },
    YearMainStyle: {
        background: "#fff",
        border: "none",
        borderRadius: 5,
        padding: 7,
    },
    SideBars: {
        background: "#f9f6f6",
        position: "relative",
        paddingBottom: 150,
    },
    searchButtons: {
        border: "1px solid #f0f0f0",
        background: "white",
        borderRadius: "5px",
    },
    backColorStyle: {
        backgroundColor: "#2D6EED",
        color: "white",
        padding: "12px 16px",
        marginTop: "3px",
        textTransform: "capitalize",
    },
    backColorGAs: {
        backgroundColor: "#2D6EED",
        color: "white",
        padding: "9px 16px",
        marginTop: "3px"
    },
    boxStylings: {
        display: "flex",
        alignItems: "center",
        marginTop: 20
    },
    boxStyleIcon: {
        marginTop: "16px",
    },
    gaMembersMain: {
        display: "flex",
        alignItems: "baseline",
        marginTop: 20,
        justifyContent: "space-between"
    },
    viewMoreStyle: {
        marginTop: 15,
        textDecoration: "underline",
        color: "#FC8434",
        fontWeight: 600,
        textAlign: "right",
    },
    gaMembersCard: {
        display: "grid",
        gridTemplateColumns: "3fr 3fr 3fr 3fr",
        gap: 20
    },
    relatedMembersCard: {
        display: "grid",
        gridTemplateColumns: "3fr 3fr 3fr",
        gap: 20
    },
    profileImagecm: {
        borderRadius: "100%",
        width: 50,
        height: 50,
        margin: "20px auto",
        padding: "0px 8px 8px 8px",
        backgroundColor: "#FFFAFA"
    },
    usersType: {
        backgroundColor: "#e2f1ff",
        borderRadius: 30,
        fontSize: "14px",
        display: "inline-block",
        padding: "3px 20px",
        color: "#2D6EED",
        fontWeight: 600,
        width:"max-content"
    },
    unitnumber: {
        marginTop: 15,
        fontWeight: 600,
        textAlign: "center"
    },
    contactIconmui: {
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        marginTop: 15
    },
    cardsStyle: {
        borderRadius: 10,
        maxWidth: 330
    },
    cursorPointercard: {
        cursor: "pointer"
    },
    mybtn: {
        backgroundColor: "#2D6EED",
        color: "white",
        padding: "12px 16px",
        textTransform: "capitalize" as "capitalize",
    },
    mainText: {
        display: "flex",
        justifyContent: "space-between",
        alignContent: "unset",
        alignItems: "center",
    },
    cardMainText: {
        marginTop: "15px",
        minHeight: "30px",
        marginBottom: "15px"
    },
    viewAll: {
        color: "#FC8434",
        borderBottom: "2px solid",
        fontWeight: 600,
        cursor: "pointer",
        padding:"0px"
    },
    card: {
        padding: "20px 40px 20px 40px",
        backgroundColor: "#FFF",
        cursor: "pointer",
        display:"grid",
        justifyContent:"center"
    },
    avatar: {
        margin: "auto",
        height: "50px",
        width: "50px"
    },
    cardContent: {
        padding: "0px 16px 16px 16px",
        backgroundColor: "#FFF"
    },
    cardText: {
        textAlign: "center" as "center",
        marginTop: "5px",
        minHeight: "30px"
    },
    subText: {
        textAlign: "center" as "center",
        marginTop: "5px"
    },
    memberText: {
        textAlign: "center" as "center",
        marginTop: "5px"
    },
    searchButton: {
        display: "flex",
        justifyContent: "space-between",
        gap: "70px",
    },
    selectMenu: {
        border: "#fff",
        padding: "7px",
        background: "#fff",
        borderRadius: "8px",
    },
};

export const userProfileView = {
    details: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "20px"
    },
    famaliyCard : {
        height: "auto",
        border: "1px solid gray",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        padding: "10px",
        marginBottom: "20px"
    },
    famaliyBox: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
    },
    moreDetails: {
        fontSize: "15px",
        color: "#2D6EED",
        display: "flex",
        alignItems: "center",
        cursor:"pointer"   
    },
    suspend: {
        border: "2px solid #396d9b",
        color: "black",
        width: "160px",
        top: '101px',
        bottom: "330px",
        height: "27px",
        textAlign: "center",
        padding: "7px",
        borderRadius: "10px",
        left: "20px",
        right: "45px",
    },
    viewCard: {
        display: "flex",
        width: "100%",
        backgroundColor: "#FFFFFF",
        boxShadow: "0px 2px 31px -29px",
        borderRadius: "10px",
        marginTop: "15px",
        position:"relative"
    },
    profileImage: {
        width: "20%",
        padding: "20px",
        background: "white",
        borderRadius: "10px",
    },
    image: {
        height: "100px",
        width: "100px",
    },
    userImage: {
        margin: "0 auto",
        width: "40px"
    },
    adminUserImage: {
        margin: "0 auto",
        width: "40px"
    },
    userPhoneImage: {
        width: "40px",
        margin: "0 auto"
    },
    division: {
        width: "70%",
        padding: "20px"
    },
    userDetails: {
        display: "flex",
        justifyContent: "flex-start",
        gap: "70px",
        marginTop: "20px"
    },
    mediaImage: {
        display: "flex",
        gap: "15px",
        marginTop: "5px",
    },
    facebookImg: {
        width: "40px"
    },
    twiterImg: {
        width: "40px"
    },
    snapImg: {
        width: "40px"
    },
    generalCard: {
        marginTop: "15px"
    },
    generalopation: {
        padding: "20px 20px",
        backgroundColor: "#FFF",
        width: 'auto',
    },
    moreDetailIcon: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-end"
    },
    card: {
        padding: "20px",
        backgroundColor: "#FFF",
        width: 'auto',
        marginTop: "20px",
        display: "flex",
    },
    cardContent: {
        backgroundColor: "#FFF",
        padding: "0px 16px 16px 16px",
        marginTop:"35px"
    },
    cardText: {
        textAlign: "center" as "center",
        marginTop: "5px",
        minHeight: "30px"
    },
    cardText2: {
        marginTop: "5px",
        minHeight: "30px"
    },
    subText: {
        textAlign: "center" as "center",
        marginTop: "5px"
    },
    memberText: {
        textAlign: "center" as "center",
        marginTop: "5px"
    },
    avatar: {
        margin: "auto",
        height: "50px",
        width: "50px"
    },
    showDetails: {
        borderRadius: 10,
        maxWidth: "100%",
        boxShadow: "none",
        padding: "0px 0px 0px 0px",
        backgroundColor: "#ffffff",
    },
    moreIcon: {
        display: "flex",
        justifyContent: "space-between"
    },
    generalCardDetail: {
        display: "flex",
        flexDirection: "column",
        height: "auto",
        backgroundColor: "#ffffff",
        borderRadius: "10px",
        padding: "25px 15px"
    },
    generalCardDetailName: {
        display: "flex",
        justifyContent: "space-between"
    },
    generalCardDetailoption: {
        textAlign: "center",
        marginTop: "5px",
        display: "flex",
    },
    generalCardData: {
        marginLeft: "2px"
    },
    userName: {
        textAlign: "center",
        marginTop: "5px",
        display: "flex",
    },
    usersType: {
        backgroundColor: "#EBF5FD",
        borderRadius: 30,
        fontWeight: 700,
        fontSize: "14px",
        display: "inline-block",
        color: "#2D6EED",
        padding: "5px 11px",
    },
    contactIconmui: {
        display: "flex",
        alignItems: "center",
        marginTop: 15
    },
    relatedMembersCard: {
        display: "grid",
        gridTemplateColumns: "3fr 3fr 3fr",
        gap: 20
    },
    cardDetails: {
        display: "flex",
        justifyContent: "space-around",
    },
    cardMoreData: {
        display: "flex",
        flexDirection: "column",
        height: "auto",
        backgroundColor: "#ffffff",
        borderRadius: "10px",
        padding: "25px 15px"
    },
    cardMoreDataName: {
        display: "flex",
        justifyContent: "space-between"
    },
    cardMoreDataImage: {
        textAlign: "center",
        marginTop: "5px",
        display: "flex",
    },
    cardMoreDataDetails: {
        width: "105px",
        height: "70px",
        backgroundColor: "gray",
        borderRadius: "5px",
        marginBottom: "10px"
    },
    cardMoreDataDetailsName: {
        marginLeft: "2px"
    },
    AdminData: {
        display: "flex",
        flexDirection: "column",
        height: "auto",
        backgroundColor: "#ffffff",
        borderRadius: "10px",
        padding: "25px 15px"
    },
    AdminDataName: {
        display: "flex",
        justifyContent: "space-between"
    },
    AdminDataWait: {
        textAlign: "center",
        marginTop: "5px",
        display: "flex",
    },
    AdminDataDetails: {
        marginLeft: "2px"
    },
    status: {
        color: "#C2C2C2"
    },
    about: {
        color: "#C2C2C2"
    },
    dob: {
        color: "#C2C2C2"
    },
    hobbies: {
        color: "#C2C2C2"
    },
    media: {
        color: "#C2C2C2",
        marginTop: "20px"
    },
    cardsubtext: {
        marginTop: "5px"
    },
    cardusersType: {
        backgroundColor: "#e2f1ff",
        borderRadius: 30,
        fontSize: "14px",
        display: "inline-block",
        padding: "5px 20px",
        color: "#2D6EED",
        fontWeight: 600,
        marginTop: "8px"
    },
    moreoptionbox: {
        display: "flex",
        color: "rgb(45, 110, 237)",
        position:"relative"
    }
};

export const addAnotherUnit = {
    container: {
        background: "#FFFFFF",
        padding: "20px 40px",
    },
    multiImage: {
        display: "flex",
        justifyContent: "center",
        alignItems: 'center',
        width: "200px",
        height: "120px",
        border: "2px dashed black",
        borderRadius: "10px",
        cursor: 'pointer',
        background: "#dcdcdc3d"
    },
    textName : {
        marginBottom: "10px",
        fontSize: "14px",
        color:"#F98640",
        border: "1px solid #F98640",
        padding: "7px",
        background: "rgba(238, 238, 238, 0.76)",
        borderRadius: "8px",
        width: "100%",
        gap:'10px'

    },
    TenantName :{
        marginBottom: "10px",
        fontSize: "14px",
        color:"#F98640",
        border: "1px solid #F98640",
        padding: "10px",
        background: "rgba(238, 238, 238, 0.76)",
        borderRadius: "8px",
        width: "100%",
        gap:'10px',
        cursor: 'pointer',

    },
    selectmenu: {
        border: "1px solid #F0F0F0",
        padding: "7px",
        background: "rgba(238, 238, 238, 0.76)",
        borderRadius: "8px",
        borderBottom: "none",
        width: "100%",
        gap:'10px'
    },
    popup:{
        marginLeft:"350px"
    },
    arrowback: {
        display:"flex",
        gap:"5px",
        alignItems:"center"
    },
    selectmenuPopup: {
        border: "1px solid #F0F0F0",
        padding: "7px",
        background: "rgba(238, 238, 238, 0.76)",
        borderRadius: "8px",
        borderBottom: "none",
        width: "100%",
        gap:'10px'
    },
    selectText: {
        marginBottom: "10px",
        fontSize: "14px"
    },
    mainText: {
        marginBottom: "20px",
        fontSize: "30px"
    },
    subcontainer: {
        background: "#FFFFFF",
        padding: "20px 40px",
        marginTop: "30px"
    },
    buttons: {
        color: " #2A71E6",
        border: "1px solid",
        width: "150px",
        fontWeight: "bold" as "bold",
        padding: "10px",
        marginRight: "20px"
    },
    buttonBox: {
        display: "flex",
        justifyContent: "end",
        marginBottom: "20px"
    },
    Approvalbuttons: {
        color: "#fff",
        fontWeight: "bold" as "bold",
        padding: "10px",
        marginRight: "20px",
        background: "#2A71E6",
        border: "none"
    }, 
     titleBox: {
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center"
    },
    cancleButton: {
        color: "#2B6FED",
        border:"1px solid #2B6FED", 
        padding:"10px 50px", 
        fontWeight:900,
        alignItems:"end",
    },
    selectButton:{
        color: "white",
        border:"1px solid #2B6FED", 
        padding:"10px 60px", 
        fontWeight:900,
        alignItems:"end",
        backgroundColor:"#454ed1",
    },
    idnum: {
        border: "1px solid #F0F0F0",
        padding: "7px",
        background: "rgba(238, 238, 238, 0.76)",
        borderRadius: "8px",
        borderBottom: "none",
    },
    idnumText: {
        marginBottom: "7px",
        fontSize: "16px",
        fontWeight:700,
        padding:"10px 5px"
    },
    mybtnSearch: {
        backgroundColor: "#2D6EED",
        color: "white",
        padding: "12px 16px",
        textTransform: "capitalize" as "capitalize",
    },

}
export default withTranslation()(
    //@ts-ignore
    withStyles(ReportsStyleWeb)(withRouter(CECommunityManagement))
  );
// Customizable Area End