// Customizable Area Start
import { Box, styled } from '@material-ui/core';
const SuggestionListing = styled(Box)(({ theme }) => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& .suggestion-header': {
        width: '100%',
        maxHeight: 60,
        borderBottom: '1px solid #F2F2F2',
        display: 'flex',
        alignItems: 'center',
        '& .suggestion-header-wrapper': {
            width: '100%',
            padding: 10,
            display: 'flex',
            alignItems: 'center',
            gap: 15,
            '& .suggestion-header-button': {
                width: '40px',
                height: '40px',
                minWidth: '40px',
                padding: 5,
            },
            '& .suggestion-header-text': {
                color: '#020433',
                fontWeight: 600,
                padding: 0,
                fontSize: '14px',
                fontFamily: 'Gotham_Bold !important',
                [theme.breakpoints.up('md')]: {
                    fontSize: '16px',
                }
            },
        },
    },
    '& .suggestion-body': {
        width: '100%',
        display: 'flex',
        background: '#F7F9FE',
        flex: 1,
        overflow: 'hidden',
        '& .suggestion-content': {
            width: '100%',
            height: '100%',
            padding: '0 20px',
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.up('md')]: {
                width: '60%'
            },
            '& .suggestion-list': {
                width: "100%",
                flex: 1,
                overflowY: 'scroll',
                paddingBlock: 20,
                paddingInlineEnd: 10,
                paddingInlineStart: 0,
                display: 'flex',
                flexDirection: 'column',
                gap: 20,
                scrollbarWidth: 'thin',
                scrollbarColor: '#2B6FED #f1f1f1',
                '& .suggestion-card': {
                    // width: '100%',
                    borderRadius: '12px',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '20px 10px',
                    border: '2px solid #ececec',
                    boxShadow: '4px 0 14px 0 #ececec',
                    gap: 20,
                    '& .suggestion-header-empty': {
                        width: '100%',
                        fontFamily: 'Gotham_Bold !important',
                        fontWeight: 600,
                        padding: 0,
                        fontSize: '16px',
                        [theme.breakpoints.up('md')]: {
                            fontSize: '18px',
                        },
                    },
                    '& .suggestion-card-header': {
                        fontFamily: 'Gotham_Bold !important',
                        fontWeight: 600,
                        fontSize: '16px',
                        [theme.breakpoints.up('md')]: {
                            fontSize: '18px',
                        },
                    },
                    '& .suggestion-card-body': {
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 20,
                    },
                    '& .suggestion-card-info': {
                        display: 'flex',
                        gap: 10,
                        '& .building-text': {
                            fontSize: '14px',
                            [theme.breakpoints.up('md')]: {
                                fontSize: '16px'
                            },
                        },
                        '& .building-name': {
                            fontFamily: 'Gotham_Bold !important',
                            fontSize: '14px',
                            [theme.breakpoints.up('md')]: {
                                fontSize: '16px'
                            },
                        }
                    },
                    '& .suggestion-card-footer': {
                        padding: '10px 0',
                        borderTop: '1px solid #ececec',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        '& .suggestion-card-date': {
                            display: 'flex',
                            alignItems: 'center',
                            gap: 5,
                            fontFamily: 'Gotham_Bold !important',
                            '& .suggestion-card-date-text': {
                                fontSize: '14px',
                                [theme.breakpoints.up('md')]: {
                                    fontSize: '16px'
                                },
                            }
                        },
                        '& .suggestion-card-response-wrapper': {
                            padding: 5,
                            '& .suggestion-card-response': {
                                display: 'flex',
                                padding: '5px 15px',
                                borderRadius: '28px',
                                '&.has-response': {
                                    background: '#d4ffe3 !important',
                                    '& .response-text': {
                                        padding: 0,
                                        color: '#1ec65b !important',
                                        fontWeight: 600,
                                        fontSize: '12px',
                                        [theme.breakpoints.up('md')]: {
                                            fontSize: '14px'
                                        },
                                    }
                                },
                                '&.no-response': {
                                    background: '#ffeaea !important',
                                    '& .response-text': {
                                        padding: 0,
                                        color: '#f21717 !important',
                                        fontWeight: 600,
                                        fontSize: '12px',
                                        [theme.breakpoints.up('md')]: {
                                            fontSize: '14px'
                                        },
                                    }
                                }
                            }
                        },
                    },
                }
            },
            '& .suggestion-cta': {
                padding: '15px 0',
                '& .suggestion-add': {
                    width: '100%',
                    background: '#2B6FED',
                    borderRadius: '28px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: 14,
                    color: '#FFFFFF',
                    fontWeight: 600,
                    fontSize: '16px',
                    [theme.breakpoints.up('md')]: {
                        fontSize: '18px'
                    },
                }
            }
        },
        '& .suggestion-common-image': {
            display: 'none',
            [theme.breakpoints.up('md')]: {
                display: 'block',
                width: '40%',
                padding: 10,
            },
            '& .right-image': {
                display: 'flex',
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: '30px'
            }
        }
    }
}));

export default SuggestionListing;
// Customizable Area End