// Customizable Area Start
import React, { CSSProperties } from "react";
import {
  Typography,
  Container,
  Select,
  Button,
  withStyles,
  Box,
  Grid,
  FormControl,
  MenuItem,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  InputBase,
  IconButton,
  TextField,
  Tooltip
} from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import COOIncidentListingController, {
  Props,
} from "./COOIncidentListingController.web";

import { withTranslation } from "react-i18next";

import MainScreen from "../../../dashboard/src/Company Employee/MainScreen.web";

import MoreVertIcon from "@material-ui/icons/MoreVert";

import SearchIcon from "@material-ui/icons/Search";
import { ReportsStyleWeb } from "../../../StoreCredits/src/ReportsStyle.web";
import PaginationModule from "../../../StoreCredits/src/PaginationModule.web";
import "./incidents.css";
import Loader from "../../../../components/src/Loader.web";
import AlertErrorWeb from "../../../../components/src/AlertError.web";



class COOIncidentListing extends COOIncidentListingController {
  constructor(props: Props) {
    super(props);
  }
  handleDetailsOptions = (id: number) => {
    this.setState({ openDetails: id });
  };

  handleCountryFilterChange = (e:any) => {
    const { value } = e.target;
    this.setState({ countryFilter: value }, () => {
      this.getComplexList();
      this.getCitiesList();
    });
  };
  nerfNames = (data: any) => {
    if (data) {
      const charLength = 10;
      if (data.length > 8) {
        const processedString = data.substring(0, charLength).concat("..");
        return processedString.length <= 11 ? data : processedString;
      }
    }
    return data;
  }
  render() {
    const { t, classes }: any = this.props;
   
   
    return (
      <>
        <MainScreen>
          <Box style={{ background: "#F4F7FF" }} className={classes.reportList}>
            <Grid
              item
              xs={12}
              md={12}
              sm={12}
              style={{ paddingTop: 35, minHeight: "95vh" }}
            >
              <Container>
                <Box className="navigation">
                  <Box>
                    <Typography variant="body1">
                      <Box
                        className="reports-BudgetReportmain budgetReportBackButton"
                        component="span"
                        style={{ cursor: "pointer" }}
                      >
                        {" "}
                        {t("Activities")}
                      </Box>
                      /{" "}
                      <Box
                        className="reports-BudgetReportmain"
                        component="span"
                        style={{ color: "#2B6FED" }}
                      >
                        {t("Incident Management")}
                      </Box>
                    </Typography>

                    <Typography
                      variant="h5"
                      className="sub-heading bold-text reports-BudgetReportmain"
                      style={webStyle.subHeadLine}
                    >
                      {t("Incident Management")}
                    </Typography>
                  </Box>
                </Box>
                <Box className= "top-bar ChairmanInvoices"  style={{display:"flex"}}>
                            <Box className="filter"  style={{display:"flex",flexWrap:"wrap"}}>
                                <Select displayEmpty 
                                 value={this.state.countryFilter}
                                 id="selectCountry"
                               
                                 onChange={(e)=>this.handleCountryFilterChange(e)}
                                 className="select-input selectBuildingForChairmanInvoices" >
                                   <MenuItem
                              value=""
                              disabled
                              id="selectStatusBudgetReport"
                            >
                              {t("Select Country")}
                            </MenuItem>
                            <MenuItem
                              value="all"
                              id="selectStatusBudgetReportAll"
                             
                            >
                              {t("All")}
                            </MenuItem>
                            {this.state.countriesList.length &&
                              this.state.countriesList.map((item: any) => {
                                return (
                                  <MenuItem key={item} value={item}>
                                    {item}
                                  </MenuItem>
                                );
                              })}
                              </Select>
                                
                                <Select displayEmpty
                                   className="select-input selectFloorChairmanInvoices"
                                   value={this.state.cityFilter}
                                   id="select-city1"
                                   
       
                                   onChange={(e:any)=>{this.setState({cityFilter:e.target.value},()=>{this.getComplexList()}) }}
                                >
                                   <MenuItem
                              value=""
                              disabled
                              id="selectStatusBudgetReport"
                            >
                             {t("Select City")} 
                            </MenuItem>
                            <MenuItem
                              value="all"
                              id="selectStatusBudgetReportAll"
                             
                            >
                              {t("All")}
                            </MenuItem>
                           
                            {this.state.cityList.length &&
                              this.state.cityList.map((item: any) => {
                                return (
                                  <MenuItem key={item} value={item.name}>
                                    {item.name}
                                  </MenuItem>
                                );
                              })}
                                </Select>
                                <Select displayEmpty 
                                 
                                 value={this.state.companyFilter}
                                 onChange={(e:any)=>{this.setState({companyFilter:e.target.value},()=>{this.getBuildingList()})}}
                                 className="select-input selectUnitChairmanInvoices complexS"
                                 >
                                    <MenuItem
                              value=""
                              disabled
                              id="selectStatusBudgetReport"
                            >
                             {t("Complex")} 
                            </MenuItem>
                           
                            {this.state.complexList?.length &&
                              this.state.complexList?.map((item: any) => {
                                return (
                                  <MenuItem key={item.attributes.name} value={item.id}>
                                    {item.attributes.name}
                                  </MenuItem>
                                );
                              })}
                                   
                                </Select>
                                <Select displayEmpty 
                                className="select-input invoiceTypeFromChairmanInvoices" 
                                value={this.state.buildingFilter}
                                id="selectBuilding"
                                
                                onChange={(e:any)=>{this.setState({buildingFilter:e.target.value},()=>{this.getUnitList()})}}
                                data-testid="slctBuilding"
                                >
                                    <MenuItem
                              value=""
                              disabled
                              id="selectStatusBudgetReport"
                            >
                              {t("Select Building")}
                            </MenuItem>
                           
                            {this.state.buildingList?.length &&
                              this.state.buildingList.map((item: any) => {
                                return (
                                  <MenuItem key={item.id} value={item.id}>
                                    {item.name}
                                  </MenuItem>
                                );
                              })}
                                </Select>
                                <Select displayEmpty 
                                className="select-input invoiceTypeFromChairmanInvoices unitSelect" 
                               
                               
                            value={this.state.unitFilter}
                            onChange={(e:any)=>this.setState({unitFilter:e.target.value})}
                                >
                                 <MenuItem
                              value=""
                              disabled
                              id="selectStatusBudgetReport"
                            >
                              {t("Select Unit")}
                            </MenuItem>
                           
                            {this.state.unitList.length &&
                              this.state.unitList.map((item: any) => {
                                return (
                                  <MenuItem key={item.id} value={item.id}>
                                    {item.apartment_name}
                                  </MenuItem>
                                );
                              })}
                                </Select>
                                <Select displayEmpty    
                                className="select-input schedule-meeting-filter-status"    
                                value={this.state.statusFilter}
                                onChange={(e:any)=>this.setState({statusFilter:e.target.value})}
                                >
                                <MenuItem
                              value=""
                              disabled
                              id="selectStatusBudgetReport"
                            >
                              {t("Status")}
                            </MenuItem>
                            <MenuItem
                              value="all"
                              id="selectStatusBudgetReportAll"
                            >
                              All
                            </MenuItem>
                            {this.state.statusList.length &&
                              this.state.statusList.map((item: any) => {
                                return (
                                  <MenuItem key={item} value={item}>
                                    {item}
                                  </MenuItem>
                                );
                              })}
                                </Select>
                                <Button className="handleSearchFilterForInvoices" 
                                startIcon={<SearchIcon />}
                               
                                onClick={this.handleFilter}
                              
                              
                                  style={{marginLeft:"10px"}}
                              
                                >
                                  {t("Search")}</Button>
                            </Box>
                        </Box>
             

                <Grid
                  className="meeting-table"
                  style={{ boxShadow: "4px 0px 14px #e9e9e9" }}
                >
                  <Grid item sm={12} md={12} xs={12}>
                    <Box className="table-top">
                      <h3 className="bold-text reports-BudgetReportmain" style={{fontSize:"22px"}}>
                        {t("Incident Management")}
                      </h3>
                      <div >
                      <TextField
                        id="searchQuery"
                        label="Search"
                        variant="outlined"
                        className="table_search"
                        style={webStyle.searchInput}
                        placeholder={`${t("Search")}`}
                        onKeyUp={this.handleFilterDataKeyUp}
                        InputProps={{
                            startAdornment: (
                                <IconButton 
                                style={webStyle.searchIconButton}>
                                    <SearchIcon />
                                </IconButton>
                            ),
                            style: webStyle.innerInput
                        }}
                    />
                        {/* <SearchIcon />
                        <InputBase
                          placeholder={t("Search")}
                          className="search searchInputBudget"
                          value={this.state.searchQuery}
                          onChange={(e)=>this.handleFilterTable(e)}
                          
                        /> */}
                      </div>
                    </Box>
                    <Divider />
                    <Table className="table-box">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            className="bold-text reports-BudgetReportmain1"
                            style={{ color: "#181d25", fontSize: "14px" }}
                          >
                            {t("#")}
                          </TableCell>
                          <TableCell
                            className="bold-text reports-BudgetReportmainYear"
                            style={{ color: "#181d25", fontSize: "14px" }}
                          >
                           {t("Incident Title")} 
                          </TableCell>
                          <TableCell
                            className="bold-text reports-BudgetReportmainGenerated"
                            style={{ color: "#181d25", fontSize: "14px" }}
                          >
                            {t("Building Name")}
                          </TableCell>
                          <TableCell
                            className="bold-text reports-BudgetReportmainAmount"
                            style={{ color: "#181d25", fontSize: "14px" }}
                          >
                           {t("Unit Number")}
                          </TableCell>
                          <TableCell
                            className="bold-text reports-BudgetReportmainAmount"
                            style={{ color: "#181d25", fontSize: "14px" }}
                          >
                           {t("Reported By")}
                          </TableCell>
                          <TableCell
                            className="bold-text reports-BudgetReportmainAmount"
                            style={{ color: "#181d25", fontSize: "14px" }}
                          >
                            {t("Status")}
                          </TableCell>

                          <TableCell
                            className="bold-text reports-BudgetReportmainAmount"
                            style={{ color: "#181d25", fontSize: "14px" }}
                          ></TableCell>
                          <TableCell />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.incidentList.length > 0 ? (
                          this.state.incidentList.map((item: any, key: any) => {
                            return (
                              <>
                                <TableRow>
                                  <TableCell >
                                    {key + 1}
                                  </TableCell>
                                  <TableCell>
                                    
                                      <Tooltip  title={item.attributes.incident_title}>
                                      <div>
                                      {this.nerfNames(item.attributes.incident_title)}
                                      </div>
                                      {/* {item.attributes.incident_title} */}
                                      </Tooltip>
                                    
                                    
                                  </TableCell>
                                  <TableCell >
                                    <Tooltip title={item.attributes.building_management.name}>
                                      <div>
                                       {this.nerfNames(item.attributes.building_management.name)}
                                      </div>
                                    </Tooltip>
                                    
                                  </TableCell>
                                  <TableCell>
                                    {
                                      item.attributes.apartment_management
                                        .apartment_name
                                    }
                                  </TableCell>
                                  <TableCell >
                                    {item.attributes.reported_by.full_name== null ? "-": item.attributes.reported_by.full_name}
                                  </TableCell>
                                  <TableCell className="bold-text" >
                                   
                                      <span  className={`${item.attributes.incident_status} bold-text`} >
                                      {item.attributes.incident_status}
                                      </span>
                                   
                                  </TableCell>

                                  <TableCell style={webStyle.moreOptions}>
                                    <IconButton data-test-id="selectmore"  
                                    onClick={() =>
                                        this.handleDetailsOptions(item.id)
                                      }>
                                    <MoreVertIcon
                                      style={webStyle.moreIcon}
                                     
                                      id="selectmore"
                                 
                                    />
                                    </IconButton>
                                   
                                    {this.state.openDetails === item.id && (
                                      <Box style={webStyle.rowOptions}>
                                        <ul style={webStyle.rowList}>
                                          <li style={webStyle.option1}>
                                            <Link
                                              to={`/CompanyEmployee/cooincident-details/${item.id}`}
                                              style={{textAlign:"center"}}
                                            >
                                              View Details
                                            </Link>
                                          </li>
                                        </ul>
                                      </Box>
                                    )}
                                  </TableCell>
                                </TableRow>
                              </>
                            );
                          })
                        ) : (
                          <TableRow>
                            <TableCell colSpan={6}>
                              {t("No Incident Report Available")}
                            </TableCell>
                          </TableRow>
                        )}
                       
                      </TableBody>
                    </Table>
                    <Divider />
                    <Box className="table-bottom">
                      <PaginationModule
                        pagination={this.state.pagination}
                        handlePagination={this.handleIncidentPagination}
                        page={this.state.page}
                        // count={this.state.incidentList?.length}
                       
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Grid>
            <Loader loading={this.state.loading} />
            
          </Box>
          <AlertErrorWeb
              show={this.state.showError}
              handleClose={() =>
                this.setState({ showError: false, error: null ,})
              }
              message={this.state.error}
            />

        </MainScreen>
      </>
    );
  }
}
const webStyle: any = {
  selectFormControl: {
    width: "15%",
  },
  selectBigFormControl: {
    width: "20%",
  },
  titleBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  companyBox: {
    padding: "30px",
  },
  smallTitle: { fontSize: "18px" },
  bigTitle: {
    fontSize: "32px",
    fontWeight: "700" as CSSProperties["fontWeight"],
  },
  registerBtn: {
    background: "#FC8434",
    borderColor: "#FC8434",
    boxShadow: "unset",
    borderRadius: "8px",
    fontWeight: "700" as CSSProperties["fontWeight"],
    height: "40px",
  },
  currentRecords: {
    color: "#FC8434",
  },
  selectBigForm: {
    minWidth: "20% ",
    color: "#181d25",
    border: "1px solid #f0f0f0",
  },
  noRecord: {
    padding: "25px",
    display: "flex",
    textAlign: "center" as CSSProperties["textAlign"],
  },
  moreIcon: {
    color: "rgba(136,136,136,0.6)",
    cursor: "pointer",
  },
  selectForm: {
    minWidth: "15%",
    color: "#181d25",
    border: "1px solid #f0f0f0",
  },
  approved: {
    background: "rgb(30,198,91,0.12)",
    color: "#1EC65B",
    textAlign: "center" as CSSProperties["textAlign"],
    padding: "5px 15px",
    borderRadius: "16px",
    fontSize: "12px",
  },
  subHeadLine:{
    fontSize:"32px",
    fontWeight:"0px"
  },
  resolved: {
    background: "rgb(30,198,91,0.12)",
    color: "#1EC65B",
    textAlign: "center" as CSSProperties["textAlign"],
    padding: "5px 15px",
    borderRadius: "16px",
    fontSize: "12px",
  },
  closed: {
    background: "rgb(30,198,91,0.12)",
    color: "#1EC65B",
    textAlign: "center" as CSSProperties["textAlign"],
    padding: "5px 15px",
    borderRadius: "16px",
    fontSize: "12px",
  },
  pending:{
    textAlign: "center" as CSSProperties["textAlign"],
    padding: "5px 15px",
    borderRadius: "16px",
    fontSize: "12px",
  },
  moreOptions: {
    position: "relative" as CSSProperties["position"],
  },
  rowOptions: {
    position: "absolute" as CSSProperties["position"],
    background: "#fff",
    width: "fit-content",
    left: "-70px",
    boxShadow: "0px 0px 6px rgba(87,148,201,0.18)",
    zIndex: "9999999" as CSSProperties["zIndex"],
    padding: "15px",
  },
  option1: {
    // marginBottom: "15px" as CSSProperties["marginBottom"],
  },
  rowList: {
    listStyle: "none",
  },
  selectBoxes: {
    marginTop: "30px",
    marginBottom: "30px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  searchBtn: {
    height: "40px",
    background: "#2B6FED",
    borderColor: "#2B6FED",
    fontSize: "14px",
    boxShadow: "unset",
  },
  searchText: {
    marginLeft: "10px",
  },
  tableContainer: {
    background: "#fff",
    margin: "20px 20px",
  },
  tableHeader: {
    padding: "30px",
    borderBottom: "1px solid rgba(0,0,0,0.1)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tableFooter: {
    padding: "30px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tableHeading: {
    fontSize: "22px",
  },
  searchInput: {
    border: "1px solid rgba(0,0,0,0.2)",
    borderRadius: "8px",
    height: "40px",
    padding: "0px",
    color: "#212121",
  },
  searchIconButton: {
    padding: "0px",
  },
  innerInput: {
    padding: "0px",
    color: "#212121",
  },
  subBox: {
    display: "flex",
    width: "100%",
    margin: 0,
    flexDirection: "row",
    flexWrap: "wrap",
  },
  searchBtnmain: {
    fontWeight: "bold",
    borderRadius: "10px",
    padding: "10px 20px",
    height: "55px",
    width: "150px",
    fontSize: "15px",
    backgroundColor:"rgb(43, 111, 237)"
  },
 
};

export default withTranslation()(
  withStyles(ReportsStyleWeb)(withRouter(COOIncidentListing))
);

// Customizable Area End
