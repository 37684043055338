// Customizable Area Start
import React from "react";
import "./Dashboard.web.css";
import { communityManagement, meetings, myTeam, TenantLogo } from "./assets";
import "../../../web/src/assets/css/style.scss";
import { Typography, withStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIosOutlined";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { withRouter } from "react-router-dom";
import DashboardController, { Props } from "./DashboardController.web";
import { useTranslation,withTranslation } from "react-i18next";
import { MyTeamImageComponent } from "./SidebarImages/MyTeamImage.web";
import { DashboardImageComponent } from "./SidebarImages/DashboardImage.web";
import { CommunityImageComponent } from "./SidebarImages/CommunityImage.web";
import { InvoiceImageComponent } from "./SidebarImages/InvoiceImage.web";
import { ComplexImageComponent } from "./SidebarImages/ComplexImage.web";
import { MeetingImageComponent } from "./SidebarImages/MeetingImage.web";
import { PollImageComponent } from "./SidebarImages/PollImage.web";
import { DocumentImageComponent } from "./SidebarImages/DocumentImage.web";
import { ChatImageComponent } from "./SidebarImages/ChatImage.web";
import { HelpImageComponent } from "./SidebarImages/HelpImage.web";
import { ROLE } from "../../../framework/src/Enum";
import {languageCondition} from "../../../components/src/ConditionManager.web";

const MyAccordionDetails = ({ children, onClick, sidebarColorHandle }: any) => {
  return (
    <AccordionDetails onClick={onClick} className="MyAccordianDeailsMainComponent">
      <Typography variant="body2" className={`cursor-pointer ${sidebarColorHandle}`}>
        {children}
      </Typography>
    </AccordionDetails>
  );
};

const MyAccordion = ({ language, expanded, onChange, id, ListItemText, ListItemIcon, children, className }: any) => {
  return (
    <Accordion expanded={expanded} onChange={onChange} className={languageCondition(language, "arabicIconChange", "AccordianComponentMain")}>
      <AccordionSummary
        className="AccordianComponentMainSummary"
        expandIcon={languageCondition(language,<ArrowBackIosIcon className="arabicSideBarIcon" style={{ width: 16, height: 16 }} />,<ArrowForwardIosIcon style={{ width: 16, height: 16 }} />)}
        id={id}
        style={dashBoardStyle.ListItem}
      >
        <Typography className={`SingleLinkSize ${className}`} id="AccordianComponentMainTypography">
          {ListItemIcon}
        </Typography>
        <Typography className={`ListItemText bold-text ${className}`} id="AccordianComponentMainTypography1">
          {ListItemText}
        </Typography>
      </AccordionSummary>
      {children}
    </Accordion>
  );
};

class ChairmanSidebar extends DashboardController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    this.getUnreadCount();
  }

  sidebarColorHandle = (pathName: any, paths: any) => {
    for (const path of paths) {
      if (pathName.toString().includes(path.toString())) {
        return "highlight";
      }
    }
  };

  sidebarBlueColorHandle = (pathName: any, paths: any) => {
    for (const path of paths) {
      if (pathName.toString().includes(path.toString())) {
        return "highlight-blue";
      }
    }
  };

  render() {
    const { t,i18n }: any = this.props;
    // @ts-ignore
    const language = i18n?.language
    const pathName = window.location.pathname;
    const { expanded } = this.state;

    return (
      <>
        <Box className="AccordinoList">
          {/* Dashboards */}
          <MyAccordion
            language={language}
            expanded={expanded === "panel1"}
            onChange={this.handleAccordinoChange("panel1")}
            id="Dashboards"
            ListItemText={t("My Dashboards")}
            ListItemIcon={<DashboardImageComponent />}
            className={this.sidebarBlueColorHandle(pathName, [
              "/DashboardTicket",
              "/DashboardGeneral",
              "/VisitorList",
              "/DashboardBudget",
              "/mv",
              "/IncidentManagement",
              "/DashboardActions",
              "/FacilityManagerDetail",
              "/ManagerFacilityReservation",
              "/DashboardActions",
              "/AvgResolutionTicket",
              "/BudgetDetails",
              "/ClassifiedManagerListing",
            ])}
          >
            <MyAccordionDetails
              className="DashboardGeneralDetailsAccordian"
              onClick={() => this.props.history.push("/DashboardGeneral")}
              sidebarColorHandle={this.sidebarColorHandle(pathName, [
                "/DashboardGeneral",
                "/mv",
                "/IncidentManagement",
                "/FacilityManagerDetail",
                "/ManagerFacilityReservation",
                "/ClassifiedManagerListing",
              ])}
            >
              {t("General Dashboard")}
            </MyAccordionDetails>
            <MyAccordionDetails
              className="DashboardTicketAccordion"
              onClick={() => this.props.history.push("/DashboardTicket")}
              sidebarColorHandle={this.sidebarColorHandle(pathName, ["/DashboardTicket", "/AvgResolutionTicket"])}
            >
              {t("Ticket Dashboard")}
            </MyAccordionDetails>
            <MyAccordionDetails
              className="DashboardActionsDashboardBudget"
              onClick={() => this.props.history.push("/DashboardBudget")}
              sidebarColorHandle={this.sidebarColorHandle(pathName, ["/DashboardBudget", "/BudgetDetails"])}
            >
              {t("Budget Dashboard")}
            </MyAccordionDetails>
            <MyAccordionDetails
              className="DashboardActions"
              onClick={() => this.props.history.push("/DashboardActions")}
              sidebarColorHandle={this.sidebarColorHandle(pathName, ["/DashboardActions"])}
            >
              {t("Actions Assigned to me")}
            </MyAccordionDetails>
            {localStorage.getItem("userType") === ROLE.MANAGER && (
              <MyAccordionDetails
                className="VisitorDashboard"
                onClick={() => this.props.history.push("/VisitorList")}
                sidebarColorHandle={this.sidebarColorHandle(pathName, ["/VisitorList"])}
              >
                {t("Visitors Dashboard")}
              </MyAccordionDetails>
            )}
          </MyAccordion>
          {/* My Team */}
          <MyAccordion
            language={language}
            expanded={expanded === "panel2"}
            onChange={this.handleAccordinoChange("panel2")}
            id="MyTeams"
            className={this.sidebarBlueColorHandle(pathName, [
              "/TeamMembers",
              "/Nominations",
              "/TaskManagement",
              "/TeamMember/userDetails",
              "/TeamMembers/",
            ])}
            ListItemText={t("My Team")}
            ListItemIcon={<MyTeamImageComponent />}
          >
            <MyAccordionDetails
              onClick={() => this.props.history.push("/TeamMembers")}
              sidebarColorHandle={this.sidebarColorHandle(pathName, [
                "/TeamMembers",
                "/TeamMember/userDetails",
                "/TeamMembers/",
              ])}
              className="TeamMembersAccordianDetails"
            >
              {t("Team Members")}
            </MyAccordionDetails>
            <MyAccordionDetails
              className="TeamMembersAccordianChairmanNomination"
              onClick={() => this.props.history.push("/Nominations")}
              sidebarColorHandle={this.sidebarColorHandle(pathName, ["/Nominations"])}
            >
              {t("Chairman and Vice Chairman Nomination")}
            </MyAccordionDetails>
            <MyAccordionDetails
              className="TeamMembersAccordianChairmanTaskManagement"
              onClick={() => this.props.history.push("/TaskManagement")}
              sidebarColorHandle={this.sidebarColorHandle(pathName, ["/TaskManagement"])}
            >
              {t("Task Management")}
            </MyAccordionDetails>
          </MyAccordion>
          {/* Community Management */}
          <MyAccordion
            language={language}
            expanded={expanded === "panel3"}
            onChange={this.handleAccordinoChange("panel3")}
            id="CommunityManagement"
            ListItemText={t("Community Management")}
            ListItemIcon={<CommunityImageComponent />}
            className={this.sidebarBlueColorHandle(pathName, [
              "/CommunityRequestManagement",
              "/PendingRequest",
              "/AwaitingAcceptece",
              "/SentInvitation",
              "/Suggestions",
              "/SuggestionDetails",
              "/CommunityUserProfile",
              "/Announcements",
              "/AnnouncementDetails",
              "/CommunityManagement",
              "/UserDetailedProfile",
              "/ga_member",
              "/resident",
              "/owner",
              "/property_manager",
            ])}
          >
            <MyAccordionDetails
              className="CommunityManagementUserProfile"
              onClick={() => this.props.history.push("/CommunityUserProfile")}
              sidebarColorHandle={this.sidebarColorHandle(pathName, [
                "/CommunityUserProfile",
                "/UserDetailedProfile",
                "/ga_member",
                "/resident",
                "/owner",
                "/property_manager",
              ])}
            >
              {t("User Profiles")}
            </MyAccordionDetails>
            <MyAccordionDetails
              className="CommunityManagementRequestManagement"
              onClick={() => this.props.history.push("/CommunityRequestManagement")}
              sidebarColorHandle={this.sidebarColorHandle(pathName, [
                "/CommunityRequestManagement",
                "/PendingRequest",
                "/AwaitingAcceptece",
                "/SentInvitation",
              ])}
            >
              {t("Request Management")}
            </MyAccordionDetails>
            <MyAccordionDetails
              className="CommunityManagementAnnouncements"
              onClick={() => this.props.history.push("/Announcements")}
              sidebarColorHandle={this.sidebarColorHandle(pathName, ["/Announcements"])}
            >
              {t("Announcements")}
            </MyAccordionDetails>
            <MyAccordionDetails
              className="CommunityManagementSuggestion"
              onClick={() => this.props.history.push("/Suggestions")}
              sidebarColorHandle={this.sidebarColorHandle(pathName, ["/Suggestions", "/SuggestionDetails"])}
            >
              {t("Suggestion")}
            </MyAccordionDetails>
          </MyAccordion>
          {/* Invoices & Receipts */}
          <MyAccordion
            expanded={expanded === "panel4"}
            onChange={this.handleAccordinoChange("panel4")}
            id="Invoice&Receipt"
            language={language}
            ListItemText={t("Invoices & Receipts")}
            ListItemIcon={<InvoiceImageComponent />}
            className={this.sidebarBlueColorHandle(pathName, ["/CharmainInvoices", "/CharmainReceipts", "/PaymentHistory"])}
          >
            <MyAccordionDetails
              className="InvoiceAndReceiptAccordianChairmanInvoices"
              onClick={() => this.props.history.push("/CharmainInvoices")}
              sidebarColorHandle={this.sidebarColorHandle(pathName, ["/CharmainInvoices"])}
            >
              {t("Invoices")}
            </MyAccordionDetails>
            <MyAccordionDetails
              className="InvoiceAndReceiptAccordianChairmanReceipts"
              onClick={() => this.props.history.push("/CharmainReceipts")}
              sidebarColorHandle={this.sidebarColorHandle(pathName, ["/CharmainReceipts"])}
            >
              {t("Receipts")}
            </MyAccordionDetails>
            <MyAccordionDetails
              className="InvoiceAndReceiptAccordianPaymentHistory"
              onClick={() => this.props.history.push("/PaymentHistory")}
              sidebarColorHandle={this.sidebarColorHandle(pathName, ["/PaymentHistory"])}
            >
              {t("Payment History")}
            </MyAccordionDetails>
          </MyAccordion>
          {/* Buildings & Apartments */}
          <Box className="SingleLink ComplexSingleLink">
            <Typography
              className={`SingleLinkSize ComplexSingleLinkTypography complex-icon ${this.sidebarBlueColorHandle(
                pathName,
                ["Complex", "Building", "UnitDetail", "SharedArea"]
              )}`}
            >
              <ComplexImageComponent />
            </Typography>
            <div onClick={() => this.props.history.push("/Complex")} className="ComplexDiv">
              <Typography
                className={`SingleLinkSize ComplexSingleLinkTypography1  bold-text complex-building-link ${this.sidebarBlueColorHandle(
                  pathName,
                  ["Complex", "Building", "UnitDetail", "SharedArea"]
                )}`}
              >
                {t("Complex & Apartments")}
              </Typography>
            </div>
          </Box>
          {/* Meetings */}
          <MyAccordion
            language={language}
            expanded={expanded === "panel5"}
            onChange={this.handleAccordinoChange("panel5")}
            id="Meetings MeetingsAccordian"
            ListItemText={t("Meeting")}
            ListItemIcon={<MeetingImageComponent />}
            className={this.sidebarBlueColorHandle(pathName, ["MeetingMinute", "ScheduledMeeting"])}
          >
            <MyAccordionDetails
              className="MeetingsAccordian"
              onClick={() => this.props.history.push("/ScheduledMeetings")}
              sidebarColorHandle={this.sidebarColorHandle(pathName, ["ScheduledMeeting"])}
            >
              {t("Scheduled Meetings")}
            </MyAccordionDetails>
            <MyAccordionDetails
              className="ScheduledMeetingsAccordian"
              onClick={() => this.props.history.push("/MeetingMinutes")}
              sidebarColorHandle={this.sidebarColorHandle(pathName, ["/MeetingMinute"])}
            >
              {t("Meeting Minutes")}
            </MyAccordionDetails>
          </MyAccordion>
          {/* Poll / Survey */}
          <Box className="SingleLink PollingSingleLink">
            <Typography
              className={`SingleLinkSize PollingSingleLinkTypography polling-icon ${pathName === "/Polling" &&
                "highlight-blue"}`}
            >
              <PollImageComponent />
            </Typography>
            <div onClick={() => this.props.history.push("/Polling")} className="PollingSingleLinkDiv">
              <Typography
                className={`SingleLinkSize PollingSingleLinkTypography1 bold-text polling-link ${pathName ===
                  "/Polling" && "highlight-blue"}`}
              >
                {t("Poll/Survey")}
              </Typography>
            </div>
          </Box>
          {/* Document & Reports */}
          <MyAccordion
            language={language}
            expanded={expanded === "panel6"}
            onChange={this.handleAccordinoChange("panel6")}
            id="Document&Report"
            ListItemText={t("Documents & Reports")}
            ListItemIcon={<DocumentImageComponent />}
            className={this.sidebarBlueColorHandle(pathName, ["/DocumentChairman", "/Reports","/DocumentChairman","/BudgetReports","/ExpenseReports","/ManagementFeeReports","/AuditReports","/InvitationReport","/ExpenseReportDetails","/BudgetReportDetails","/ManagementFeeReportDetails","/AuditReportDetails"])}
          >
            <MyAccordionDetails
              className="DocumentsChairmanAccordian"
              onClick={() => this.props.history.push("/DocumentChairman")}
              sidebarColorHandle={this.sidebarColorHandle(pathName, ["/DocumentChairman"])}
            >
              {t("Document")}
            </MyAccordionDetails>
            <MyAccordionDetails
              className="ReportsChairmanAccordian"
              onClick={() => this.props.history.push("/Reports")}
              sidebarColorHandle={this.sidebarColorHandle(pathName, ["/Reports","/BudgetReports","/ExpenseReports","/ManagementFeeReports","/AuditReports","/InvitationReport","/ExpenseReportDetails","/BudgetReportDetails","/ManagementFeeReportDetails","/AuditReportDetails"])}
            >
              {t("Report")}
            </MyAccordionDetails>
          </MyAccordion>
          {/* Chat */}
          <Box className="SingleLink ChatSingleLink">
            <Typography
              className={`SingleLinkSize ChatSingleLinkTypography ${pathName === "/ChairmanChat" && "highlight-blue"}`}
            >
              <ChatImageComponent />
            </Typography>
            <div onClick={() => this.props.history.push("/ChairmanChat")} className="ChatSingleLinkDiv" style={{ display : 'flex', gap: 10 }}>
              <Typography
                className={`SingleLinkSize bold-text ChatSingleLinkTypography1 ${pathName === "/ChairmanChat" &&
                  "highlight-blue"}`}
              >
                {t("Chat")}
              </Typography>
              <Typography variant='body2' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: 20, minHeight: 15, maxHeight: 20, padding: 5, borderRadius: '50%', background: '#fc853614', color: '#FC8434' }}>{this.state.unReadCount}</Typography>
            </div>
          </Box>
          {/* Help */}
          <MyAccordion
            language={language}
            expanded={expanded === "panel7"}
            onChange={this.handleAccordinoChange("panel7")}
            id="Help"
            ListItemText={t("Help")}
            ListItemIcon={<HelpImageComponent />}
            className={this.sidebarBlueColorHandle(pathName, [
              "/SubscriptionDetail",
              "/FaqChairman",
              "/ContactUsChairman",
            ])}
          >
            <MyAccordionDetails
              className="SubscriptionDetailsAccordian"
              onClick={() => this.props.history.push("/SubscriptionDetail")}
              sidebarColorHandle={this.sidebarColorHandle(pathName, ["/SubscriptionDetail"])}
            >
              {t("Subscription")}
            </MyAccordionDetails>
            <MyAccordionDetails
              className="FAQAccordianMAIN"
              onClick={() => this.props.history.push("/FaqChairman")}
              sidebarColorHandle={this.sidebarColorHandle(pathName, ["/FaqChairman"])}
            >
              {t("Frequently asked questions")}
            </MyAccordionDetails>
            <MyAccordionDetails
              className="ContactUSAccordian"
              onClick={() => this.props.history.push("/ContactUsChairman")}
              sidebarColorHandle={this.sidebarColorHandle(pathName, ["/ContactUsChairman"])}
            >
              {t("Contact Us")}
            </MyAccordionDetails>
          </MyAccordion>
        </Box>
        <Box className="SideBarBottom BottomBarPremium">
          <Box>
            <Typography style={{ fontSize: 10, fontWeight: 600 }}>
              <Box component="span" style={languageCondition(language,dashBoardStyle.PremiumPlan_ar,dashBoardStyle.PremiumPlan)}>
                {t("Premium")}
              </Box>
              {t("Plan")}
            </Typography>
            <Typography style={{ fontSize: 12, marginTop: 10 }}>
              {t("Expires in")} 125 {t("days")}
            </Typography>
          </Box>
          <Box>
            <img src={TenantLogo.default} alt="TenantLogo" width={110} />
          </Box>
        </Box>
      </>
    );
  }
}

const dashBoardStyle = {
  PremiumPlan: {
    background: "#ff8100",
    padding: 8,
    borderRadius: "5px",
    marginRight: 8,
  },
  PremiumPlan_ar: {
    background: "#ff8100",
    padding: 8,
    borderRadius: "5px",
    marginLeft: 8,
  },
  ListItem: {
    marginTop: "20px",
    alignItems: "center",
  },
  ListItemText: {
    marginLeft: 15,
    fontSize: 14,
  },
  Item: {
    cursor: "pointer",
  },
};

//@ts-ignore
export default withTranslation()(withStyles(dashBoardStyle)(withRouter(ChairmanSidebar)));
// Customizable Area End
